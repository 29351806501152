import React, { useCallback } from 'react';
import NumberFormat from 'react-number-format';

const RenderFieldNumber = (props) => {
  const { inputRef, input: { onChange } = {}, ...other } = props;

  const onValueChange = useCallback(
    (val) => {
      onChange?.(val.value);
    },
    [onChange],
  );

  return (
    <NumberFormat
      {...other}
      {...props?.field}
      prefix="$"
      autoFocus
      placeholder="Enter revenue ($)"
      isNumericString
      thousandSeparator
      fixedDecimalScale
      decimalScale={2}
      getInputRef={inputRef}
      onValueChange={onValueChange}
      maxLength={13}
    />
  );
};
export default RenderFieldNumber;
