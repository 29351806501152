import { applyMiddleware, createStore, compose } from 'redux';
import reducers from '../reducers';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createOffline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';
import { invoke } from './apiRequest';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['offline', 'session', 'viewer'],
};
const effect = (effect, _action) => invoke(effect.apiInfo, effect.data, {}, effect.apiInfo.token);
const discard = (error, _action, _retries) => {
  const { request, response } = error;
  if (!request) throw error; // There was an error creating the request
  if (!response) return false; // There was no response
  return 400 <= response.status && response.status <= 500;
};
const {
  middleware: offlineMiddleware,
  enhanceReducer: offlineEnhanceReducer,
  enhanceStore: offlineEnhanceStore,
} = createOffline({
  ...offlineConfig,
  persist: false,
  effect,
  discard,
});
const persistedReducer = persistReducer(persistConfig, offlineEnhanceReducer(reducers));
const composeEnhancers =
  (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const middlewares = [thunk, offlineMiddleware];

export const store = createStore(
  persistedReducer,
  composeEnhancers(offlineEnhanceStore, applyMiddleware(...middlewares)),
);

export const persistor = persistStore(store);
