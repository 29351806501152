import React from 'react';
import { Field, reduxForm } from 'redux-form';

import '../../assets/css/dialog.css';
import '../../assets/css/forms.css';
import renderFieldNormal from '../shared/RenderFieldNormal';
import { required, otpValidation } from '../../helpers';

let OtpForm = ({ handleSubmit, onSubmit, onReturnKeyPress, valid }) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset className="login-user forgot-password">
        <Field
          component={renderFieldNormal}
          onReturnKeyPress={onReturnKeyPress}
          type="text"
          placeholder="Verification code"
          name="code"
          label="Verification code"
          validate={[required, otpValidation]}
          showError="true"
          maxLength="6"
          autoFocus={true}
        />
      </fieldset>
      <button type="submit" className="button-primary" disabled={!valid}>
        {' '}
        Submit Verification code
      </button>
    </form>
  );
};

OtpForm = reduxForm({
  // a unique name for the form
  form: 'OtpForm',
})(OtpForm);

export default OtpForm;
