import { pick } from 'lodash';

import { getViewerFromAPI, getReportsData } from '../../services/viewer/viewer.service';
import {
  API_URLS,
  LOAD_VIEWER,
  LOAD_OVERVIEW_CHARTS,
  LOAD_OVERVIEW_TABLE,
  UPDATE_REPORT_OF,
  yes,
  GROUP,
  REPORT_CASES_KEYVALUE,
  RESET_REPORT_OF,
  sharedStatus,
  LOAD_HIGHLIGHTS_DATA,
  CLIENTS_TABLE_REQUEST,
  CLIENTS_TABLE_SUCCESS,
  CLIENTS_TABLE_FAILURE,
  TARGETS_TABLE_REQUEST,
  TARGETS_TABLE_SUCCESS,
  TARGETS_TABLE_FAILURE,
  OVERLAPS_TABLE_REQUEST,
  OVERLAPS_TABLE_SUCCESS,
  OVERLAPS_TABLE_FAILURE,
  VIEWER_DATA_REQUEST,
  VIEWER_DATA_RECEIVED,
  PROGRESS_TABLE_FAILURE,
  PROGRESS_TABLE_SUCCESS,
  API_METHODS,
  WORKSHEETS_TABLE_SUCCESS,
  WORKSHEETS_TABLE_FAILURE,
  USER_WORKSHEETS_SUCCESS,
  USER_WORKSHEETS_FAILURE,
} from '../../constant';
import { showLoader, hideLoader } from '../shared/loader.action';
import { invoke } from '../../helpers';

/**
 * get lessons from Api
 * @param { boolean } fromLogin - { true, false } is api called from after logged i.e. first time after logged on device
 */
export const getViewer = () => (dispatch, getState) => {
  dispatch(showLoader());
  dispatch({ type: VIEWER_DATA_REQUEST });
  return getViewerFromAPI()
    .then((response) => {
      let { graphOf = {} } = getState().viewer;
      let item = {};
      if (response.org && response.org.access === yes) {
        item = response.org;
        item.key = REPORT_CASES_KEYVALUE.org;
      } else if (response.groups && response.groups.length) {
        item = response.groups[0];
        item.key = REPORT_CASES_KEYVALUE.group;
      }
      let org = [response.org];
      if (!response.org) {
        org = [];
      }
      const mergedata = response.groups && response.groups.length > 0 ? response.groups.concat(org) : org;
      const IsExist = mergedata.findIndex((i) => i.id === graphOf.id);
      if (IsExist === -1) {
        graphOf = {
          id: item.id,
          key: item.key,
          type: item.type,
        };
      }
      dispatch(hideLoader());
      response.graphOf = graphOf;
      response.which = 'score';
      dispatch({ type: VIEWER_DATA_RECEIVED });
      dispatch({
        type: LOAD_VIEWER,
        payload: response,
      });
    })
    .catch(() => {
      dispatch(hideLoader());
    });
};
/**
 * action creator for fetching the overview charts data and load in state
 * @param {*} selectedType
 * @param {*} range
 */
export const getOverviewCharts = (selectedType, range) => (dispatch) => {
  dispatch({ type: VIEWER_DATA_REQUEST });
  return getReportsData(selectedType, API_URLS.CHARTS, range)
    .then((response) => {
      dispatch({
        type: LOAD_OVERVIEW_CHARTS,
        payload: response,
      });
      dispatch({ type: VIEWER_DATA_RECEIVED });
      dispatch(hideLoader());
    })
    .catch(() => {
      dispatch(hideLoader());
    });
};

/**
 * action creator for fetching the overview table data and load in state
 * @param {*} selectedType
 * @param {*} range
 */
export const getOverviewTable = (selectedType, type, range) => (dispatch) => {
  dispatch(showLoader());
  return getReportsData(selectedType, type, range)
    .then((response) => {
      response.org.type = REPORT_CASES_KEYVALUE.org;
      if (selectedType.type === GROUP) {
        if (response.group) {
          response.group.type = selectedType.type;
          response.group.members = response.group.members ? response.group.members : [];
        }
      } else {
        response.groups = response.groups ? response.groups : [];
      }
      dispatch(hideLoader());
      dispatch({
        type: LOAD_OVERVIEW_TABLE,
        payload: response,
      });
    })
    .catch(() => {
      dispatch(hideLoader());
    });
};

export const updateGraphOf = (id, type) => (dispatch) => {
  const graphOf = {
    id: id,
    key: type === GROUP ? REPORT_CASES_KEYVALUE.group : REPORT_CASES_KEYVALUE.org,
    type: type,
  };
  dispatch({
    type: UPDATE_REPORT_OF,
    payload: graphOf,
  });
};

/**
 *@description reset graph data while switching the user
 */
export const resetGraphOf = () => (dispatch) => {
  dispatch({
    type: RESET_REPORT_OF,
  });
};

/**
 * @description get hightreport data
 */
export const getHighlightsData = (selectedType, range) => (dispatch) => {
  dispatch({ type: VIEWER_DATA_REQUEST });
  return getReportsData(selectedType, API_URLS.HIGHLIGHTS, range)
    .then((response) => {
      // filter data based on the keys getting in reponse
      const highlightsPeopleKeys = [
        'mostProactive',
        'strongestCloser',
        'greatestPotential',
        'top_collaborator',
        'top_contributor',
      ];
      const highlightsStatsKeys = ['deadlines', 'types', 'value', 'cross_selling', 'effective_communication'];
      const highlightsPeople = pick(response, highlightsPeopleKeys);
      const highlightsStats = pick(response, highlightsStatsKeys);

      dispatch({ type: VIEWER_DATA_RECEIVED });
      dispatch({
        type: LOAD_HIGHLIGHTS_DATA,
        payload: {
          highlightsPeople,
          highlightsStats,
        },
      });
    })
    .catch(() => dispatch({ type: VIEWER_DATA_RECEIVED }));
};

export const getTargetsData = (selectedType, range) => (dispatch) => {
  dispatch({ type: TARGETS_TABLE_REQUEST });
  return getReportsData(selectedType, API_URLS.TARGETS, range)
    .then((response) => {
      const data = response
        .filter((item) => !(item.shared === sharedStatus.rejected || item.shared === sharedStatus.revoked))
        .map((target) => {
          target.targetName = `${target.contact} >> ${target.company}`;
          target.steps = target.tasks ? target.tasks.length : 0;
          target.collaborators = target.collaborators.filter(
            (collaborator) =>
              collaborator.response === sharedStatus.pending || collaborator.response === sharedStatus.accepted,
          );
          target.isShared = target.collaborators.length;
          if (!target.isShared && target.tasks.length) target.collaborators = [target.tasks[0]];
          return target;
        });

      dispatch({
        type: TARGETS_TABLE_SUCCESS,
        payload: data,
      });
    })
    .catch(() => dispatch({ type: TARGETS_TABLE_FAILURE }));
};

export const getClientsData = (selectedType) => (dispatch) => {
  dispatch({ type: CLIENTS_TABLE_REQUEST });
  return getReportsData(selectedType, API_URLS.CLIENTS)
    .then((response) => {
      dispatch({
        type: CLIENTS_TABLE_SUCCESS,
        payload: response,
      });
    })
    .catch(() => dispatch({ type: CLIENTS_TABLE_FAILURE }));
};

export const getOverlapsData = (selectedType) => (dispatch) => {
  dispatch({ type: OVERLAPS_TABLE_REQUEST });
  return getReportsData(selectedType, API_URLS.OVERLAPS)
    .then((response) => {
      // in case we are getting full name, then split in first_name and last_name
      const data = response.map((item) => {
        item.members = item.members.map((member) => {
          if (member.name) {
            const [first_name, last_name] = member.name.split(' ');
            member.first_name = first_name;
            member.last_name = last_name;
          }
          return member;
        });
        return item;
      });
      dispatch({
        type: OVERLAPS_TABLE_SUCCESS,
        payload: data,
      });
    })
    .catch(() => dispatch({ type: OVERLAPS_TABLE_FAILURE }));
};

export const getProgressData = (url) => async (dispatch) => {
  dispatch({ type: CLIENTS_TABLE_REQUEST });
  try {
    const response = await invoke({ url, type: API_METHODS.GET });
    dispatch({ type: PROGRESS_TABLE_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: PROGRESS_TABLE_FAILURE });
  }
};

export const getWorksheetsData = (url) => async (dispatch) => {
  dispatch({ type: CLIENTS_TABLE_REQUEST });
  try {
    const response = await invoke({ url, type: API_METHODS.GET });
    dispatch({ type: WORKSHEETS_TABLE_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: WORKSHEETS_TABLE_FAILURE });
  }
};

export const getUserWorksheetsData = (url, userId) => async (dispatch) => {
  const options = { proxyUser: userId };
  const params = { userid: userId };
  dispatch({ type: CLIENTS_TABLE_REQUEST });
  try {
    const response = await invoke(
      {
        url,
        type: API_METHODS.GET,
        params: params,
      },
      options,
    );
    dispatch({ type: USER_WORKSHEETS_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: USER_WORKSHEETS_FAILURE });
  }
};
