import React, { useCallback } from 'react';

import { LESSON_STATUS } from '../../../../constant/apiEndPoints';
import icons from '../../../../assets/img/icons.svg';

let FilterLessons = (props) => {
  const { filterBy, keyword, lessons } = props;

  /**
   * @description filter targets
   * @param {object} $filterBy - {filterBy, value}
   */
  const filterTarget = (filterBy) => {
    if (filterBy) props.filterTarget(filterBy);
  };

  const lessonType = useCallback(
    (type) => {
      switch (type) {
        case LESSON_STATUS.NOT_STARTED:
          return lessons.filter((lesson) => lesson.watched?.seconds === 0).length;
        case LESSON_STATUS.NOT_COMPLETED:
          return lessons.filter((lesson) => lesson.watched?.seconds > 0 && lesson.watched?.done === 'no').length;
        case LESSON_STATUS.COMPLETED:
          return lessons.filter((lesson) => lesson.watched?.done === 'yes').length;
        case LESSON_STATUS.WORKSHEET:
          return lessons.filter((lesson) => lesson.worksheet !== '').length;
        case LESSON_STATUS.NO_WORKSHEET:
          return lessons.filter((lesson) => lesson.worksheet === '').length;
        default:
          return lessons.length;
      }
    },
    [lessons],
  );

  return (
    <>
      <div className="info-block search">
        <fieldset>
          <legend>
            <svg className="icon">
              <use xlinkHref={`${icons}#action-search`} />
            </svg>
            Search
          </legend>
          <div className="search">
            <input
              type="text"
              placeholder="Search"
              className="clearable kwrd-srch"
              onChange={props.updateSearchedKeyword}
              value={props.keyword}
              onKeyUp={props.searchData}
            />
            <svg className="icon">
              <use xlinkHref={`${icons}#action-search`} />
            </svg>
          </div>
        </fieldset>
      </div>

      <div className="info-block filter">
        <fieldset>
          <legend>
            <svg className="icon">
              <use xlinkHref={`${icons}#action-filter`} />
            </svg>
            Filter
          </legend>

          <ul className="button-bar ui full-width value">
            <li
              onClick={() => filterTarget({ filterBy: 'value', value: LESSON_STATUS.NOT_STARTED })}
              className={`${filterBy.value === LESSON_STATUS.NOT_STARTED ? 'active has-marker' : 'has-marker'}`}
            >
              <a href={undefined}>
                Not Started <label className="marker overdue">{lessonType(LESSON_STATUS.NOT_STARTED)}</label>
              </a>
            </li>
            <li
              onClick={() => filterTarget({ filterBy: 'value', value: LESSON_STATUS.NOT_COMPLETED })}
              className={`${filterBy.value === LESSON_STATUS.NOT_COMPLETED ? 'active has-marker' : 'has-marker'}`}
            >
              <a href={undefined}>
                Started (Not Completed) <label className="marker soon">{lessonType(LESSON_STATUS.NOT_COMPLETED)}</label>
              </a>
            </li>
            <li
              onClick={() => filterTarget({ filterBy: 'value', value: LESSON_STATUS.COMPLETED })}
              className={`${filterBy.value === LESSON_STATUS.COMPLETED ? 'active has-marker' : 'has-marker'}`}
            >
              <a href={undefined}>
                Completed <label className="marker later">{lessonType(LESSON_STATUS.COMPLETED)}</label>
              </a>
            </li>
          </ul>

          <ul className="button-bar ui full-width type">
            <li
              onClick={() => filterTarget({ filterBy: 'type', value: LESSON_STATUS.WORKSHEET })}
              className={`${filterBy.value === LESSON_STATUS.WORKSHEET ? 'active has-marker' : 'has-marker'}`}
            >
              <a href={undefined}>
                Worksheet <label className="marker">{lessonType(LESSON_STATUS.WORKSHEET)}</label>
              </a>
            </li>
            <li
              onClick={() => filterTarget({ filterBy: 'type', value: LESSON_STATUS.NO_WORKSHEET })}
              className={`${filterBy.value === LESSON_STATUS.NO_WORKSHEET ? 'active has-marker' : 'has-marker'}`}
            >
              <a href={undefined}>
                No Worksheet <label className="marker">{lessonType(LESSON_STATUS.NO_WORKSHEET)}</label>
              </a>
            </li>
          </ul>

          <ul className="button-bar ui full-width reset">
            <li
              onClick={() => filterTarget({ filterBy: 'all', value: 'all' })}
              className={`show-all ${filterBy.value === 'all' && !keyword ? 'active has-marker' : 'has-marker'}`}
            >
              <a href={undefined}>
                <svg className="icon">
                  <use xlinkHref={`${icons}#action-reset`} />
                </svg>{' '}
                Show All Lessons
                {`${filterBy.value !== 'all' ? ' (Reset)' : ''}`}
              </a>
            </li>
          </ul>
        </fieldset>
      </div>
    </>
  );
};

export default FilterLessons;
