import { SHOW_COLLABORATORS_DIALOG, HIDE_COLLABORATORS_DIALOG, USER_SELECTION } from '../../constant';

const initialState = { open: false };

const selectUserAutoSuggest = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_COLLABORATORS_DIALOG:
    case HIDE_COLLABORATORS_DIALOG:
      return { ...action.payload };
    case USER_SELECTION:
      return { ...action.payload, tileId: state.tileId };
    default:
      return state;
  }
};

export default selectUserAutoSuggest;
