import React from 'react';

import TileCollapse from '../../TileCollapse.component';
import WorksheetForm from './WorksheetForm.component';

const WorksheetItem = ({ detail, ...props }) => (
  <TileCollapse detail={detail}>
    <WorksheetForm {...detail.worksheet} lessonId={detail.id} {...props} />
  </TileCollapse>
);

export default WorksheetItem;
