import React from 'react';

import icons from '../../assets/img/icons.svg';

const Icon = (props) => {
  return (
    <svg className={`icon ${props.customClass}`}>
      <use xlinkHref={`${icons}#${props.iconId}`} />
    </svg>
  );
};

export default Icon;
