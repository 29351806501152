import React from 'react';

const renderFieldRadio = ({ input, type, label }) => {
  return (
    <li className={`${input.checked ? 'active' : ''}`}>
      <a>{label}</a>
      <input type={type} {...input} />
    </li>
  );
};

export default renderFieldRadio;
