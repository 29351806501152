import { createSelector } from 'reselect';

import { setBadgeCount } from '../../services/shared/badgeCount.service';
import history from '../../helpers/history';
import { API_PARAMS } from '../../constant';

// a varible to check the targets to be filtered
// in case of people targets it will be true.
let filterPeopleTargets = false;
let status = '';

const getTargets = (state) => {
  const allTargets = filterPeopleTargets ? state.people.targets : state.dashBoard.targets;
  return allTargets.filter((target) => target.status === status);
};
const getCount = (array, key, value) => {
  const count = array.filter((item) => item[key] === value).length;
  let status;
  if (history.location.pathname) {
    status = history.location.pathname.split('/').pop();
  }
  if (status === API_PARAMS.ACTIVE && value === 'overdue') {
    setBadgeCount(count);
  }
  return count;
};

// get targets count
const getCountTargets = createSelector(getTargets, (targets) => {
  filterPeopleTargets = false;
  return {
    standing: {
      total: targets.length,
      overdue: getCount(targets, 'bucket', 'overdue'),
      soon: getCount(targets, 'bucket', 'soon'),
      later: getCount(targets, 'bucket', 'later'),
    },
    value: {
      L: getCount(targets, 'value', 'L'),
      M: getCount(targets, 'value', 'M'),
      H: getCount(targets, 'value', 'H'),
    },
    type: {
      client: getCount(targets, 'type', 'client'),
      prospect: getCount(targets, 'type', 'prospect'),
      refsource: getCount(targets, 'type', 'refsource'),
    },
  };
});

export default (state, isPeopleTarget, tabStatus) => {
  filterPeopleTargets = Boolean(isPeopleTarget);
  status = tabStatus;
  return getCountTargets(state);
};
