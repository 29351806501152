import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import icons from '../../../assets/img/icons.svg';
import { yes } from '../../../constant';
import ReportDownload from './ReportDownload.component';
import MoveTrash from './MoveTrash.component';

const HUDPopup = (props) => {
  const { isPeopleTarget, uid, getTargetByStatus } = props;

  const [copiedText, updateText] = useState(null);
  const Permission = useSelector((state) => state.session.user.Subscriptions.targets.features.report);

  const node = useRef({});

  const hideDialog = () => {
    document.removeEventListener('click', handleOutsideClick, true);
    props.hideDialog();
  };

  // close filter dialog if click outside of dialog
  const handleOutsideClick = (e) => {
    if (!node?.current?.contains(e.target)) {
      hideDialog();
    }
  };

  // Add eventlisteners for swiper after initializing
  useEffect(() => {
    if (props.active) {
      document.addEventListener('click', handleOutsideClick, true);
    }

    return () => {
      if (props.active !== null) {
        document.removeEventListener('click', handleOutsideClick, false);
      }
    };
  });

  const showMsg = () => {
    updateText('Copied');
    setTimeout(() => {
      updateText('');
    }, 200);
  };

  return (
    <div className={`dialog info-only hud-dialog ${props.active && 'active'}`}>
      <div className="box">
        <div className="close-dialog" onClick={hideDialog}>
          <svg className="icon nav-icon">
            <use xlinkHref={`${icons}#action-close`} />
          </svg>
        </div>
        {props.active && (
          <div className={`inner ${props.active && 'keyboard'}`} ref={node}>
            {props.subscription.targets && props.subscription.targets.features.ical.on === yes && (
              <div className="info-block calendar">
                <fieldset>
                  <legend>
                    <svg className="icon nav-icon">
                      <use xlinkHref={`${icons}#action-calendar`} />
                    </svg>
                    Outlook/iCal Calendar Subscription
                  </legend>
                  <p>
                    You can add your Targets to your Outlook or iCal calendar. Copy this link and paste it into your
                    calendar:
                  </p>
                  <div className="url-copy">
                    <input
                      type="text"
                      readOnly={true}
                      value={props.subscription.targets && props.subscription.targets.features.ical.data.webcal}
                      onFocus={(e) => e.target.select()}
                    />
                    <CopyToClipboard
                      text={props.subscription.targets.features.ical.data.webcal}
                      onCopy={() => showMsg()}
                    >
                      <a>
                        Click here to copy your Calendar subscription link <span className="error">{copiedText}</span>
                      </a>
                    </CopyToClipboard>
                  </div>
                  <p>
                    For step by step instructions, please visit{' '}
                    <a href="https://pipelineplus.com/help#calendar-subscription" target="_blank" rel="noreferrer">
                      our help center
                    </a>
                    .
                  </p>
                </fieldset>
              </div>
            )}
            {Permission.on !== 'no' && <ReportDownload isPeopleTarget={isPeopleTarget} uid={uid} />}
            <MoveTrash hideDialog={hideDialog} getTargetByStatus={getTargetByStatus} />
          </div>
        )}
      </div>
    </div>
  );
};

export default HUDPopup;
