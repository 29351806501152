/* eslint-disable no-undef */
import React from 'react';
import queryString from 'query-string';
import moment from 'moment';
import icons from '../../../assets/img/icons.svg';
import { connect, useSelector } from 'react-redux';
import { DueDateFormatForAPI } from '../../../constant';
import { gTag, downloadContent } from '../../../helpers';

const { stringify } = queryString;

/**
 * @description Componet to download the report as pdf and exl
 * @param {boolean} $viewer - true/false
 * @param {object} $reports - {other, targets, trainer}
 * @param {string} $activeReport - active report tab on sidebar
 * @param {object} $graphOf - {id, key, type}
 * @param {object} $selectionRange - {endDate, key, startDate}
 * @param {boolean} $isPeopleTarget - true/false
 * @param {string} $uid
 * @param {string} $which - score, revenue, steps, activity
 */

const Report = ({ viewer, reports, activeReport, graphOf, selectionRange, isPeopleTarget, uid, which }) => {
  const orgId = useSelector((state) => state.session.user.Org.id);

  const baseURL =
    window.app_instance !== 'ackert'
      ? process.env.REACT_APP_API_BASE_URL.split('//')[0] +
        '//' +
        localStorage.host_prefix +
        '.' +
        process.env.REACT_APP_API_BASE_URL.split('//')[1]
      : process.env.REACT_APP_API_BASE_URL;

  const Report =
    activeReport === 'progress' || activeReport === 'worksheets' || activeReport === 'user_worksheets'
      ? 'trainer'
      : 'targets';

  const prepareDownloadUrl = (fileType) => {
    const report = Object.keys(reports).length > 0 ? (reports[Report] ? reports[Report][activeReport] : '') : '';
    let downloadUrl = '';
    if (isPeopleTarget && report.download[fileType]) {
      const query = { id: uid, entity: 'user', format: fileType };
      const stringifyArgs = stringify(query);
      downloadUrl =
        baseURL +
        'viewer/report/' +
        `${activeReport === 'user_worksheets' ? 'user_worksheets?' : 'activity?'}` +
        stringifyArgs;
    } else if (report && report.download && report.download[fileType]) {
      const { id, type } = graphOf;
      const { base } = report.download[fileType];
      const query =
        Report === 'trainer' ? { id: orgId, entity: 'org', format: fileType } : { id, entity: type, format: fileType };
      if (selectionRange) {
        query.start = moment(selectionRange.startDate).format(DueDateFormatForAPI);
        query.end = moment(selectionRange.endDate).format(DueDateFormatForAPI);
      }
      if (activeReport === 'overview') query.which = which;

      const stringifyArgs = stringify(query);
      downloadUrl = baseURL + base.slice(1) + '?' + stringifyArgs;
    }
    return downloadUrl;
  };

  const handleDownload = (fileType) => {
    let url = prepareDownloadUrl(fileType);
    downloadContent(url, 'Generating ' + activeReport + ' report...');
  };

  // show the download button only if we have either csv or pdf downloads in the report config
  if (viewer) {
    const dl_csv = prepareDownloadUrl('csv');
    const dl_pdf = prepareDownloadUrl('pdf');
    if (dl_csv || dl_pdf) {
      return (
        <div className="mgmt-report-dl">
          <div className="tags">
            {dl_csv && (
              <div
                style={{ display: 'inline' }}
                onClick={() => {
                  gTag('Viewer: Download Report', 'Excel');
                }}
              >
                <a className="tag" onClick={() => handleDownload('csv')}>
                  <svg>
                    <use xlinkHref={`${icons}#button-download`} />
                  </svg>
                  Excel
                </a>
              </div>
            )}
            {dl_pdf && (
              <div
                style={{ display: 'inline' }}
                onClick={() => {
                  gTag('Viewer: Download Report', 'PDF');
                }}
              >
                <a className="tag" onClick={() => handleDownload('pdf')}>
                  <svg>
                    <use xlinkHref={`${icons}#button-download`} />
                  </svg>
                  PDF
                </a>
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
};

// map state to props
const mapStateToProps = (state) => ({
  reports: state.viewer.reports,
  graphOf: state.viewer.graphOf,
  which: state.viewer.which,
});

export default connect(mapStateToProps)(Report);
