import { isOnline } from '../../helpers/sessionManager';
import { getHUD } from '../../services/targets/dashboard.service';
import { updateATile, getHUDfromLocaldb } from '../../helpers/dbStorage';
import {
  LOAD_HUD,
  UPDATE_YTD_REVENUE_REQUEST,
  API_URLS,
  API_METHODS,
  UPDATE_YTD_REVENUE_COMMIT,
  UPDATE_YTD_REVENUE_ROLLBACK,
  CALL_HUD,
  API_STATUS_CODE,
} from '../../constant';

let updateStepLocal = false;

export const updateSteps = () => {
  updateStepLocal = !isOnline();
};
export const onlyCallHudOnce = () => (dispatch) => {
  dispatch({
    type: CALL_HUD,
    payload: !isOnline(),
  });
};
export const callHud = () => (dispatch) => {
  dispatch({
    type: CALL_HUD,
    payload: true,
  });
};

const getLocalHUD = () => (dispatch) => {
  getHUDfromLocaldb().then((response) => {
    if (response.status !== API_STATUS_CODE.NOTFOUND) {
      dispatch({
        type: LOAD_HUD,
        payload: response,
      });
    }
  });
};

const handleHUDResponse = (response, userId) => (dispatch) => {
  if (!response.id && !userId) {
    response.id = 'idhud';
    if (isOnline()) {
      updateATile(response, response.id);
      dispatch(callHud());
    }
  }
  if (updateStepLocal && !userId) {
    const step = +response.steps.you.this_week + 1;
    response.steps.you.this_week = step;
    updateATile(response, response.id);
    updateStepLocal = false;
  }
  dispatch({
    type: LOAD_HUD,
    payload: response,
  });
};

export const getUsersHUD = (userId) => (dispatch) => {
  const localpromise = getHUD(userId);
  if (localpromise) {
    localpromise.then(
      (response) => {
        if (response.status !== API_STATUS_CODE.NOTFOUND) {
          dispatch(handleHUDResponse(response, userId));
        }
      },
      () => {
        dispatch(getLocalHUD());
      },
    );
  }
};
export const submitYTDRevenue = (revenue) => ({
  type: UPDATE_YTD_REVENUE_REQUEST,
  payload: revenue,
  meta: {
    offline: {
      // the network action to execute:
      effect: { apiInfo: { url: API_URLS.SETYTDREVENUE, type: API_METHODS.POST }, data: revenue },
      // action to dispatch when effect succeeds:
      commit: { type: UPDATE_YTD_REVENUE_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: { type: UPDATE_YTD_REVENUE_ROLLBACK },
    },
  },
});
