import React from 'react';

const DataValue = ({ detail }) => {
  if (detail.category === 'score') {
    return (
      <span className={`${detail.category} ${detail.category}-${detail.data_value} you`}>
        <b className="label">{detail.data_value}</b>
      </span>
    );
  } else {
    return <div className={detail.category}>{detail.data_value}</div>;
  }
};

export default DataValue;
