import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { getTargetsData } from '../../../../actions';
import { TargetsTable, NoReports, TileLoader } from '../../../../components';
import { DueDateFormatForAPI, targetsTabs } from '../../../../constant';
import icons from '../../../../assets/img/icons.svg';
import { formatNumber } from '../../../../helpers';

class Targets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTabFilter: targetsTabs[0].which,
      searchKeyword: '',
      targetsTable: props.tableData || [],
    };
    this.searchGlobal = null;
  }

  componentDidMount() {
    if (this.props.graphOf.id && this.props.dateRange) {
      this.key = this.props.graphOf.id;
      this.getTargetsDataFromAPI();
    }
    this.setState({ targetsTable: this.props.tableData });
  }

  componentDidUpdate(prevprops) {
    if (this.props.graphOf.id && this.props.dateRange && this.key !== this.props.graphOf.id) {
      this.key = this.props.graphOf.id;
      this.getTargetsDataFromAPI();
    }
    if (this.props.graphOf.key && this.props.dateRange && prevprops.dateRange !== this.props.dateRange) {
      this.getTargetsDataFromAPI();
    }
    if (prevprops.tableData !== this.props.tableData) {
      this.setState({ targetsTable: this.props.tableData });
    }
  }

  /**
   * call Api to get data for getting targets table data
   */
  getTargetsDataFromAPI = () => {
    if (this.props.graphOf.key) {
      const range = {
        start: moment(this.props.dateRange.startDate).format(DueDateFormatForAPI),
        end: moment(this.props.dateRange.endDate).format(DueDateFormatForAPI),
      };
      this.props.getTargetsData(this.props.graphOf, range);
    }
  };

  /**
   * update the searchKeyword in state input
   */
  updateSearchedKeyword = (e) => {
    const searchKeyword = e.target.value;
    this.setState({ searchKeyword });
  };

  // Search targets for the input provided
  searchTargets = (e) => {
    const keyword = e.target.value;
    // this.searchGlobal && this.searchGlobal(keyword);
    this.customFilter(keyword);
  };

  getTabData = (tab) => {
    const { tableData } = this.props;
    let data = tableData;
    switch (tab) {
      case targetsTabs[1].which:
        data = data.filter((item) => !item.isShared);
        break;
      case targetsTabs[2].which:
        data = data.filter((item) => item.isShared);
        break;
      default:
        data = tableData;
        break;
    }
    return data;
  };

  customFilter = (value) => {
    const { currentTabFilter } = this.state;
    let filteredData = this.getTabData(currentTabFilter).filter((item) => {
      let company = item.company.toUpperCase().includes(value.toUpperCase());
      let contact = item.contact.toUpperCase().includes(value.toUpperCase());
      let user = item.collaborators.find((ele) => {
        const { first_name = '', last_name = '' } = ele;
        let name = first_name + last_name;
        return name.toUpperCase().includes(value.toUpperCase());
      });
      let goal = item.goal.toUpperCase().includes(value.toUpperCase());
      let status = item.status.toUpperCase().includes(value.toUpperCase());
      let valueterm = item.valueterm?.replace(' Value', '').toUpperCase().includes(value.toUpperCase());
      let typeterm = item.typeterm.toUpperCase().includes(value.toUpperCase());
      let steps;
      if (!isNaN(parseInt(value))) {
        steps = item.steps === parseInt(value);
      }
      let revenue = item.revenue ? `$${formatNumber(+item.revenue?.replace(/,/g, ''))}` : ``;
      revenue = revenue.toUpperCase().includes(value.toUpperCase());
      return company || contact || !!user || goal || status || valueterm || typeterm || steps || revenue;
    });
    this.setState({ targetsTable: filteredData });
  };

  // clear search filters
  clearSearchFilter = (tab) => {
    this.setState({
      searchKeyword: '',
      targetsTable: this.getTabData(tab),
      currentTabFilter: tab,
    });
  };

  setFilterProps = (filters) => {
    // if (!this.searchGlobal)
    this.searchGlobal = filters.setGlobalFilter;
  };

  render() {
    const { currentTabFilter, targetsTable } = this.state;
    const { loading } = this.props;
    if (!targetsTable.length && loading) return <TileLoader />;
    return (
      <section className="panel table targets">
        {targetsTable ? (
          <>
            <header className="filter">
              <div className="table-filter">
                <input
                  placeholder="Filter by keyword"
                  className="clearable kwrd-srch"
                  onChange={this.updateSearchedKeyword}
                  value={this.state.searchKeyword}
                  onKeyUp={this.searchTargets}
                />
                <svg className="icon">
                  <use xlinkHref={`${icons}#action-search`} />
                </svg>
                <div className="clear-table-filter" onClick={() => this.clearSearchFilter(currentTabFilter)}>
                  <svg className="icon">
                    <use xlinkHref={`${icons}#action-close`} />
                  </svg>
                </div>
              </div>
              <div className="categories">
                <ul className="button-bar ui">
                  {targetsTabs.map((item) => (
                    <li
                      className={item.which === currentTabFilter ? 'active' : ''}
                      onClick={() => {
                        currentTabFilter !== item.which && this.clearSearchFilter(item.which);
                      }}
                      key={item.which}
                    >
                      <span>{item.name}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </header>
            <div className="scroller horz">
              <TargetsTable targets={targetsTable} headRows={targetsTable} getFilterProps={this.setFilterProps} />
            </div>
          </>
        ) : (
          <NoReports />
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  graphOf: state.viewer.graphOf,
  tableData: state.targetsTable.data || [],
  loading: state.targetsTable.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getTargetsData: (id, range) => dispatch(getTargetsData(id, range)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Targets);
