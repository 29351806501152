import React from 'react';

// add class showing-action-sheet
const ActionSheetStep = ({ item, onSelect }) => {
  return (
    <button className="action-item" onClick={onSelect} value={item.key}>
      <b>{item.key}</b> <small>{item.value}</small>
    </button>
  );
};

export default ActionSheetStep;
