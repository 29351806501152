import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import * as moment from 'moment';
import { CSSTransition } from 'react-transition-group';

import { NavSecondaryTargetDetail, TargetSwiper, SelectUser, Loader } from '../../../components';
import { ActionSheetContainer } from '../..';
import {
  showActionSheet,
  hideActionSheet,
  setAttention,
  getAllUsers,
  selectUser,
  hideCollaborationDialog,
  getPeopleTargets,
  getTargetsBasedOnTileID,
  onTargetRefresh,
} from '../../../actions';
import {
  sharedStatus,
  ROUTES_PATH,
  DueDateFormatToDisplay,
  MESSAGES,
  EDIT_CONFIRM,
  API_PARAMS,
} from '../../../constant';
import getVisibleTargets from '../../../selectors/targets/getVisibleTargets.selector';
import 'swiper/dist/css/swiper.min.css';
import '../../../assets/css/swiper-overrides.css';
import '../../../assets/css/card.css';
import '../../../assets/css/stat.css';
import history from '../../../helpers/history';
import { redirectionAllowed, validateUser } from '../../../helpers';

class TargetDetailContainer extends Component {
  state = {
    initialSlide: 0,
    targets: [],
    goal: '',
    taskdue: '',
    taskdescription: '',
    lostReason: '',
    shared: false,
    inProp: false,
  };
  swiperObj = {};
  deletedIndex;
  slideChange = 1;

  handelTargetDisplay = (props) => {
    const {
      isPeopleTarget,
      dateRange,
      match: { params },
      getPeopleTargets,
      getTargetsBasedOnTileID,
      targets,
    } = props;

    if (isPeopleTarget) {
      getPeopleTargets('', dateRange, params.uid).then((response) => {
        this.filterSharedTarget(response);
      });
    } else {
      if (!targets.length) {
        getTargetsBasedOnTileID(params.id);
      } else {
        this.filterSharedTarget(targets);
      }
    }
  };

  componentDidMount() {
    const {
      match: { params },
      getAllUsers,
    } = this.props;
    if (!redirectionAllowed(validateUser(), 'targets')) {
      localStorage.clear();
      history.push(ROUTES_PATH.LOGIN);
    } else {
      this.handelTargetDisplay(this.props);
      getAllUsers(params.uid || '');
      // add a class to the body so we can turn off scrolling
      document.body.classList.add('swiper-on');
    }
    this.setState({ inProp: true });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.goBack !== this.props.goBack && this.props.goBack) {
      this.props.history.replace(ROUTES_PATH.DASHBOARD);
    } else {
      if (prevProps.targets !== this.props.targets) {
        if (!this.props.isPeopleTarget) {
          this.filterSharedTarget(this.props.targets);
        }
      }
    }

    if (prevProps.targetNoteTracker?.counter !== this.props.targetNoteTracker?.counter) {
      this.props.onRefresh('active');
      this.handelTargetDisplay(this.props);
    }
  }

  componentWillUnmount() {
    // remove a class to the body so we can turn on scrolling
    document.body.classList.remove('swiper-on');
  }

  filterSharedTarget = (targets = []) => {
    let tempTargets = [...targets];
    const {
      isPeopleTarget,
      history,
      match: { params },
    } = this.props;
    const targetId = params.id || params.targetId;
    // find the tile by id and check if the shared is pending
    let tile = targets.find((target) => targetId === target.id);
    if (tile) {
      if (tile.shared === sharedStatus.pending) {
        this.setState({ shared: true });
        tempTargets = targets.filter((item) => {
          return item.shared === sharedStatus.pending;
        });
      } else {
        tempTargets = targets.filter((item) => item.status === tile.status && item.shared !== sharedStatus.pending);
      }
    }
    if (!window.refresh) {
      this.afterDelete(targets);
    } else if (window.refresh && isPeopleTarget && !targets.length) {
      history.goBack();
    }
    let initialSlide = tempTargets.findIndex((item) => {
      if (isPeopleTarget) return item.id === params.targetId;
      else return item.id === params.id;
    });
    if (initialSlide === -1) {
      initialSlide = 0;
    }
    this.setState({ initialSlide, targets: tempTargets });
  };

  // toggle action sheet
  toggleActionSheet = ({ type, open }) => {
    if (open) {
      this.setState({ actionSheetType: type });
      this.props.showActionSheet(
        {
          open: true,
          type: type,
        },
        type,
      );
    } else {
      this.resetState();
      this.props.hideActionSheet();
    }
  };

  // callback when value is selected from action sheet
  onSelect = (e) => {
    let value;
    if (e.nativeEvent) {
      value = e.nativeEvent.target.value ? e.nativeEvent.target.value : e.nativeEvent.target.parentElement.value;
    } else {
      value = moment(e).format(DueDateFormatToDisplay);
    }
    if (value.indexOf('...') > -1) {
      value = value.replace('...', '');
    }
    this.setState({ [this.state.actionSheetType]: value });
    this.props.hideActionSheet();
  };

  resetState = () => {
    this.setState({ [this.state.actionSheetType]: '', actionSheetType: '' });
  };

  /**
   * Call back after deleting the target
   */
  afterDelete = (targets) => {
    const {
      isPeopleTarget,
      history,
      match: { params },
    } = this.props;
    const targetLengths = targets ? targets.length : this.state.targets.length;
    // there is no slide to move so navigate to active target
    if (targetLengths === 0) {
      if (isPeopleTarget)
        history.replace(`${ROUTES_PATH.VIEWER}/${API_PARAMS.PIPELINE}${ROUTES_PATH.PEOPLE}/${params.uid}`);
      else history.replace(ROUTES_PATH.DASHBOARD);
    }
  };

  confirmCancel = (routeName) => {
    if (this.props.editConfirm) {
      toastr.confirm(MESSAGES.EDITCHANGESCONFIRM, {
        okText: 'No, Cancel Changes',
        cancelText: 'Yes, Finish & Save',
        onOk: () => {
          this.props.editConfirmFalse();
          this.props.history.push('/' + routeName);
        },
      });
    } else {
      let currentEndpoint = '';
      const idLocation = window.location.href.search('people/');
      let findUserID = '';
      for (let i = idLocation + 7; i < window.location.href.length; i++) {
        if (window.location.href[i] === '/') {
          break;
        }
        findUserID += window.location.href[i];
      }
      for (let i = 0; i < 6; i++) {
        currentEndpoint += window.location.hash[i];
      }
      if (currentEndpoint === '#/view') {
        const personsViewer = '/viewer/pipeline/people/' + findUserID;
        this.props.history.push(personsViewer);
      } else if (currentEndpoint === '#/targ') {
        this.props.history.push('/target');
      } else {
        this.props.history.push('/' + routeName);
      }
    }
  };

  renderSwiper = () => {
    let checkForViewer = '';
    for (let i = 2; i < 8; i++) {
      checkForViewer += window.location.hash[i];
    }
    let yesViewerPage = false;
    if (checkForViewer === 'viewer') {
      yesViewerPage = true;
    }
    const {
      isPeopleTarget,
      setAttention,
      match: { params },
    } = this.props;

    return this.state.targets.length ? (
      <TargetSwiper
        targets={this.state.targets}
        setAttention={setAttention}
        selectedValueFromAS={{
          goal: this.state.goal,
          taskdue: this.state.taskdue,
          taskdescription: this.state.taskdescription,
          lostReason: this.state.lostReason,
        }}
        toggleActionSheet={this.toggleActionSheet}
        resetState={this.resetState}
        initialSlide={this.state.initialSlide}
        afterDelete={this.afterDelete}
        shared={this.state.shared}
        confirmCancel={this.confirmCancel}
        isPeopleTarget={isPeopleTarget}
        uid={params.uid}
        viewerPage={yesViewerPage}
      />
    ) : (
      <></>
    );
  };

  render() {
    const { loading, targets, isPeopleTarget } = this.props;
    return (
      <>
        {!targets.length && loading && <Loader />}
        {!!targets.length && isPeopleTarget && (
          <div className="dashboard-container swiper">
            <NavSecondaryTargetDetail confirmCancel={this.confirmCancel} isPeopleTarget={isPeopleTarget} />
            <div className="status-wrapper">{this.renderSwiper()}</div>
          </div>
        )}
        {!!targets.length && !isPeopleTarget && (
          <>
            <CSSTransition classNames="fade" in={this.state.inProp} timeout={300}>
              <section className="nav-page" id="tiles">
                {this.renderSwiper()}
              </section>
            </CSSTransition>
            <NavSecondaryTargetDetail confirmCancel={this.confirmCancel} />
          </>
        )}
        {createPortal(
          <div
            id="action-sheet"
            className={`inner ${this.props.actionSheetProps.open ? 'showing-action-sheet target-detail-sheet' : ''}`}
          >
            <ActionSheetContainer onSelect={this.onSelect} close={this.props.hideActionSheet} />
          </div>,
          document.getElementById('root'),
        )}

        <SelectUser
          shareTarget={this.props.selectUser}
          users={this.props.users}
          active={this.props.userDialog}
          closePopUp={this.props.closePopUp}
        />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { search } = ownProps.location;
  let isPeopleTarget = !!ownProps.isPeopleTarget;
  if (search) isPeopleTarget = new URLSearchParams(search).get('peopleTarget');
  return {
    targets: isPeopleTarget ? getVisibleTargets(state, true) : getVisibleTargets(state),
    ...state.actionSheet,
    editConfirm: state.editConfirm,
    goBack: state.dashBoard.goBack,
    users: state.users,
    userDialog: state.selectUser.open,
    isPeopleTarget,
    loading: state.people.loader,
    targetNoteTracker: state.trackTargetNote,
  };
};
const mapDispatchToProps = (dispatch) => ({
  hideActionSheet: () => dispatch(hideActionSheet()),
  setAttention: (id) => dispatch(setAttention({ id })),
  showActionSheet: (actionSheetProps, actionSheetType) => {
    dispatch(showActionSheet({ actionSheetProps, actionSheetType }));
  },
  getAllUsers: (uid) => dispatch(getAllUsers(false, uid)),
  selectUser: (user) => dispatch(selectUser(user)),
  getTargetsBasedOnTileID: (id) => {
    dispatch(getTargetsBasedOnTileID(id));
  },
  closePopUp: () => dispatch(hideCollaborationDialog()),
  editConfirmFalse: () => dispatch({ type: EDIT_CONFIRM, payload: false }),
  getPeopleTargets: (status, range, userId) => {
    return dispatch(getPeopleTargets(status, range, userId));
  },
  onRefresh: (status) => dispatch(onTargetRefresh(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TargetDetailContainer);
