import React, { Fragment, useEffect, useState } from 'react';
import { ReactComponent as DraggableIcon } from '../../../../../assets/img/draggable.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/img/close.svg';
import { ReactComponent as AddIcon } from '../../../../../assets/img/add.svg';
import '../../styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_MODALS } from '../../../../../constant/insights';
import { setDigestValues } from '../../../../../actions';

const Content = ({ values, setValues, setNewElementModalOpen }) => {
  const [draggableElement, setDraggableElement] = useState('');
  const [active, setActive] = useState('');
  const [indexOfDragged, setIndexOfDragged] = useState(-1);
  const dispatch = useDispatch();
  const digestValues = useSelector((state) => state.insightDashboard.digestValues);
  const { content: items } = digestValues;
  const draggedInsight = useSelector((state) => state.insightDashboard.draggedInsight);

  const handleDragStart = (e, item) => {
    setDraggableElement(item);
    setIndexOfDragged(items.findIndex((i) => i.id === item.id));
  };

  const handleDragEnd = () => {
    setDraggableElement(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleCardDragOver = (e, item) => {
    e.preventDefault();
    if (draggableElement) setActive(item.id);
  };

  const handleCardDragLeave = () => {
    setActive('');
  };

  const handleDrop = (e, targetItem) => {
    e.preventDefault();
    if (!draggableElement) return;

    const currentIndex = items.map((item) => item.id).indexOf(draggableElement.id);
    const targetIndex = items.map((item) => item.id).indexOf(targetItem.id);

    if (currentIndex !== -1 && targetIndex !== -1) {
      const newItems = [...items];
      newItems.splice(currentIndex, 1);
      newItems.splice(targetIndex, 0, draggableElement);
      dispatch(setDigestValues({ ...digestValues, content: newItems }));
    }
    setDraggableElement(null);
    setActive('');
  };

  const handleRemove = (id) => {
    const filteredList = items.filter((item) => item.id !== id);
    dispatch(setDigestValues({ ...digestValues, content: filteredList }));
  };

  const handleWrapperDrop = (e) => {
    e.preventDefault();
    if (!items.find((item) => item.id === draggedInsight.id)) {
      draggedInsight && dispatch(setDigestValues({ ...digestValues, content: [draggedInsight, ...items] }));
    } else {
      alert('Looks like this video was already added');
    }
    setActive('');
  };

  const handleExternalShare = (externalShareId) => {
    let contentCopy = [...items];

    const selectedIndex = items.findIndex(({ id }) => id === externalShareId);
    const selectedItem = items.find(({ id }) => id === externalShareId);

    contentCopy.splice(selectedIndex, 1, {
      ...selectedItem,
      allow_external: !selectedItem?.allow_external,
    });
    dispatch(setDigestValues({ ...digestValues, content: contentCopy }));
  };

  useEffect(() => {
    setValues({ ...values, content: items });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const indexOfActive = items.findIndex((item) => item.id === active);

  return (
    <div className="content-btn-wrapper">
      <ul className="content-section-wrapper" onDrop={(e) => handleWrapperDrop(e)}>
        {items?.length ? (
          items?.map((item) => {
            const { name, allow_external, id, noExternal } = item;
            return (
              <Fragment key={id}>
                {active === item.id && indexOfActive < indexOfDragged ? <div className="drag-indicator" /> : null}
                <li
                  className={`content  ${draggableElement?.id === id && 'dragging'} `}
                  key={id}
                  draggable
                  onDragStart={(e) => handleDragStart(e, item)}
                  onDragEnd={handleDragEnd}
                  onDragOver={(e) => handleCardDragOver(e, item)}
                  onDragLeave={handleCardDragLeave}
                  onDrop={(e) => handleDrop(e, item)}
                >
                  <div className={`content-data-list ${!noExternal && 'enableShare'}`}>
                    <DraggableIcon />
                    <div className="content-name">
                      <span>{name}</span>
                      {!noExternal && (
                        <div className="checkbox-wrapper">
                          <input
                            id={`external-share-${id}`}
                            type="checkbox"
                            value={allow_external}
                            onChange={() => handleExternalShare(id)}
                          />
                          <label htmlFor={`external-share-${id}`}>Allow external share</label>
                        </div>
                      )}
                    </div>
                    <span onClick={() => handleRemove(id)}>
                      <CloseIcon />
                    </span>
                  </div>
                </li>
                {active === item.id && indexOfActive >= indexOfDragged ? <div className="drag-indicator" /> : null}
              </Fragment>
            );
          })
        ) : (
          <div className="upload-container" draggable onDrop={(e) => handleWrapperDrop(e)} onDragOver={handleDragOver}>
            <p className="bold">Drag and drop your content here</p>
          </div>
        )}
      </ul>
      <button
        type="button"
        className="external-url-button"
        onClick={() => setNewElementModalOpen(CREATE_MODALS.CONTENT)}
      >
        <AddIcon />
        Add External URL Content
      </button>
    </div>
  );
};

export default Content;
