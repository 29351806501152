import { _apps, initializeApp } from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  appId: process.env.REACT_APP_MOBILESDK_APP_ID,
  messagingSenderId: process.env.REACT_APP_PROJECT_NUMBER,
  apiKey: process.env.REACT_APP_GA_API_KEY,
  authDomain: process.env.REACT_APP_WEB_BASE_URL,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
};

let firebaseApp;

if (_apps.size == 0) {
  firebaseApp = initializeApp(firebaseConfig);
}

export default firebaseApp;
