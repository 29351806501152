import React, { useState, useEffect } from 'react';
import { groupBy, sortBy } from 'lodash';

/**
 * component for Worksheet form in the trainer
 * @param {Object} props
 */
const WorksheetForm = (props) => {
  const { parameters, questions, lessonId, saveWorksheetMatrix } = props;
  const [matrix, setMatrix] = useState({});
  const [event, setEvent] = useState('loaded');
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    const groupAndSortMatrixByQuestion = sortBy(groupBy(props.matrix, 'question'), questions);
    const reducedMatrix = groupAndSortMatrixByQuestion.reduce((result, value, index) => {
      result[questions[index]] = value;
      return result;
    }, {});

    setMatrix(reducedMatrix);
  }, [props.matrix, questions]);

  const handleMatrixUpdate = (event, question, index) => {
    const { value } = event.target;
    const temp = { ...matrix };
    temp[question][index].value = value;
    setMatrix(temp);
    setEvent('need-to-save');
  };

  const handleBlur = async () => {
    if (hasChanged) {
      setEvent('saving');
      const finalMatrix = Object.values(matrix).flat();
      try {
        await saveWorksheetMatrix(lessonId, finalMatrix);
        setTimeout(() => {
          setEvent('save-succeeded ');
        }, 1000);
      } catch (error) {
        setEvent('error-saving');
      }
      setHasChanged(false);
    }
  };

  const handleFocus = (i, subItem) => {
    const textId = window.document.getElementById(i + subItem.name);
    textId.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <div className={`worksheet-form ${event}`}>
      <div className="data-status">
        <span className="loaded">
          {' '}
          <span role="img" aria-label="fill">
            ✅
          </span>{' '}
          Changes will be saved automatically.
        </span>
        <span className="need-to-save">
          {' '}
          <span role="img" aria-label="changed">
            🕒
          </span>{' '}
          Data has changed...
        </span>
        <span className="saving throb">
          {' '}
          <span role="img" aria-label="saving">
            ⏳
          </span>{' '}
          Saving...
        </span>
        <span className="save-succeeded">
          {' '}
          <span role="img" aria-label="saved">
            ✅
          </span>{' '}
          Saved to your account.
        </span>
        <span className="error-saving error">
          {' '}
          <span role="img" aria-label="error">
            ⚠️
          </span>{' '}
          There was an error saving these changes.
        </span>
      </div>
      <div className="scroller horz">
        <table className="worksheet" data-="">
          <colgroup>
            <col className="name" width="20%" />
            <col className="data" width="40%" />
            <col className="data" width="40%" />
          </colgroup>
          <thead>
            <tr>
              <th>&nbsp;</th>
              {parameters.map((param, index) => (
                <th key={index}>{param}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.entries(matrix).map((item, index) => {
              return (
                <tr key={index}>
                  <th>{item[0]}</th>
                  {item[1].map((subItem, i) => {
                    return (
                      <td key={i}>
                        <textarea
                          id={i + subItem.name}
                          className="ws-data"
                          name={subItem.name}
                          value={subItem.value}
                          onChange={(event) => {
                            handleMatrixUpdate(event, item[0], i);
                            setHasChanged(true);
                          }}
                          onFocus={() => {
                            handleFocus(i, subItem);
                          }}
                          onBlur={handleBlur}
                        />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <footer className="actions">
        <p>Enter your info above. All your changes will be saved automatically.</p>
        {/*
        <button
          className="save"
          type="submit"
          style={{margin: '0 auto'}}
          onClick={submitMatrix}
        >
          Submit
        </button>
        */}
      </footer>
    </div>
  );
};

export default WorksheetForm;
