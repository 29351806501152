import React from 'react';
import '../../assets/css/dialog.css';
import '../../assets/css/forms.css';
import logo from '../../assets/img/ackert-logo.svg';
import logoPP from '../../assets/img/PipelinePlus_Horizontal_Logo_RGB_Full_Color.svg';
import plusAdvisory from '../../assets/img/plus-advisory.svg';
import plusTargets from '../../assets/img/plus-targets.svg';
import plusTrainer from '../../assets/img/plus-trainer.svg';
import plusViewer from '../../assets/img/plus-viewer.svg';
import insights from '../../assets/img/insights.svg';
import icons from '../../assets/img/icons.svg';

let About = (_props) => {
  return (
    <div className="more-about" id="more-about">
      <div>
        <div className="title">
          <img src={logoPP} alt="PIPELINEPLUS" />
        </div>
        <div className="inner">
          <h2>A suite of simple, practical tools and services for enhancing your business development pipeline.</h2>
          <ul>
            <li>
              <h3>
                <a>
                  <img src={plusTargets} alt="PIPELINEPLUS Pipeline" />
                </a>
              </h3>
              <p>Simple system for organizing key relationships and pursuing business opportunities.</p>
            </li>
            <li>
              <h3>
                <a>
                  <img src={plusTrainer} alt="PIPELINEPLUS Trainer" />
                </a>
              </h3>
              <p>Video e-learning curriculum that elevates your business development acumen and mindset.</p>
            </li>
            <li>
              <h3>
                <a>
                  <img src={plusViewer} alt="PIPELINEPLUS Viewer" />
                </a>
              </h3>
              <p>Comprehensive reporting system that tracks performance and illuminates new avenues for growth.</p>
            </li>
            <li>
              <h3>
                <a>
                  <svg className="icon nav-icon">
                    <use xlinkHref={`${icons}#insights-on`} />
                  </svg>
                  <img
                    style={{
                      height: '17px',
                      marginLeft: '5px',
                    }}
                    src={insights}
                    alt="PIPELINEPLUS Insights"
                  />
                </a>
              </h3>
              <p>Our proprietary content distribution tool.</p>
            </li>
            <li>
              <h3>
                <a>
                  <img src={plusAdvisory} alt="ACKERT Advisory" />
                </a>
              </h3>
              <p>Business development coaching and consulting that accelerates results.</p>
            </li>
          </ul>
          <p className="footer">
            <span className="product-name">PipelinePlus</span> is powered by{' '}
            <img src={logo} alt="Powered by Ackert, Inc." />.
            <br />
            To learn more about what we can do for your firm, visit our{' '}
            <a href="https://pipelineplus.com/" target="_blank" rel="noreferrer">
              website
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
