import { LOAD_FIRMS } from '../../constant';

const initialState = [];

const firms = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_FIRMS:
      return action.payload;
    default:
      return state;
  }
};

export default firms;
