import React, { useEffect, useMemo, useState } from 'react';

import closeIcon from '../../../assets/img/close.svg';
import { ReactComponent as InfoIcon } from '../../../assets/img/info-square.svg';
import './styles.css';
import { INSIGHTS_MODAL_TYPES } from '../../../constant/modaltypes';
import NewContentForm from './newContent/index';
import NewDigestForm from './newDigest/index';
import Switch from '../../../components/common/switch';
import { useDispatch, useSelector } from 'react-redux';
import { FAVORITE, MAX_POPULATE } from '../../../constant/insights';
import { removePopulatedData, setPopulatedContent } from '../../../actions';
import { MESSAGES } from '../../../constant';

const modalTypes = Object.values(INSIGHTS_MODAL_TYPES);

const NewInsightModal = ({ modalType, setModalType, setNewElementModalOpen, editContent, setEditContent }) => {
  const [resetFields, setResetFields] = useState(false);
  const [autoPopulate, setAutoPopulate] = useState(false);
  const [autoPopulateCount, setAutoPopulateCount] = useState(0);
  const insightsSent = useSelector((state) => state.insightDashboard.videoInsightsSent);
  const allInsights = useSelector((state) => state.insightDashboard.content);
  const content = useSelector((state) => state.insightDashboard.digestValues.content);
  const populatedData = useSelector((state) => state.insightDashboard.populatedInsights);

  const dispatch = useDispatch();

  const disabled = useMemo(() => {
    let favorites = 0;
    allInsights.forEach(({ tag_names }) => tag_names && tag_names.split(', ').includes(FAVORITE) && favorites++);
    return favorites === 0;
  }, [allInsights]);

  const handleClose = () => {
    setModalType('');
    setResetFields(true);
    setEditContent({});
  };

  const handleHeader = () => {
    switch (true) {
      case modalType === INSIGHTS_MODAL_TYPES.ADD:
        return 'Add Content';
      case modalType === INSIGHTS_MODAL_TYPES.DIGEST:
        return 'Create Digest';
      case modalType === INSIGHTS_MODAL_TYPES.EDIT:
        return 'Edit Content';
      default:
        return '';
    }
  };

  const handleAutoPopulate = (checked) => {
    setAutoPopulate(checked);
  };

  const getPopulatedInsights = () => {
    const insightsSentIds = insightsSent?.map(({ videoinsight_id }) => videoinsight_id);
    let populatedInsights = [];

    const allFavoriteContent = [];
    let unusedFavorites = [];
    const usedFavorites = [];

    allInsights.forEach((insight) => {
      const { tag_names } = insight;
      tag_names && tag_names.split(', ').includes(FAVORITE) && allFavoriteContent.push(insight);
    });

    allFavoriteContent.forEach((insight) =>
      !insightsSentIds?.includes(insight.id) ? unusedFavorites.push(insight) : usedFavorites.push(insight),
    );

    unusedFavorites.sort(() => 0.5 - Math.random());

    if (autoPopulateCount > 1) {
      usedFavorites.sort(() => 0.5 - Math.random());
    }

    if (unusedFavorites.length >= MAX_POPULATE) {
      populatedInsights = unusedFavorites.splice(0, MAX_POPULATE);
    } else {
      const latestUsedFavorites = usedFavorites.splice(0, MAX_POPULATE - unusedFavorites.length);
      populatedInsights = [...unusedFavorites, ...latestUsedFavorites];
    }
    dispatch(setPopulatedContent(populatedInsights));
  };

  const removePopulatedContent = () => {
    const populatedNames = populatedData.map(({ name }) => name);
    const filteredContent = content.filter(({ name }) => !populatedNames.includes(name));
    dispatch(removePopulatedData(filteredContent));
  };

  useEffect(() => {
    if (modalType !== '') setResetFields(false);
  }, [modalType]);

  useEffect(() => {
    if (autoPopulate) {
      getPopulatedInsights();
      setAutoPopulateCount(autoPopulateCount + 1);
    } else {
      removePopulatedContent();
    }
  }, [autoPopulate]);

  useEffect(() => {
    return () => {
      setAutoPopulate(false);
      setAutoPopulateCount(0);
    };
  }, []);

  return (
    <div className={`new-insight ${modalTypes.includes(modalType) && 'open'}`}>
      <div className="content-wrapper">
        <div className="new-insight-header">
          <span>{handleHeader()}</span>
          <img
            src={closeIcon}
            alt="close-modal"
            onClick={handleClose}
            style={{ maxHeight: '20px', maxWidth: '20px' }}
          />
        </div>
        {modalType === INSIGHTS_MODAL_TYPES.DIGEST && (
          <div className="new-insight-autoPopulate">
            <div>
              <span>Auto-populate favorites</span>
              {/*eslint-disable-next-line react/no-unknown-property*/}
              <span tooltip={MESSAGES.AUTO_POPULATE} tooltip-position="top" className="info-icon">
                <InfoIcon />
              </span>
            </div>
            <Switch checked={autoPopulate} setChecked={handleAutoPopulate} disabled={disabled} />
          </div>
        )}
        {modalType === INSIGHTS_MODAL_TYPES.ADD || modalType === INSIGHTS_MODAL_TYPES.EDIT ? (
          <NewContentForm
            setModalType={setModalType}
            setNewElementModalOpen={setNewElementModalOpen}
            resetFields={resetFields}
            editContent={modalType === INSIGHTS_MODAL_TYPES.ADD ? null : editContent}
          />
        ) : modalType === INSIGHTS_MODAL_TYPES.DIGEST ? (
          <NewDigestForm
            handleClose={handleClose}
            setNewElementModalOpen={setNewElementModalOpen}
            setAutoPopulate={setAutoPopulate}
          />
        ) : null}
      </div>
    </div>
  );
};

export default NewInsightModal;
