import React, { useEffect } from 'react';

import '../../assets/css/login.css';

import { useLoginWithRedirect } from '../../services/user/auth0';

const Auth0Login = () => {
  const loginWithRedirect = useLoginWithRedirect();

  useEffect(() => {
    loginWithRedirect();
  }, [loginWithRedirect]);

  return (
    <div className="login-wrapper keyboard">
      <h4 className="login-wrapper keyboard">Redirecting...</h4>
    </div>
  );
};

export default Auth0Login;
