import { navigate } from '../services/shared/branch.service';
export const initDeepLinks = () => {
  if (window.Branch) {
    window.Branch.initSession().then(function (data) {
      if (data) {
        // read deep link data on click
        navigate(data);
      }
    });
  }
};
