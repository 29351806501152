import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as ExpandIcon } from '../../../assets/img/dropdown.arrow.svg';
import './styles.css';
import { useOnClickOutside } from '../../../hooks/onClickOutside';

const Accordion = ({ title, children, label, errors, values, digestContentLength, checkErrors }) => {
  const [childrenSelect, setChildrenSelect] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [errorsAmount, setErrorsAmount] = useState(0);
  const content = useRef(null);
  useOnClickOutside(content, () => setChildrenSelect(false));

  const handleExpand = () => {
    if (expanded) {
      checkErrors();
    }
    setExpanded((expanded) => !expanded);
  };

  const handleChildrenHeight = (e) => {
    e.stopPropagation();
    if (!e.target.className?.includes('mbsc')) {
      const isSelectOrPicker =
        content.current.children[0].className === 'select-wrapper' ||
        content.current.children[0].className === 'general-wrapper';
      setChildrenSelect(isSelectOrPicker);
    }
  };

  useEffect(() => {
    setChildrenSelect(false);
  }, [values]);

  useEffect(() => {
    if (errors?.length) {
      let errorsFound = 0;
      errors.map((error) => error && errorsFound++);
      setErrorsAmount(errorsFound);
      errorsAmount > 0 && setExpanded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    errorsAmount > 0 && setExpanded(true);
  }, [errorsAmount]);

  const contentHeight = `${content?.current?.clientHeight + (label ? 170 : 100) + 10 * errorsAmount + (childrenSelect ? 300 : 0)}px`;

  const digestContentHeight = `${(label ? 170 : 100) + digestContentLength * 100 + 120}px`;

  const titleHeight = label ? '134px' : '64px';

  return (
    <div
      className={`accordion-wrapper ${!!expanded && 'expanded'} ${!!label && 'with-label'}`}
      style={{
        height: expanded ? (digestContentLength ? digestContentHeight : contentHeight) : titleHeight,
      }}
    >
      <div className="accordion-title" aria-expanded={String(expanded)} onClick={handleExpand}>
        <span>{title}</span>
        <ExpandIcon />
      </div>
      {!!label &&
        (!Array.isArray(label) ? (
          <span className="selected-data">{label}</span>
        ) : (
          <div className="pill-wrapper">
            {label?.map((label) => (
              <span key={label} className="selected-data">
                {label}
              </span>
            ))}
          </div>
        ))}
      <div ref={content} onClick={(e) => handleChildrenHeight(e)}>
        {children}
      </div>
    </div>
  );
};

export default Accordion;
