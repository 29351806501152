import { SAVE_LOGIN_DATA, INIITIAL_SESSION, INIITIAL_SESSION_SWITCHBACK, SWITCHED_USER } from '../../constant';
const initialState = {
  user: {
    Subscriptions: {
      targets: {
        features: {
          sharing: { on: 'no' },
          ical: { on: 'no' },
          report: {
            data: { pdf: null, csv: null },
          },
        },
      },
      insights: {},
      builder: { features: { ical: { on: 'no' } } },
    },
    Org: {
      logo: {},
    },
  },
  switchedUser: false,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LOGIN_DATA:
      return {
        ...action.payload,
      };
    case SWITCHED_USER:
      return { ...state, switchedUser: action.payload };
    case INIITIAL_SESSION:
      return { ...initialState };
    case INIITIAL_SESSION_SWITCHBACK:
      return { ...initialState, token: action.payload.token };
    default:
      return state;
  }
};

export default login;
