import React from 'react';
import { Field, reduxForm } from 'redux-form';

import '../../assets/css/dialog.css';
import '../../assets/css/forms.css';
import renderFieldNormal from '../shared/RenderFieldNormal';
import { required } from '../../helpers';

/**
 * @description This component render login screen
 * @param {function} $handleSubmit
 * @param {function} $onSubmit
 * @param {boolean} $submitting - true/false
 * @param {function} $onReturnKeyPress
 */
let LoginForm = ({ handleSubmit, onSubmit, submitting, onReturnKeyPress }) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset className="login-user">
        <Field
          component={renderFieldNormal}
          onReturnKeyPress={onReturnKeyPress}
          type="email"
          inputmode="email"
          id="username"
          placeholder="Your email address..."
          name="username"
          label="Your email address..."
          validate={[required]}
          autoFocus={true}
        />
      </fieldset>
      <fieldset className="login-pwd">
        <Field
          component={renderFieldNormal}
          onReturnKeyPress={onReturnKeyPress}
          type="password"
          id="password"
          placeholder="Your password..."
          name="password"
          label="Password"
          validate={[required]}
          enterKeyHint="send"
        />
      </fieldset>
      <button type="submit" className="button-primary" disabled={submitting}>
        {submitting ? 'Logging in' : 'Login'}
      </button>
    </form>
  );
};

LoginForm = reduxForm({
  // a unique name for the form
  form: 'LoginForm',
})(LoginForm);

export default LoginForm;
