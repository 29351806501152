import { LOAD_STANDING } from '../../constant';

const standing = (state = { standing: {}, value: { L: {}, M: {}, H: {} }, type: {} }, action) => {
  return action.type === LOAD_STANDING
    ? {
        standing: action.payload.you.standing,
        value: action.payload.you.value,
        type: action.payload.you.type,
      }
    : state;
};

export default standing;
