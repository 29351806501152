import { CLIENTS_TABLE_REQUEST, CLIENTS_TABLE_SUCCESS, CLIENTS_TABLE_FAILURE } from '../../constant';

const initialState = {
  data: [],
  loading: true,
};

const clientsTable = (state = initialState, action) => {
  switch (action.type) {
    case CLIENTS_TABLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CLIENTS_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case CLIENTS_TABLE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default clientsTable;
