export const actionSheetType = {
  goal: {
    customCssClass: 'objective-examples',
    title: 'Examples of objectives...',
    data: [
      'Get a referral from a referral source',
      'Expand work in an industry/niche/region',
      'Originate new client',
      'Obtain new work from existing client',
      'Cross-sell',
      'Secure $100k in fees',
      'Gain access to decision-maker',
    ],
  },
  taskdescription: {
    customCssClass: 'step-suggestions',
    title: 'Suggestions to get you started...',
    data: [
      { key: 'Research...', value: '(their background, market challenges, or LinkedIn connections)' },
      { key: 'Follow up...', value: '(Connect on LinkedIn, call to catch up)' },
      { key: 'Send...', value: '(a relevant blog, article, or introduction)' },
      { key: 'Invite...', value: '(to an upcoming event)' },
      { key: 'Introduce...', value: '(to someone who can help them)' },
      { key: 'Schedule...', value: '(discuss business opportunities over lunch/drinks or virtually)' },
      { key: 'Ask...', value: '(for business or for an introduction)' },
    ],
  },
  taskdue: {
    customCssClass: 'choose-date',
    title: 'When will you complete this step?',
  },
  lostReason: {
    title: 'Reason for losing this target?',
    data: ['Pricing', 'Capabilities', 'Non-Responsive', 'Timing', 'Decision Maker Buy In', 'Competitor', 'Bad Fit'],
  },
};

export const calendarTheme = {
  accentColor: '#2d498d',
  floatingNav: {
    background: '#2d498d',
    chevron: 'black',
    color: '#FFF',
  },
  headerColor: '#2d498d',
  selectionColor: '#2d498d',
  textColor: {
    active: '#FFF',
    default: '#2d498d',
  },
  todayColor: '#FFA726',
  weekdayColor: '#2d498d',
};
