import React from 'react';

// add class showing-action-sheet
const ActionSheetObjective = ({ item, onSelect }) => {
  return (
    <button className="action-item" onClick={onSelect} value={item}>
      {item}
    </button>
  );
};

export default ActionSheetObjective;
