import React from 'react';
import { invoke, store, getBrowserUUID } from '../../helpers';
import history from '../../helpers/history';
import markPP from '../../assets/img/pipelineplus-mark.svg';
import { API_URLS, API_METHODS, API_STATUS_CODE, MESSAGES, ROUTES_PATH, API_PARAMS } from '../../constant';
import toaster from '../shared/toaster.service';
import firebase from './initFirebase.service';
import * as FirebaseMessage from 'firebase/messaging';

import { getAndSaveTargets } from '../../actions/targets/dashboard.action';
import { bindActionCreators } from 'redux';
import { actions as toastrActions } from 'react-redux-toastr';
import { accountInfo } from '../../actions/account/account.action';

let messaging;
const fcmkey = 'gcm.notification.data';
const dashboardSync = (id) => {
  store.dispatch(
    getAndSaveTargets(false, () => {
      id
        ? history.push(`${ROUTES_PATH.TARGETDETAIL}/${id}`)
        : history.push(`${ROUTES_PATH.DASHBOARD}/${API_PARAMS.ACTIVE}`);
    }),
  );
};

export const navigateToScreen = (data, syncOnly) => {
  if (data) {
    const payload = typeof data == 'string' ? JSON.parse(data) : data;
    if (payload && !syncOnly) {
      const name = payload.category ? payload.category.name : '';
      const id = payload.category ? payload.category.id : '';
      switch (name) {
        case 'share':
          store.dispatch(
            getAndSaveTargets(false, () => {
              history.push(`${ROUTES_PATH.TARGETDETAIL}/${id}`);
            }),
          );
          break;
        case 'insights':
          history.push(`${ROUTES_PATH.INSIGHTS}/${API_PARAMS.NEW}`);
          break;
        case 'insight':
          history.push(`${ROUTES_PATH.INSIGHTDETAIL}/${id}`);
          break;
        case 'user':
          store.dispatch(accountInfo());
          break;
        default:
          dashboardSync(id);
      }
    }
  }
};
/**
 * show web notiification in Foreground mode
 * @param {*} payload
 */
export const webNotificationForeGround = (payload) => {
  const toastr = bindActionCreators(toastrActions, store.dispatch);
  const a = toastr.add({
    type: 'confirm',
    title: payload.notification.title,
    position: 'top-right', // This will override the global props position.
    attention: true, // This will add a shadow like the confirm method.
    message: payload.notification.body,
    options: {
      icon: (
        <div className="toastr-avatar">
          <img src={markPP} style={{ maxWidth: '105%' }} alt="" />
        </div>
      ),
      onToastrClick: () => {
        navigateToScreen(payload.data[fcmkey]);
      },
      timeOut: 3000,
      showCloseButton: true, // false by default
      closeOnToastrClick: true,
    },
  });
  toastr.remove(a);
};

const handleElectronNotification = () => {
  window.ipcRenderer.removeListener(window.electron_push.NOTIFICATION_RECEIVED, () => {});
  // Display notification
  window.ipcRenderer.on(window.electron_push.NOTIFICATION_RECEIVED, (_, serverNotificationPayload) => {
    // check to see if payload contains a body string, if it doesn't consider it a silent push
    if (serverNotificationPayload.notification.title && serverNotificationPayload.notification.body) {
      // payload has a body, so show it to the user
      let myNotification = new Notification(serverNotificationPayload.notification.title, {
        body: serverNotificationPayload.notification.body,
      });

      myNotification.onclick = (event) => {
        window.ipcRenderer.send('focus');
        navigateToScreen(serverNotificationPayload.data[fcmkey]);
        event.preventDefault();
      };
      myNotification.onclose = () => {
        navigateToScreen(serverNotificationPayload.data[fcmkey], true);
      };
    } else {
      // payload has no body, so consider it silent (and just consider the data portion)
      const category = JSON.parse(serverNotificationPayload.data.category);
      navigateToScreen({ category });
    }
  });
};

// notification received on mobile app
export const notificationReceived = () => {
  // notification recevied on ios android device
  if (window.FirebasePlugin) {
    window.FirebasePlugin.onMessageReceived(
      (data) => {
        if (data.tap == 'background') {
          if (data[fcmkey] && data[fcmkey] != '') {
            navigateToScreen(data[fcmkey]);
          }
        }
      },
      function (error) {
        console.log('Failed receiving FirebasePlugin message', error);
      },
    );
  } else if (window.FCM) {
    window.FCM.onNotification(function (data) {
      if (data.wasTapped) {
        //Notification was received on device tray and tapped by the user.
        navigateToScreen(data[fcmkey]);
      } else {
        //Notification was received in foreground. Maybe the user needs to be notified.
        // no need of foreground notification as firbase auto refresh is implemented so commenting this code -
        // if we need it we will enable it
        /*
        const msg = data.aps.alert;
        navigator.notification.alert(
          msg.body,
          () => {
            navigateToScreen(data[fcmkey]);
          },
          msg.title
        );
        */
        // For user data update for ios
        if (!data[fcmkey]) {
          data[fcmkey] = {
            category: data.category ? JSON.parse(data.category) : data.title,
          };
        }
        if (!data.body) {
          navigateToScreen(data[fcmkey]);
        }
      }
    });
  }

  // notification received on electron device mac or windows
  if (window.isElectron) {
    handleElectronNotification();
  }
};
/**
 * Function to call api to for register device token for notification
 * @param {Object} data
 */
export const registerDevice = (token) => {
  const dPlatform = window.isElectron ? window.navigator.platform : 'browser';
  const jsonData = {
    token,
    platform: window.cordova ? window.cordova.platformId : dPlatform,
    UUID: window.device ? window.device.uuid : getBrowserUUID(),
  };
  return invoke(
    {
      url: `${API_URLS.USER}${API_URLS.NOTIFICATIONREGISTER}`,
      type: API_METHODS.POST,
      options: jsonData,
    },
    jsonData,
  ).then(
    (_res) => {
      notificationReceived();
    },
    (err) => {
      if (!err.response && err.message === MESSAGES.NETWORKERR) {
        toaster.error(MESSAGES.NOINTERNET);
      } else if (err.response.data.code === API_STATUS_CODE.UNAUTHORIZED) {
        toaster.error(err.response.data.message);
      }
    },
  );
};

const initWebNotification = () => {
  if (window.isElectron) {
    // notification for electron
    import('../renderer').then((module) => {
      module.electronNotification(registerDevice);
    });
  } else {
    // notification for browser
    try {
      messaging = FirebaseMessage.getMessaging(firebase);
      FirebaseMessage.onMessage(messaging, (payload) => {
        if (payload.notification) {
          webNotificationForeGround(payload);
        } else {
          if (!payload.data[fcmkey]) {
            const category = JSON.parse(payload.data.category);
            navigateToScreen({ category });
          } else {
            navigateToScreen(payload.data[fcmkey]);
          }
        }
      });

      FirebaseMessage.getToken(messaging, (token) => {
        registerDevice(token);
      });
    } catch (error) {
      console.log('🚀 ~ file: pushNotification.service.js ~ line 246 ~ initWebNotification ~ error', error);
    }
  }
};

export const initNotification = () => {
  if (!window.FCM) {
    initWebNotification();
  }
};

export const onRefresh = () => {
  if (window.FirebasePlugin) {
    window.FirebasePlugin.onTokenRefresh(function (token) {
      registerDevice(token);
    });
  } else if (window.FCM) {
    window.FCM.onTokenRefresh(function (token) {
      registerDevice(token);
    });
  } else {
    initWebNotification();
  }
};

export const registerPushServiceWorker = () => {
  if ('serviceWorker' in navigator && !window.isElectron) {
    navigator.serviceWorker
      .register('./firebase-messaging-sw.js')
      .then(function (registration) {
        console.log('Registration successful, scope is:', registration.scope);
      })
      .catch(function (err) {
        console.log('Service worker registration failed, error:', err);
      });
  }
};

export const askPermissionToSendNotification = async (_api) => {
  if (window.FirebasePlugin) {
    getPushPermissions(false, (token) => {
      registerDevice(token);
      initNotification();
    });
  } else if (!window.FCM) {
    const messaging = FirebaseMessage.getMessaging(firebase);
    try {
      const _response = await Notification.requestPermission();
      FirebaseMessage.getToken(messaging, (token) => {
        registerDevice(token);
        initNotification();
      });
    } catch (error) {
      console.log('🚀 ~ file: pushNotification.service.js ~ line 293 ~ error', error);
      initNotification();
    }
  } else {
    initNotification();
  }
};

async function getPushPermissions(requested, callback) {
  let hasPermission = await window.FirebasePlugin.hasPermission();
  console.log(hasPermission, 'hasPermission');
  if (hasPermission) {
    getToken((token) => {
      console.log(token, 'token');
      callback(token);
    });
  } else if (!requested) {
    window.FirebasePlugin.grantPermission(
      (e) => {
        console.log(e, 'grantPermission');
        getToken((token) => {
          console.log(token, 'token');
          callback(token);
        });
      },
      function (error) {
        console.log(error);
      },
    );
  }
}

function getToken(callback) {
  window.FirebasePlugin.getToken(
    function (token) {
      callback(token);
    },
    function (error) {
      console.log('Failed to get FCM token', error);
    },
  );
}
