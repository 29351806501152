/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import Lesson from './Lesson.component';
import { loaderArray } from '../../../../constant';
import TileLoader from '../../../shared/TileLoader';
import { gTag } from '../../../../helpers';
import { capitalize } from 'lodash';

/**
 * @description This component render the list of lessons
 * @param {object} $lessons - {done, percent, seconds, when}
 * @param {boolean} $loading - true/false
 */
const Lessons = ({ lessons, loading, setAttention }) => {
  const [show, showHide] = useState({});
  const [showAll, setShowAll] = useState(null);

  const toggleShowClose = (lessons, status) =>
    Object.keys(lessons).reduce((prevValue, currValue) => {
      return {
        ...prevValue,
        [currValue]: status,
      };
    }, {});

  useEffect(() => {
    let show = localStorage.getItem('show') && JSON.parse(localStorage.getItem('show'));
    let showAll = localStorage.getItem('showAll') && JSON.parse(localStorage.getItem('showAll'));
    showAll && setShowAll(showAll);
    show && showHide(show);
    if (showAll) {
      showHide(toggleShowClose(lessons, true));
    } else if (show && !showAll) {
      showHide(show);
    } else if (!showAll) {
      showHide(toggleShowClose(lessons, false));
    }
  }, [lessons]);

  const handleShowHide = (item) => {
    let LessonTypes;
    show ? (LessonTypes = { ...show, [item]: !show[item] }) : (LessonTypes = { [item]: !show[item] });
    showHide(LessonTypes);
    localStorage.setItem('show', JSON.stringify(LessonTypes));
  };

  const showHideAll = () => {
    setShowAll(!showAll);
    localStorage.setItem('showAll', JSON.stringify(!showAll));
    showHide(toggleShowClose(lessons, !showAll));
    !!showAll && localStorage.removeItem('show');
  };
  return (
    <div>
      <header className="intro">
        {window.location.hash === '#/insights/blog' ? (
          <p>These are your company's blog lesson shenanigans you should watch</p>
        ) : (
          <p>
            These short video Lessons answer critical questions about how to develop business. Watch them in sequence or
            skip ahead to topics of interests.
          </p>
        )}
        {/* <p>
          These short video Lessons answer critical questions about how to
          develop business. Watch them in sequence or skip ahead to topics of
          interest.
        </p> */}
      </header>
      <ol className="ai-dashboard lesson-container" id="trainer-list">
        <li className="break collapsible" onClick={showHideAll}>
          <h2 className="open-close-all">
            <label>
              <span>{showAll ? 'Close All' : 'Open All'}</span>
              <span className="caret-wrapper">
                <span className="caret" />
              </span>
            </label>
          </h2>
        </li>
        {loading
          ? loaderArray.map((i) => (
              <li key={i}>
                <TileLoader />
              </li>
            ))
          : Object.keys(lessons).map((item, index) => {
              const description = lessons[item][0].focus_description || '';
              return (
                <React.Fragment key={index}>
                  <li
                    className="break collapsible"
                    onClick={() => {
                      handleShowHide(item);
                      gTag(`Lesson Category: ${capitalize(item)}`, !show[item] ? 'Expanded' : 'Collapsed');
                    }}
                  >
                    <h2>
                      <label>
                        <span>{item}</span>
                        <span className="caret-wrapper">
                          <span className="caret" />
                        </span>
                      </label>
                    </h2>
                    <div className="description">{description}</div>
                  </li>
                  {show[item] &&
                    lessons[item].map((lesson) => <Lesson key={lesson.id} {...lesson} setAttention={setAttention} />)}
                </React.Fragment>
              );
            })}
      </ol>
    </div>
  );
};

export default Lessons;
