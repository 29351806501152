import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { gTag } from '../../../helpers';
import WorksheetForm from '../listing/worksheet/WorksheetForm.component';
import DownloadButton from '../DownloadButton.component';
import { no, DueDateFormatToDisplay, API_PARAMS, trainerSubTabs } from '../../../constant';
import WistiaPlayer from './WistiaPlayer.component';
import icons from '../../../assets/img/icons.svg';
import history from '../../../helpers/history';

/**
 * @description component for tabs option on lesson view page i.e Lessons, Worksheets and Transcript
 * @param {string} activeBar - lessons/worksheets/transcript
 * @param {Object} detail - detail of the lesson
 * @param {function} saveWorksheetMatrix - call to save the worksheet data
 */
const LessonTabs = ({ activeBar, detail, saveWorksheetMatrix }) => {
  switch (activeBar) {
    case API_PARAMS.LESSONS:
      return (
        <div className="lesson active">
          <div className="intro" dangerouslySetInnerHTML={{ __html: detail.description }} />
          {detail.homework ? (
            <>
              <div dangerouslySetInnerHTML={{ __html: detail.homework }} />
            </>
          ) : (
            ''
          )}
        </div>
      );
    case API_PARAMS.WORKSHEETS:
      return (
        <div className="worksheet active">
          {detail.worksheet ? (
            <>
              {detail.worksheet.instructions && (
                <div
                  className="intro"
                  dangerouslySetInnerHTML={{
                    __html: detail.worksheet.instructions,
                  }}
                />
              )}
              {detail.worksheet.download && (
                <DownloadButton
                  url={detail.worksheet.download[0].url}
                  title={detail.worksheet.download[0].title}
                  caption={detail.worksheet.download[0].caption}
                />
              )}
              <WorksheetForm {...detail.worksheet} lessonId={detail.id} saveWorksheetMatrix={saveWorksheetMatrix} />
            </>
          ) : (
            <p>This Lesson has no Worksheet to complete.</p>
          )}
        </div>
      );
    case API_PARAMS.TRANSCRIPT: {
      const { transcript = [] } = detail;
      let title = '',
        url = '',
        caption = '';
      if (transcript && transcript.length) {
        title = transcript[0].title;
        caption = transcript[0].caption;
        url = transcript[0].url;
      }
      return (
        <div className="transcript active">
          {transcript && transcript.length ? (
            <DownloadButton url={url} title={title} caption={caption} />
          ) : (
            <p>This Lesson has no downloadable Transcript.</p>
          )}
        </div>
      );
    }
    default:
      return null;
  }
};

/**
 * @description component for tabs on slider lesson view
 * @param {Object} detail - detail of the lesson
 * @param {string} activeBar - lessons
 * @param {function} saveWorksheetMatrix - call to save the worksheet data
 * @param {function} updateLessonWatchedTime - call to update the lesson watched time
 */
const LessonSlide = (props) => {
  const {
    detail,
    offset,
    updateLessonWatchedTime,
    saveWorksheetMatrix,
    match: { params },
  } = props;
  const [activeBar, setActiveBar] = useState(API_PARAMS.LESSONS);
  const [videoHashId, setVideoHashId] = useState('');

  const Permission = useSelector((state) => state.session.user.Subscriptions.trainer.features.transcripts);

  useEffect(() => {
    setActiveBar(API_PARAMS.LESSONS);
    detail.handle ? setVideoHashId(detail.handle) : setVideoHashId('');
    return () => setVideoHashId('');
  }, [detail]);
  return (
    <div className="swiper-slide swiper-slide-active" style={{ left: `${offset}px` }}>
      <article className={`card lesson ${detail.watched !== no ? 'watched' : ''}`}>
        <div>
          <header>
            <h1 style={{ '--percent-watched': `${detail.watched.percent}%` }}>{detail.title}</h1>
            <div className="tags">
              {detail.duration && (
                <span className="tag">
                  <svg className="icon">
                    <use xlinkHref={`${icons}#clock`} />
                  </svg>
                  {detail.duration}
                </span>
              )}
              <span className="tag">{detail.focus}</span>
              {detail.worksheet && <span className="tagW">worksheet</span>}
              {detail.watched.when && detail.watched.when !== 0 && (
                <span className="tag">
                  <time className="watched" dateTime={detail.watched.when}>
                    {moment(detail.watched.when).format(DueDateFormatToDisplay)}
                  </time>
                </span>
              )}
            </div>
            <div
              className="close-dialog"
              onClick={() => {
                history.push('/trainers/lessons');
                gTag('Tile Close', `Trainer: ${params.id}`);
              }}
            >
              <svg className="icon nav-icon">
                <use xlinkHref={`${icons}#action-close`} />
              </svg>
            </div>
          </header>
          <div className="block video">
            <div className="vid">
              {videoHashId && (
                <WistiaPlayer
                  // ref={videoPlayerRef}
                  key={videoHashId}
                  hashedId={videoHashId}
                  time={detail.watched.seconds} // attempt to set the start point to where the user left off
                  updateLessonWatchedTime={(userId, time) => {
                    updateLessonWatchedTime(userId, time);
                  }}
                />
              )}
            </div>
          </div>
          <main>
            <div className="block context">
              <ul className="button-bar context" data-section-container="div.context-container">
                {trainerSubTabs.map((tab, index) => {
                  if (
                    (tab.name === 'Transcript' && Permission.on !== 'yes') ||
                    (tab.name === 'Worksheet' && !detail.worksheet)
                  ) {
                    return null;
                  } else {
                    return (
                      <li
                        key={index}
                        className={activeBar === tab.status ? 'active' : ''}
                        onClick={() => setActiveBar(tab.status)}
                      >
                        <span>{tab.name}</span>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
            <div className="context-container">
              <LessonTabs detail={detail} activeBar={activeBar} saveWorksheetMatrix={saveWorksheetMatrix} />
            </div>
          </main>
        </div>
      </article>
    </div>
  );
};

export default withRouter(LessonSlide);
