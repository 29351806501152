import { store } from '../../helpers/store';
import { isOnline } from '../../helpers';
import { CREATE_TARGET_REQUEST } from '../../constant';

export const updateOfflineQueue = (payload, status) => {
  const outbox = store.getState().offline.outbox;
  if (outbox.length) {
    outbox.map((item) => {
      if (item.type === CREATE_TARGET_REQUEST && item.payload.id === payload.id) {
        if (status) {
          item.payload.status = status;
          item.payload.revenue = payload.revenue;
          item.meta.offline.effect.data.revenue = payload.revenue;
          item.meta.offline.effect.data.status = status;
        } else {
          item.payload = payload;
          item.meta.offline.effect.data = payload;
        }
      }
    });
    const offline = JSON.stringify({ ...store.getState().offline, outbox });
    localStorage.setItem('offline', offline);
  }
};

export const listenClearOfflineQueue = () => {
  return new Promise((resolve, _rejected) => {
    if (isOnline()) {
      const unsubscribe = store.subscribe(
        () => {
          const offline = store.getState().offline;
          const outbox = offline.outbox;
          const online = offline.online;
          if (outbox.length === 0 || !online) {
            unsubscribe();
            resolve(true);
          }
        },
        (_error) => {
          unsubscribe();
          resolve(true);
        },
      );
    } else {
      resolve(true);
    }
  });
};
