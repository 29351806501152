import { getFirms } from '../../services/targets/dashboard.service';
import { LOAD_FIRMS } from '../../constant';

export const firms = (query) => (dispatch) => {
  return getFirms(query).then(
    (response) => {
      dispatch({
        type: LOAD_FIRMS,
        payload: response,
      });
    },
    () => {
      dispatch({
        type: LOAD_FIRMS,
        payload: [],
      });
    },
  );
};
