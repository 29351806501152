import React from 'react';
import { Field, reduxForm } from 'redux-form';

import '../../assets/css/dialog.css';
import '../../assets/css/forms.css';
import { required, passwordValidation } from '../../helpers';
import { submitChangePassword } from '../../services/account/changePassword.service';
import { Loader } from '../shared/TileLoader';

/**
 * @description This component render change password screen for user
 * @param {object} $input - {name, onBlur, onChange, onDragStart, onFocus, value}
 * @param {string} $label
 * @param {string} $type
 * @param {string} $placeholder
 * @param {boolean} $autoFocus - true/false
 * @param {string} $description
 */
const renderField = ({ input, label, type, placeholder, autoFocus, meta: { touched, error } }) => {
  if (autoFocus) {
    try {
      window.cordova && window.Keyboard.show();
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <li>
      <label>
        Enter your <b>{label}</b> Password<small> *</small>
      </label>
      <input
        {...input}
        type={type}
        placeholder={placeholder}
        className={touched && error ? 'error' : ''}
        autoFocus={autoFocus}
      />
      {touched && error && <span className="error">{error}</span>}
    </li>
  );
};

/**
 * @param {function} $handleSubmit - call for updating the password
 * @param {function} $reset - reset the values of the form
 * @param {boolean} $submitting - true/false
 * @param {boolean} $loading - true/false
 */
let ChangePassword = ({ handleSubmit, reset, submitting, loading }) => {
  const submit = (values) => submitChangePassword(values, reset);
  return (
    <>
      {loading && submitting && <Loader />}
      <form onSubmit={handleSubmit(submit)}>
        <fieldset>
          <ol>
            <li>
              <p>
                If you would like to change your password, fill out the form below. We’ll send you an email to confirm.
              </p>
            </li>
            <Field
              component={renderField}
              type="password"
              placeholder="Current password"
              name="current"
              label="current"
              validate={[required]}
              autoFocus={true}
            />
            <Field
              component={renderField}
              type="password"
              placeholder="New password"
              name="new"
              validate={[required, passwordValidation]}
              label="new"
            />
            <li>
              <input className="save-account" type="submit" disabled={submitting} value="Save Password Change" />
            </li>
          </ol>
        </fieldset>
      </form>
    </>
  );
};
ChangePassword = reduxForm({
  // a unique name for the form
  form: 'ChangePassword',
})(ChangePassword);

export default ChangePassword;
