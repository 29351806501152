import React from 'react';
import closeIcon from '../../../assets/img/circle-close.svg';
import addIcon from '../../../assets/img/circle-plus.svg';
import searchIcon from '../../../assets/img/search-circle.svg';
import warningIcon from '../../../assets/img/warning.svg';
import recipientsIcon from '../../../assets/img/recipients.svg';
import NewCategory from './NewCategory';
import NewStyle from './NewStyle';
import NewContent from './NewContent';
import NewSection from './NewSection';
import NewDiffusionList from './NewDiffusionList';
import './styles.css';
import { CREATE_MODALS } from '../../../constant/insights';
import ConfirmationModal from './ConfirmationModal';
import { pluralize } from '../../../helpers';
import RecipientsModal from './RecipientsModal';

const CreateModal = ({ newElementModalOpen, setNewElementModalOpen, deleteAction, deleteItem, distributionData }) => {
  const handleClose = () => setNewElementModalOpen(false);

  const getModalData = () => {
    switch (newElementModalOpen) {
      case CREATE_MODALS.CATEGORY:
        return { header: 'Add new Tag', component: <NewCategory handleClose={handleClose} /> };
      case CREATE_MODALS.STYLES:
        return { header: 'New Template', component: <NewStyle handleClose={handleClose} /> };
      case CREATE_MODALS.DIFFUSION:
        return { header: 'Add new Distribution List', component: <NewDiffusionList handleClose={handleClose} /> };
      case CREATE_MODALS.CONTENT:
        return { header: 'Add external content', component: <NewContent handleClose={handleClose} /> };
      case CREATE_MODALS.SECTION:
        return { header: 'Add new section', component: <NewSection handleClose={handleClose} /> };
      case CREATE_MODALS.DELETE:
        return {
          header: 'Warning',
          component: (
            <ConfirmationModal handleClose={handleClose} deleteAction={deleteAction} deleteItem={deleteItem} />
          ),
        };
      case CREATE_MODALS.DISTRIBUTION:
        return {
          header: distributionData?.distribution
            ? `${distributionData.distribution} (${distributionData.recipients.length})`
            : pluralize(distributionData.recipients.length, 'contact'),
          component: <RecipientsModal distributionData={distributionData.recipients} />,
        };
      default:
        return '';
    }
  };

  return (
    <div className="category-modal-overlay" onClick={handleClose}>
      <div
        className={`category-modal ${newElementModalOpen === CREATE_MODALS.STYLES && 'styles-modal'} ${newElementModalOpen === CREATE_MODALS.DISTRIBUTION && 'distribution-modal'}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="category-content">
          <img className="close-icon" src={closeIcon} alt="Closing of popup modal" onClick={handleClose} />
          <div className="modal-header">
            <img
              src={
                newElementModalOpen === CREATE_MODALS.CONTENT
                  ? searchIcon
                  : newElementModalOpen === CREATE_MODALS.DELETE
                    ? warningIcon
                    : newElementModalOpen === CREATE_MODALS.DISTRIBUTION
                      ? recipientsIcon
                      : addIcon
              }
              alt="Adding new element"
            />
            <h2>{getModalData().header}</h2>
          </div>
          {getModalData().component}
        </div>
      </div>
    </div>
  );
};

export default CreateModal;

/*
value, name, errors, label, handleChange, clearError, errorMessage
*/
