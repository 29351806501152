import { LOAD_LESSONS, LOAD_LESSON_DETAILS } from '../../constant';

const initialState = {
  data: [],
  listing: {},
  details: {},
};

const lessons = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_LESSONS:
      return {
        ...state,
        ...action.payload,
      };
    case LOAD_LESSON_DETAILS:
      return {
        ...state,
        details: action.payload,
      };
    default:
      return state;
  }
};

export default lessons;
