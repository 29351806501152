import React from 'react';
import { capitalize } from 'lodash';
import icons from '../../assets/img/icons.svg';
import { gTag, downloadContent } from '../../helpers';

/**
 * @description component for button to download transcript in lessons
 * @param {string} $url - to download the pdf
 * @param {string} $title
 * @param {string} $caption
 */
const DownloadButton = ({ url, title, caption }) => {
  return (
    <ul className="downloads">
      <li>
        <div className="dl" onClick={() => gTag('Download Transcript', capitalize(title))}>
          <a onClick={() => downloadContent(url, 'Downloading "' + title + '"...')} className="button">
            <svg className="icon nav-icon">
              <use xlinkHref={`${icons}#button-download`}></use>
            </svg>
            Download
          </a>
        </div>
        <div className="info">
          <span className="title">{title}</span>
          <p>{caption || 'Download a PDF transcript of this lesson so you can review it later.'}</p>
        </div>
      </li>
    </ul>
  );
};

export default DownloadButton;
