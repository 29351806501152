import React from 'react';
import { Link } from 'react-router-dom';

import Table from './Table.component';
import icons from '../../../assets/img/icons.svg';
import { ROUTES_PATH } from '../../../constant';
import { timeHumanize } from '../../../helpers';

const GroupList = ({ list = '', row }) => {
  return row.canExpand ? (
    <h1 className="target-name">
      <div className="expand-wrapper" {...row.getToggleRowExpandedProps()}>
        <span className="caret-wrapper">
          <div className="caret" />
        </span>
        {list}
      </div>
    </h1>
  ) : null;
};

// show userlist with initial in uppercase
const UserList = ({ list = '', original }) => {
  if (list) {
    return (
      <div
        className="user"
        onClick={() => localStorage.setItem('userName', original.first_name + ' ' + original.last_name)}
      >
        <Link
          to={{
            pathname: `${ROUTES_PATH.VIEWER}${ROUTES_PATH.PIPELINE}${ROUTES_PATH.USER_WORKSHEETS}/${list}`,
            state: {
              activeReport: 'progress',
            },
          }}
        >
          <dl className="handle person">
            <dt className="avatar">
              {original.first_name.charAt(0).toUpperCase()}
              {original.last_name.charAt(0).toUpperCase()}
            </dt>
            <dd className="name">{original.first_name ? original.first_name + ' ' + original.last_name : '~'}</dd>
          </dl>
        </Link>
        <svg className="icon">
          <use xlinkHref={`${icons}#trainer`} />
        </svg>
      </div>
    );
  } else {
    return '~';
  }
};

const Activity = ({ list = '' }) => {
  return list ? <span>{timeHumanize(list)}</span> : '~';
};

// show total progress in percentage format
const OverallProgress = ({ percent = '' }) => {
  return (
    <div
      className={percent === 100 ? 'percentDone-bar' : 'percent-bar'}
      style={{ '--percent-watched': `${percent}%` }}
    />
  );
};

// show percentage of video watched
const VidWatched = ({ list = {} }) => {
  if (list.watched.toUpperCase() === 'YES') {
    return (
      <svg className="icon">
        <use xlinkHref={`${icons}#task-done`} />
      </svg>
    );
  } else {
    return Number(list.percent_watched) ? `${list.percent_watched}%` : null;
  }
};

/**
 * show progress in tabular format
 * @param {*} props
 */
const ProgressTable = ({ progressData }) => {
  // memoised progess data to shos
  const data = React.useMemo(() => {
    if (progressData) {
      let groupList = progressData.map((ele) => ele.group);
      let uniqueGroup = groupList.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      return uniqueGroup.map((group) => {
        return {
          groupName: group,
          subRows: progressData.filter((item) => item.group === group),
        };
      });
    }
    return [];
  }, [progressData]);

  // calculate the total number of columns
  const numcols = data.length ? data[0].subRows[0].lessons.length + 4 : '';

  // table columns
  const columns = React.useMemo(() => {
    let fixColumns = [
      {
        Header: 'Group',
        accessor: 'groupName',
        // id: 'groupName',
        Cell: ({ row, value }) => <GroupList list={value} row={row} />,
        className: 'text group',
        disableSortBy: true,
        colGroup: 'now',
        colSpan: numcols,
        colGroupClass: 'text group',
      },
      {
        Header: 'User',
        accessor: 'user_id',
        Cell: ({ row: { original }, value }) => <UserList list={value} original={original} />,
        disableSortBy: true,
        className: 'text user',
        colGroup: 'now',
        colGroupClass: 'text user',
        tooltip: 'Click on a User to see the User’s Worksheets.',
      },
      {
        Header: 'Latest Activity',
        accessor: 'latest_session_unixtime',
        Cell: ({ row: { original }, value }) => <Activity list={value} original={original} />,
        disableSortBy: true,
        colGroup: 'now',
        colGroupClass: 'text latest-activity',
        tooltip: 'The most recent date this User watched a Lesson.',
      },
      {
        Header: 'Overall Progress',
        accessor: 'percent_watched',
        Cell: ({ value }) => <OverallProgress percent={value} />,
        disableSortBy: true,
        colGroup: 'now',
        colGroupClass: 'text progress',
        tooltip: 'The percentage this User has watched of the entire curriculum.',
      },
    ];
    let dynamicColumn = [];
    if (progressData.length) {
      dynamicColumn = progressData[0].lessons.map((lesson, index) => ({
        // Header: `Vid ${lession.number}`,
        Header: `${lesson.lesson}`,
        accessor: `lessons[${index}]`,
        // id: `${lesson.number}`,
        Cell: ({ value }) => <VidWatched list={value} />,
        disableSortBy: true,
        className: 'progress',
        colGroup: 'data',
        colGroupClass: 'text lesson',
        tooltip:
          'The percent of this Lesson this User has watched. A checkmark indicates the User finished watching the entire Lesson.',
      }));
    }
    return fixColumns.concat(dynamicColumn);
  }, [numcols, progressData]);

  const hrows = data
    .map((row) => {
      if (row) {
        return {
          props: {
            className: 'freeze active',
          },
          columns: [],
        };
      } else return {};
    })
    .filter((anyValue) => typeof anyValue !== 'undefined');

  const defaultExpandedRows = data.map(() => {
    return { index: true };
  });

  const initialState = {
    // we don't want to sort this report
    // sortBy: React.useMemo(() => [{id: 'groupName', desc: false}], []),
    expanded: defaultExpandedRows,
  };
  return (
    <section className="panel table progress">
      <div className="scroller horz">
        {data.length ? (
          <Table
            data={data}
            columns={columns}
            headRows={hrows}
            initialState={initialState}
            isExpandable
            getRowProps={(row) => ({
              className: `${row.original.groupName ? 'group-head' : 'user'}`,
            })}
          />
        ) : null}
      </div>
    </section>
  );
};
export default ProgressTable;
