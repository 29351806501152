import React, { useEffect, useState } from 'react';
import addIcon from '../../assets/img/add.svg';
import createDigestIcon from '../../assets/img/create.svg';
import { SearchBtn } from '../shared/SearchBtn';
import '../../assets/css/nav-secondary.css';
import NavTabs from './NavTabs';
import { INSIGHTS_MODAL_TYPES } from '../../constant/modaltypes';
import { INSIGHTS } from '../../constant/insights';
import { isMobile } from '../../helpers';
import NewSearchButton from '../shared/NewSearchButton';
import { useDispatch } from 'react-redux';

/**
 * @description This component render sub navigation menu for insights, trainer and account
 * @param {Array} $tabs - list of secondary tabs
 * @param {string} $type - pipeline, insights, trainer, viewer
 * @param {string} $status - current active tab
 * @param {function} $changeTab - update the status of the tab on click
 */
// const NavSecondary = ({type, tabs, status, openFilter, changeTab}) => (
function NavSecondary({ type, tabs, status, openFilter, changeTab, setModalType, modalType, loading }) {
  const [showAddContent, setShowAddContent] = useState(false);
  const [width, setWidth] = useState(null);
  const dispatch = useDispatch();

  const handleModalType = (type) => {
    if (modalType === type) return;
    if (modalType !== '') {
      setModalType('');
      setTimeout(() => setModalType(type), 200);
    } else {
      setModalType(type);
    }
  };

  const handleCreateDigest = () => {
    handleModalType(INSIGHTS_MODAL_TYPES.DIGEST);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth);
      const updateWindowDimensions = () => {
        setWidth(window.innerWidth);
      };
      window.addEventListener('resize', updateWindowDimensions);
    }
  }, []);

  useEffect(() => {
    setShowAddContent(type === INSIGHTS && width > 768 && !isMobile());
  }, [type, width]);

  return (
    <div id="secondary-container" className={`show nav-${type}`}>
      <nav id={`nav-${type}`} className={`app nav-secondary ${type}`}>
        <NavTabs
          tabs={tabs}
          getData={(status) => changeTab(status)}
          status={status}
          openFilter={openFilter}
          SearchBtn={type === INSIGHTS ? NewSearchButton : SearchBtn}
          handleModalType={handleModalType}
          loading={loading}
        />
        {!loading && !!showAddContent && (
          <div className="buttons-wrapper">
            <button className="add-content" onClick={() => handleModalType(INSIGHTS_MODAL_TYPES.ADD)}>
              <img src={addIcon} alt="add content" />
              Add Content
            </button>
            <button className="add-digest" onClick={handleCreateDigest}>
              <img src={createDigestIcon} alt="create digest" />
              Create Digest
            </button>
          </div>
        )}
      </nav>
    </div>
  );
}

export default NavSecondary;
