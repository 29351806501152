import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import icons from '../../../assets/img/icons.svg';
import { ROUTES_PATH, yes } from '../../../constant';
import { invoke, getUserData } from '../../../helpers';
import { API_URLS, API_PARAMS, API_METHODS } from '../../../constant';
import { getViewerFromAPI } from '../../../services/viewer/viewer.service';

/**
 * @description popup to select firm or user
 * @param {boolean} $active - true/false
 * @param {function} $close - close the dialog
 * @param {array} $groups - list of groups with the users within that group
 * @param {object} $org - { Firm Name }
 * @param {string} $activeId
 * @param {function} $updateReport - update the GraphOf - id and type
 */
const GroupAndFirmPopUp = ({ active, close, groups: _groups, org, activeId, updateReport }) => {
  const node = useRef({});

  const [groupList, setGroupList] = useState([]);

  const changeReport = (id, type) => {
    if (activeId !== id) {
      updateReport(id, type);
    }
  };

  const hideDialog = () => {
    document.removeEventListener('click', handleOutsideClick, false);
    close();
  };

  // close filter dialog if click outside of dialog
  const handleOutsideClick = (e) => {
    if (active && !node.current.contains(e.target)) {
      hideDialog();
    }
  };

  const userData = getUserData();
  // const getUsersData = (status = API_PARAMS.ACTIVE) => {
  //   return invoke({ url: API_URLS.ORG + userData.user.Org.id + '/report/progress', type: API_METHODS.GET, params: { status } })
  // };

  const getUsersMemberships = (status = API_PARAMS.ACTIVE) => {
    return invoke({ url: API_URLS.ORG + userData.user.Org.id + '/groups', type: API_METHODS.GET, params: { status } });
  };

  useEffect(() => {
    getViewerFromAPI().then((result) => {
      getUsersMemberships().then((response) => {
        const map = {};
        let j = 1;
        for (let i = 0; i < response.length; i++) {
          if (response[i].member_access.split(',').includes(userData.user.id) && !map[response[i].name]) {
            map[response[i].name] = j++;
          }
        }
        const filterThis = result.groups.filter((element) => map[element.name]);
        setGroupList(filterThis);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Add eventlisteners for swiper after initializing
  useEffect(() => {
    document.addEventListener('click', handleOutsideClick, true);
    return () => document.removeEventListener('click', handleOutsideClick, true);
  });
  return (
    <div className={`dialog info-only ${active ? 'active' : ''}`} id="viewer-switcher">
      <div className="box">
        <div className="close-dialog" onClick={hideDialog}>
          <svg className="icon nav-icon">
            <use xlinkHref={`${icons}#action-close`} />
          </svg>
        </div>
        <div className={`inner ${active && 'keyboard'}`} ref={node}>
          <div className="info-block">
            <fieldset>
              <legend>
                <svg className="icon">
                  <use xlinkHref={`${icons}#target`} />
                </svg>
                Select a View or User
              </legend>
              <p>This allows you to change how the data in this Report is displayed.</p>
              <p>
                {org.access === yes ? (
                  <>
                    Click on your{' '}
                    <b className="report-tag">
                      <svg className="icon">
                        <use xlinkHref={`${icons}#org-org`} />
                      </svg>{' '}
                      Firm
                    </b>{' '}
                    to see Groups.{' '}
                  </>
                ) : (
                  <></>
                )}
                Click on a{' '}
                <b className="report-tag">
                  <svg className="icon">
                    <use xlinkHref={`${icons}#org-group`} />
                  </svg>{' '}
                  Group
                </b>{' '}
                to see Users within that Group. Click on a User’s name to see that User’s Pipeline.
              </p>
              <div className="scroller">
                <div className="orgchart">
                  {org.access === yes ? (
                    <div
                      className={activeId === org.id ? 'branch org node active' : 'branch org node'}
                      key={org.id}
                      data-id="org"
                      onClick={() => changeReport(org.id, 'org')}
                    >
                      <dl className="handle org">
                        <dt className="avatar">
                          <svg className="icon">
                            <use xlinkHref={`${icons}#org-org`} />
                          </svg>
                        </dt>
                        <dd className="name">{org.name}</dd>
                      </dl>
                      <div className="context">{activeId === org.id ? 'Showing' : 'Show'} the Firm View</div>
                      {/* <svg className="icon"><use xlinkHref={`${icons}#reports`} /></svg> */}
                    </div>
                  ) : (
                    <> </>
                  )}
                  {/* a button for each group */}
                  {groupList.map((group) => (
                    <React.Fragment key={group.id}>
                      <div
                        className={activeId === group.id ? 'branch group node active' : 'branch group node'}
                        key={group.id}
                        onClick={() => changeReport(group.id, 'group')}
                      >
                        <dl className="handle group">
                          <dt className="avatar">
                            <svg className="icon">
                              <use xlinkHref={`${icons}#org-group`} />
                            </svg>
                          </dt>
                          <dd className="name">{group.name}</dd>
                        </dl>
                        <div className="context">
                          {activeId === group.id ? 'Showing' : 'Show'} the User View for this Group
                        </div>
                        {/* <svg className="icon"><use xlinkHref={`${icons}#reports`} /></svg> */}
                      </div>
                      <p className="help">Users who belong to this Group:</p>

                      {/* a button for each member inside this group */}
                      {group.members.map((member) => (
                        <Link
                          className="node user"
                          key={member.uid}
                          onClick={() => {
                            hideDialog();
                            localStorage.setItem('user_name', member.first_name + ' ' + member.last_name);
                          }}
                          to={{
                            pathname: `/viewer/pipeline${ROUTES_PATH.PEOPLE}/${member.uid}`,
                            state: {
                              activeReport: 'overview',
                              userName: member.first_name + ' ' + member.last_name,
                            },
                          }}
                        >
                          <dl className="handle person">
                            <dt className="avatar">
                              {member.first_name.charAt(0).toUpperCase()}
                              {member.last_name.charAt(0).toUpperCase()}
                            </dt>
                            <dd className="name">{member.first_name + ' ' + member.last_name || '~'}</dd>
                          </dl>
                          <div className="context">Show this User’s Pipeline</div>
                          {/* <svg className="icon"><use xlinkHref={`${icons}#targets`} /></svg> */}
                        </Link>
                      ))}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </fieldset>
            {/*
            <div className="close-dialog" onClick={hideDialog}>
              <svg className="icon nav-icon"><use xlinkHref={`${icons}#action-close`} /></svg>
            </div>
            */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupAndFirmPopUp;
