import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENTID;
const packageId = process.env.REACT_APP_PACKAGE_NAME;

export const redirect_uri = window?.cordova?.platformId
  ? `${packageId}://${domain}/capacitor/${packageId}/callback`
  : window.location.origin;

export const Auth0ProviderWithHistory = ({ children, history }) => {
  const onRedirectCallback = () => {
    history.replace(window.location.hash + window.location.search);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri,
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
