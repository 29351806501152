import { GET_ATTENTION, REMOVE_ATTENTION } from '../../constant';

export const setAttention = (obj) => (dispatch) => {
  dispatch({
    type: GET_ATTENTION,
    payload: obj,
  });
};

export const removeAttention = () => (dispatch) => {
  dispatch({
    type: REMOVE_ATTENTION,
  });
};
