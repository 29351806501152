import React from 'react';

import { Link } from 'react-router-dom';
import { ROUTES_PATH, ROUTES_SUB_PATH } from '../../constant';
import { SEARCH_MODAL } from '../../constant/modaltypes';

/**
 * @desc secondary tabs for insights and trainer
 * @param {Array} $tabs - list of secondary tabs
 * @param {Array} $status - active seconday tab
 * @param {function} $getData - call to get the data for the active tab
 */
const NavTabs = ({ tabs, status, getData, openFilter, SearchBtn, handleModalType, loading }) => {
  const isLessonTab = window.location.hash === `#${ROUTES_PATH.TRAINER}${ROUTES_SUB_PATH.TRAINER.LESSONS}`;
  const isBlogTab = window.location.hash === `#${ROUTES_PATH.INSIGHTS}${ROUTES_SUB_PATH.INSIGHTS.BLOG}`;

  if (isLessonTab) {
    return (
      <ul>
        {SearchBtn && isLessonTab && <SearchBtn openFilter={openFilter} />}
        <li className="status sub-tabs">
          <ul className="button-bar ui status has-value">
            {tabs?.map((tab) => (
              <li
                className={status === tab.status ? 'active' : ''}
                onClick={() => {
                  if (tab.link) return;
                  getData(tab.status);
                }}
                key={tab.name}
              >
                {tab.link ? <Link to={tab.link}>{tab.name}</Link> : <a>{tab.name}</a>}
              </li>
            ))}
          </ul>
        </li>
      </ul>
    );
  } else {
    return (
      <ul>
        {!loading && !!SearchBtn && <SearchBtn onClick={() => handleModalType(SEARCH_MODAL.INSIGHTS)} />}
        {isBlogTab}
        <li className="status sub-tabs">
          <ul className="button-bar ui status has-value">
            {tabs?.map((tab) => (
              <li
                className={status === tab.status ? 'active' : ''}
                onClick={() => {
                  if (tab.link) return;
                  getData(tab.status);
                }}
                key={tab.name}
              >
                {tab.link ? <Link to={tab.link}>{tab.name}</Link> : <a>{tab.name}</a>}
              </li>
            ))}
          </ul>
        </li>
      </ul>
    );
  }
};
export default NavTabs;
