import { groupBy, differenceBy } from 'lodash';
import {
  getLessons,
  getWorksheets,
  getResources,
  getLessonDetails,
  updateWatchTime,
  getFirmVideos,
  removeFirmVideo,
  updateFirmVideo,
} from '../../services/trainer/trainer.service';
import {
  API_PARAMS,
  LOAD_LESSONS,
  LOAD_LESSON_DETAILS,
  LOAD_WORKSHEETS,
  LOAD_RESOURCES,
  SAVE_WORKSHEET_REQUEST,
  SAVE_WORKSHEET_ROLLBACK,
  SAVE_WORKSHEET_COMMIT,
  API_URLS,
  API_METHODS,
  GET_FIRM_CONTENT,
  REMOVE_FIRM_CONTENT,
  UPDATE_FIRM_CONTENT,
  LESSON_FILTER_KEYWORD,
} from '../../constant';
import { isOnline, getFirmProjectId } from '../../helpers';
import { getInsight, insertDocs, deleteDoc } from '../../helpers/dbStorage';
import { hideLoader } from '../shared/loader.action';

/**
 * Get trainer data from Local DB based on status
 * @param {string} status Trainer tabs
 */
const getTrainerDataFromLocalDB = (status) => getInsight(status);

/**
 * get lessons
 * @param {string} status
 */
export const getAndSaveLessons = (status) => async (dispatch) => {
  let response = [];

  if (isOnline()) {
    // get lessons from server
    response = await getLessons();
  } else {
    // get lessons from local DB
    const localData = await getTrainerDataFromLocalDB(status);
    response = localData.docs || [];
  }
  if (response.length) {
    response.map((item) => {
      item.status = status;
      // if (item.description) item.description = item.description.replace(/<div>\r\n\r\n|\r\n\r\n<\/div>|<div>|<\/div>/g, '');
      return item;
    });
    insertDocs(response);

    const localPromise = getTrainerDataFromLocalDB(status);
    localPromise &&
      localPromise.then((lessons) => {
        if (lessons) {
          const needtoDeleted = differenceBy(lessons.docs, response, 'id');
          needtoDeleted.forEach((lesson) => deleteDoc(lesson.id));
        }
      });

    const lessons = [...response];

    // sort lessons by a fixed order
    // the server sends the payload already sorted
    // response.sort((a, b) => LESSONS_ORDER.indexOf(a.focus) - LESSONS_ORDER.indexOf(b.focus));

    dispatch(hideLoader());
    dispatch({
      type: LOAD_LESSONS,
      payload: {
        listing: groupBy(response, 'focus'),
        data: lessons,
      },
    });
  }
};

export const getLessonFilterKeyword = (keyword) => (dispatch) => {
  dispatch({
    type: LESSON_FILTER_KEYWORD,
    keyword,
  });
};

/**
 * get Worksheets
 * @param {string} status
 */
export const getAndSaveWorksheets = (status) => async (dispatch) => {
  let response = [];

  if (isOnline()) {
    // get worksheet from server
    response = await getWorksheets();
  } else {
    // get worksheet from local DB
    const localData = await getTrainerDataFromLocalDB(status);
    response = localData.docs || [];
    // sort response by lesson number
    response.sort((a, b) => a.number - b.number);
  }

  if (response.length) {
    response.map((item) => (item.status = API_PARAMS.WORKSHEETS));
    insertDocs(response);

    const localPromise = getTrainerDataFromLocalDB(status);
    localPromise &&
      localPromise.then((worksheets) => {
        if (worksheets) {
          const needtoDeleted = differenceBy(worksheets.docs, response, 'id');
          needtoDeleted.forEach((worksheet) => deleteDoc(worksheet.id));
        }
      });

    dispatch({
      type: LOAD_WORKSHEETS,
      payload: response,
    });
  }
};

/**
 * get Resources
 * @param {string} status
 */
export const getAndSaveResources = (status) => async (dispatch) => {
  let response = [];

  if (isOnline()) {
    // get resources from server
    response = await getResources();
  } else {
    // get resources from local DB
    const localData = await getTrainerDataFromLocalDB(status);
    response = localData.docs || [];
    // sort resouces by date
    response.sort((a, b) => new Date(b.date) - new Date(a.date));
  }

  if (response.length) {
    response.map((item) => (item.status = API_PARAMS.RESOURCES));
    insertDocs(response);

    const localPromise = getTrainerDataFromLocalDB(status);
    localPromise &&
      localPromise.then((resources) => {
        if (resources) {
          const needtoDeleted = differenceBy(resources.docs, response, 'id');
          needtoDeleted.forEach((resource) => deleteDoc(resource.id));
        }
      });

    dispatch({
      type: LOAD_RESOURCES,
      payload: response,
    });
  }
};

/**
 * funtion to get trainer data according to type
 * @param {string} status Trainer tabs
 */
export const getTrainerData = (status) => (dispatch) => {
  switch (status) {
    case API_PARAMS.WORKSHEETS:
      dispatch(getAndSaveWorksheets(status));
      break;
    case API_PARAMS.RESOURCES:
      dispatch(getAndSaveResources(status));
      break;
    default:
      dispatch(getAndSaveLessons(status));
      break;
  }
};

/**
 * Get lesson details
 * @param {string} lessonId
 */
export const getLessonDetailsData = (lessonId) => (dispatch) => {
  return getLessonDetails(lessonId).then((response) => {
    dispatch({
      type: LOAD_LESSON_DETAILS,
      payload: response,
    });
  });
};

/**
 * update watch time of lesson video
 * @param {string} lessonId
 * @param {number} time
 */
export const updateLessonWatchedTime = (lessonId, time) => () => updateWatchTime(lessonId, time);

/**
 * Action for saving worksheet matrix form
 * @param {string} lessonId
 * @param {object} matrix
 */
export const saveWorksheetMatrix = (lessonId, matrix) => (dispatch, getState) => {
  // get lesson from redux store to handle offline mode
  const {
    worksheets,
    lessons: { data },
  } = getState();
  const allLessons = worksheets.length ? worksheets : data;
  const payload = allLessons.find((item) => item.id === lessonId);
  payload.worksheet.matrix = [...matrix];
  // // show success message
  // setTimeout(() => {
  //     toaster.success(MESSAGES.saveWorksheet);
  //     // close all expanded worksheets
  //     const allExpandedNodes = document.querySelectorAll('.expanded');
  //     allExpandedNodes.forEach(node => node.classList.remove('expanded'));
  // }, 1000);
  return dispatch({
    type: SAVE_WORKSHEET_REQUEST,
    payload,
    meta: {
      offline: {
        // the network action to execute:
        // stringify data to send as query parameter
        effect: {
          apiInfo: { url: `${API_URLS.WORKSHEETS}${lessonId}`, type: API_METHODS.POST },
          data: { matrix: JSON.stringify(matrix) },
        },
        // action to dispatch when effect succeeds:
        commit: { type: SAVE_WORKSHEET_COMMIT },
        // action to dispatch if network action fails permanently:
        rollback: { type: SAVE_WORKSHEET_ROLLBACK },
      },
    },
  });
};

/**
 * Fetch User generated content
 */
export const getFirmContents = () => (dispatch) => {
  return getFirmVideos(getFirmProjectId()).then((response) =>
    dispatch({
      type: GET_FIRM_CONTENT,
      payload: response,
    }),
  );
};

/**
 * Delete User generated content
 */
export const removeFirmContents = (hashed_id) => (dispatch) => {
  return removeFirmVideo(hashed_id).then((response) =>
    dispatch({
      type: REMOVE_FIRM_CONTENT,
      payload: response,
    }),
  );
};

/**
 * Update User generated content details
 */
export const updateFirmContents = (hashed_id, title, description) => (dispatch) => {
  return updateFirmVideo(hashed_id, title, description).then((response) =>
    dispatch({
      type: UPDATE_FIRM_CONTENT,
      payload: response,
    }),
  );
};
