import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { isEqual } from 'lodash';

import getVisibleTargets from '../../../selectors/targets/getVisibleTargets.selector';
import { DashBoard, NavSecondaryTargets, CreateTargetButton, HUDPopup } from '../../../components';
import { CreateTarget, FilterTarget } from '../..';
import { API_PARAMS, sharedStatus, DueDateFormatForAPI, ROUTES_PATH } from '../../../constant';
import {
  getAllTiles,
  showModal,
  clearFilter,
  clearSearch,
  showPipelineControlsModal,
  getUsersHUD,
  onTargetRefresh,
  showLoader,
  setAttention,
  getPeopleTargets,
  showFilter,
} from '../../../actions';
import dashboardHOC from '../../shared/DashboardHOC.container';
import { hidePipelineControlsModal } from '../../../actions';
import { redirectionAllowed, validateUser, getUserId } from '../../../helpers';

// Dashboard container to wrap all the components avalible on dashboard
class DashBoardContainer extends Component {
  state = {
    show: true,
    sharedStatusPendingTargets: [],
    targets: [],
    activeHUD: '',
  };
  callHud = true;

  componentDidMount() {
    if (!redirectionAllowed(validateUser(), 'targets')) {
      localStorage.clear();
      this.props.history.push(ROUTES_PATH.LOGIN);
    } else {
      const {
        match: {
          params: { status },
        },
      } = this.props;
      if (status) {
        this.init(status);
      } else {
        this.init(API_PARAMS.ACTIVE);
      }
      this.ref = React.createRef();
      this.filterTargets(this.props.targets);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      targets,
      filterBy,
      sortBy,
      loading,
      dateRange,
      hudUpdate,
      getUsersHUD,
      match: { params = {} },
    } = this.props;
    if (!isEqual(prevProps.targets, targets)) {
      this.filterTargets(targets);
    }
    if (prevProps.filterBy !== filterBy || prevProps.sortBy !== sortBy) {
      this.scrollUp();
      this.filterTargets(targets);
    }
    // this check is for if there is any offline request pending first sync the request then load hud
    // if there is a loader then request in process
    // loader is finished load hud
    const time = setTimeout(() => {
      clearTimeout(time);
      if (!loading && this.callHud && hudUpdate) {
        this.callHud = false;
        getUsersHUD(params.uid || '');
      }
    }, 100);

    // Viewer: Get people targets
    if (dateRange && !isEqual(prevProps.dateRange, dateRange)) {
      this.getPeopleTargets(this.state.status);
    }
  }

  filterTargets = (nextTargets) => {
    const currentUserId = getUserId();
    const sharedStatusPendingTargets = [];
    const targets = [];
    nextTargets.forEach((item) => {
      const isTileOwnerCurrentUser = item.owner_id === currentUserId;
      if (item.shared === sharedStatus.pending && !isTileOwnerCurrentUser) {
        sharedStatusPendingTargets.push(item);
      } else if (item.shared !== sharedStatus.pending || isTileOwnerCurrentUser) {
        targets.push(item);
      }
    });
    // this is for scrolling to the recently created item
    if (this.props.attention.id) {
      this.props.getAttention({
        list: targets.concat(sharedStatusPendingTargets),
        attention: this.props.attention,
      });
    }
    this.setState({ sharedStatusPendingTargets, targets });
  };

  // initialise the data for tabs like active/ onhold/ completed
  init = (status) => {
    const { attention, getAllTiles, isPeopleTarget } = this.props;
    this.scrollUp();
    const showLoader = attention.id && !attention.from;
    isPeopleTarget ? this.getPeopleTargets(status, showLoader) : getAllTiles(status, showLoader);
    this.setState({ status });
  };

  getTargetByStatus = (status) => {
    const { history } = this.props;
    history.push({
      pathname: `${ROUTES_PATH.DASHBOARD}/${status}`,
    });
    this.init(status);
  };

  scrollUp = () => {
    if (this.ref && this.ref.current) {
      this.ref.current.scrollTop = 0;
    }
  };

  openCreateTargetModal = () => {
    this.callHud = true;
    this.props.openCreateTargetModal({ open: true });
  };

  onRefresh = (status) => {
    const { onRefresh, isPeopleTarget } = this.props;
    if (isPeopleTarget) {
      this.init(status);
      return true;
    } else {
      return onRefresh(status);
    }
  };

  // Viewer: get people dashboard targets
  getPeopleTargets = (status, showLoader) => {
    const {
      getPeopleTargets,
      dateRange,
      match: { params },
    } = this.props;
    if (dateRange) {
      const range = {
        start: moment(dateRange.startDate).format(DueDateFormatForAPI),
        end: moment(dateRange.endDate).format(DueDateFormatForAPI),
      };
      getPeopleTargets(status, range, params.uid, showLoader);
    }
  };

  render = () => {
    const {
      match: { params },
      dateRange = {},
      isPeopleTarget = false,
      history: _history,
      filterBy,
      loading,
      hud,
      targetSubscriptions,
      searchKeyword,
      openFilter,
      openPipelineControls,
      subscription,
      setAttention,
    } = this.props;
    const uid = isPeopleTarget ? params.uid : null;
    const range = {
      start: moment(dateRange.startDate).format(DueDateFormatForAPI),
      end: moment(dateRange.endDate).format(DueDateFormatForAPI),
    };
    let _user_name = localStorage.getItem('user_name');
    return (
      <>
        {/* <button
          className="go-back"
          onClick={() => {
            localStorage.removeItem("user_name");
            history.goBack();
          }}
        >
          <span className="icon-wrapper">
            <svg className="icon nav-icon">
              <use xlinkHref={`${icons}#button-cancel`} />
            </svg>
          </span>
          <small>Back</small>
          <span className="user-name">{user_name}</span>
        </button> */}
        <DashBoard
          targets={this.state.targets}
          status={this.state.status}
          ref={this.ref}
          filter={filterBy}
          loading={loading}
          shared={this.state.sharedStatusPendingTargets}
          hud={hud}
          onRefresh={() => this.onRefresh(this.state.status)}
          targetSubscriptions={targetSubscriptions}
          setAttention={setAttention}
          searchKeyword={searchKeyword}
          isPeopleTarget={isPeopleTarget}
          uid={uid}
          getTargetByStatus={this.init}
          openFilter={openFilter}
          openPipelineControls={openPipelineControls}
        />
        {/* React Portal to transfer Modal popup to the root element of DOM */}
        {ReactDOM.createPortal(
          <>
            <CreateTarget isPeopleTarget={isPeopleTarget} uid={uid} range={range} />
            <FilterTarget isPeopleTarget={isPeopleTarget} status={this.state.status} />
            <HUDPopup
              active={this.props.open}
              hud={hud}
              isPeopleTarget={isPeopleTarget}
              getTargetByStatus={this.getTargetByStatus}
              uid={uid}
              openHUD={this.openHUD}
              subscription={subscription}
              hideDialog={this.props.hideDialog}
            />
          </>,
          document.getElementById('root'),
        )}
        <CreateTargetButton openCreateTargetModal={this.openCreateTargetModal} />
        {!isPeopleTarget && (
          <NavSecondaryTargets
            openFilter={openFilter}
            openPipelineControls={openPipelineControls}
            getTargetByStatus={this.getTargetByStatus}
            status={this.state.status}
          />
        )}
      </>
    );
  };
}

// map dispatch to props
const mapDispatchToProps = (dispatch) => ({
  openCreateTargetModal: (modalProps) => {
    dispatch(showModal({ modalProps }));
  },
  getAllTiles: (status, _attention) => {
    dispatch(getAllTiles(status));
    dispatch(clearSearch()); // clear search
    dispatch(clearFilter(status)); // clear filter
  },
  getUsersHUD: (userId) => dispatch(getUsersHUD(userId)),
  openFilter: () => dispatch(showFilter()),
  openPipelineControls: () => dispatch(showPipelineControlsModal()),
  onRefresh: (status) => dispatch(onTargetRefresh(status)),
  setAttention: (id) => dispatch(setAttention({ id })),
  clearSearch: () => dispatch(clearSearch()),
  clearFilter: (status) => dispatch(clearFilter(status)),
  showLoader: () => dispatch(showLoader()),
  getPeopleTargets: (status, range, userId, attention) => {
    if (!attention) {
      dispatch(showLoader());
    }
    dispatch(getPeopleTargets(status, range, userId));
    dispatch(clearSearch()); // clear search
    dispatch(clearFilter(status)); // clear filter
  },
  hideDialog: () => {
    dispatch(hidePipelineControlsModal());
  },
});

// map state to props
const mapStateToProps = (state, ownProps) => {
  return {
    targets: ownProps.isPeopleTarget ? getVisibleTargets(state, true) : getVisibleTargets(state),
    filterBy: state.filterTarget.filterModalProps.filterBy,
    searchKeyword: state.filterTarget.filterModalProps.keyword,
    sortBy: state.filterTarget.filterModalProps.sortBy,
    loading: state.loader,
    hud: state.hud,
    targetSubscriptions:
      state.session.user && state.session.user.Subscriptions
        ? state.session.user.Subscriptions.targets
        : { features: { sharing: { on: 'no' } } },
    attention: state.attention,
    hudUpdate: state.hudUpdate,
    open: state.pipelineControls.controlsModalProps.open,
    subscription: state.session.user ? { ...state.session.user.Subscriptions } : {},
  };
};

export default dashboardHOC(connect(mapStateToProps, mapDispatchToProps)(DashBoardContainer));
