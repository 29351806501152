// set app badge count
export const setBadgeCount = (count = 0) => {
  if (window.isElectron) {
    // window.require("electron").remote.app.setBadgeCount(count);
  }
  if (window.cordova) {
    count > 0
      ? window.cordova.plugins.notification.badge.set(count)
      : window.cordova.plugins.notification.badge.clear();
  }
};
