import React from 'react';
import { withRouter } from 'react-router-dom';
import { capitalize } from 'lodash';

import { Icon } from '..';
import '../../assets/css/nav-secondary.css';
import { ROUTES_PATH, SWIPER_NAV_NAME } from '../../constant';
import { gTag } from '../../helpers';

//on tile detail page back icon with the text
const Nav = (props) => {
  const { title = '', path = '' } = SWIPER_NAV_NAME[props.routeName] || {};
  return (
    <nav id="nav-tiles-swipe" className={`${props.isPeopleTarget ? 'controls-inline' : 'app'} nav-secondary`}>
      {title && (
        <ul>
          <li
            className="back-to-dashboard btn"
            onClick={() => {
              props.confirmCancel(path);
              gTag('Back To', capitalize(title));
            }}
          >
            <a>
              <Icon iconId="action-left" />
              <span className="label" style={{ display: 'block' }}>
                Back to {title}
              </span>
            </a>
          </li>
        </ul>
      )}
    </nav>
  );
};

const NavSecondaryTargetDetail = (props) => {
  const pathArr = props.history.location.pathname.split('/');
  const routeName = pathArr[1];
  const confirmCancel = () => {
    if (`/${routeName}` === ROUTES_PATH.TARGETDETAIL) {
      props.confirmCancel(routeName);
    } else if (routeName.includes('viewer')) {
      let getThisEndpoint = '/viewer/pipeline/people/' + props.match.params.uid;
      props.history.push(getThisEndpoint);
    } else if (routeName.includes('insight')) {
      props.history.push('/insights/new');
    } else {
      props.history.push('/trainers/lessons');
    }
  };

  return props.isPeopleTarget ? (
    <Nav confirmCancel={confirmCancel} routeName={routeName} isPeopleTarget={props.isPeopleTarget} />
  ) : (
    <div id="secondary-container" className="show nav-tiles ">
      {/* Secondary Navigation - Targets Swipe */}
      <Nav confirmCancel={confirmCancel} routeName={routeName} />
    </div>
  );
};

export default withRouter(NavSecondaryTargetDetail);
