import { ROUTES_PATH } from '../constant';
import { Insights, InsightDetail } from '../containers';

const insightRoutes = [
  {
    path: ROUTES_PATH.INSIGHTS + '/:status',
    exact: false,
    component: Insights,
    isProtected: true,
  },
  {
    path: `${ROUTES_PATH.INSIGHTDETAIL}/:id`,
    exact: false,
    component: InsightDetail,
    isProtected: true,
  },
];

export default insightRoutes;
