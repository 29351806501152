import React from 'react';
import { Link } from 'react-router-dom';

/**
 * @desc filter out reports we don't want to show in the nav
 * @param {object} $reports - contain trainer reports - progress, worksheet and user_worksheet
 * @param {string} $url
 * @param {string} $activeReport - display the name of the selected report in pipleine and trainer reports
 */

const ReportTypes = ({ reports, url, activeReport }) => {
  return (
    <ol className="org-chart reports">
      {reports &&
        Object.entries(reports)
          .filter(function (v) {
            return v[1].show_in_nav ? v : null;
          })
          .map((report, k) => (
            <li key={k} className={activeReport === report[0] ? 'active' : ''}>
              <Link
                to={{
                  pathname: `${url}/${report[0]}`,
                  state: {
                    activeReport: report[0],
                  },
                }}
              >
                <span className="title">{report[1].name}</span>
                <small>{report[1].description}</small>
                <div className="caret right" />
              </Link>
            </li>
          ))}
    </ol>
  );
};

export default ReportTypes;
