import { HIDE_NEW_LOOK_INFO, SHOW_NEW_LOOK_INFO } from '../../constant';

const newLookPopUp = (state = { open: false }, action) => {
  switch (action.type) {
    case SHOW_NEW_LOOK_INFO:
    case HIDE_NEW_LOOK_INFO:
      return { open: action.open };
    default:
      return state;
  }
};

export default newLookPopUp;
