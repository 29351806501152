import React from 'react';

import icons from '../../assets/img/icons.svg';

const RenderFieldWithActionSheet = ({
  input,
  placeholder,
  type,
  label,
  customclass,
  maxLength,
  toggleActionSheet,
  icon,
  readOnly = false,
  meta: { touched, error, active },
}) => {
  const hasValue = input.value && input.value.trim() && !active;
  const name = input.name;

  const openActionSheet = (e) => {
    e.preventDefault();
    toggleActionSheet(name);
  };

  return (
    <div
      className={`field-line ${customclass} ${active ? 'focused' : ''}  ${error && touched ? 'error ' : ''}${
        hasValue && 'has-value'
      }`}
    >
      <label>{hasValue ? label : placeholder}</label>
      <input
        type={type}
        name={name}
        maxLength={maxLength}
        autoComplete="off"
        {...input}
        readOnly={readOnly}
        onClick={(e) => {
          readOnly && openActionSheet(e);
        }}
      />
      <button
        className="action-sheet-btn"
        onClick={(e) => {
          openActionSheet(e);
        }}
        type="button"
      >
        <svg className="icon nav-icon">
          <use xlinkHref={`${icons}#${icon}`} />
        </svg>
        <span className="caret" />
      </button>
    </div>
  );
};

export default RenderFieldWithActionSheet;
