import React from 'react';
import { withRouter } from 'react-router-dom';
import { NavMain } from '../components';
import { NONAVBAR } from '../constant';
import { listenToFirebaseDB } from '../services/shared/sync.service';
import { onRefresh } from '../services/shared/pushNotification.service';
import { gTag, setPlatformOnGA } from '../helpers/gaTag';
import { getUserId, getUserData } from '../helpers';

// HOC to show hide Navbar
class Layout extends React.Component {
  componentDidMount() {
    listenToFirebaseDB();
    onRefresh();
    gTag('User', getUserId());
    setPlatformOnGA();
  }

  render() {
    const showNavBar =
      NONAVBAR.findIndex((item) => {
        const path = `/${this.props.location.pathname.split('/')[1]}`;
        return item.indexOf(path) > -1;
      }) === -1;
    return (
      <>
        {showNavBar ? <NavMain userData={getUserData()?.user} /> : <></>}
        {this.props.children}
      </>
    );
  }
}

export default withRouter(Layout);
