import React, { useCallback, useEffect, useState } from 'react';
import { setSelectedRecipients } from '../../../../../actions';
import { ReactComponent as InfoIcon } from '../../../../../assets/img/info-square.svg';
import { ReactComponent as UserIcon } from '../../../../../assets/img/user.svg';
import { ReactComponent as AddIcon } from '../../../../../assets/img/add.svg';
import { CREATE_MODALS } from '../../../../../constant/insights';
import './styles.css';
import '../../styles.css';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { selectedRecipients, sortAlphabetically } from '../../../../../helpers';
import { MESSAGES } from '../../../../../constant';

const Recipients = ({ values, setValues, initialRecipients, setNewElementModalOpen }) => {
  const [changedSelection, setChangedSelection] = useState(false);
  const [selectAll, setSelectAll] = useState(true);
  const [selectedDiffusions, setSelectedDiffusions] = useState([]);
  const dispatch = useDispatch();
  const { recipients } = values;

  sortAlphabetically(recipients, 'firstName');

  const noRecipientsSelected = selectedRecipients(recipients);
  const diffusionLists = useSelector((state) => state.insightDashboard.diffusionLists);

  const handleSelectedLists = useCallback(() => {
    const selectedIds = [];
    diffusionLists.forEach((list) => String(values.diffusionLists).includes(list.name) && selectedIds.push(list.id));
    setSelectedDiffusions(selectedIds);
  }, [values]);

  const handleDiffusionCheck = (e) => {
    const id = e.target.id;
    const checked = e.target.checked;
    if (checked) {
      setSelectedDiffusions((selected) => [...selected, id]);
      setSelectAll(false);
    } else {
      const newSelection = selectedDiffusions.filter((listId) => listId !== id);
      setSelectedDiffusions(newSelection);
    }
  };

  const handleDiffusionRecipients = () => {
    if (!selectAll) {
      const checkedDiffusionList = [];

      selectedDiffusions.forEach((id) => {
        const diffusionList = diffusionLists.filter((list) => list?.id === id);
        diffusionList?.length && checkedDiffusionList.push(...diffusionList);
      });

      const diffusionRecipients = checkedDiffusionList.reduce(
        (allRecipients, { users }) => allRecipients.concat(users),
        [],
      );

      const recipientsCopy = recipients.map((recipient) => ({ ...recipient, checked: false }));
      const diffusionRecipientsIds = diffusionRecipients.map(({ user_id }) => String(user_id));

      recipientsCopy.forEach((recipient, index) => {
        if (diffusionRecipientsIds.includes(String(recipient?.id))) {
          recipientsCopy[index] = { ...recipient, checked: true };
        }
      });
      setValues({
        ...values,
        recipients: recipientsCopy,
        diffusionLists: checkedDiffusionList.map((list) => `${list?.name} (${list.users.length})`),
      });
      dispatch(setSelectedRecipients(recipientsCopy));
    }
  };

  const handleCheckedValues = (id, list) => {
    const newValues = list.map((recipient) =>
      recipient.id === id ? { ...recipient, checked: !recipient.checked } : { ...recipient },
    );
    return newValues;
  };

  const handleChecked = (id) => {
    const newValues = handleCheckedValues(id, recipients);
    const selectedValues = newValues.filter(({ checked }) => checked);
    setChangedSelection(!isEqual(newValues, initialRecipients));
    setValues({ ...values, recipients: newValues });
    dispatch(setSelectedRecipients(selectedValues));
  };

  const handleSelectAll = () => {
    setSelectAll((selectAll) => !selectAll);
    const newValues = recipients.map((recipient) => ({ ...recipient, checked: !selectAll }));
    setValues({ ...values, recipients: newValues });
    dispatch(setSelectedRecipients(newValues));
    setSelectedDiffusions([]);
  };

  useEffect(() => {
    handleDiffusionRecipients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDiffusions]);

  useEffect(() => {
    if (noRecipientsSelected) setSelectAll(false);
  }, [noRecipientsSelected]);

  useEffect(() => {
    if (selectedDiffusions?.length === 0 && values.diffusionLists?.length > 0) {
      handleSelectedLists();
    }
  }, []);

  return (
    <div className="recipients-wrapper">
      <div className="diffusion-list-wrapper">
        <span className="diffusion-list-header">
          Distribution List
          {/*eslint-disable-next-line react/no-unknown-property*/}
          <span tooltip={MESSAGES.DISTRIBUTION_LIST} tooltip-position="top" className="info-icon">
            <InfoIcon />
          </span>
        </span>
        {diffusionLists?.length ? (
          diffusionLists?.map((list) => (
            <li className="list-item" key={list?.id}>
              <div>{list?.name}</div>
              <div className="diffusion-count-wrapper">
                <UserIcon />
                {list?.users?.length}
                <input
                  id={list?.id}
                  name={`${list?.name} (${list?.users?.length})`}
                  className="recipients-checked"
                  type="checkbox"
                  onChange={(e) => handleDiffusionCheck(e)}
                  checked={selectedDiffusions.includes(list?.id)}
                />
              </div>
            </li>
          ))
        ) : (
          <li className="list-item">
            <div>No lists created yet</div>
          </li>
        )}
      </div>
      <div className="general-list-wrapper">
        <div className="general-list-header">
          <span>General List</span>
          <div>
            <label htmlFor="select-all">All</label>
            <input
              id="select-all"
              className="recipients-checked"
              type="checkbox"
              onChange={() => handleSelectAll()}
              checked={selectAll}
            />
          </div>
        </div>
        {recipients?.map(({ firstName, lastName, id, checked }) => (
          <li className="list-item" key={id}>
            <div className="recipient-avatar">
              {firstName[0]}
              {lastName[0]}
            </div>
            <div>
              {firstName} {lastName}
            </div>
            <input
              className="recipients-checked"
              type="checkbox"
              checked={checked}
              onChange={() => handleChecked(id)}
            />
          </li>
        ))}
      </div>
      <button
        type="button"
        className="diffusion-button"
        disabled={!changedSelection || noRecipientsSelected}
        onClick={() => setNewElementModalOpen(CREATE_MODALS.DIFFUSION)}
      >
        <AddIcon /> SAVE SELECTION AS A DISTRIBUTION LIST
      </button>
    </div>
  );
};

export default Recipients;
