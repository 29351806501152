import React, { useState } from 'react';
import '../styles.css';
import Input from '../../input';
import { useDispatch } from 'react-redux';
import { addNewSection } from '../../../../actions';
import { MESSAGES } from '../../../../constant';
import toaster from '../../../../services/shared/toaster.service';

const NewSection = ({ handleClose }) => {
  const [inputValue, setInputValue] = useState('');
  const [errors, setErrors] = useState({ new: false });
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const regExp = /^(?=.*[a-zA-Z\d].*)[a-zA-Z\d:,;-\s]{1,}$/;

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    checkErrors(newValue);
  };

  const checkErrors = (value) => {
    if (!regExp.test(value) && value.trim() !== '') {
      setErrorMessage('Name can only include letters, numbers or special characters: ": , ;"');
      setErrors({ new: true });
    } else if (value.length > 60) {
      setErrorMessage('Maximum length is 60 characters');
      setErrors({ new: true });
    } else {
      setErrors({ new: false });
      setErrorMessage('');
    }
  };

  const handleClearErrors = (value) => {
    setErrors({ new: value });
    setErrorMessage('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!regExp.test(inputValue) || inputValue > 60) {
      checkErrors(inputValue);
      return;
    }
    setLoading(true);
    try {
      await dispatch(addNewSection(inputValue));
      toaster.success(MESSAGES.CREATE_SECTION);
      handleClose();
    } catch (error) {
      setErrors({ new: true });
      setErrorMessage(error?.message);
    }
    setLoading(false);
  };

  return (
    <form className="creation-form" onSubmit={handleSubmit}>
      <Input
        value={inputValue}
        name="new"
        errors={errors}
        handleChange={handleInputChange}
        clearError={handleClearErrors}
        errorMessage={errorMessage || 'Section Name is required'}
        label="Section Name"
      />
      <button type="submit" disabled={inputValue.trim() === '' || errors['new']}>
        {loading ? <span className="loader" /> : 'Add Section'}
      </button>
    </form>
  );
};

export default NewSection;
