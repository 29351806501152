import { invoke } from '../../helpers/apiRequest';
import { insertTile, logoFromdb } from '../../helpers/dbStorage';
import { UPDATE_LOGO } from '../../constant';
import logoPP from '../../assets/img/PipelinePlus_Horizontal_Logo_RGB_Full_Color.svg';

const dowloadLogo = (logo) => (dispatch) => {
  if (logo.url) {
    invoke({ type: 'GET', url: logo.url, responseType: 'blob' }).then((response) => {
      let reader = new FileReader();
      reader.readAsDataURL(response); // converts the blob to base64 and calls onload
      reader.onload = function () {
        const url = reader.result;
        insertTile({ id: 'logo', type: 'logo', url });
        dispatch({
          type: UPDATE_LOGO,
          payload: url,
        });
      };
    });
  } else {
    insertTile({ id: 'logo', type: 'logo', url: logo.url });
    dispatch({
      type: UPDATE_LOGO,
      payload: logoPP,
    });
  }
};

export const getlogoFromdb = () => (dispatch) => {
  return (
    logoFromdb() &&
    logoFromdb().then((data) => {
      if (data.url) {
        dispatch({
          type: UPDATE_LOGO,
          payload: data.url,
        });
      }
      return data.url;
    })
  );
};
export default dowloadLogo;
