import { LOAD_INSIGHTS_COUNT, REDUCE_INSIGHTS_COUNT } from '../../constant';
const insightCount = (state = 0, action) => {
  switch (action.type) {
    case LOAD_INSIGHTS_COUNT:
      return action.payload;
    case REDUCE_INSIGHTS_COUNT: {
      let count = state;
      count = count - 1;
      return count;
    }
    default:
      return state;
  }
};

export default insightCount;
