import React, { useMemo } from 'react';
import icons from '../../../assets/img/icons.svg';
import { timeFormater } from '../../../helpers/timeformater';
import { ShowMore } from '../../shared/ShowMore';

const CollaboratorsNotes = ({ targetNote, counter }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const collaboratorsNote = useMemo(() => targetNote, [counter, targetNote]);

  return (
    <>
      <h4>
        <span className="icon">
          <svg className="icon">
            <use xlinkHref={`${icons}#shared`} />
          </svg>
        </span>{' '}
        COLLABORATOR NOTES
      </h4>
      <p>Notes made by collaborating colleagues will appear below.</p>
      {collaboratorsNote.length ? (
        <ul className="colleague-list container">
          {collaboratorsNote?.map((collaborator) => (
            <div key={collaborator.id}>
              <div className="collaborator-notes">
                <div className="avatar pending">
                  <b>
                    {collaborator?.first_name?.charAt(0)?.toUpperCase()}
                    {collaborator?.last_name?.charAt(0)?.toUpperCase()}
                  </b>
                </div>
                <div className="items">
                  <span>
                    <p>
                      <ShowMore text={collaborator?.note} length={200} />
                    </p>
                  </span>
                </div>
              </div>
              {collaborator?.first_name && collaborator?.last_name && (
                <div className="last-saved">
                  <span>{`Last saved ${
                    timeFormater(collaborator?.modified) || timeFormater(collaborator?.created)
                  } by`}</span>
                  <span className="pending">
                    <b>{` ${collaborator?.first_name} ${collaborator?.last_name}`}</b>
                  </span>
                </div>
              )}
            </div>
          ))}
        </ul>
      ) : null}
    </>
  );
};

export default CollaboratorsNotes;
