import { invoke } from '../../helpers';
import { API_URLS, API_METHODS } from '../../constant';

/**
 * Api call to get lessons
 * @param {string} status
 */
export const getViewerFromAPI = () => {
  return invoke({ url: API_URLS.VIEWER, type: API_METHODS.GET });
};

export const getReportsData = (selectedType, tab, range = {}) => {
  const url = `${API_URLS[selectedType.key.toUpperCase()]}${selectedType.id}${API_URLS.REPORT}${tab}`;
  return invoke({ url, type: API_METHODS.GET, params: range });
};
