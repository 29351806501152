import { invoke, updateInfo } from '../../helpers';
import { API_URLS, API_METHODS, API_STATUS_CODE, MESSAGES } from '../../constant';
import toaster from '../shared/toaster.service';

/**
 * Function to call api to for account info update
 * @param {Object} data
 */
export const changeAccountInfo = (values) => {
  return invoke({ url: `${API_URLS.USER}`, type: API_METHODS.PUT }, values).then(
    (_res) => {
      updateInfo(values);
      toaster.success(MESSAGES.INFOUPDATE);
    },
    (err) => {
      const error = err.response.data.message || err.message;
      toaster.error(error);
      if (!err.response && err.message === MESSAGES.NETWORKERR) {
        toaster.error(MESSAGES.NOINTERNET);
      } else if (err.response.data.code === API_STATUS_CODE.UNAUTHORIZED) {
        toaster.error(err.response.data.message);
      }
    },
  );
};
/**
 * get account info
 */
export const getAccountInfo = () => {
  return invoke({ url: `${API_URLS.USER}`, type: API_METHODS.GET }, {});
};
