import axios from 'axios';
import { callHud, hideLoader } from '../actions';
import { API_METHODS, API_STATUS_CODE, API_URLS, IDENTIFIERS } from '../constant';
import { WISTIA_API, accessToken } from '../services/shared/wistia.service';
import { destroy, getToken } from './sessionManager';
import { store } from './store';

const callHudIf = (calledUrl) => {
  return (
    calledUrl.includes(API_URLS.TILES) ||
    calledUrl.includes(API_URLS.TASKS) ||
    calledUrl.includes(API_URLS.SHARE) ||
    calledUrl.includes(API_URLS.SETYTDREVENUE)
  );
};

// create axios obj with headers and token
const getAxiosObj = (apiInfo, data, options, forceToken) => {
  let baseURL =
    window.app_instance !== 'ackert'
      ? process.env.REACT_APP_API_BASE_URL.split('//')[0] +
        '//' +
        (localStorage.host_prefix !== 'jenner' || !process.env.REACT_APP_API_BASE_URL.includes('jenner')
          ? localStorage.host_prefix + '.'
          : '') +
        process.env.REACT_APP_API_BASE_URL.split('//')[1]
      : process.env.REACT_APP_API_BASE_URL;
  const obj = {
    method: apiInfo['type'],
    url: apiInfo['url'],
    params: apiInfo['params'],
    data,
    baseURL,
    headers: {},
  };
  if (forceToken) {
    obj.headers.Authorization = `Bearer ${forceToken}`;
  } else if (getToken()) {
    obj.headers.Authorization = `Bearer ${getToken()}`;
  }
  // if we want to send client like android ios enable this
  // let client = window.device ? `${window.device.platform} ${window.device.version} ${window.device.model}` : window.navigator.userAgent;
  // client = client.indexOf('Electron') > -1 ? `DESKTOP_${window.navigator.platform} ${client}` : client;
  // obj.headers["X-Requesting-Client"] = client;

  let switchUserdb = localStorage.getItem('switchUserdb');
  if (switchUserdb != null && switchUserdb != '') {
    obj.headers['X-Switched-From'] = switchUserdb;
  }
  obj.headers['X-Requesting-Client'] = IDENTIFIERS;
  obj.headers['X-REQUESTING-INSTANCE'] = window.app_instance;
  // obj.headers['X-Requesting-Instance'] = window.app_instance
  if (options.proxyUser) {
    obj.headers['X-Proxy-For'] = options.proxyUser;
  }
  if (apiInfo.responseType) {
    obj.responseType = apiInfo.responseType;
  }
  return obj;
};

//  API call using axios
export const invoke = (apiInfo, data = {}, options = {}, token = false) => {
  return new Promise((resolve, reject) => {
    const axiosObj = getAxiosObj(apiInfo, data, options, token);
    // checks if the user is logging in to jenner through regular pipeline api. create the jenner endpoint to call for this user at all times until logout
    if (
      axiosObj.url === 'v1/user/login' &&
      axiosObj.data.username.includes('@jenner.com') &&
      !axiosObj.baseURL.includes('https://jenner.api.pipelineplus.com')
    ) {
      const splitUrl = axiosObj.baseURL.split('//');
      const jennerUrl = splitUrl[0] + '//jenner.' + splitUrl[1];
      axiosObj.baseURL = jennerUrl;
      localStorage.host_prefix = 'jenner';
      window.app_instance = 'jenner';
      // return axios(axiosObj)
      // .then(res => { // success
      //     const resData = res['data'];
      //     if (res['status'] === 204 || res['status'] === 200) {
      //         resolve(resData);
      //         if ((apiInfo.type === API_METHODS.POST || apiInfo.type === API_METHODS.PUT) && callHudIf(apiInfo.url)) {
      //             store.dispatch(callHud());
      //         }
      //     }
      // }, (err) => {
      //     if (err.response && (err.response.data.status === API_STATUS_CODE.FORBIDDEN
      //         || err.response.status === API_STATUS_CODE.EXPIRETOKEN
      //         || err.response.data.status === API_STATUS_CODE.UNAUTHORIZED)) {
      //         destroy();

      //     }
      //     store.dispatch(hideLoader());
      //     reject(err);
      // });
    }
    // if user is already on jenner domain, rename host_prefix before regular api calls. the baseURL is made when app is initialized
    if (
      axiosObj.url === 'v1/user/login' &&
      axiosObj.data.username.includes('@jenner.com') &&
      axiosObj.baseURL.includes('https://jenner.api.pipelineplus.com')
    ) {
      localStorage.host_prefix = 'jenner';
      window.app_instance = 'jenner';
    }
    // if the user isnt logging in as a jenner and had attempted to previously, but failed logging in
    if (axiosObj.url === 'v1/user/login' && !axiosObj.data.username.includes('@jenner.com')) {
      axiosObj.baseURL = process.env.REACT_APP_API_BASE_URL;
      localStorage.host_prefix = 'ackert';
      window.app_instance = 'ackert';
    }
    return axios(axiosObj).then(
      (res) => {
        // success
        const resData = res['data'];
        if (res['status'] === 204 || res['status'] === 200) {
          resolve(resData);
          if ((apiInfo.type === API_METHODS.POST || apiInfo.type === API_METHODS.PUT) && callHudIf(apiInfo.url)) {
            store.dispatch(callHud());
          }
        }
      },
      (err) => {
        if (
          err.response &&
          (err.response.data.status === API_STATUS_CODE.FORBIDDEN ||
            err.response.status === API_STATUS_CODE.EXPIRETOKEN ||
            err.response.data.status === API_STATUS_CODE.UNAUTHORIZED)
        ) {
          destroy();
        }
        store.dispatch(hideLoader());
        reject(err);
      },
    );
  });
};

export const mediaActions = async (id, requestType, urlPath = 'medias') => {
  return await axios({
    url: `${WISTIA_API}/${urlPath}/${id}`,
    method: requestType,
    baseURL: WISTIA_API,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
