import { toastr } from 'react-redux-toastr';

// notification tosater
const toaster = {
  success(message = '') {
    toastr.success('', message);
  },
  error(message = '') {
    toastr.error('', message);
  },
};

export default toaster;
