import React, { useState, useMemo, useEffect, useRef } from 'react';
import { RgbaStringColorPicker, HexColorInput } from 'react-colorful';
import { useOnClickOutside } from '../../../hooks/onClickOutside';
import { colord, extend } from 'colord';
import namesPlugin from 'colord/plugins/names';
extend([namesPlugin]);
import './styles.css';

const rgbItems = ['r', 'g', 'b'];

const ColorPicker = ({ color, setColor }) => {
  const [rgb, setRgb] = useState({ r: 170, g: 187, b: 204, a: 1 });
  const [showPicker, setShowPicker] = useState(false);

  const wrapperRef = useRef(null);
  useOnClickOutside(wrapperRef, () => setShowPicker(false));

  const handleRgb = (value, type) => {
    const newColor = { ...rgb, [type]: value };
    const rgba = colord(newColor)?.rgba;
    const spreadRgba = Object.values(colord(newColor)?.rgba);
    setRgb(rgba);
    setColor(`rgba(${String(spreadRgba)})`);
  };

  useEffect(() => {
    setRgb(colord(color).toRgb());
  }, [color]);

  const rgbaString = useMemo(() => {
    return color.startsWith('rgba') ? color : colord(color).toRgbString();
  }, [color]);

  return (
    <div className="btn-picker-wrapper">
      <button
        type="button"
        className="open-picker-btn"
        style={{ background: color }}
        onClick={() => setShowPicker(true)}
      ></button>
      <div ref={wrapperRef} className="color-picker-wrapper" style={{ display: showPicker ? 'block' : 'none' }}>
        <div className="picker-wrapper">
          <RgbaStringColorPicker color={rgbaString} onChange={setColor} />
          <div className="color-inputs">
            <div className="hex-input-wrapper">
              <label className="rgb-label">HEX</label>
              <HexColorInput color={color} onChange={setColor} placeholder="Type a color" prefixed alpha />
            </div>
            <div className="rgb-wrapper">
              {rgbItems.map((item) => (
                <div key={item} className="rgb-input-wrapper">
                  <label className="rgb-label" htmlFor={item}>
                    {item}
                  </label>
                  <input className="rgb-input" value={rgb[item]} onInput={(e) => handleRgb(e.target.value, item)} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorPicker;
