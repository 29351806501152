import { SHOW_COLLABORATORS_DIALOG, HIDE_COLLABORATORS_DIALOG, USER_SELECTION } from '../../constant';

export const showCollaborationDialog = (tileId) => (dispatch) => {
  dispatch({
    type: SHOW_COLLABORATORS_DIALOG,
    payload: { open: true, tileId },
  });
};

export const hideCollaborationDialog = () => (dispatch) => {
  dispatch({
    type: HIDE_COLLABORATORS_DIALOG,
    payload: { open: false },
  });
};
export const selectUser = (user) => (dispatch) => {
  dispatch({
    type: USER_SELECTION,
    payload: { open: true, user },
  });
};
