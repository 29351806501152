import { ROUTES_PATH } from '../constant';
import { Viewer } from '../containers';

const viewerRoutes = [
  {
    path: ROUTES_PATH.VIEWER,
    exact: false,
    component: Viewer,
    isProtected: true,
  },
];

export default viewerRoutes;
