import React from 'react';

import Tags from '../shared/Tags.component';

/**
 * component for header on slider target detail
 * @param {Object} props
 */
const Header = (props) => {
  const {
    contact,
    company,
    valueterm,
    type,
    goal,
    projected_revenue,
    projected_revenue_formatted,
    revenue,
    revenue_formatted,
    collaborators,
    overlaps,
  } = props.detail;
  return (
    <header>
      <h1>
        <span className="contact">{contact}</span>
        <span className="company">{company}</span>
      </h1>
      <Tags valueterm={valueterm} typeterm={type} collaborators={collaborators} overlaps={overlaps} detail={true} />
      <h2 className="objective overflowed">
        <span>{goal}</span>
        <ul className="tags">
          {projected_revenue && projected_revenue > 0 ? (
            <li className="tag projected-revenue">{projected_revenue_formatted}</li>
          ) : (
            ''
          )}
          {revenue && revenue > 0 ? <li className="tag won-revenue">{revenue_formatted}</li> : ''}
        </ul>
      </h2>
    </header>
  );
};

export default Header;
