import React, { useCallback, useEffect, useState } from 'react';
import './styles.css';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { ROUTES_PATH } from '../../../constant';
import history from '../../../helpers/history';
import VideoInsight from './VideoInsight';
import PdfInsight from './PdfInsight';
import { BLOG, PDF, VIDEO } from '../../../constant/insights';
import { getEditContentType } from '../../../helpers';

const ExpandedInsight = () => {
  const [insight, setInsight] = useState({});
  const [loading, setLoading] = useState(true);
  const { search } = useLocation();
  const allInsights = useSelector((state) => state.insightDashboard.content);
  const contentType = insight?.content_type ? getEditContentType(insight.content_type) : null;

  const getExpandedInsight = useCallback(() => {
    const id = search.split('=')[1];
    const expandedInsight = allInsights.filter((insight) => insight.id === id);
    setInsight(expandedInsight?.[0]);
    setTimeout(() => setLoading(false), 1500);
  }, [allInsights]);

  useEffect(() => {
    getExpandedInsight();
  }, [allInsights]);

  useEffect(() => {
    if (!search) history.push(ROUTES_PATH.INSIGHTSBD);
  }, [search]);

  useEffect(() => {
    if (contentType === 'blog' || contentType === BLOG) window.location.href = insight.blog_url;
  }, [insight]);

  return (
    <div className="expanded-insight-wrapper">
      <div className="expanded-content-wrapper">
        {loading ? (
          <div className="expanded-loader-wrapper" />
        ) : contentType === PDF ? (
          <PdfInsight pdfFile={insight?.file_url} insight={insight} />
        ) : contentType === VIDEO ? (
          <VideoInsight insight={insight} />
        ) : null}
      </div>
    </div>
  );
};

export default ExpandedInsight;
