import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';

import { TrainerComp, NavSecondary } from '../../../components';
import { API_PARAMS, trainerTabs, LESSON_STATUS } from '../../../constant';
import { getTrainerData, saveWorksheetMatrix, showLoader, setAttention, showFilter } from '../../../actions';
import LessonFilter from '../../Trainer/Listing/LessonFilter.container';
import dashboardHOC from '../../shared/DashboardHOC.container';
import { getUserData } from '../../../helpers';
import '../../../assets/css/page-trainer.css';
import { groupBy } from 'lodash';

// Trainer container to wrap all the components avalible on Trainer
class Trainer extends Component {
  trainerRef = createRef(null);
  state = {
    status: API_PARAMS.LESSONS,
    shared: [],
    targets: [],
    activeHUD: '',
    activeTabs: [],
  };

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.showTabs();
    if (params) {
      let { status } = params;
      this.init(status);
    } else {
      this.init(API_PARAMS.LESSONS);
    }
  }

  componentDidUpdate(prevProps) {
    const { match: { params: prevParams = {} } = {} } = prevProps;
    const { match: { params = {} } = {} } = this.props;
    if (params.status !== prevParams.status) {
      this.init(params.status);
      // scroll container to the top when tab status changes
      this.scrollUp();
    }
  }

  init = (status) => {
    const { attention, lessonData, getAttention, getTrainerData } = this.props;

    const showLoader = lessonData.length || attention.id;
    getTrainerData(status, showLoader);
    // this is for scrolling to the recently visited item
    if (attention.id) {
      getAttention({ list: lessonData, attention });
    }
    this.setState({ status });
  };

  // scroll to top when status changes
  scrollUp = () => {
    if (this.trainerRef && this.trainerRef.current) {
      this.trainerRef.current.scrollTop = 0;
    }
  };

  showTabs = () => {
    const brand = getUserData().user && getUserData().user.Subscriptions.trainer.features?.branded.on;
    const isBranded = brand === 'yes';

    const activeTabs = trainerTabs.filter((tab) => {
      if (!isBranded) {
        return tab.name !== 'Firm Content';
      } else {
        return tab;
      }
    });
    this.setState({ activeTabs });
  };

  filteredLesson = () => {
    const { keyword, lessons, lessonData, filterBy } = this.props;
    if (keyword)
      return groupBy(
        lessonData.filter(
          (lesson) =>
            lesson.description.toLowerCase().includes(keyword.toLowerCase()) ||
            lesson.title.toLowerCase().includes(keyword.toLowerCase()),
        ),
        'focus',
      );

    switch (filterBy.value) {
      case LESSON_STATUS.NOT_STARTED:
        return groupBy(
          lessonData.filter((lesson) => lesson.watched?.seconds === 0),
          'focus',
        );
      case LESSON_STATUS.NOT_COMPLETED:
        return groupBy(
          lessonData.filter((lesson) => lesson.watched?.seconds > 0 && lesson.watched?.done === 'no'),
          'focus',
        );
      case LESSON_STATUS.COMPLETED:
        return groupBy(
          lessonData.filter((lesson) => lesson.watched?.done === 'yes'),
          'focus',
        );
      case LESSON_STATUS.WORKSHEET:
        return groupBy(
          lessonData.filter((lesson) => lesson.worksheet !== ''),
          'focus',
        );
      case LESSON_STATUS.NO_WORKSHEET:
        return groupBy(
          lessonData.filter((lesson) => lesson.worksheet === ''),
          'focus',
        );
      default:
        return lessons;
    }
  };

  render() {
    const { worksheets, resources, loading, saveWorksheetMatrix, getAttention, setAttention, openFilter } = this.props;
    const { status, activeTabs } = this.state;
    const filteredLesson = this.filteredLesson();
    return (
      <>
        <TrainerComp
          ref={this.trainerRef}
          status={status}
          lessons={filteredLesson}
          worksheets={worksheets}
          resources={resources}
          loading={loading}
          saveWorksheetMatrix={saveWorksheetMatrix}
          getAttention={getAttention}
          setAttention={setAttention}
        />
        <NavSecondary status={status} tabs={activeTabs} type="trainer" openFilter={openFilter} />
        <LessonFilter />
      </>
    );
  }
}

// map dispatch to props
const mapDispatchToProps = (dispatch) => ({
  getTrainerData: (status, attention) => {
    if (!attention) dispatch(showLoader());
    return dispatch(getTrainerData(status));
  },
  saveWorksheetMatrix: (lessonId, matrix) => dispatch(saveWorksheetMatrix(lessonId, matrix)),
  showLoader: () => dispatch(showLoader()),
  setAttention: (id) => dispatch(setAttention({ id })),
  openFilter: () => dispatch(showFilter()),
});
// map state to props
const mapStateToProps = (state) => ({
  lessons: state.lessons.listing || {},
  lessonData: state.lessons.data,
  worksheets: state.worksheets,
  resources: state.resources,
  loading: state.loader,
  attention: state.attention,
  filterBy: state.lessonsFilter.filterModalProps.filterBy,
  keyword: state.lessonsFilter.filterModalProps.keyword,
});

export default dashboardHOC(connect(mapStateToProps, mapDispatchToProps)(Trainer));
