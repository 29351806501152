import { invoke, mediaActions } from '../../helpers';
import { API_URLS, API_METHODS, API_PARAMS } from '../../constant';
import { updateATile } from '../../helpers/dbStorage';

/**
 * Api call to get lessons
 * @param {string} status
 */
export const getLessons = () => {
  return invoke({ url: API_URLS.LESSONS, type: API_METHODS.GET });
};

/**
 * Api call to get lessons details
 * @param {string} status
 */
export const getLessonDetails = (lessonId) => {
  const url = API_URLS.LESSONS + lessonId;
  return invoke({ url, type: API_METHODS.GET });
};

/**
 * Api call update lesson watched time
 * @param {number} time
 */
export const updateWatchTime = (lessonId, time) => {
  const url = API_URLS.LESSONS + lessonId;
  return invoke({ url, type: API_METHODS.POST, params: { watched: time } });
};

/**
 * Api call to get worksheets
 * @param {string} status
 */
export const getWorksheets = () => {
  return invoke({ url: API_URLS.WORKSHEETS, type: API_METHODS.GET });
};

/**
 * Save updated worksheet data in DB
 * @param {object} tile
 */
export const changeWorksheetsDataInDB = (tile) => {
  tile.status = API_PARAMS.WORKSHEETS;
  tile.modified = new Date();
  updateATile(tile, tile.id);
  return tile;
};

/**
 * Api call to get resources
 * @param {string} status
 */
export const getResources = () => {
  return invoke({ url: API_URLS.RESOURCES, type: API_METHODS.GET });
};

/**
 * @params {string} url
 */
export const getFirmVideos = (id) => {
  return mediaActions(`${id}.json`, 'GET', 'projects');
};

export const removeFirmVideo = (id) => mediaActions(`${id}.json`, 'DELETE');

export const updateFirmVideo = (id, title, description) =>
  mediaActions(
    `${id}.json?${title && `name=${encodeURIComponent(title)}&`}${description && `description=${description}`}`,
    'PUT',
  );
