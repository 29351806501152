import history from '../../helpers/history';
import { branchFeature } from '../../constant';
import { navigateToScreen } from './pushNotification.service';

export const navigate = (parsedData) => {
  if (parsedData.$canonical_identifier) {
    const url = parsedData.$canonical_identifier.replace('/#', '');
    if (parsedData['~feature'] === branchFeature.TARGETDETAIL) {
      const id = parsedData.$canonical_identifier.split('/')[2];
      navigateToScreen({ category: { name: 'share', id } });
    } else {
      history.push(url);
    }
  }
};
const init = () => {
  // load Branch
  (function (b, r, a, n, c, h, _, s, d, k) {
    if (!b[n] || !b[n]._q) {
      for (; s < _.length; ) c(h, _[s++]);
      d = r.createElement(a);
      d.async = 1;
      d.src = 'https://cdn.branch.io/branch-latest.min.js';
      k = r.getElementsByTagName(a)[0];
      k.parentNode.insertBefore(d, k);
      b[n] = h;
    }
  })(
    window,
    document,
    'script',
    'branch',
    function (b, r) {
      b[r] = function () {
        b._q.push([r, arguments]);
      };
    },
    {
      _q: [],
      _v: 1,
    },
    'addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking'.split(
      ' ',
    ),
    0,
  );
  // init Branch
  window.branch.init(process.env.REACT_APP_BRANCH_INIT_KEY, function (err, data) {
    if (data) {
      const parsedData = data.data_parsed;
      navigate(parsedData);
    }
  });
};

export default init;
