import { invoke, destroy, store } from '../../helpers';
import { API_URLS, API_METHODS, API_STATUS_CODE, MESSAGES, ROUTES_PATH } from '../../constant';
import history from '../../helpers/history';
import { SubmissionError } from 'redux-form';
import toaster from '../shared/toaster.service';
import { showLoader, hideLoader } from '../../actions';

/**
 * Function to call api to for change Password
 * @param {Object} data
 */
export const submitChangePassword = (values, resetform) => {
  store.dispatch(showLoader());
  return invoke({ url: API_URLS.CHANGEPWD, type: API_METHODS.POST }, values).then(
    (_res) => {
      store.dispatch(hideLoader());
      toaster.success(MESSAGES.changePwdSuccess);
      resetform();
      destroy();
      history.replace(ROUTES_PATH.LOGIN);
    },
    (err) => {
      const error = err.response.data.message || err.message;
      toaster.error(error);
      store.dispatch(hideLoader());
      if (!err.response && err.message === MESSAGES.NETWORKERR) {
        toaster.error(MESSAGES.NOINTERNET);
      } else if (err.response.data.code === API_STATUS_CODE.UNAUTHORIZED) {
        throw new SubmissionError({
          current: MESSAGES.currentPwdError,
        });
      }
    },
  );
};
