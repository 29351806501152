import React from 'react';
import './styles.css';

const Switch = ({ checked, setChecked, disabled }) => {
  return (
    <label className="switch">
      <input disabled={disabled} type="checkbox" checked={checked} onChange={(e) => setChecked(e.target.checked)} />
      <span className="slider round" />
    </label>
  );
};

export default Switch;
