import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getUserWorksheetsData } from '../../../../actions';
import { API_URLS } from '../../../../constant';
import TileLoader from '../../../shared/TileLoader';
import { NoReports } from './../../../../components';

const UserWorksheet = (props) => {
  const {
    match: {
      params: { userId },
    },
  } = props;

  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const matrixData = (userWorksheet) => {
    return userWorksheet.map((item) => {
      item.questions = item.worksheet.questions.map((question) => {
        let questionResult = item.worksheet.matrix.filter((ele) => ele.question === question);
        let tempObj = {
          question,
        };
        questionResult.forEach((ele) => {
          tempObj[ele.parameter] = ele.value;
        });
        return tempObj;
      });
      return item;
    });
  };

  useEffect(() => {
    const url = `${API_URLS.WORKSHEETS}`;
    dispatch(getUserWorksheetsData(url, userId));
  }, [userId, dispatch]);

  const { loading, userWorksheet } = useSelector((state) => state.progressTable);

  useEffect(() => {
    setData(matrixData(userWorksheet));
  }, [userWorksheet]);

  return (
    <>
      {!data.length && loading && <TileLoader />}
      {data.length ? (
        <div className="mgmt-report active">
          {data.map((item) => (
            <section className="panel worksheet" key={item.id}>
              <article className="lesson worksheet expanded">
                <h1>
                  <p>{item.title}</p>
                  <span className="expando dashicons dashicons-arrow-down" />
                </h1>
                <div className="scroller horz sheet">
                  <table className="worksheet" cellSpacing="0">
                    <colgroup>
                      <col className="name" width="20%" />
                      <col className="data" width="40%" />
                      <col className="data" width="40%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        {item.worksheet.parameters.map((parameter) => (
                          <th key={parameter + Math.random()}>{parameter}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {item.questions.map((question, index) => (
                        <tr key={question + index + Math.random()}>
                          <th>{question.question}</th>
                          {item.worksheet.parameters.map((parameter, index) => (
                            <td key={parameter + index}>{question[parameter] ? question[parameter] : '~'}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </article>
            </section>
          ))}
        </div>
      ) : (
        <NoReports loading={loading} />
      )}
    </>
  );
};
export default withRouter(UserWorksheet);
