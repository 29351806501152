import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import Tile from '../../shared/Tile';
import InsightTile from './InsightTile.component';
import icons from '../../../assets/img/icons.svg';
import { ROUTES_PATH } from '../../../constant';
import history from '../../../helpers/history';
import '../../../assets/css/page-insights.css';
import '../../../assets/css/dashboard.css';
import InsightPodcast from '../../../containers/Insights/podcasts/InsightPodcastContainer';
import { getUserData } from '../../../helpers';
import NewInsightModal from '../../../containers/Insights/newInsightModal';
import { FAVORITE } from '../../../constant/insights';
import { useDispatch, useSelector } from 'react-redux';
import { addNewTag, getDigestData, getInsightSections, getNewContent, getTags } from '../../../actions';

/* Pipeline / insights page functionality */
const _renderTiles = (props) => {
  const goToDetail = (id) => {
    props.setAttention(id);
    history.push(`${ROUTES_PATH.INSIGHTDETAIL}/${id}`);
  };

  return props.insights.length > 0 ? (
    props.insights.map((insight, i) => {
      return (
        <Tile tile={insight} insight={true} key={i} goTo={() => goToDetail(insight.id)}>
          <InsightTile tile={insight} />
        </Tile>
      );
    })
  ) : (
    <div className="no-results-found">
      <p>
        <span>
          <svg className="icon">
            <use xlinkHref={`${icons}#no-${props.status}-insights`} />
          </svg>
        </span>
        <span>You have no {props.status} Insights.</span>
      </p>
    </div>
  );
};

// list of insights
const Listing = (props) => {
  const [inProp, setInProp] = useState(false);
  const [editContent, setEditContent] = useState({});
  const tags = useSelector((state) => state.insightDashboard.tags);
  const dispatch = useDispatch();
  const { setNewElementModalOpen } = props;

  const checkForFavorites = async () => {
    const tagNames = tags.map(({ name }) => name);
    if (tagNames.length && !tagNames.includes(FAVORITE)) {
      await dispatch(addNewTag(FAVORITE));
    }
  };

  useEffect(() => {
    checkForFavorites();
  }, [tags]);

  useEffect(() => {
    setInProp(true);
  }, []);

  useEffect(() => {
    dispatch(getInsightSections());
    dispatch(getNewContent());
    dispatch(getTags());
    dispatch(getDigestData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CSSTransition classNames="fade" in={inProp} timeout={300}>
      <section className="nav-page" id="insights">
        <div className="block-wrapper">
          <div className={`insights-${props.status} active`}>
            {/* <header className="intro"> */}
            {/* {props.status === 'new' ?
                            <p>These are new Insights. Click to view more info and suggestions on next steps.</p>
                            : <p>You have already viewed these Insights. Click to review.</p>
                        } */}
            {/* </header> */}
            <NewInsightModal
              modalType={props.modalType}
              setModalType={props.setModalType}
              setNewElementModalOpen={setNewElementModalOpen}
              setEditContent={setEditContent}
              editContent={editContent}
            />
            <ol className="ai-dashboard insights-container" id={`insights-${props.status}`}>
              <InsightPodcast
                {...props}
                user={getUserData()?.user}
                modalType={props.modalType}
                setModalType={props.setModalType}
                setEditContent={setEditContent}
                setNewElementModalOpen={setNewElementModalOpen}
              />
            </ol>
          </div>
        </div>
      </section>
    </CSSTransition>
  );
};

export default Listing;
