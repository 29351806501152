import { SHOW_LOADING, HIDE_LOADING } from '../../constant';
export const showLoader = () => (dispatch) => {
  dispatch({
    type: SHOW_LOADING,
  });
};

export const hideLoader = () => (dispatch) => {
  dispatch({
    type: HIDE_LOADING,
  });
};
