import { API_URLS, API_METHODS, EDIT_TARGET_REQUEST, EDIT_TARGET_COMMIT, EDIT_TARGET_ROLLBACK } from '../../constant';
import { updateOfflineQueue } from '../../services/targets/updateOfflineQueue.service';
import { convertDueDateToAPIFormat } from '../../helpers';
import { editPeopleTarget } from '../viewer/people.action';
/**
 * Action to edit/snooze target
 * @param  {payload} payload of edit/snooze edit target
 */
export const editTarget = (payload, userId) => (dispatch) => {
  payload.revenue = payload.revenue ? payload.revenue : '';
  const dueDate = convertDueDateToAPIFormat(payload.taskdue);
  payload.due = dueDate;
  payload.description = payload.taskdescription;
  payload.task = {
    due: dueDate,
    description: payload.taskdescription,
    help: payload.taskhelp ? payload.taskhelp : null,
    user_id: payload.taskUserId,
    id: payload.taskid,
  };
  delete payload.taskdescription;
  delete payload.taskdue;
  if (payload.isTemp) {
    updateOfflineQueue(payload);
    return dispatch({
      type: EDIT_TARGET_REQUEST,
      payload: { ...payload },
    });
  }
  if (payload.isPeopleTarget) {
    // dispatch action for edit people target
    delete payload.isPeopleTarget;
    return dispatch(editPeopleTarget(payload, userId));
  }
  return dispatch({
    type: EDIT_TARGET_REQUEST,
    payload: { ...payload },
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          apiInfo: {
            url: `${API_URLS.TILES}/${payload.id}`,
            type: API_METHODS.PUT,
          },
          data: payload,
        },
        // action to dispatch when effect succeeds:
        commit: { type: EDIT_TARGET_COMMIT },
        // action to dispatch if network action fails permanently:
        rollback: { type: EDIT_TARGET_ROLLBACK },
      },
    },
  });
};
