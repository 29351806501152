import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavSecondary, AccountForm, Notifications, ChangePassword, About } from '../../components';
import '../../assets/css/page-more.css';
import { accountInfo, resetDigestData, resetGraphOf, setSwitchedUser } from '../../actions';
import { accountTab, accountTabs, ROUTES_PATH } from '../../constant';
import { destroy, handleLogout } from '../../helpers';

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: accountTab.account,
      enableReinitialize: false,
      logOutText: 'Sign Out',
    };
    this.updateCount = 0;
  }

  // when user press enter shift focus to the next field
  onReturnKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (event.target.id === 'username') {
        let nextField = document.getElementById('password');
        nextField && nextField.focus();
      } else {
        this.handleBlur();
      }
    }
  };

  // set the account sub menu to active tab, update logout text if user switch to another account
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    let tabActive = params.status;
    this.defaultTab(tabActive);
    this.props.getAccountInfo();
    if (tabActive === accountTab.notifications) {
      this.setState({ enableReinitialize: true });
    }
    if (this.props.switch) {
      this.setState({ logOutText: `Switch back to ${this.props.switch.revert.name}` });
    }
  }

  componentDidUpdate() {
    this.defaultTab();
    this.updateCount++;
    // this is added to escape the reinitialization of notification form if there is no changes after reloading from browser
    if (this.updateCount === 4) {
      this.setState({ enableReinitialize: false });
    }
  }
  defaultTab = (tabActive) => {
    const {
      match: { params },
    } = this.props;
    if (params) {
      tabActive = tabActive ? tabActive : params.status;
      if (this.state.tabActive !== tabActive) this.setState({ tabActive });
    }
  };
  changeTab = (tabActive) => {
    this.props.history.push({
      pathname: `${ROUTES_PATH.ACCOUNT}/${tabActive}`,
    });
    this.setState({ tabActive });
  };

  /**
   * @description handle user switch OR logout
   */
  logout = () => {
    this.props.setSwitchedUser(false);
    let logOutText = 'Logging Out...';
    if (this.props.switch) {
      logOutText = `Switching back to ${this.props.switch.revert.name}`;
    } else {
      handleLogout();
      localStorage.clear();
    }
    if (window.location.hostname.includes('jenner')) {
      window.app_instance = 'jenner';
      localStorage.host_prefix = 'jenner';
    } else {
      localStorage.host_prefix = 'ackert';
      window.app_instance = 'ackert';
    }
    this.setState({ logOutText });
    this.props.clearDigestData();
    destroy(true, this.props.switch);
    this.props.resetViewer();
  };

  setNotifications = () => {};
  render() {
    return (
      <>
        <section className="nav-page" id="more">
          <div className="block-wrapper">
            <div className="more-account active" id="more-account">
              {this.state.tabActive === accountTab.account && (
                <AccountForm
                  changeTab={this.changeTab}
                  initialValues={this.props.initialValues}
                  subscription={this.props.subscription}
                  settings={this.props.settings}
                  org={this.props.org}
                  logout={this.logout}
                  logOutText={this.state.logOutText}
                  enableReinitialize={true}
                />
              )}
              {this.state.tabActive === accountTab.notifications && (
                <Notifications
                  onSubmitSetNotifications={this.setNotifications}
                  initialValues={this.props.settings}
                  enableReinitialize={this.state.enableReinitialize}
                  subscription={this.props.subscription}
                />
              )}
              {this.state.tabActive === accountTab.password && (
                <ChangePassword loading={this.props.loading} onSubmitChangePwd={this.changePwd} />
              )}
              {this.state.tabActive === accountTab.about && <About />}
            </div>
          </div>
        </section>
        <NavSecondary status={this.state.tabActive} changeTab={this.changeTab} tabs={accountTabs} type="nav-more" />
        {/* <NavAccount changeTab={this.changeTab} active={this.state.tabActive} /> */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const user = state.session.user;
  return {
    subscription: user ? { ...user.Subscriptions } : {},
    settings: user ? { ...user.Settings } : {},
    org: user ? { ...user.Org } : {},
    initialValues: {
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      phone: user.phone,
      username: user.user_nicename,
    },
    switch: user.Switch,
    loading: state.loader,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getAccountInfo: () => dispatch(accountInfo()),
  resetViewer: () => dispatch(resetGraphOf()),
  setSwitchedUser: (switchedUser) => dispatch(setSwitchedUser(switchedUser)),
  clearDigestData: () => dispatch(resetDigestData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);
