import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import icons from '../../../assets/img/icons.svg';
import { required } from '../../../helpers';

const renderFieldWithActionSheet = ({ field, form: { touched, errors }, ...props }) => {
  const { active, type, toggleActionSheet, customclass, autoFocus, readOnly, icon, onBlur, onFocus, placeholderfloat } =
    props;
  const openActionSheet = (e) => {
    e.preventDefault();
    toggleActionSheet(field.name);
  };
  const hasValue = field.value && field.value.trim() && !active;
  return (
    <div
      className={`field-line ${customclass} ${active ? 'focused' : ''} ${
        errors[field.name] && touched[field.name] ? 'error ' : ''
      }`}
    >
      <label>{hasValue ? '' : placeholderfloat}</label>
      <input
        {...field}
        type={type}
        readOnly={readOnly}
        maxLength={100}
        autoComplete="off"
        onBlur={onBlur}
        onFocus={onFocus}
        onClick={(e) => {
          readOnly && openActionSheet(e);
        }}
        autoFocus={autoFocus}
      />
      <button
        className="action-sheet-btn"
        onClick={(e) => {
          openActionSheet(e);
        }}
        type="button"
      >
        <svg className="icon nav-icon">
          <use xlinkHref={`${icons}#${icon}`} />
        </svg>
        <span className="caret" />
      </button>
    </div>
  );
};

const taskSchema = Yup.object().shape({
  taskdescription: Yup.string().required(),
  taskdue: Yup.string().required(),
});

let MarkAsDoneForm = (props) => {
  const toggleActionSheet = (type) => {
    props.toggleActionSheet({ type, open: true });
  };

  const [focused, focusEl] = useState({});
  const { bindSetValue, bindresetForm } = props;
  return (
    <div className={`followup next-step ${props.active && 'active'}`}>
      {props.active && (
        <Formik
          initialValues={{ taskdescription: '', taskdue: '', taskhelp: '' }}
          onSubmit={(values, actions) => {
            props.nextTask(values);
            actions.setSubmitting(false);
            actions.setValues({
              taskdescription: '',
              taskdue: '',
              taskhelp: '',
            });
          }}
          validationSchema={taskSchema}
          render={({ handleSubmit, setFieldValue, setValues }) => {
            bindSetValue(setFieldValue);
            bindresetForm(setValues);
            return (
              <form onSubmit={handleSubmit}>
                <fieldset className="step create edit complete-step">
                  <Field
                    component={renderFieldWithActionSheet}
                    type="text"
                    placeholderfloat="Describe your next step"
                    customclass="decorated"
                    name="taskdescription"
                    label="Objective"
                    toggleActionSheet={toggleActionSheet}
                    icon="action-suggest"
                    validate={[required]}
                    onFocus={() => focusEl({ taskdescription: true })}
                    onBlur={() => focusEl({ taskdescription: false })}
                    active={!!focused.taskdescription}
                    autoFocus={false}
                  />
                  <Field
                    component={renderFieldWithActionSheet}
                    type="text"
                    placeholderfloat="When will you complete   this step?"
                    customclass="decorated"
                    name="taskdue"
                    label="Due Date"
                    toggleActionSheet={toggleActionSheet}
                    icon="action-calendar"
                    readOnly={true}
                    validate={[required]}
                    onFocus={() => focusEl({ taskdue: true })}
                    onBlur={() => focusEl({ taskdue: false })}
                    active={!!focused.taskdue}
                  />
                  <button className="action next" type="submit">
                    <span>Submit</span>
                  </button>
                </fieldset>
              </form>
            );
          }}
        />
      )}
    </div>
  );
};

export default MarkAsDoneForm;
