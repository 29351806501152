import React from 'react';

import { calculateDays } from '../../../helpers';
import Tags from '../shared/Tags.component';
import { ReactivateText } from '../../';
import { reactive, taskType } from '../../../constant';

/* Pipeline / Active Tiles Targets page functionality */
const ActiveTile = (props) => {
  const { due, valueterm, type, typeterm, collaborators, overlaps, phase, projected_revenue } = props.tile;
  const task =
    props.tile.tasks &&
    props.tile.tasks.find((item) => item.done === taskType.incomplete && props.currentuser === item.user_id);
  return (
    <>
      <h2>
        {/* if this target has been re-activated AND we do NOT have a task */}
        {phase === reactive && !task ? <ReactivateText /> : task && task.description}
      </h2>
      <time className="due">{calculateDays(due)}</time>
      <Tags
        detail="1"
        type={type}
        valueterm={valueterm}
        typeterm={typeterm}
        collaborators={collaborators}
        overlaps={overlaps}
        projected_revenue={projected_revenue}
      />
    </>
  );
};

export default ActiveTile;
