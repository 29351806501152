import { API_PARAMS } from './apiEndPoints';
import { ROUTES_PATH, ROUTES_SUB_PATH } from './routes';

export const mainTabs = [
  {
    name: 'Tracker',
    link: { pathname: ROUTES_PATH.DASHBOARD + ROUTES_SUB_PATH.DASHBOARD.ACTIVE },
    activeOn: ROUTES_PATH.TARGETDETAIL,
    icon: 'targets',
    permission: 'targets',
  },
  {
    name: 'Trainer',
    link: { pathname: ROUTES_PATH.TRAINER + ROUTES_SUB_PATH.TRAINER.LESSONS },
    activeOn: ROUTES_PATH.TRAINER,
    icon: 'trainer',
    permission: 'trainer',
  },
  {
    name: 'Insights',
    link: { pathname: ROUTES_PATH.INSIGHTSBD },
    activeOn: ROUTES_PATH.INSIGHTS,
    icon: 'insights',
    badge: true,
    permission: 'insights',
  },
  {
    name: 'Viewer',
    link: { pathname: `${ROUTES_PATH.VIEWER}/${API_PARAMS.PIPELINE}${ROUTES_PATH.OVERVIEW}` },
    activeOn: ROUTES_PATH.VIEWER,
    icon: 'viewer',
    permission: 'viewer',
  },
  {
    name: 'Account',
    link: { pathname: ROUTES_PATH.ACCOUNT + ROUTES_SUB_PATH.ACCOUNT.EDIT },
    activeOn: ROUTES_PATH.ACCOUNT,
    icon: 'account',
    id: 'menu-more',
    permission: 'account',
  },
];

export const insightsTabs = [
  // { name: 'New', status: API_PARAMS.NEW, link:{pathname: `${ROUTES_PATH.INSIGHTS}/new`} },
  // { name: 'Viewed', status: API_PARAMS.VIEWED, link:{pathname: `${ROUTES_PATH.INSIGHTS}/viewed`} }
  { name: 'BD Insights', status: API_PARAMS.INSIGHTS_BD, link: { pathname: `${ROUTES_PATH.INSIGHTSBD}` } },
  { name: 'Firm Insights', status: API_PARAMS.INSIGHTS_FIRM, link: { pathname: `${ROUTES_PATH.INSIGHTSFIRM}` } },
  // { name: 'Videos', status: API_PARAMS.VIDEO, link:{pathname: `${ROUTES_PATH.INSIGHTS}/videos`} },
  // changine API_PARAMS.(STATUS FROM APIENDPOINTS.JS)
  // { name: 'Events', status: API_PARAMS.NEW, link:{pathname: `${ROUTES_PATH.INSIGHTS}/events`} },
  // { name: 'Guides', status: API_PARAMS.NEW, link:{pathname: `${ROUTES_PATH.INSIGHTS}/guides`} },
  // { name: 'White Papers', status: API_PARAMS.NEW, link:{pathname: `${ROUTES_PATH.INSIGHTS}/whitepapers`} },
  // { name: 'FAQs', status: API_PARAMS.NEW, link:{pathname: `${ROUTES_PATH.INSIGHTS}/FAQs`} },
];
export const trainerTabs = [
  { name: 'Lessons', status: API_PARAMS.LESSONS, link: { pathname: `${ROUTES_PATH.TRAINER}/lessons` } },
  { name: 'Worksheets', status: API_PARAMS.WORKSHEETS, link: { pathname: `${ROUTES_PATH.TRAINER}/worksheets` } },
  { name: 'Firm Content', status: API_PARAMS.FIRMCONTENT, link: { pathname: `${ROUTES_PATH.TRAINER}/firmcontent` } },
];

export const trainerSubTabs = [
  { name: 'Lesson', status: API_PARAMS.LESSONS },
  { name: 'Worksheet', status: API_PARAMS.WORKSHEETS },
  { name: 'Transcript', status: API_PARAMS.TRANSCRIPT },
];

export const viewerTabs = [
  { name: 'Trainer', status: API_PARAMS.TRAINER, link: `${ROUTES_PATH.VIEWER}/${API_PARAMS.TRAINER}` },
  { name: 'Planner', status: API_PARAMS.PLANNER, link: `${ROUTES_PATH.VIEWER}/${API_PARAMS.PLANNER}` },
  { name: 'Pipeline', status: API_PARAMS.PIPELINE, link: `${ROUTES_PATH.VIEWER}/${API_PARAMS.PIPELINE}` },
  { name: 'External', status: API_PARAMS.EXTERNAL, link: `${ROUTES_PATH.VIEWER}/${API_PARAMS.EXTERNAL}` },
];
export const accountTab = {
  account: 'edit',
  notifications: 'notifications',
  password: 'pwd',
  about: 'about',
  link: 'https://share.hsforms.com/1FqSDo-DsS1afTk51dLcmZQ1kbv3',
};
export const accountTabs = [
  { name: 'Account', status: accountTab.account },
  { name: 'Notifications', status: accountTab.notifications },
  { name: 'Password', status: accountTab.password },
  { name: 'About', status: accountTab.about },
];

export const overviewTabs = [
  { name: 'Score', which: 'average_score' },
  { name: 'Revenue', which: 'total_revenue' },
  { name: 'Steps', which: 'total_steps' },
  { name: 'Activity', which: 'total_usage_events' },
];

export const overviewTableHeader = [
  { Header: `Name`, accessor: 'name', id: 'name' },
  { Header: `Latest Activity`, accessor: 'current_score', id: 'current_score1' },
  { Header: `Current Score`, accessor: 'current_score', id: 'current_score' },
  { Header: `Average Score`, accessor: 'average_score', id: 'average_score' },
  { Header: `Steps Taken`, accessor: 'total_steps', id: 'total_steps' },
  { Header: 'Clients Pursued', accessor: 'clients_pursued', id: 'clients_pursued' },
  { Header: 'Average Potential', accessor: 'average_low', id: 'average_low' },
  { Header: 'Close Ratio <small><b>Created / Completed', accessor: 'total_created', id: 'total_created' },
  { Header: 'Total Revenue', accessor: 'total_revenue', id: 'total_revenue' },
];

export const targetsTabs = [
  { name: 'All', which: 'all' },
  { name: 'Personal', which: 'private' },
  { name: 'Shared', which: 'shared' },
];
