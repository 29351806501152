import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { isEmpty } from 'lodash';

import { getHighlightsData } from '../../../../actions';
import { DueDateFormatForAPI } from '../../../../constant';
import { PieChart, NoReports, TileLoader } from '../../../../components';

// Highlights container to wrap all the components for Highlights listing and graphs
class Highlight extends Component {
  state = {
    selectionRange: {
      startDate: new Date(),
      endDate: new Date(),
    },
    range: {
      startDate: new Date(),
      endDate: null,
      key: 'selection',
    },
    toggleRange: false,
  };

  componentDidMount() {
    if (this.props.graphOf.id && this.props.dateRange) {
      this.key = this.props.graphOf.id;
      this.getHighligtsDataFromAPI();
    }
  }

  componentDidUpdate(prevprops) {
    if (this.props.graphOf.id && this.props.dateRange && this.key !== this.props.graphOf.id) {
      this.key = this.props.graphOf.id;
      this.getHighligtsDataFromAPI();
    }
    if (this.props.graphOf.key && this.props.dateRange && prevprops.dateRange !== this.props.dateRange) {
      this.getHighligtsDataFromAPI();
    }
  }

  /**
   * call Api to get data for getting highlights stats && creating Pie Chart
   */
  getHighligtsDataFromAPI = () => {
    if (this.props.graphOf.key) {
      const range = {
        start: moment(this.props.dateRange.startDate).format(DueDateFormatForAPI),
        end: moment(this.props.dateRange.endDate).format(DueDateFormatForAPI),
      };
      this.props.getHighlightsData(this.props.graphOf, range);
    }
  };

  renderPeopleHighlights = (item, index) => {
    const { title, description, data } = item;
    if (!data) return index === 0 ? <NoReports key={index} style={{ width: '100%' }} /> : null;
    const [firstName, lastName] = data.name ? data.name.split(' ') : '';
    return (
      <div key={index} className="column-box">
        <h5> {title} </h5>
        <p className="legend"> {description || item[0]} </p>
        <dl className="handle person">
          <dt className="avatar">
            {firstName.charAt(0).toUpperCase()}
            {lastName.charAt(0).toUpperCase()}
          </dt>
          <dd className="name"> {data.name || ''} </dd>
        </dl>
      </div>
    );
  };

  renderHighlightsStats = (item, index) => {
    const { title, description, data = [] } = item;
    const isData = data.filter((item) => item.value).length;
    if (!isData) return null;
    return (
      <div key={index} className="column-box highlight pie">
        <h5> {title.split('Target ')[1]} </h5>
        <p className="legend" dangerouslySetInnerHTML={{ __html: description }}></p>
        <div className="pie-graph">
          <PieChart graphdata={data} title={title} />
        </div>
      </div>
    );
  };

  render() {
    const { highlights } = this.props;
    const { highlightsPeople = {}, highlightsStats = {} } = highlights;
    return (
      <main>
        {isEmpty(highlights) && <TileLoader />}
        <div id="mgmt-loading" className="img">
          <div className="middle-section">
            <div className="col-wrapper">{Object.values(highlightsPeople).map(this.renderPeopleHighlights)}</div>
          </div>
          <div className="middle-section">
            <div className="col-wrapper">{Object.values(highlightsStats).map(this.renderHighlightsStats)}</div>
          </div>
        </div>
      </main>
    );
  }
}

// map dispatch to props
const mapDispatchToProps = (dispatch) => ({
  getHighlightsData: (id, range) => dispatch(getHighlightsData(id, range)),
});

// map state to props
const mapStateToProps = (state) => ({
  graphOf: state.viewer.graphOf,
  highlights: state.viewer.highlights,
  loading: state.viewer.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(Highlight);
