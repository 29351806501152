import React, { useState, useEffect, useRef } from 'react';
import ChartistGraph from 'react-chartist';

const classNames = {
  'Target Deadlines': {
    0: 'ct-series-overdue',
    1: 'ct-series-soon',
    2: 'ct-series-later',
  },
  'Target Types': {
    0: 'ct-series-client',
    1: 'ct-series-prospect',
    2: 'ct-series-refsource',
  },
  'Target Value': {
    0: 'ct-series-low',
    1: 'ct-series-medium',
    2: 'ct-series-high',
  },
};

// add labels & series to graph data to show
const createGraphData = (graphdata, graphTitle) => {
  const labels = [];
  const series = [];
  console.log('CREATEGRAPHDATA', graphdata, graphTitle);
  graphdata.forEach(({ value, title }, index) => {
    series.push({
      value: Number(value),
      name: title,
      className: classNames[graphTitle][index],
    });
    labels.push(title);
  });
  return { labels, series };
};

/**
 * show piechart graph
 * @param {*} props
 */
const PieChart = ({ graphdata = [], title = '' }) => {
  const [pieData, setPieData] = useState({ labels: [], series: [] });

  const pieChartRef = useRef();

  useEffect(() => {
    const data = createGraphData(graphdata, title);
    setPieData(data);
    // update chart data on data update
    if (pieChartRef.current) pieChartRef.current.chart.__chartist__.update(data);
  }, [graphdata, title]);

  // reducer function to calculate the total of all values for this pie chart
  const sum = function (a, b) {
    return parseInt(typeof a === 'object' ? a.value : a) + parseInt(typeof b === 'object' ? b.value : b);
  };
  let total = graphdata.reduce(sum);

  console.log('pieData=', pieData, 'graphdata=', graphdata);

  // build the options
  const pieOptions = {
    showLabels: true,
    ignoreEmptyValues: true,
    chartPadding: 0,
    donut: true,
    donutWidth: 40,
    startAngle: 270,
    labelInterpolationFnc: function (label, idx) {
      var val = graphdata[idx].value;
      var percentage = Math.round((val / total) * 100);
      console.log({ label: label, idx: idx, value: val, total: total, pct: percentage });
      return percentage + '%';
    },
  };

  return (
    <ChartistGraph ref={pieChartRef} data={pieData} type={'Pie'} className={'chart graph-area'} options={pieOptions} />
  );
};

export default PieChart;
