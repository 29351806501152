import { API_URLS, API_METHODS, ADD_TASK_REQUEST, ADD_TASK_COMMIT, ADD_TASK_ROLLBACK } from '../../constant';
import { createPeopleTask } from '../viewer/people.action';

/**
 * Action to create task
 * @param  {object} payload {
    "tile_id": "28717",
    "description": "Call them",
    "help": "",
    "due": "2018-04-10",
    "done": "incomplete",
    "completed": "2018-04-09",
    "num": 2
},
 */
export const createTask = (payload, userId, accepted) => (dispatch) => {
  if (payload.isPeopleTarget) {
    delete payload.isPeopleTarget;
    return dispatch(createPeopleTask(payload, userId));
  }
  return dispatch({
    type: ADD_TASK_REQUEST,
    payload: { ...payload, accepted },
    meta: {
      offline: {
        // the network action to execute:
        effect: { apiInfo: { url: API_URLS.TASKS, type: API_METHODS.POST }, data: payload },
        // action to dispatch when effect succeeds:
        commit: { type: ADD_TASK_COMMIT, meta: { accepted, id: payload.id } },
        // action to dispatch if network action fails permanently:
        rollback: { type: ADD_TASK_ROLLBACK, meta: { accepted, id: payload.id } },
      },
    },
  });
};
