import React from 'react';

import icons from '../../../assets/img/icons.svg';

const NoTargets = (props) => (
  <p>
    <span>
      <svg className="icon">
        <use xlinkHref={`${icons}#no-${props.status}-targets`} />
      </svg>
    </span>
    {props.isPeopleTarget ? (
      <span>There are no {props.status} Targets.</span>
    ) : (
      <>
        <span>You have no {props.status} Targets.</span>
        {props.status === 'active' && <span className="tip">Use the ➕ button to create new Targets.</span>}
        {props.status === 'archived' && (
          <span className="tip">Congratulations. Keep working and completing Targets.</span>
        )}
        {props.status === 'completed' && <span className="tip">Grow your Pipeline by completing Targets.</span>}
      </>
    )}
  </p>
);

const NoResult = (props) => {
  return (
    <div className="no-results-found">
      {props.filter.filterBy !== 'all' || props.searchKeyword ? (
        <p>No Targets match your search terms.</p>
      ) : (
        <NoTargets props={props} />
      )}
    </div>
  );
};

export default NoResult;
