// this pattern is 8+ characters, plus one capital, one number, and one special char
// const passwordPattern = '^(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&_()#])([a-zA-Z0-9$@$!%*?&_()#]{8,})+$';
// this pattern is 8+ characters, plus one of [capital|number|special]
const passwordPattern = '^(?=.*[A-Z|0-9|[$@$!%*?&_()#])([a-zA-Z0-9$@$!%*?&_()#]{8,})+$';
export const required = (value) => ((value && value.trim()) || typeof value === 'number' ? undefined : 'Required');
export const passwordValidation = (value) =>
  value && !new RegExp(passwordPattern).test(value)
    ? 'At least 8 characters, including one capital, special character, or number.'
    : undefined;
export const emailValidation = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined;
export const requiredCompany = (value, allValues) => {
  const contact = allValues.contact ? allValues.contact.trim() : allValues.contact;
  return (value && value.trim()) || contact || typeof value === 'number' ? undefined : 'Required';
};
export const requiredContact = (value, allValues) => {
  const company = allValues.company ? allValues.company.trim() : allValues.company;
  return (value && value.trim()) || company || typeof value === 'number' ? undefined : 'Required';
};
export const otpValidation = (value) => {
  return value && !/^[0-9]{1,6}$/.test(value) ? 'This is number only field.' : undefined;
};
