import React from 'react';
import '../../styles.css';
import { MESSAGES } from '../../../../../constant';
import Input from '../../../../../components/common/input';
import TextArea from '../../../../../components/common/textArea';

const General = ({ values, setValues, formErrors, setFormErrors }) => {
  const handleChange = (value, name) => {
    setValues({ ...values, [name]: value });
    if (name === 'title' || name === 'curatedBy') {
      setFormErrors({ ...formErrors, [name]: value === '' || value.length > 60 });
    }
  };

  return (
    <div className="general-wrapper-inputs">
      <div className="field">
        <Input
          value={values.title}
          name="title"
          errors={formErrors}
          handleChange={(value) => handleChange(value, 'title')}
          clearError={(value) => setFormErrors({ ...formErrors, title: value })}
          errorMessage={values.title === '' ? MESSAGES.MANDATORY : MESSAGES.MAX_LENGTH_60}
          label="Title"
        />
      </div>
      <div className="field">
        <Input
          value={values.curatedBy}
          name="curatedBy"
          errors={formErrors}
          handleChange={(value) => handleChange(value, 'curatedBy')}
          clearError={(value) => setFormErrors({ ...formErrors, curatedBy: value })}
          errorMessage={values.curatedBy === '' ? MESSAGES.MANDATORY : MESSAGES.MAX_LENGTH_60}
          label="Curated by"
        />
      </div>
      <div className="field">
        <TextArea
          value={values.description}
          name="description"
          errors={formErrors}
          handleChange={(value) => handleChange(value, 'description')}
          clearError={(value) => setFormErrors({ ...formErrors, description: value })}
          errorMessage={values.description === '' ? MESSAGES.MANDATORY : MESSAGES.MAX_LENGTH}
          label="Description"
          rows="6"
        />
      </div>
    </div>
  );
};

export default General;
