import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { CSSTransition } from 'react-transition-group';

import { GroupAndFirmPopUp, TileLoader } from '../../components';
import { getViewer, updateGraphOf, resetGraphOf } from '../../actions';
import { getUserData } from '../../helpers';

import Mgmt from './mgmt/Mgmt.container';
import dashboardHOC from '../shared/DashboardHOC.container';
import 'chartist/dist/chartist.min.css';
import '../../assets/css/page-viewer.css';
import '../../assets/css/colleague-list.css';
import '../../assets/css/ai-frame.css';
import '../../assets/css/org-charts.css';
import { MESSAGES } from '../../constant';

/**
 * @description viewer container wrapper for pipeline and trainer reports
 */
class Viewer extends PureComponent {
  state = {
    shared: [],
    targets: [],
    tab: 'overview',
    activeFirmPopUp: false,
    inProp: false,
  };
  componentDidMount() {
    this.setState({ userData: getUserData().user });
  }
  /**
   * @description handle firm popup
   * @param { boolean } activeFirmPopUp - { true, false }
   */
  toggleFirmPopup = (activeFirmPopUp) => {
    this.setState({ activeFirmPopUp });
  };

  /**
   * @description update the GraphOf
   * @param { string } id
   * @param { string } type
   */
  updateReport = (id, type) => {
    this.props.updateGraphOf(id, type);
    this.toggleFirmPopup(false);
  };

  render() {
    const {
      match,
      viewer: { org, groups, graphOf, reports },
    } = this.props;
    const { tab, userData } = this.state;
    let canShowReportsNav = false;
    if (userData && userData.Subscriptions) {
      if (userData.Subscriptions.viewer.features.dashboards.on == 'yes') {
        canShowReportsNav = true;
      }
    }
    return (
      <>
        <GroupAndFirmPopUp
          active={this.state.activeFirmPopUp}
          close={() => this.toggleFirmPopup(false)}
          groups={groups}
          org={org}
          activeId={graphOf.id}
          updateReport={this.updateReport}
        />
        <CSSTransition classNames="fade" in={this.state.inProp} timeout={300}>
          {!isEmpty(reports.targets) || !isEmpty(reports.trainer) || !isEmpty(reports.digest) ? (
            <section className="nav-page active" id="viewer">
              {canShowReportsNav ? (
                <div className="viewer-sub-header nav-secondary app">
                  <ul className="button-bar ui status has-value">
                    <li
                      className={tab == 'overview' ? ' active' : null}
                      onClick={() => this.setState({ tab: 'overview' })}
                    >
                      <a>Reports</a>
                    </li>
                    <li
                      onClick={() => this.setState({ tab: 'kpi-dashboard' })}
                      className={tab == 'kpi-dashboard' ? 'active' : null}
                    >
                      <a>KPI Dashboard</a>
                    </li>
                  </ul>
                </div>
              ) : null}
              <div className="block-wrapper">
                <div className="viewer-pipeline-mgmt-report active">
                  <div
                    className={
                      canShowReportsNav
                        ? tab == 'overview'
                          ? 'ai-frame show-report-header'
                          : 'ai-frame show-report-header kpi-dash'
                        : 'ai-frame'
                    }
                    style={canShowReportsNav == false ? { paddingTop: 0 } : null}
                  >
                    <Route
                      path={`${match.url}/:tab`}
                      render={(props) => (
                        <Mgmt
                          {...props}
                          tab={tab}
                          openFirmPopup={this.toggleFirmPopup}
                          activeId={graphOf.key}
                          reports={{
                            ...reports,
                            //TODO this wont be necessary once it's coming from backend
                            digest: {
                              digest: {
                                code: 'VW_PL_FIRM_OVERVIEW_DIGEST',
                                description: 'Status of scheduled Digests',
                                download: { pdf: {}, csv: {} },
                                intro: MESSAGES.VIEWER_DIGEST_REPORTS,
                                name: 'Digests',
                                show_in_nav: true,
                              },
                            },
                          }}
                          // minDate={meta.minDate}
                          // maxDate={meta.maxDate}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <TileLoader />
          )}
        </CSSTransition>
      </>
    );
  }
}

// map dispatch to props
const mapDispatchToProps = (dispatch) => ({
  getViewer: () => dispatch(getViewer()),
  updateGraphOf: (id, type) => dispatch(updateGraphOf(id, type)),
  resetData: () => dispatch(resetGraphOf()),
});

// map state to props
const mapStateToProps = (state) => ({
  viewer: state.viewer,
});

export default dashboardHOC(connect(mapStateToProps, mapDispatchToProps)(Viewer));
