/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ReactIdSwiper from 'react-id-swiper';

import { swiperParams } from '../../../constant';
import TargetSlide from '../../../containers/targets/TargetDetail/TargetSlide.container';
import SharedTargetSlide from '../../../containers/targets/TargetDetail/SharedTargetSlide.container';
import { getUserId, clickOutSideSwiper } from '../../../helpers';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { ROUTES_PATH } from '../../../constant';

const TargetSwiper = (props) => {
  // Swiper instance
  const [swiper, updateSwiper] = useState(null);
  const [virtual, updateSlide] = useState({ slides: [] });
  const historyParams = useParams();
  // Slides current index
  const [currentIndex, updateCurrentIndex] = useState(props.initialSlide);
  const id = (props.targets[props.initialSlide] && props.targets[props.initialSlide].id) || 0;
  const [activeSlideId, updateActiveID] = useState(id);

  const currentUser = props.isPeopleTarget ? props.uid : getUserId();
  // Params definition
  const params = {
    ...swiperParams,
    getSwiper: updateSwiper, // Get swiper instance callback
    initialSlide: props.initialSlide, // don't want it to scroll to slide when it first opens
    virtual: {
      slides: [...props.targets],
      renderExternal(data) {
        // assign virtual slides data
        updateSlide(data);
      },
    },
  };

  const afterDelete = () => {
    if (swiper.virtual) {
      swiper.virtual.removeSlide(currentIndex);
      swiper.virtual.update();
      swiper.update();
      updateCurrentIndex(swiper.realIndex);
      swiper.virtual.slides[swiper.realIndex] && updateActiveID(swiper.virtual.slides[swiper.realIndex].id);
    }
    props.afterDelete();
  };

  const updateSlider = () => {
    if (virtual.slides.length > 0) {
      let virtualIndex = currentIndex;
      if (currentIndex !== 0) {
        virtualIndex =
          currentIndex !== props.targets.length - 1 ? virtual.to - virtual.from - 1 : virtual.slides.length - 1;
      }
      const virtualObj = { ...virtual };
      const currentTarget = props.targets.find((item) => item.id === virtualObj.slides[virtualIndex].id);
      if (currentTarget) {
        virtualObj.slides[virtualIndex] = currentTarget;
        swiper.virtual.slides = swiper.virtual.slides.map((item) => {
          if (item.id === currentTarget.id) {
            return currentTarget;
          }
          return item;
        });
        updateSlide(virtualObj);
      }
      swiper.update();
      swiper.virtual.update();
    }
  };

  /**
   * close target detail on outside click
   * @param {Object} target
   */
  const clickOutSide = (target) => {
    clickOutSideSwiper(target, props.confirmCancel);
  };

  // Add eventlisteners for swiper after initializing
  useEffect(() => {
    if (swiper !== null && !swiper.destroyed) {
      swiper.off('slideChange').on('slideChange', () => {
        let targetId = props?.targets[swiper.realIndex];

        if (historyParams.tab === 'pipeline') {
          window.history.pushState(
            '/',
            '',
            `/#/${ROUTES_PATH.VIEWER}/${ROUTES_PATH.PIPELINE}/${ROUTES_PATH.PEOPLE}/${historyParams?.uid}/${ROUTES_PATH.TARGETDETAIL}/${targetId.id}`,
          );
        }

        props.setAttention && props.setAttention(targetId.id);

        updateCurrentIndex(swiper.realIndex);
        updateActiveID(swiper?.virtual?.slides[swiper?.realIndex]?.id);
      });
      swiper.off('click').on('click', (e) => {
        clickOutSide(e.target);
      });
    }

    return () => {
      if (swiper !== null) {
        swiper.off('slideChange', () => {
          updateCurrentIndex(swiper.realIndex);
          updateActiveID(swiper.virtual.slides[swiper.realIndex].id);
        });
        swiper.off('click', (e) => {
          clickOutSide(e.target);
        });
      }
    };
  }, [swiper, props.initialSlide]);

  useEffect(() => {
    if (props.targets.length > 0) {
      updateSlider();
    }
  }, [props.targets]);

  if (props.viewerPage === true) {
    params.navigation = false;
  }

  return (
    <ReactIdSwiper {...params}>
      {virtual.slides.map((target) => {
        return (
          <div style={{ left: `${virtual.offset}px` }} key={target.id}>
            {target.collaborators.some(
              (collaborator) => collaborator.user_id === currentUser && collaborator.response === 'pending',
            ) ? (
              <SharedTargetSlide
                detail={{ ...target }}
                offset={virtual.offset}
                toggleActionSheet={props.toggleActionSheet}
                selectedValueFromAS={props.selectedValueFromAS}
                activeSlideId={activeSlideId}
                updateSlider={updateSlider}
                activeSlide={currentIndex}
                resetState={props.resetState}
                afterDelete={afterDelete}
                isPeopleTarget={props.isPeopleTarget}
                uid={props.uid}
              />
            ) : (
              <TargetSlide
                detail={{ ...target }}
                offset={virtual.offset}
                afterDelete={afterDelete}
                updateSlider={updateSlider}
                activeSlide={currentIndex}
                activeSlideId={activeSlideId}
                selectedValueFromAS={props.selectedValueFromAS}
                toggleActionSheet={props.toggleActionSheet}
                resetState={props.resetState}
                currentUser={currentUser}
                isPeopleTarget={props.isPeopleTarget}
                uid={props.uid}
              />
            )}
          </div>
        );
      })}
    </ReactIdSwiper>
  );
  // }
  // else {
  //     return <div>
  //                                 <SharedTargetSlide
  //                                     detail={{ ...targetproperties }}
  //                                     offset={virtual.offset}
  //                                     toggleActionSheet={props.toggleActionSheet}
  //                                     selectedValueFromAS={props.selectedValueFromAS}
  //                                     activeSlideId={activeSlideId}
  //                                     updateSlider={updateSlider}
  //                                     activeSlide={currentIndex}
  //                                     resetState={props.resetState}
  //                                     afterDelete={afterDelete}
  //                                     isPeopleTarget={props.isPeopleTarget}
  //                                     uid={props.uid}
  //                                 />
  //                                 :
  //                                 <TargetSlide
  //                                     detail={{ ...targetproperties }}
  //                                     offset={virtual.offset}
  //                                     afterDelete={afterDelete}
  //                                     updateSlider={updateSlider}
  //                                     activeSlide={currentIndex}
  //                                     activeSlideId={activeSlideId}
  //                                     selectedValueFromAS={props.selectedValueFromAS}
  //                                     toggleActionSheet={props.toggleActionSheet}
  //                                     resetState={props.resetState}
  //                                     currentUser={currentUser}
  //                                     isPeopleTarget={props.isPeopleTarget}
  //                                     uid={props.uid}
  //                                     />

  //                     </div>
  // }
};

export default TargetSwiper;
