import { GET_ATTENTION, REMOVE_ATTENTION } from '../../constant';
const initState = { id: '' };
const attention = (state = initState, action) => {
  switch (action.type) {
    case GET_ATTENTION:
      return action.payload;
    case REMOVE_ATTENTION:
      return initState;
    default:
      return state;
  }
};

export default attention;
