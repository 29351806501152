import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ClientsTable, TableFilter, NoReports, FirmReportHeader, TileLoader } from '../../../../components';
import { getClientsData } from '../../../../actions';
import icons from '../../../../assets/img/icons.svg';

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchClients: null,
      filteredData: props.clientsTableData,
    };
  }

  componentDidMount() {
    if (this.props.graphOf.id) {
      this.key = this.props.graphOf.id;
      this.getClientsDataFromAPI();
    }
    this.setState({ filteredData: this.props.clientsTableData });
  }

  componentDidUpdate(prevProps) {
    if (this.props.graphOf.id && this.key !== this.props.graphOf.id) {
      this.key = this.props.graphOf.id;
      this.getClientsDataFromAPI();
    }
    if (prevProps.clientsTableData !== this.props.clientsTableData) {
      this.setState({ filteredData: this.props.clientsTableData });
    }
  }

  /**
   * call Api to get data for clients
   */
  getClientsDataFromAPI = () => {
    if (this.props.graphOf && this.props.graphOf.id) {
      this.props.getClientsData(this.props.graphOf);
    }
  };

  // set global table filters
  setFilterProps = (filters) => {
    if (!this.state.searchClients) this.setState({ searchClients: filters.setGlobalFilter });
  };

  customFilter = (value) => {
    const { clientsTableData } = this.props;
    let company;
    let initiator;
    let collaborator;
    let desired;
    let filteredData = clientsTableData.filter((item) => {
      company =
        item.companies &&
        item.companies.find((ele) => {
          return ele.toUpperCase().includes(value.toUpperCase());
        });
      collaborator =
        item.collaborators &&
        item.collaborators.find((ele) => {
          const { first_name = '', last_name = '' } = ele;
          let name = first_name + last_name;
          return name.toUpperCase().includes(value.toUpperCase());
        });
      desired =
        item.objectives &&
        item.objectives.find((ele) => {
          return ele.toUpperCase().includes(value.toUpperCase());
        });
      initiator =
        item.initiators &&
        item.initiators.find((ele) => {
          const { first_name = '', last_name = '' } = ele;
          let name = first_name + last_name;
          return name.toUpperCase().includes(value.toUpperCase());
        });
      return collaborator || !!initiator || !!desired || !!company;
    });
    this.setState({ filteredData });
  };

  openPopup = () => {
    this.props.openFirmPopup(true);
  };

  render() {
    const { clientsTableData, loading } = this.props;
    const { filteredData } = this.state;

    return (
      <>
        <details
          className="report-params"
          open={this.props.reportParamsView === 'open' ? true : false}
          onToggle={this.props.handleReportParamsExpando}
        >
          <summary>
            <p>
              Viewing{' '}
              <b className="report-tag">
                <svg className="icon">
                  <use xlinkHref={`${icons}#org-${this.props.activeFirmGroupInfo.type}`} />
                </svg>{' '}
                {this.props.activeFirmGroupInfo.name}
              </b>
            </p>
          </summary>
          <div className="param org-view">
            <label>
              <span>View</span>
              <small>Select the Firm, a Group, or one User’s Pipeline.</small>
            </label>
            <div className="input-wrapper">
              <div className="value" onClick={() => this.openPopup()}>
                <div className="selection orgchart active">
                  <FirmReportHeader firmHeaderTitle={this.props.activeFirmGroupInfo} />
                  <div className="arrow">
                    <div className="caret-wrapper">
                      <div className="caret"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="presets">
                <small>
                  The{' '}
                  <b className="report-tag">
                    <svg className="icon">
                      <use xlinkHref={`${icons}#org-org`} />
                    </svg>{' '}
                    Firm
                  </b>{' '}
                  view shows Groups. The{' '}
                  <b className="report-tag">
                    <svg className="icon">
                      <use xlinkHref={`${icons}#org-group`} />
                    </svg>{' '}
                    Group
                  </b>{' '}
                  view shows Users in that Group.
                </small>
              </div>
            </div>
          </div>
        </details>
        <section className="panel table clients">
          {!clientsTableData.length && loading && <TileLoader />}
          {clientsTableData.length ? (
            <>
              <header className="filter">
                <TableFilter searchGlobal={this.state.searchClients} customFilter={this.customFilter} />
              </header>
              <div className="scroller horz">
                <ClientsTable clients={filteredData} headRows={clientsTableData} getFilterProps={this.setFilterProps} />
              </div>
            </>
          ) : (
            <NoReports loading={loading} />
          )}
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  graphOf: state.viewer.graphOf,
  clientsTableData: state.clientsTable.data || [],
  loading: state.clientsTable.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getClientsData: (id) => dispatch(getClientsData(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
