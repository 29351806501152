import React from 'react';

import WorksheetItem from './WorksheetItem.component';
import TileLoader from '../../../shared/TileLoader';

const Worksheets = ({ worksheets, ...props }) => (
  <>
    <header className="intro">
      <p>These Worksheets help you put the concepts in the Lessons into action.</p>
    </header>
    {!worksheets.length && <TileLoader />}
    <ol className="ai-dashboard lesson-container horz" id="lesson-list">
      {worksheets.map((worksheet) => (
        <WorksheetItem key={worksheet.id} detail={worksheet} {...props} />
      ))}
    </ol>
  </>
);

export default Worksheets;
