import { LOAD_OVERVIEW_CHARTS, LOAD_OVERVIEW_TABLE } from '../../constant';

const initialState = {
  groups: {},
  labels: [],
  legend: {},
  org: {},
  members: {},
};

const overviewCharts = (state = initialState, action) => {
  if (action.type === LOAD_OVERVIEW_CHARTS) {
    return action.payload;
  }
  return state;
};

export default overviewCharts;

export const overviewTable = (state = { columns: [], org: { latest_activity: {} }, groups: {} }, action) => {
  if (action.type === LOAD_OVERVIEW_TABLE) return action.payload;
  else return state;
};
