import React, { useEffect, useRef, useState } from 'react';
import icons from '../../../assets/img/icons.svg';
import closeIcon from '../../../assets/img/circle-close.svg';
import filterIcon from '../../../assets/img/filter.svg';
import searchIcon from '../../../assets/img/search-circle.svg';
import { useOnClickOutside } from '../../../hooks/onClickOutside';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { filteredInsights, insightSearchValues, restoreSearch } from '../../../actions';
import _, { isEmpty } from 'lodash';
import { ENTER, ESCAPE } from '../../../constant';

const SearchInsights = ({ setModalType }) => {
  const [showAll, setShowAll] = useState(true);
  const modal = useRef(null);

  const dispatch = useDispatch();
  const content = useSelector((state) => state.insightDashboard.content);
  const tags = useSelector((state) => state.insightDashboard.tags);
  const searchValues = useSelector((state) => state.insightDashboard.searchValues);

  const { searchValue, selectedFilters } = searchValues;

  useOnClickOutside(modal, () => setModalType(''));

  const getTagQuantity = (tagName) => {
    let counter = 0;
    content.forEach(({ tag_names }) => {
      if (tag_names) {
        tag_names.split(', ').forEach((name) => name === tagName && counter++);
      }
    });
    return counter;
  };

  const handleShowAll = () => {
    if (!showAll) {
      dispatch(restoreSearch());
    }
    setShowAll(true);
  };

  const handleFilters = () => {
    if (isEmpty(selectedFilters) && !searchValue) return;
    let filteredOptions = content;
    const checkedFilters = [];
    Object.entries(selectedFilters).forEach(([tag, value]) => value && checkedFilters.push(tag));

    if (searchValue.trim() !== '') {
      filteredOptions = content.filter(
        ({ name, description }) =>
          name.toLowerCase().trim().includes(searchValue.toLowerCase().trim()) ||
          description.toLowerCase().trim().includes(searchValue.toLowerCase().trim()),
      );
    }

    if (checkedFilters.length && filteredOptions.length) {
      const options = filteredOptions.length ? filteredOptions : content;
      filteredOptions = options.filter(
        ({ tag_names }) => tag_names && tag_names.split(', ').some((name) => checkedFilters.includes(name)),
      );
    }
    dispatch(filteredInsights(filteredOptions));
  };

  useEffect(() => {
    const filterChecked = Object.values(selectedFilters).some((filter) => filter === true);
    setShowAll(!filterChecked && !searchValue);
    handleFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters, searchValue]);

  useEffect(() => {
    window.addEventListener('keydown', (e) => {
      if (e.key === ENTER || e.key === ESCAPE) setModalType('');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="search-overlay" id="filter">
      <div ref={modal} className="search-modal-wrapper" onClick={(e) => e.stopPropagation()}>
        <div className="category-content">
          <img className="close-icon" src={closeIcon} alt="Closing of popup modal" onClick={() => setModalType('')} />
          <div className="search-modal-header">
            <img src={searchIcon} alt="Search" />
            <h2>Search</h2>
          </div>
          <input
            className="search-modal-input"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => dispatch(insightSearchValues({ ...searchValues, searchValue: e.target.value }))}
          />
        </div>
        <div className="category-content">
          <div className="search-modal-header">
            <img src={filterIcon} alt="Filter" />
            <h2>Filter</h2>
          </div>
          <div className="tag-filter-wrapper">
            {tags.map((tag) => (
              <div className="tag-filter-option" key={tag.id}>
                <input
                  id={tag.id}
                  type="checkbox"
                  className="tag-filter-check"
                  checked={!!selectedFilters[tag.name]}
                  onChange={() =>
                    dispatch(
                      insightSearchValues({
                        ...searchValues,
                        selectedFilters: { ...selectedFilters, [tag.name]: !selectedFilters[tag.name] },
                      }),
                    )
                  }
                />
                <label htmlFor={tag.id} className="tag-filter-name">
                  {tag?.name}
                </label>
                <span className="tag-filter-quantity">{getTagQuantity(tag?.name)}</span>
              </div>
            ))}
          </div>
          <div className="tag-filter-option show-all">
            <input
              id="show-all"
              type="checkbox"
              className="tag-filter-check"
              checked={showAll}
              onChange={handleShowAll}
            />
            <label htmlFor="show-all" className={`tag-filter-name show-all-label ${showAll ? 'show-all-checked' : ''}`}>
              <svg className="icon">
                <use xlinkHref={`${icons}#action-reset`} />
              </svg>
              <span>Show All Insights {showAll ? '' : ' (Reset)'}</span>
            </label>
            <span className="tag-filter-quantity">{content.length}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchInsights;
