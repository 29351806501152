import React from 'react';
import icons from '../../assets/img/icons.svg';

export const SearchBtn = ({ openFilter }) => (
  <li className="btn filter" onClick={openFilter}>
    <a href={undefined}>
      <svg className="icon nav-icon">
        <use xlinkHref={`${icons}#action-search`} />
      </svg>
      <span className="label">Search</span>
    </a>
  </li>
);
