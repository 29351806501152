import { Swiper, Navigation, Pagination, Virtual, Keyboard } from 'swiper/dist/js/swiper.esm';
import { isMobile } from '../helpers';

export const swiperParams = {
  Swiper,
  modules: [Navigation, Pagination, Virtual, Keyboard],
  init: true,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
    dynamicBullets: true,
  },
  keyboard: {
    enabled: true,
    onlyInViewport: false,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  // swipe instantly, except ignore touch events on these elements
  noSwipingSelector: 'input, textarea, article.card.flipped, button, .button, .button-bar, .action, .close-dialog',
  direction: 'horizontal',
  shortSwipes: isMobile(),
  loop: false,
  centeredSlides: true,
  spaceBetween: 0,
  preventClicks: false,
  preventClicksPropagation: true,
  containerClass: 'swiper-container soon active status-active', // Replace swiper-container with customized-swiper-container
  addSlidesAfter: 1,
  // threshold: 30
};
