import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import icons from '../../../assets/img/icons.svg';
import { CollaborationItem } from './CollaborationItem.component';
import { sharedStatus, yes } from '../../../constant';
import { ColleagueList } from '../../../components';

const getSharing = (props) => {
  const { revoke, showRevoke, currentuserid, showCollaborationDialog } = props;

  // we want to show only pending and accepted requests
  let collaborators = props.collaborators.filter(
    (collaborator) => collaborator.response === sharedStatus.pending || collaborator.response === sharedStatus.accepted,
  );
  if (collaborators.length === 1) {
    // there is only owner all the collaborators are revoked
    collaborators = [];
  }
  // sort
  collaborators.sort((a, b) => b.share_id - a.share_id);
  return (
    <>
      {props.showSharing && (
        <div className="search-container">
          <label>Share this Target with colleagues: </label>
          <input type="text" onClick={showCollaborationDialog} placeholder="Search for a name..." readOnly={true} />
        </div>
      )}
      {collaborators && collaborators.length > 0 ? (
        <>
          <h4>
            <span className="icon">
              <svg className="icon">
                <use xlinkHref={`${icons}#shared`} />
              </svg>
            </span>{' '}
            Currently Collaborating
          </h4>
          <p>You are working directly with these colleagues on this opportunity.</p>
          <ul className="colleague-list">
            {collaborators.map((collaborator, index) => (
              <CollaborationItem
                collaborator={collaborator}
                key={index}
                revoke={(e) => revoke(e)}
                showRevoke={showRevoke}
                currentuserid={props.isPeopleTarget ? props.uid : currentuserid}
              />
            ))}
          </ul>
        </>
      ) : (
        !props.showSharing && <p>You are not currently collaborating with anyone on this Target.</p>
      )}
    </>
  );
};
/**
 * component for Collaboration tabs on slider target detail
 * @param {Object} props
 */
const Collaboration = (props) => {
  const {
    overlaps,
    targetSubscription: { features },
  } = props;
  const Permission = useSelector((state) => state.session.user.Subscriptions.targets.features.overlaps);
  const collaboratorHead = useRef(null);
  return (
    <>
      <div className="collaboration" ref={collaboratorHead}>
        {features.sharing && features.sharing.on === yes
          ? getSharing(props)
          : overlaps.length === 0 && (
              <p>There are currently no overlaps with your colleagues for this contact or company.</p>
            )}
        {Permission.on !== 'no' && overlaps && overlaps.length > 0 ? (
          <>
            <h4>
              <span className="icon">
                <svg className="icon">
                  <use xlinkHref={`${icons}#overlapped`} />
                </svg>
              </span>{' '}
              Potential Overlaps
            </h4>
            <p>
              The following colleagues may be contacting companies or people with similar names. Consider touching base.
            </p>
            <ColleagueList list={overlaps} type="overlaps" />
          </>
        ) : (
          ''
        )}
      </div>
    </>
  );
};
export default Collaboration;
