import React, { useCallback, useEffect, useRef } from 'react';
import NumberFormat from 'react-number-format';

export const renderFieldNormal = ({ field, form: { touched, errors }, ...props }) => {
  const { active, type, disabled, onBlur, onFocus } = props;
  const hasValue = field.value && field.value.trim() && !active;
  return (
    <div
      className={`field-line ${props.customclass}
                        ${props.active ? 'focused' : ''} ${hasValue ? 'has-value' : ''}
                     ${errors[field.name] && touched[field.name] ? 'error ' : ''}`}
    >
      <label>{hasValue ? props.label : props.placeholderfloat}</label>
      <input
        {...field}
        maxLength={100}
        type={type}
        disabled={disabled}
        onBlur={onBlur}
        onFocus={onFocus}
        autoComplete="off"
      />
    </div>
  );
};

export const RenderFieldNumber = ({ field, form: { touched, errors }, input: { onChange } = {}, ...props }) => {
  const { active, disabled, onBlur, onFocus } = props;
  const hasValue = field.value && `${field.value}`.trim() && !active;

  const onValueChange = useCallback(
    (val) => {
      onChange?.(val.value);
    },
    [onChange],
  );

  const inputRef = useRef(null);

  useEffect(() => {
    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener('focus', onFocus);
      inputElement.addEventListener('blur', onBlur);
    }
    return () => {
      if (inputElement) {
        inputElement.removeEventListener('focus', onFocus);
        inputElement.removeEventListener('blur', onBlur);
      }
    };
  }, [onBlur, onFocus]);

  return (
    <div
      className={`field-line ${props.customclass}
                        ${props.active ? 'focused' : ''} ${hasValue ? 'has-value' : ''}
                     ${errors[field.name] && touched[field.name] ? 'error ' : ''}`}
    >
      <label>{hasValue ? props.label : props.placeholderfloat}</label>
      <NumberFormat
        {...props}
        {...field}
        isNumericString
        disabled={disabled}
        thousandSeparator
        fixedDecimalScale
        decimalScale={2}
        getInputRef={inputRef}
        onValueChange={onValueChange}
        maxLength={13}
      />
    </div>
  );
};

export const renderFieldSuggest = (props) => {
  const {
    field,
    form: { touched, errors },
  } = props;
  const { active, type, disabled, onBlur, onFocus } = props;
  const hasValue = field.value && field.value.trim() && !active;
  const a = { ...props };
  delete a.active;
  return (
    <div
      className={`field-line ${props.customclass}
                        ${props.active ? 'focused' : ''} ${hasValue ? 'has-value' : ''}
                     ${errors[field.name] && touched[field.name] ? 'error ' : ''}`}
    >
      <label>{hasValue ? props.label : props.placeholderfloat}</label>
      <input
        {...field}
        maxLength={100}
        type={type}
        disabled={disabled}
        onBlur={onBlur}
        onFocus={onFocus}
        autoComplete="off"
        {...a}
      />
    </div>
  );
};

export const renderFieldRadio = ({ field, form: { values }, ...props }) => {
  return (
    <li className={`${values[field.name] === field.value ? 'active' : ''}`}>
      <a>{props.label}</a>
      <input {...field} type="radio" />
    </li>
  );
};
