import { GET_FIRM_CONTENT } from '../../constant';

const initialState = {
  medias: [],
};

const firmContent = (state = initialState, action) => {
  switch (action.type) {
    case GET_FIRM_CONTENT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default firmContent;
