import React, { Component } from 'react';
import { connect } from 'react-redux';
import InfiniteCalendar from 'react-infinite-calendar';

import { ActionSheet, ActionSheetStep, ActionSheetObjective } from '../../components';
import { actionSheetType, calendarTheme } from '../../constant';
import 'react-infinite-calendar/styles.css'; // only needs to be imported once

const ACTION = {
  goal: ActionSheetObjective,
  taskdescription: ActionSheetStep,
  lostReason: ActionSheetObjective,
};

class ActionSheetContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.close = this.close.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.setState({
        isOpen: nextProps.actionSheetProps.open,
      });
    }
  }

  close() {
    this.props.close();
  }

  render() {
    const Sheet = ACTION[this.props.actionSheetType] ? ACTION[this.props.actionSheetType] : '';
    const today = new Date();
    const { title, customCssClass, data } = actionSheetType[this.props.actionSheetType];
    return (
      <ActionSheet isOpen={this.state.isOpen} onRequestClose={this.close} title={title} customCssClass={customCssClass}>
        {Sheet ? (
          data.map((item, i) => {
            return <Sheet item={item} key={i} onSelect={this.props.onSelect} />;
          })
        ) : (
          <div className="action-item window">
            <InfiniteCalendar
              theme={calendarTheme}
              width={'100%'}
              // height={200}
              selected={today}
              onSelect={this.props.onSelect}
              // minDate={new Date()} ... allow users to select dates in the past
            />
          </div>
        )}
      </ActionSheet>
    );
  }
}
const mapStateToProps = (state) => ({
  ...state.actionSheet,
});
export default connect(mapStateToProps, null)(ActionSheetContainer);
