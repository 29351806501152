import React, { useState } from 'react';
import '../styles.css';

const ConfirmationModal = ({ handleClose, deleteAction, deleteItem }) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      deleteAction();
      handleClose();
    } catch (error) {
      setError('Looks like something went wrong, please try again');
    }
    setLoading(false);
  };

  return (
    <>
      <p className="delete-warning">
        You are about to delete {deleteItem}. <br />
        Are you sure you want to perform this action?
      </p>
      {!!error && <span className="delete-error-message error-message">{error}</span>}
      <div className="preview-button-wrapper">
        <button className="cancel-button" type="button" onClick={handleClose}>
          Cancel
        </button>
        <button className="delete-button" type="button" onClick={handleDelete}>
          {loading ? <span className="loader" /> : 'Delete'}
        </button>
      </div>
    </>
  );
};

export default ConfirmationModal;
