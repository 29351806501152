import React from 'react';

import { getClass } from '../../../helpers/utils';
import history from '../../../helpers/history';
import icons from '../../../assets/img/icons.svg';
import DataValue from '../shared/DataValue.component';
import { gTag } from '../../../helpers';

/**
 * @desc render insight slide
 * @param {object} $detail - detail of the insight- {title, category, type, observation, code, data_label, suggestion, id}
 */
const InsightSlide = ({ offset, detail }) => {
  return (
    <div className="swiper-slide" style={{ left: `${offset}px` }}>
      <article className={`card insight ${detail.type}`}>
        <div>
          <header>
            <h1>{detail.title}</h1>
            {/*
						<div className="tags">
							<span className="icon">
								<svg className="icon">
									<use xlinkHref={`${icons}#cat-${detail.category}`} />
								</svg>
							</span>
							<span className="icon type">
								<svg className="icon">
									<use xlinkHref={`${icons}#${detail.type}`} />
								</svg>
							</span>
						</div>
						*/}
            <h2 className="objective overflowed">
              <span dangerouslySetInnerHTML={{ __html: detail.observation }}></span>
            </h2>
          </header>
          <div className="main">
            <div className="block data">
              <div className={`nudge ${detail.category}`}>
                <div className="data">
                  <DataValue detail={detail} />
                </div>
                <p className={`label ${getClass(detail.code)}`}>{detail.data_label}</p>
              </div>
            </div>
            <div className="block suggestion">
              <p dangerouslySetInnerHTML={{ __html: detail.suggestion }}></p>
            </div>
          </div>
          <div
            className="close-dialog"
            onClick={() => {
              history.push('/insights/new');
              gTag('Tile Close', `Insight: ${detail.id}`);
            }}
          >
            <svg className="icon nav-icon">
              <use xlinkHref={`${icons}#action-close`}></use>
            </svg>
          </div>
        </div>
      </article>
    </div>
  );
};

export default InsightSlide;
