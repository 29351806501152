import React from 'react';

import '../../assets/css/page-insights.css';
import { sharedStatus } from '../../constant';
import icons from '../../assets/img/icons.svg';
import { gTag, getUserId } from '../../helpers';
import { withRouter } from 'react-router-dom';

/* Pipeline / Targets page functionality */
const Tile = (props) => {
  const { tile, insight, target, trainer } = props;
  const currentuser = getUserId();
  const isTileOwnerCurrentUser = currentuser === tile.owner_id;
  let bucketName = 'insights';
  if (tile.shared === sharedStatus.pending && !isTileOwnerCurrentUser) {
    bucketName = 'shared';
  } else {
    bucketName = tile.status === 'active' ? tile.bucket : tile.status;
  }
  return (
    <li
      onClick={() => {
        props.goTo();
        gTag('Tile Open', `${tile.status === 'new' || tile.status === 'viewed' ? 'Insight' : 'Target'}: ${tile.id}`);
      }}
      className={`${tile.shared === sharedStatus.pending && !isTileOwnerCurrentUser ? 'do-now' : ''}`}
      id={tile.id}
    >
      <article className={`tile ${insight ? 'insights ' + tile.type : bucketName}`}>
        {insight && <h1>{tile.title}</h1>}
        {target && (
          <h1>
            <span className="contact">{tile.contact}</span>
            <span className="company">{tile.company}</span>
          </h1>
        )}
        {trainer && (
          <h3>
            <svg className="icon nav-icon">
              <use xlinkHref={`${icons}#cat-${tile.category}`} />
            </svg>
            <span className="company">{'Warm Market Connectors'}</span>
          </h3>
        )}
        {props.children}
      </article>
    </li>
  );
};

export default withRouter(Tile);
