import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';

import '../../../assets/css/auto-suggest.css';
import { isMobile } from '../../../helpers/utils';

/**
 * component for select user to share target
 * @param {Object} props
 */
// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => {
  return (
    <>
      <span className="name">{suggestion.fullname}</span>
      <small>{suggestion.email}</small>
    </>
  );
};

const SelectUser = (props) => {
  const { users, shareTarget } = props;

  const [items, updateSuggestion] = useState(users);
  const [value, setValue] = useState('');
  const [focus, setValueFocused] = useState('active');

  const getSuggestionValue = (suggestion) => suggestion.fullname;

  let input;

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0 ? [] : users.filter((lang) => lang.fullname.toLowerCase().indexOf(inputValue) > -1);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    const suggestions = getSuggestions(value);
    updateSuggestion(suggestions);
  };

  const onSuggestionsClearRequested = () => {
    updateSuggestion([]);
  };

  const onSuggestionSelected = (event, { suggestion, method }) => {
    if (method === 'enter') {
      input && input.blur();
    } else {
      setValue('');
      shareTarget(suggestion);
    }
  };

  const closePopUp = () => {
    setValue('');
    props.closePopUp();
  };

  return (
    <div className={`dialog collaborationDl  ${props.active && 'active'}`}>
      {props.active && (
        <div className="box">
          <div>Collaborators will be able to view Steps and Notes associated with this target.</div>
          <br />
          <div className="search-con-outer">
            <button type="button" onClick={closePopUp}>
              {' '}
              CANCEL{' '}
            </button>
            <div className="search-container">
              <Autosuggest
                suggestions={items}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                inputProps={{
                  label: 'Search for a name...',
                  value,
                  focus,
                  onChange: (event, { newValue }) => {
                    setValue(newValue);
                  },
                  onBlur: (e, { highlightedSuggestion }) => {
                    if (!isMobile()) {
                      if (highlightedSuggestion) {
                        shareTarget(highlightedSuggestion);
                        setValue('');
                      }
                    }
                    setValueFocused(null);
                  },
                  onFocus: () => {
                    setValueFocused('active');
                  },
                  autoFocus: true,
                }}
                ref={(autosuggest) => {
                  if (autosuggest !== null) {
                    input = autosuggest.input;
                  }
                }}
                onSuggestionSelected={onSuggestionSelected}
                focusInputOnSuggestionClick={false}
                renderInputComponent={(inputProps) => {
                  const focus = inputProps.focus || inputProps.value;
                  return (
                    <div className={`field-line decorated ${focus ? 'focused' : ''}`}>
                      <label>{inputProps.label}</label>
                      <input type="text" maxLength={100} autoComplete="off" {...inputProps} />
                    </div>
                  );
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default SelectUser;
