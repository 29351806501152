import { UPDATE_LOGO } from '../../constant';

const logo = (state = '', action) => {
  if (action.type === UPDATE_LOGO) {
    return action.payload;
  } else {
    return state;
  }
};

export default logo;
