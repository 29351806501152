import React, { useEffect, useRef, useState } from 'react';
import dropdownArrow from '../../../assets/img/dropdown.arrow.svg';
import './styles.css';
import { useOnClickOutside } from '../../../hooks/onClickOutside';
import { MESSAGES } from '../../../constant';
import { getRandomString } from '../../../helpers';

const StyledSelect = ({
  options,
  defaultOption,
  handleChange,
  error,
  value,
  isSearchable = true,
  isCreatable = true,
  handleCreate,
  addNew,
  name,
  multiselect = false,
}) => {
  const [activeOption, setActiveOption] = useState(false);
  const [ariaExpanded, setAriaExpanded] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultOption);
  const [searchValue, setSearchValue] = useState('');
  const [searchOptions, setSearchOptions] = useState([]);

  const wrapperRef = useRef(null);
  useOnClickOutside(wrapperRef, () => setActiveOption(false));

  const handleSelectClick = () => {
    setActiveOption((active) => !active);
    setAriaExpanded((expanded) => !expanded);
  };

  const optionHandler = (e) => {
    if ((e.type === 'click' && e.clientX !== 0 && e.clientY !== 0) || e.key === 'Enter') {
      const value = e.target?.labels[0]?.innerHTML || e.target?.nextSibling?.innerHTML || e.target.dataset['label'];
      if (multiselect) {
        if (!value) return;
        if (selectedValue?.includes(value)) {
          const newValues = selectedValue?.filter((selected) => selected !== value);
          setSelectedValue(newValues);
          handleChange(newValues, name || options[0].name);
        } else {
          const newValues = selectedValue === defaultOption ? [value] : [...selectedValue, value];
          setSelectedValue(newValues);
          handleChange(newValues, name || options[0].name);
        }
      } else {
        setSelectedValue(value);
        handleChange(value, name || options[0].name);
        setActiveOption(false);
      }
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value.trim() !== '') {
      const filteredOptions = options.filter(({ label }) =>
        label.toLowerCase().trim().includes(e.target.value.toLowerCase().trim()),
      );
      setSearchOptions(filteredOptions);
    } else {
      setSearchOptions(options);
    }
  };

  const handleCreation = () => {
    handleCreate(true);
  };

  useEffect(() => {
    setSearchOptions(options);
  }, [options]);

  useEffect(() => {
    if (value === '') {
      setActiveOption(false);
      setSelectedValue(defaultOption);
    } else {
      setSelectedValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (selectedValue?.length === 0 || !selectedValue) setSelectedValue(defaultOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  return (
    <div className="select-wrapper">
      <div className={`custom-select ${activeOption && 'active'}`} ref={wrapperRef}>
        <button
          type="button"
          className={`select-button ${error && 'selectError'}`}
          role="combobox"
          aria-labelledby="select button"
          aria-haspopup="listbox"
          aria-expanded={String(ariaExpanded)}
          aria-controls="select-dropdown"
          onClick={handleSelectClick}
        >
          <span className="selected-value">
            {Array.isArray(selectedValue) ? selectedValue.join(', ') : selectedValue}
          </span>
          <span className="arrow">
            <img src={dropdownArrow} />
          </span>
        </button>
        <ul className="select-dropdown" role="listbox" id="select-dropdown">
          {!!isSearchable && (
            <li className="search-li">
              <input
                className="search-input"
                placeholder="Search"
                value={searchValue}
                onChange={(e) => handleSearch(e)}
              />
            </li>
          )}
          {searchOptions?.map(({ id, name, label }) => {
            const uuid = getRandomString();
            return (
              <li
                className={`select-option ${multiselect && 'multiselect'}`}
                key={uuid}
                role="option"
                onClick={optionHandler}
                onKeyUp={optionHandler}
              >
                <input type="radio" id={id} name={name} data-label={label} />
                {!!multiselect && <input id={id} type="checkbox" checked={selectedValue?.includes(label)} readOnly />}
                <label htmlFor={id}>{label}</label>
              </li>
            );
          })}
          {!!isCreatable && (
            <li className="create-li">
              <button type="button" className="select-creation" onClick={handleCreation}>
                {addNew || 'Add New Tag'}
              </button>
            </li>
          )}
        </ul>
      </div>
      {!!error && <span className="error-message">{MESSAGES.MANDATORY}</span>}
    </div>
  );
};

export default StyledSelect;
