import React, { useState } from 'react';
import DragAndDrop from '../../../../containers/Insights/newInsightModal/newContent/DragAndDrop';
import exampleLogo from '../../../../assets/img/example-logo.svg';
import '../styles.css';
import Input from '../../input';
import ColorPicker from '../../colorPicker';
import Preview from './preview';
import Tabs from '../../tabs';
import { blobToFile } from '../../../../helpers';
import { useDispatch } from 'react-redux';
import { setNewTemplate } from '../../../../actions';

export const TABS = {
  IMAGE: 'Image',
  COLOR: 'Color',
};

const NewStyle = ({ handleClose }) => {
  const [templateName, setTemplateName] = useState('');
  const [errors, setErrors] = useState({ templateName: false });
  const [activeTab, setActiveTab] = useState(TABS.IMAGE);
  const [loading, setLoading] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('#15397E');
  const [backgroundImage, setBackgroundImage] = useState('');
  const [backgroundBlob, setBackgroundBlob] = useState('');
  const [logoImage, setLogoImage] = useState(exampleLogo);
  const [logoBlob, setLogoBlob] = useState(exampleLogo);
  const dispatch = useDispatch();

  const handleInputChange = (newValue) => {
    setTemplateName(newValue);
  };

  const handleClearErrors = (value) => {
    setErrors({ templateName: value });
  };

  const handleBackgroundChange = (value) => {
    setBackgroundImage(value === '' ? value : URL.createObjectURL(value));
    setBackgroundBlob(value);
  };

  const handleLogoChange = (value) => {
    setLogoImage(value === '' ? exampleLogo : URL.createObjectURL(value));
    setLogoBlob(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.append('name', templateName);
    bodyFormData.append('color', activeTab === TABS.IMAGE ? '' : backgroundColor);
    bodyFormData.append(
      'image',
      activeTab === TABS.IMAGE ? blobToFile(backgroundBlob, `${backgroundBlob?.name?.split('.')?.[0]}.jpg`) : '',
    );
    bodyFormData.append('logo_image', blobToFile(logoBlob, `${logoBlob?.name?.split('.')?.[0]}.jpg`));
    bodyFormData.append('image_selected', activeTab === TABS.IMAGE ? 1 : 0);
    try {
      await dispatch(setNewTemplate(bodyFormData));
    } catch (error) {
      console.error(error);
    }
    handleClose();
    setLoading(false);
  };

  return (
    <form className="creation-form" onSubmit={handleSubmit}>
      <div className="style-creation-section">
        <label className="style-creation-label">Create template</label>
        <Input
          value={templateName}
          name="templateName"
          errors={errors}
          handleChange={handleInputChange}
          clearError={handleClearErrors}
          errorMessage="Template Name is required"
          label="Template Name"
        />
        <div className="pickers-wrapper">
          <div className="picker">
            <label>Banner Background</label>
            <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabTexts={Object.values(TABS)} />

            <span style={{ display: activeTab === TABS.COLOR ? 'block' : 'none' }}>
              <ColorPicker color={backgroundColor} setColor={setBackgroundColor} />
            </span>

            <span style={{ display: activeTab === TABS.IMAGE ? 'block' : 'none' }}>
              <DragAndDrop image handleChange={handleBackgroundChange} blob={backgroundImage} />
            </span>
          </div>
          {/* Commenting while it's not defined by client */}
          {/* <div className="picker">
            <label>Body color</label>
            <ColorPicker color={bodyColor} setColor={setBodyColor} />
          </div> */}
        </div>
        <div className="picker">
          <label>Logo Image</label>
          <DragAndDrop image handleChange={handleLogoChange} blob={logoImage} />
        </div>
        <div className="save-button-container">
          {' '}
          <button
            className="style-preview-button"
            type="submit"
            disabled={
              templateName === '' || logoImage === exampleLogo || (activeTab === TABS.IMAGE && backgroundImage === '')
            }
          >
            {loading ? <span className="loader" /> : 'SAVE TEMPLATE'}
          </button>
        </div>
      </div>
      <div className="style-preview-section">
        <label className="style-creation-label">Preview</label>
        <div className="style-preview-wrapper">
          <Preview
            backgroundColor={backgroundColor}
            backgroundImage={backgroundImage}
            logo={logoImage}
            activeTab={activeTab}
          />
        </div>
      </div>
    </form>
  );
};

export default NewStyle;
