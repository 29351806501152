import {
  CLIENTS_TABLE_REQUEST,
  PROGRESS_TABLE_FAILURE,
  PROGRESS_TABLE_SUCCESS,
  USER_WORKSHEETS_FAILURE,
  USER_WORKSHEETS_SUCCESS,
} from '../../constant';

const initialState = {
  data: [],
  loading: true,
  userWorksheet: [],
};

const progressTable = (state = initialState, action) => {
  switch (action.type) {
    case CLIENTS_TABLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PROGRESS_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case PROGRESS_TABLE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case USER_WORKSHEETS_SUCCESS:
      return {
        ...state,
        loading: false,
        userWorksheet: action.payload,
      };
    case USER_WORKSHEETS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default progressTable;
