import React from 'react';
import icons from '../../../assets/img/icons.svg';
import { emptyTrash } from '../../../services/targets/dashboard.service';

export default function MoveToTrash(props) {
  function goToDelete() {
    props.getTargetByStatus('deleted');
    props.hideDialog();
  }
  function handleMoveToTrash() {
    emptyTrash();
    props.hideDialog();
    props.getTargetByStatus('active');
  }
  return (
    <div className="info-block revenue">
      <fieldset>
        <legend>
          <svg className="icon nav-icon">
            <use xlinkHref={`${icons}#status-trashed`} />
          </svg>
          Recently deleted
        </legend>
        <p>Recently Deleted Targets will be automatically removed after 30 days. You can review them here.</p>
        <ul className="strip">
          <li style={{ marginRight: 30 }}>
            <a onClick={handleMoveToTrash} style={{ color: '#2d498d', textDecoration: 'underline' }}>
              Delete targets now
            </a>
          </li>
          <li>
            <a className="button review-trashed" onClick={goToDelete}>
              Review Deleted Targets
            </a>
          </li>
        </ul>
      </fieldset>
    </div>
  );
}
