import React from 'react';
import { ReactComponent as EmailIcon } from '../../../../assets/img/email.svg';
import { ReactComponent as LinkIcon } from '../../../../assets/img/link.svg';
import { ReactComponent as FacebookIcon } from '../../../../assets/img/facebook.svg';
import { ReactComponent as LinkedinIcon } from '../../../../assets/img/linkedin.svg';
import { ReactComponent as TwitterIcon } from '../../../../assets/img/twitter.svg';
import { ReactComponent as DurationIcon } from '../../../../assets/img/time.svg';
import './preview.css';
import { getRandomString } from '../../../../helpers';
import { TABS } from '.';

const contentPreviewLength = 3;

const Preview = ({ backgroundColor, backgroundImage, logo, activeTab }) => {
  const sharingPlatforms = [
    { id: 1, icon: <EmailIcon /> },
    { id: 3, icon: <FacebookIcon /> },
    { id: 4, icon: <LinkedinIcon /> },
    { id: 5, icon: <TwitterIcon /> },
  ];

  return (
    <div className="style-preview">
      <div
        className="style-preview-header"
        style={{
          background:
            activeTab === TABS.COLOR
              ? backgroundColor
              : backgroundImage
                ? `center / cover url(${backgroundImage})`
                : backgroundColor,
        }}
      >
        <img src={logo} />
        <span>MONTH DD, YYYY</span>
      </div>
      <div className="style-preview-title-wrapper">
        <span className="style-preview-title" style={{ color: backgroundColor }}>
          Lorem ipsum dolor sit amet.
        </span>
        <span className="style-preview-curated">Curated by: John Doe</span>
      </div>

      <p className="style-preview-description">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed elit nulla, faucibus at lorem malesuada, egestas
        auctor.
      </p>
      {[...Array(contentPreviewLength)].map((index) => (
        <div key={index} className="style-preview-content">
          <div className="content-image-preview"></div>
          <div className="content-description-wrapper">
            <span className="content-description-title">Lorem ipsum dolor sit amet.</span>
            <div className="sharing-links sharing-links-preview">
              {sharingPlatforms.map((platform, index) => (
                <>
                  <span key={getRandomString()}>{platform.icon}</span>
                  {!!(index < sharingPlatforms.length - 1) && <div className="external-share-division" />}
                </>
              ))}
            </div>
          </div>
          <div className="style-preview-duration">
            <DurationIcon />
            <span>HH:MM</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Preview;
