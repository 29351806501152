import React from 'react';
import './styles.css';

const Tabs = ({ activeTab, setActiveTab, tabTexts }) => (
  <div className="tab-container">
    {tabTexts.map((tab) => (
      <button
        key={tab}
        type="button"
        className={`custom-tab ${activeTab === tab && 'active'}`}
        onClick={() => setActiveTab(tab)}
      >
        {tab}
      </button>
    ))}
  </div>
);

export default Tabs;
