import React, { useEffect, useState } from 'react';
import './styles.css';

const TextArea = ({ value, name, errors, label, handleChange, clearError, errorMessage, rows }) => {
  const [showLabel, setShowLabel] = useState(true);

  const handleOnBur = (e) => {
    clearError(e.target.value.length > 500);
    setShowLabel(value ? false : true);
  };

  useEffect(() => {
    setShowLabel(value ? false : true);
  }, [value]);

  return (
    <>
      <div className="field">
        <textarea
          id={name}
          className={`default-textarea ${errors[name] && 'textarea-error'}`}
          name={name}
          value={value}
          onChange={(e) => handleChange(e.target.value, name)}
          onBlur={handleOnBur}
          onFocus={() => setShowLabel(true)}
          rows={rows}
        />
        {!!showLabel && (
          <label htmlFor={name} className={`${errors[name] && 'label-error'}`}>
            {label}
          </label>
        )}
      </div>
      {!!errors[name] && <span className="error-message">{errorMessage}</span>}
    </>
  );
};

export default TextArea;
