import React, { Component } from 'react';
import { connect } from 'react-redux';

import { tabs, MESSAGES, sharedStatus } from '../../../constant';
import '../../../assets/css/colleague-list.css';
import toaster from '../../../services/shared/toaster.service';
import { getUserId, getFirstName } from '../../../helpers/sessionManager';
import { TargetDetailActivity, Collaboration, TargetDetailTabs } from '../../../components';
import {
  shareTarget,
  revokeSharedTarget,
  deleteShareRequest,
  hideCollaborationDialog,
  showCollaborationDialog,
} from '../../../actions';
import TargetNotes from '../../../containers/targets/TargetDetail/TargetNotes.container.js';

/**
 * conatiner for context tabs in detail page
 */
class Context extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: tabs[0],
    };
    // this is causing issues in the embedded pipeline dashboard view
    // this.scrollRef = React.createRef();
  }

  changeTab = (activeTab) => {
    this.setState({ activeTab });
    // this is causing issues in the embedded pipeline dashboard view
    // !isIE() && setTimeout(() => { this.scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) }, 100);
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.selectUser.user &&
      this.props.selectUser.tileId === this.props.detail.id &&
      prevProps.selectUser.user !== this.props.selectUser.user
    ) {
      this.shareTarget(this.props.selectUser.user);
      this.props.hideCollaborationDialog();
    }
  }
  /**
   * @description use to share the target with other users
   * @param {object} shareTo
   */
  shareTarget = (shareTo) => {
    const owner = this.props.detail.user_id === shareTo.id;
    shareTo.from_id = getUserId();
    shareTo.from_first_name = getFirstName();
    shareTo.from_last_name = getFirstName();
    const alreadyExit = this.props.detail.collaborators.findIndex(
      (item) =>
        item.user_id === shareTo.id &&
        (item.response === sharedStatus.accepted || item.response === sharedStatus.pending),
    );
    if (alreadyExit === -1 && !owner) {
      const detail = this.props.detail;
      detail.revenue = '';
      this.props.shareTarget(detail, shareTo, this.props.uid);
    } else {
      let msg = MESSAGES.alreadyShared;
      msg = msg.replace('username', shareTo.fullname);
      toaster.error(msg);
    }
  };

  revoke = (collaborator) => {
    this.props.revokeSharing(collaborator, this.props.detail, this.props.uid);
    if (collaborator.user_id === getUserId() && !this.props.uid) {
      this.props.afterDelete();
    }
  };
  // this is causing issues in the embedded pipeline dashboard view
  /*
    scrollToBottom = () => {
        !isIE() && setTimeout(() => { this.scrollRef.current && this.scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) }, 100);
    };
    */
  targetTabs = () => {
    switch (this.state.activeTab) {
      case tabs[0]:
        return (
          <TargetNotes
            tiles={this.props.detail.id}
            uid={this.props.detail.owner?.user_id}
            notes={this.props.detail?.notes}
          />
        );
      case tabs[1]:
        return <TargetDetailActivity tasks={this.props.detail.tasks} />;
      case tabs[2]:
        return (
          <Collaboration
            shareTarget={this.shareTarget}
            users={this.props.users}
            collaborators={this.props.detail.collaborators}
            overlaps={this.props.detail.overlaps}
            revoke={this.revoke}
            showSharing={this.props.showSharing}
            showRevoke={!this.props.shared}
            currentuserid={getUserId()}
            showCollaborationDialog={() => this.props.showCollaborationDialog(this.props.detail.id)}
            targetSubscription={this.props.targetSubscription}
            isPeopleTarget={this.props.isPeopleTarget}
            uid={this.props.uid}
          />
        );
      default:
        break;
    }
  };
  // render function to render the JSX
  render() {
    const TargetTabs = this.targetTabs;
    return (
      <>
        <TargetDetailTabs
          changeTab={this.changeTab}
          activeTab={this.state.activeTab}
          isTemp={this.props.detail.isTemp}
        />
        <div className="block context">
          <div className="context-container">
            <TargetTabs />
          </div>
        </div>
      </>
    );
  }
}

// map dispatch to props
const mapDispatchToProps = (dispatch) => ({
  shareTarget: (payload, id, uid) => dispatch(shareTarget(payload, id, uid)),
  revokeSharing: (collaborator, tile, uid) => dispatch(revokeSharedTarget(collaborator, tile, uid)),
  deleteShareRequest: (collaborator, tileId) => dispatch(deleteShareRequest(collaborator, tileId)),
  showCollaborationDialog: (tileId) => dispatch(showCollaborationDialog(tileId)),
  hideCollaborationDialog: () => dispatch(hideCollaborationDialog()),
});

// map state to props
const mapStateToProps = (state) => ({
  targetSubscription:
    state.session.user && state.session.user.Subscriptions ? state.session.user.Subscriptions.targets : {},
  selectUser: state.selectUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(Context);
