import {
  GET_PEOPLE_TARGETS,
  EDIT_PEOPLE_TARGETS,
  RESET_PEOPLE_TARGETS,
  COMPLETE_PEOPLE_TARGETS,
  DELETE_PEOPLE_TARGETS,
  WORK_ON_PEOPLE_TARGETS,
  CREATE_PEOPLE_TARGETS,
  CREATE_PEOPLE_TASK,
  SHARE_PEOPLE_TARGET_REQUEST,
  REVOKE_PEOPLE_SHARE_TARGET_REQUEST,
  LOADER_PEOPLE_REQUEST,
} from '../../constant';
import { makePayloadForCreation, makePayloadForStatusChange } from '../../services/targets/localTargetUpdate.service';

const initialState = {
  targets: [],
  loader: false,
};

const updateState = (state, payload) => {
  if (payload) {
    return state.targets.map((item) => {
      if (item.id === payload.id) {
        return {
          ...payload,
        };
      }
      return item;
    });
  }
  return state.targets;
};

const people = (state = initialState, action) => {
  switch (action.type) {
    case GET_PEOPLE_TARGETS:
      return {
        ...state,
        targets: action.payload,
      };
    case CREATE_PEOPLE_TARGETS: {
      const createdTarget = makePayloadForCreation(action.payload);
      return {
        ...state,
        targets: [...state.targets, { ...createdTarget }],
      };
    }
    case EDIT_PEOPLE_TARGETS:
      return {
        targets: updateState(state, action.payload),
      };
    case DELETE_PEOPLE_TARGETS: // move to hold
    case COMPLETE_PEOPLE_TARGETS: // move to completed
    case WORK_ON_PEOPLE_TARGETS: {
      const target = makePayloadForStatusChange(action.payload, true);
      const index = state.targets.findIndex((item) => item.id === target.id);
      const targets = state.targets;
      targets.splice(index, 1);
      return {
        targets: [...targets],
      };
    }
    case CREATE_PEOPLE_TASK: {
      const tile = state.targets.find((item) => item.id === action.payload.tile_id);
      return {
        targets: updateState(state, tile),
      };
    }
    case RESET_PEOPLE_TARGETS:
      return {
        ...state,
        targets: [],
      };
    case SHARE_PEOPLE_TARGET_REQUEST:
      return {
        ...state,
        targets: updateState(state, action.payload),
      };
    case REVOKE_PEOPLE_SHARE_TARGET_REQUEST: {
      const tileId = action.payload.object_id;
      const target = state.targets.find((i) => i.id == tileId);
      target.collaborators.forEach((item) => {
        if (item.share_id === action.payload.id) {
          item.response = 'revoked';
        }
      });
      return {
        ...state,
        targets: updateState(state, target),
      };
    }
    case LOADER_PEOPLE_REQUEST:
      return {
        ...state,
        loader: action.loader,
      };
    default:
      return state;
  }
};

export default people;
