import React from 'react';
import PropTypes from 'prop-types';

import icons from '../../../assets/img/icons.svg';
import TileLoader from '../../shared/TileLoader';

/**
 * component to show no reports available
 * @param {*} props
 */
const NoReports = ({ loading, style }) => {
  if (loading) return <TileLoader />;
  return (
    <div className="no-results-found" style={style}>
      <p>
        <span>
          <svg className="icon">
            <use xlinkHref={`${icons}#reports`} />
          </svg>
        </span>
        <span>There is currently no data for this report.</span>
      </p>
    </div>
  );
};

NoReports.defaultProps = {
  style: {},
};

NoReports.propTypes = {
  loading: PropTypes.bool,
  style: PropTypes.object,
};

export default NoReports;
