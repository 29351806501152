import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setSwitchedUser, switchUser } from '../../actions';
import ContentLoader from 'react-content-loader';
import { getToken, getUserData, getUserId } from '../../helpers';
import { ROUTES_PATH } from '../../constant';
import history from '../../helpers/history';

class SwitchUser extends Component {
  state = {
    anotherUser: false,
  };

  componentDidMount() {
    const { location } = this.props;
    const userId = atob(decodeURIComponent(location.search.split('=')[1]));
    const token = getToken();
    const user = getUserData().user;
    if (userId && token) {
      if (user.Switch) {
        this.setState({ anotherUser: true });
      } else {
        if (userId === getUserId()) {
          history.replace(ROUTES_PATH.DASHBOARD);
        } else {
          this.props.setSwitchedUser(true);
          this.props.switchuser(userId);
        }
      }
    } else {
      if (token) {
        history.replace(ROUTES_PATH.DASHBOARD);
      } else {
        localStorage.switchUser = userId;
        history.replace(ROUTES_PATH.LOGIN);
      }
    }
  }

  render() {
    return (
      <section className="nav-page" style={{ background: 'none', color: 'black' }}>
        {this.state.anotherUser ? (
          <div>Could not switch user</div>
        ) : (
          <ContentLoader style={{ fill: 'blue' }}>
            <text x="150" y="70" fontFamily="Verdana" fill="blue">
              Switching User
            </text>
          </ContentLoader>
        )}
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  switchuser: (userId) => dispatch(switchUser(userId)),
  setSwitchedUser: (switchedUser) => dispatch(setSwitchedUser(switchedUser)),
});

export default connect(null, mapDispatchToProps)(SwitchUser);
