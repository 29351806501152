import React from 'react';
import { Field, reduxForm } from 'redux-form';

import '../../assets/css/dialog.css';
import '../../assets/css/forms.css';
import renderFieldNormal from '../shared/RenderFieldNormal';
import { required } from '../../helpers';
import { Loader } from '../shared/TileLoader';

let ResetPasswordForm = ({ handleSubmit, onSubmit, submitting, onReturnKeyPress }) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {submitting && <Loader />}
      <fieldset className="login-user forgot-password">
        <Field
          component={renderFieldNormal}
          onReturnKeyPress={onReturnKeyPress}
          type="text"
          placeholder="Email"
          name="email"
          label="Email"
          validate={[required]}
          autoFocus={true}
        />
      </fieldset>
      <button type="submit" className="button-primary" disabled={submitting}>
        GET NEW PASSWORD
      </button>
    </form>
  );
};

ResetPasswordForm = reduxForm({
  // a unique name for the form
  form: 'ResetPasswordForm',
})(ResetPasswordForm);

export default ResetPasswordForm;
