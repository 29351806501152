/* eslint-disable react/no-unknown-property */
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useImportScript } from '../../../hooks/useImportScript';
import { gTag } from '../../../helpers';

/**
 * component for video on slider lesson view
 */
const WistiaPlayer = (props) => {
  const scriptSource = 'https://fast.wistia.com/assets/external/E-v1.js';
  // import wistia script
  useImportScript(scriptSource);

  let interval;
  const {
    location,
    match: { params },
  } = props;

  const { hashedId, updateLessonWatchedTime } = { ...props };

  let userId = location.pathname.split('/')[2];
  window._wq = window._wq || [];
  window._wq.push({
    id: hashedId,
    options: { controlsVisibleOnLoad: { playBar: true } },
    onHasData: (v) => {
      v.unbind('play').bind('play', () => {
        // stop all other videos playing
        if (window.Wistia) {
          var allVideos = window.Wistia.api.all();
          for (var i = 0; i < allVideos.length; i++) {
            if (allVideos[i].hashedId() !== v.iframe.id) {
              allVideos[i].pause();
            }
          }
        }

        let video = window.Wistia && window.Wistia.api(v.iframe.id);
        if (updateLessonWatchedTime) {
          // save watched time on 5 sec interval
          clearInterval(interval);
          interval = setInterval(() => {
            if (video.state() === 'playing') {
              const watchedTime = video.time();
              updateLessonWatchedTime(userId, watchedTime);
            }
          }, 5e3);
        }

        video.unbind('pause').bind('pause', () => {
          gTag('Lesson Video Status', `${video.state()}: ${params.id}`);
        });

        // clear interval on end event
        video.unbind('end').bind('end', () => {
          gTag('Lesson Video Status', `${video.state()}: ${params.id}`);
          const watchedTime = video.time();
          updateLessonWatchedTime(userId, watchedTime);
          clearInterval(interval);
        });
        gTag('Lesson Video Status', `${video.state()}: ${params.id}`);
      });
    },
  });

  useEffect(() => {
    return () => {
      clearInterval(interval);
    };
  });

  return (
    <div
      className="wistia_responsive_padding"
      style={{ padding: '56.25% 0 0 0', position: 'relative', backgroundColor: 'white' }}
    >
      <div
        className="wistia_responsive_wrapper"
        style={{
          height: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          width: '100%',
        }}
      >
        <iframe
          id={`${props.hashedId}`}
          src={`https://fast.wistia.com/embed/iframe/${props.hashedId}`}
          allowtransparency="true"
          frameBorder={0}
          scrolling="no"
          className="wistia_embed"
          name="wistia_embed"
          allowFullScreen
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
          oallowfullscreen="true"
          msallowfullscreen="true"
          title={props.hashedId}
          time={props.time} // attempt to set the start point to where the user left off
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};

export default withRouter(WistiaPlayer);
