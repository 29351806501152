import { HIDE_FILTER_MODAL, SHOW_FILTER_MODAL, FILTER_DATA, SORT_DATA, CLEAR_FILTER, SEARCH } from '../../constant';
import { modalOpen } from '../../helpers/utils';

const initialState = {
  filterModalProps: {
    open: false,
    filterBy: { filterBy: 'all', value: 'all' },
    sortBy: { type: 'due', isAscending: false },
    keyword: '',
  },
};

const filterTarget = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_FILTER_MODAL:
    case HIDE_FILTER_MODAL:
      modalOpen(action.filterModalProps.open);
      return Object.assign({}, state, {
        filterModalProps: {
          open: action.filterModalProps.open,
          filterBy: state.filterModalProps.filterBy,
          sortBy: state.filterModalProps.sortBy,
          keyword: state.filterModalProps.keyword,
        },
      });
    case FILTER_DATA:
      modalOpen(false);
      return {
        filterModalProps: {
          open: false,
          filterBy: action.filterModalProps.filterBy,
          sortBy: state.sortBy,
          keyword: state.filterModalProps.keyword,
        },
      };
    case SEARCH:
      modalOpen(state.filterModalProps.open);
      return {
        filterModalProps: {
          open: state.filterModalProps.open,
          filterBy: state.filterModalProps.filterBy,
          sortBy: state.sortBy,
          keyword: action.keyword,
        },
      };
    case CLEAR_FILTER:
      return initialState;
    case SORT_DATA:
      modalOpen(false);
      return {
        filterModalProps: {
          open: false,
          sortBy: action.sortBy,
          filterBy: state.filterModalProps.filterBy,
          keyword: action.keyword,
        },
      };
    default:
      return state;
  }
};

export default filterTarget;
