import { getAccountInfo } from '../../services/account/accountUpdate.service';
import { getUserData, store } from '../../helpers';
import dowloadLogo from '../shared/logo.action';
import { getToken } from '../../helpers/sessionManager';

/**
 * action to send data to login service and handle response
 * @param {object} data  {username, password}
 */
export const accountInfo = () => (dispatch) => {
  let switchedUser = store.getState().session.switchedUser;
  if (getToken() && !switchedUser) {
    return getAccountInfo().then((response) => {
      const session = getUserData();
      const Switch = session.user && session.user.Switch;
      session.user = response;
      if (Switch) {
        session.user.Switch = Switch;
      }
      dispatch({
        type: 'SAVE_LOGIN_DATA',
        payload: {
          ...session,
        },
      });
      const logo = session.user.Org.logo;
      if (logo) {
        dispatch(dowloadLogo(logo));
      }
    });
  }
};
