import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { NavSecondaryTargetDetail, LessonSwiper } from '../../../components';
import {
  getLessonDetailsData,
  updateLessonWatchedTime,
  saveWorksheetMatrix,
  getTrainerData,
} from '../../../actions/trainer/trainer.action';
import { setAttention } from '../../../actions';
import 'swiper/dist/css/swiper.min.css';
import '../../../assets/css/swiper-overrides.css';
import '../../../assets/css/card.css';
import '../../../assets/css/page-targets.css';
import '../../../assets/css/page-trainer.css';
import { MESSAGES, ROUTES_PATH } from '../../../constant';
import history from '../../../helpers/history';
import toaster from '../../../services/shared/toaster.service';

class LessonDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: '',
      inProp: false,
    };
  }

  setInitialSlide = () => {
    const {
      lessons,
      match: { params },
    } = this.props;
    const initialSlide = lessons.findIndex((item) => item.id === params.id);
    this.setState({ initialSlide });

    if (initialSlide === -1) {
      history.push(ROUTES_PATH.TRAINER + '/lessons');
      toaster.error(MESSAGES.NO_LESSON_FOUND);
    }
  };

  componentDidMount() {
    const {
      lessons,
      match: { params },
      getTrainerData,
    } = this.props;
    if (lessons.length) {
      this.setInitialSlide();
    } else {
      getTrainerData('lessons');
    }
    // add a class to the body so we can turn off scrolling
    document.body.classList.add('swiper-on');
    this.props.getLessonDetails(params.id);
    this.setState({ inProp: true });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lessons !== this.props.lessons) {
      this.setInitialSlide();
    }
  }

  componentWillUnmount() {
    // add a class to the body so we can turn off scrolling
    document.body.classList.remove('swiper-on');
  }

  render() {
    const { lessons, updateLessonWatchedTime, saveWorksheetMatrix, setAttention } = this.props;
    return (
      <>
        <CSSTransition classNames="fade" in={this.state.inProp} timeout={300}>
          <section className="nav-page active" id="tiles">
            {this.state.initialSlide > -1 && (
              <LessonSwiper
                lessons={lessons}
                history={this.props.history}
                setAttention={setAttention}
                updateLessonWatchedTime={updateLessonWatchedTime}
                initialSlide={this.state.initialSlide}
                saveWorksheetMatrix={saveWorksheetMatrix}
              />
            )}
          </section>
        </CSSTransition>
        <NavSecondaryTargetDetail />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  details: state.lessons.details,
  lessons: state.lessons.data,
});

const mapDispatchToProps = (dispatch) => ({
  setAttention: (id) => dispatch(setAttention({ id })),
  getTrainerData: (status) => dispatch(getTrainerData(status)),
  getLessonDetails: (lessonId) => dispatch(getLessonDetailsData(lessonId)),
  updateLessonWatchedTime: (lessonId, time) => dispatch(updateLessonWatchedTime(lessonId, time)),
  saveWorksheetMatrix: (lessonId, matrix) => dispatch(saveWorksheetMatrix(lessonId, matrix)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LessonDetail);
