import React, { useMemo } from 'react';
import { useStore } from 'react-redux';

import icons from '../../../assets/img/icons.svg';
import { yes, sharedStatus } from '../../../constant';
import { formatMoney } from '../../../helpers';

/**
 * @description Pipeline / Active Tiles Targets page functionality
 * @param {string} $valueterm - low value/medium value/high value
 * @param {object} $type - {other, targets, trainer}
 * @param {string} $typeterm - type of the target - client/prospect/referral source
 * @param {array} $collaborators - list of collaborators of the specific target
 * @param {array} $overlaps
 * @param {boolean} $detail - true/false
 */
const Tags = ({
  valueterm,
  type,
  typeterm,
  collaborators,
  overlaps = [],
  detail,
  revenue,
  revenue_formatted,
  projected_revenue,
}) => {
  const store = useStore();
  const state = store.getState();
  const formattedProjectedRevenue = useMemo(() => formatMoney(projected_revenue), [projected_revenue]);

  const collaboratorCount = collaborators
    ? collaborators.filter(
        (collaborator) =>
          collaborator.response === sharedStatus.pending || collaborator.response === sharedStatus.accepted,
      ).length
    : 0;
  const {
    features: { sharing },
  } =
    state.session.user && state.session.user.Subscriptions
      ? state.session.user.Subscriptions.targets
      : { features: { sharing: { on: 'no' } } };

  return (
    <div className="tags">
      {sharing.on === yes && collaboratorCount > 1 ? (
        <span className="icon">
          <svg className="icon">
            <use xlinkHref={`${icons}#shared`}></use>
          </svg>{' '}
          {collaboratorCount}
        </span>
      ) : (
        ''
      )}
      {overlaps.length > 0 ? (
        <span className="icon">
          <svg className="icon">
            <use xlinkHref={`${icons}#overlapped`}></use>
          </svg>{' '}
          {overlaps.length}
        </span>
      ) : (
        ''
      )}
      {detail && (
        <>
          <span className="tag">
            {valueterm && valueterm.includes(' Value') ? valueterm.replace(' Value', '') : valueterm}
          </span>
          <span className="tag">{type === 'refsource' ? 'Ref Src' : typeterm}</span>
        </>
      )}
      {revenue > 0 ? <span className="tag won-revenue">{revenue_formatted}</span> : ''}
      {projected_revenue > 0 ? <span className="tag projected-revenue">{formattedProjectedRevenue}</span> : ''}
    </div>
  );
};

export default Tags;
