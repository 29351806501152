import { CLIENTS_TABLE_REQUEST, WORKSHEETS_TABLE_FAILURE, WORKSHEETS_TABLE_SUCCESS } from '../../constant';

const initialState = {
  data: [],
  loading: true,
};

const worksheetsTable = (state = initialState, action) => {
  switch (action.type) {
    case CLIENTS_TABLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case WORKSHEETS_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case WORKSHEETS_TABLE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default worksheetsTable;
