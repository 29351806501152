import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useImportScript } from '../../../hooks/useImportScript';
import { WISTIA_PROJECT_API, VIDEO_ACTION_TYPE } from '../../../services/shared/wistia.service';
import { getFirmContents, removeFirmContents, updateFirmContents, showMoreToggle } from '../../../actions';
import { VideoUploaderPopUp } from '../../../components/trainer/listing/firm-content/VideoUploaderPopup.component';
import { VideoWrapper } from '../../../components/trainer/listing/firm-content/VideoWrapper.component';

const FirmContentContainer = (props) => {
  const {
    firmContent,
    user,
    removeFirmContents,
    getFirmContents,
    getAttention,
    setAttention,
    attention,
    updateFirmContents,
    showMore,
    showMoreToggle,
  } = props;
  const medias = firmContent?.data?.medias || [];
  const isAddLesson = user?.Settings['key-org_report'];

  // import wistia script
  useImportScript(WISTIA_PROJECT_API);

  const [isUploaderOpen, setIsUploaderOpen] = useState(false);
  const [input, setInput] = useState({
    description: '',
    title: '',
  });
  const [videoAction, setVideoAction] = useState('');

  const uploaderModal = useRef(null);
  const mediaRef = useRef(null);

  const { description, title } = input;

  const openUploaderDialog = () => {
    setIsUploaderOpen(true);
    setVideoAction(VIDEO_ACTION_TYPE.create);
    setInput({
      description: '',
      title: '',
    });
  };

  const videoEditAction = () => {
    setIsUploaderOpen(true);
    setVideoAction(VIDEO_ACTION_TYPE.edit);
  };

  const closeUploaderDialog = () => {
    setIsUploaderOpen(!isUploaderOpen);
  };

  const onChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    getFirmContents();
  }, [getFirmContents]);

  return (
    <div className="user-content lesson-container" id="user-content">
      <header className="intro">
        <p>View content uploaded by your firm.</p>
      </header>
      {isAddLesson === 1 && (
        <div className="upload">
          <button className="uploader-btn" onClick={openUploaderDialog}>
            {' '}
            Add Lesson
          </button>
        </div>
      )}
      <div className="medias" ref={mediaRef}>
        {medias.map((item) => (
          <VideoWrapper
            key={item.id}
            id={item.id}
            title={item.name}
            description={item.description}
            hashedId={item.hashed_id}
            removeFirmContents={removeFirmContents}
            getFirmContents={getFirmContents}
            medias={medias}
            getAttention={getAttention}
            setAttention={setAttention}
            videoEditAction={videoEditAction}
            isAddLesson={isAddLesson}
            showMoreToggle={showMoreToggle}
            showMore={showMore}
            setInput={setInput}
            attention={attention}
          />
        ))}
      </div>
      {isUploaderOpen && (
        <VideoUploaderPopUp
          id={attention.id}
          title={title}
          description={description}
          closeUploaderDialog={closeUploaderDialog}
          isUploaderOpen={isUploaderOpen}
          onChange={onChange}
          uploaderModal={uploaderModal}
          getFirmContents={getFirmContents}
          setInput={setInput}
          mediaRef={mediaRef}
          videoAction={videoAction}
          updateFirmContents={updateFirmContents}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  firmContent: state.firmContent,
  attention: state.attention,
  showMore: state.showMore,
});

const mapDispatchToProps = (dispatch) => ({
  getFirmContents: () => dispatch(getFirmContents()),
  removeFirmContents: (id) => dispatch(removeFirmContents(id)),
  showMoreToggle: (isReadMore) => dispatch(showMoreToggle(isReadMore)),
  updateFirmContents: (id, title, description) => dispatch(updateFirmContents(id, title, description)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FirmContentContainer);
