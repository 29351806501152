import React, { useEffect, useState } from 'react';
import { ReactComponent as UrlIcon } from '../../../assets/img/url.svg';
import './styles.css';

const Input = ({
  value,
  name,
  errors,
  label,
  handleChange,
  handleOnBlur,
  clearError,
  errorMessage,
  placeholder,
  url,
}) => {
  const [showLabel, setShowLabel] = useState(true);

  const handleOnBur = (e) => {
    clearError(e.target.value === '' || e.target.value.length > 60);
    setShowLabel(value ? false : true);
    handleOnBlur && handleOnBlur();
  };

  useEffect(() => {
    setShowLabel(value ? false : true);
  }, [value]);

  return (
    <>
      <div className="field">
        {!!url && (
          <span className="url-icon">
            <UrlIcon />
          </span>
        )}
        <input
          id={name}
          className={`default-input ${errors[name] && 'input-error'} ${url && 'url-input'}`}
          name={name}
          value={value}
          onChange={(e) => handleChange(e.target.value, name)}
          onBlur={handleOnBur}
          onFocus={() => setShowLabel(true)}
          placeholder={placeholder}
          autoComplete="off"
        />
        {!!showLabel && (
          <label htmlFor={name} className={`${errors[name] && 'label-error'}`}>
            {label}
          </label>
        )}
        {!!errors[name] && <span className="error-message">{errorMessage}</span>}
      </div>
    </>
  );
};

export default Input;
