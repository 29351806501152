import React, { useState, useMemo } from 'react';
import { Field, Form, Formik } from 'formik';
import icons from '../../../assets/img/icons.svg';
import { timeFormater } from '../../../helpers/timeformater';
import './styles.css';

const RenderFieldNormal = ({ field, form: { touched, errors }, ...props }) => {
  const textareaRef = React.useRef(null);

  const { active, type, disabled, onBlur, onFocus } = props;
  const hasValue = field.value && field.value.trim() && !active;
  return (
    <div
      id="target-note"
      className={`field-line ${props.customclass}
            ${props.active ? 'focused' : ''} ${hasValue ? 'has-value' : ''}
            ${errors[field.name] && touched[field.name] ? 'error ' : ''} target-notes-container`}
    >
      <label>{hasValue ? props.label : props.placeholderfloat}</label>
      <textarea
        {...field}
        className="target-notes-textarea"
        maxLength={1000}
        type={type}
        disabled={disabled}
        ref={textareaRef}
        onBlur={onBlur}
        onFocus={onFocus}
        autoComplete="off"
        rows={3}
      />
    </div>
  );
};

const TargetNoteItem = (props) => {
  const {
    targetNote,
    tiles,
    uid: _uid,
    trackTargetNote,
    setIsLoading,
    isLoading,
    targetNoteTracker,
    addTargetNote,
    editTargetNote,
  } = props;

  const [focused, focusEl] = useState({});
  let submitForm;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const taskNote = useMemo(() => targetNote[0]?.note || '', [targetNote, targetNoteTracker]);

  return (
    <section className="your-notes">
      <div>
        <Formik
          enableReinitialize="true"
          initialValues={{ taskNote }}
          onSubmit={(values) => {
            if (props?.targetNote[0]) {
              setIsLoading(true);
              editTargetNote(props?.targetNote[0].id, values.taskNote);
            } else {
              setIsLoading(true);
              values.taskNote && addTargetNote(tiles, values.taskNote);
            }
          }}
        >
          {(formProps) => (
            <Form
              onKeyDown={(e) => {
                if (e.target.name === 'taskNote') {
                  e.stopPropagation();
                  window.cordova && window.Keyboard && window.Keyboard.hide();
                }
              }}
              onBlur={() => {
                setIsLoading(true);
                submitForm = setTimeout(() => {
                  formProps.handleSubmit();
                  trackTargetNote();
                }, 1000);
              }}
              onFocus={clearTimeout(submitForm)}
              onKeyUp={submitForm && clearTimeout(submitForm)}
            >
              <fieldset className="target-notes">
                <h4>
                  <span className="icon">
                    <svg className="icon">
                      <use xlinkHref={`${icons}#notes`} />
                    </svg>
                  </span>{' '}
                  Your Note
                </h4>
                <p>A few things to remember the next time you reconnect.</p>
                <br />
                <span className="collaborator-notes">
                  {targetNote && (
                    <>
                      <Field
                        component={RenderFieldNormal}
                        type="text"
                        id="field-notes"
                        maxLength="300"
                        name="taskNote"
                        onFocus={() => {
                          focusEl({ taskNote: true });
                        }}
                        onBlur={() => focusEl({ taskNote: false })}
                        active={!!focused.taskNote}
                      />
                    </>
                  )}
                  {targetNote[0] && (
                    <div className="save-time" style={{ marginLeft: 10 }}>
                      {isLoading ? (
                        <span className="icon">
                          <svg className="icon">
                            <use xlinkHref={`${icons}#clock`} />
                          </svg>
                        </span>
                      ) : (
                        `Last saved ${timeFormater(targetNote[0]?.modified) || timeFormater(targetNote[0]?.created)}`
                      )}
                    </div>
                  )}
                </span>
              </fieldset>
              <button className="save-note-button">Save</button>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default TargetNoteItem;
