import React, { useCallback, useEffect, useState } from 'react';
import icons from '../../assets/img/icons.svg';
import { NoReports, TileLoader } from '../../components';
import DigestTable from '../../components/viewer/mgmt/DigestTable';
import CreateModal from '../../components/common/createModal';
import { CREATE_MODALS } from '../../constant/insights';
import { useDispatch, useSelector } from 'react-redux';
import { getDigestData, removeDigest } from '../../actions';

const DigestOverview = () => {
  const [loading, setLoading] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [digestTable, setDigestTable] = useState([]);
  const [digestToDelete, setDigestToDelete] = useState('');
  const [modalOpened, setModalOpened] = useState('');
  const [distributionData, setDistributionData] = useState('');
  const tableData = useSelector((state) => state.insightDashboard.digestsSent);
  const dispatch = useDispatch();

  const updateSearchedKeyword = () => {
    if (searchKeyword.trim() !== '') {
      const filteredOptions = tableData.filter(({ title }) =>
        title.toLowerCase().trim().includes(searchKeyword.toLowerCase().trim()),
      );
      setDigestTable(filteredOptions);
    } else {
      setDigestTable(tableData);
    }
  };

  const clearSearchFilter = () => {
    setSearchKeyword('');
    setDigestTable(tableData);
  };

  const handleDeleteConfirmation = (digest) => {
    setModalOpened(CREATE_MODALS.DELETE);
    setDigestToDelete(digest);
  };

  const handleDistributionModal = (distributionData) => {
    setModalOpened(CREATE_MODALS.DISTRIBUTION);
    setDistributionData(distributionData);
  };

  const handleDelete = async () => {
    try {
      await dispatch(removeDigest(digestToDelete.id));
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetData = useCallback(async () => {
    await dispatch(getDigestData());
    setLoading(false);
  }, []);

  useEffect(() => {
    if (searchKeyword) {
      updateSearchedKeyword();
    } else {
      setDigestTable(tableData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword, tableData]);

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="panel table targets">
        {!digestTable.length && loading ? (
          <TileLoader />
        ) : (
          <>
            <header className="filter">
              <div className="table-filter">
                <input
                  placeholder="Filter by keyword"
                  className="clearable kwrd-srch"
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                    updateSearchedKeyword();
                  }}
                  value={searchKeyword}
                />
                <svg className="icon">
                  <use xlinkHref={`${icons}#action-search`} />
                </svg>
                <div className="clear-table-filter" onClick={clearSearchFilter}>
                  <svg className="icon">
                    <use xlinkHref={`${icons}#action-close`} />
                  </svg>
                </div>
              </div>
            </header>
            {digestTable.length ? (
              <div className="scroller horz">
                <DigestTable
                  tableData={digestTable}
                  headRows={digestTable}
                  handleDelete={handleDeleteConfirmation}
                  openDistributionModal={handleDistributionModal}
                />
              </div>
            ) : (
              <NoReports />
            )}
          </>
        )}
      </section>
      {(modalOpened === CREATE_MODALS.DELETE || modalOpened === CREATE_MODALS.DISTRIBUTION) && (
        <CreateModal
          deleteAction={handleDelete}
          deleteItem={digestToDelete.name}
          newElementModalOpen={modalOpened}
          setNewElementModalOpen={setModalOpened}
          distributionData={distributionData}
        />
      )}
    </>
  );
};

export default DigestOverview;
