import React from 'react';

import { SearchBtn } from '../shared/SearchBtn';
import icons from '../../assets/img/icons.svg';
import '../../assets/css/nav-secondary.css';
import { API_PARAMS } from '../../constant';

/**
 * @description This component render the secondary tabs of target - Pursuing, On Hold, Complete
 * @param {function} $openFilter - open the popup for the filter, search or sorting the targets
 * @param {string} $status - status of the target - active, archived or completed
 * @param {function} $getTargetByStatus - get the targerts by the status
 * @param {boolean} $isPeopleTarget - true/false
 * @param {function} $openPipelineControls - open the control popup for updating the revenue or downloading the activity report
 */
const ChildNav = ({ isPeopleTarget, openFilter, status, getTargetByStatus, openPipelineControls }) => {
  return (
    <div>
      <nav id="nav-tiles" className={`${isPeopleTarget ? 'controls-inline' : 'app'} nav-secondary`}>
        <ul>
          <SearchBtn openFilter={openFilter} />
          <li className="status sub-tabs">
            <ul className="button-bar ui status has-value">
              <li
                className={status === API_PARAMS.ACTIVE ? 'active' : ''}
                onClick={() => getTargetByStatus(API_PARAMS.ACTIVE)}
              >
                <a>Pursuing</a>
              </li>
              <li
                onClick={() => getTargetByStatus(API_PARAMS.COMPLETED)}
                className={status === API_PARAMS.COMPLETED ? 'active' : ''}
              >
                <a>Won</a>
              </li>
              <li
                onClick={() => getTargetByStatus(API_PARAMS.LOST)}
                className={status === API_PARAMS.LOST ? 'active' : ''}
              >
                <a>Lost</a>
              </li>
              <li
                onClick={() => getTargetByStatus(API_PARAMS.ARCHIVED)}
                className={status === API_PARAMS.ARCHIVED ? 'active' : ''}
              >
                <a>On Hold</a>
              </li>
            </ul>
          </li>
          <li className="btn sort" onClick={openPipelineControls}>
            <a href={undefined}>
              <svg className="icon nav-icon">
                <use xlinkHref={`${icons}#settings`} />
              </svg>
              <span className="label">Controls</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

const NavSecondaryTargets = (props) =>
  props.isPeopleTarget ? (
    <ChildNav {...props} />
  ) : (
    <div id="secondary-container" className="show nav-tiles ">
      {/* Secondary Navigation - Targets */}
      <ChildNav {...props} />
    </div>
  );

export default NavSecondaryTargets;
