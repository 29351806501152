import { uniqBy } from 'lodash';
import {
  VIEWER_DATA_REQUEST,
  VIEWER_DATA_RECEIVED,
  LOAD_VIEWER,
  UPDATE_REPORT_OF,
  RESET_REPORT_OF,
  LOAD_HIGHLIGHTS_DATA,
  UPDATE_REPORT_WHICH,
} from '../../constant';

const initialState = {
  groups: [],
  reports: {},
  org: {
    members: [],
  },
  graphOf: {},
  highlights: {},
  loading: false,
  allMembers: [],
};

const viewer = (state = initialState, action) => {
  switch (action.type) {
    case VIEWER_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VIEWER_DATA_RECEIVED:
      return {
        ...state,
        loading: false,
      };
    case LOAD_VIEWER: {
      const allMembers = [];
      action.payload?.groups?.forEach(({ members }) => allMembers.push(...members));
      const uniqueMembers = uniqBy(allMembers, 'uid');
      const initialRecipients = uniqueMembers.map((member) => ({
        id: member.uid,
        firstName: member['first_name'],
        lastName: member['last_name'],
        email: member['email'],
        checked: true,
      }));
      return {
        ...state,
        ...action.payload,
        allMembers: initialRecipients,
      };
    }
    case UPDATE_REPORT_OF: {
      state.graphOf = action.payload;
      return { ...state };
    }
    case RESET_REPORT_OF: {
      return initialState;
    }
    case LOAD_HIGHLIGHTS_DATA: {
      return {
        ...state,
        highlights: action.payload,
      };
    }
    case UPDATE_REPORT_WHICH: {
      return {
        ...state,
        which: action.payload,
      };
    }
    default:
      return state;
  }
};

export default viewer;
