import React, { useState, useEffect } from 'react';
import ReactIdSwiper from 'react-id-swiper/lib/ReactIdSwiper.custom';

import { swiperParams, API_PARAMS } from '../../../constant';
import InsightSlide from './InsightSlide.component';
import { clickOutSideSwiper } from '../../../helpers';
import history from '../../../helpers/history';

const InsightSwiper = (props) => {
  // Swiper instance
  const [swiper, updateSwiper] = useState(null);
  const [virtual, updateSlide] = useState({ slides: [] });
  // Params definition
  const params = {
    ...swiperParams,
    getSwiper: updateSwiper, // Get swiper instance callback
    initialSlide: props.initialSlide, // don't want it to scroll to slide when it first opens
    virtual: {
      slides: [...props.insights],
      renderExternal(data) {
        // assign virtual slides data
        updateSlide(data);
      },
    },
  };
  const markAsViewed = (index) => {
    if (props.status !== API_PARAMS.VIEWED) {
      props.markAsViewed(props.insights[index]);
    }
  };
  // Add eventlisteners for swiper after initializing
  useEffect(() => {
    if (swiper !== null) {
      swiper.on('slideChange', () => {
        let insightsId = props.insights[swiper.realIndex];
        history.push(insightsId.id);
        markAsViewed(swiper.realIndex);
      });
      swiper.on('click', (e) => {
        clickOutSideSwiper(e.target);
      });
      markAsViewed(swiper.realIndex);
    }
    return () => {
      if (swiper !== null) {
        swiper.off('slideChange', () => {
          markAsViewed(swiper.realIndex);
        });
        swiper.off('click', (e) => {
          clickOutSideSwiper(e.target);
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swiper, props.initialSlide]);

  return (
    <ReactIdSwiper {...params}>
      {virtual.slides.map((slide, key) => (
        <InsightSlide key={key} offset={virtual.offset} detail={slide} />
      ))}
    </ReactIdSwiper>
  );
};

export default InsightSwiper;
