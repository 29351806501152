import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { removeAttention } from '../../actions';
import '../../assets/css/dashboard.css';
import '../../assets/css/page-targets.css';

let dashboardHOC = (Child) => {
  // eslint-disable-next-line react/display-name
  return class extends Component {
    getAttention = ({ list, attention }) => {
      const i = list?.findIndex((item) => {
        return item.id === attention.id;
      });
      if (i > -1) {
        setTimeout(() => {
          const tileObj = document.getElementById(list[i].id);
          if (tileObj) {
            tileObj.scrollIntoView({ behavior: 'smooth', block: 'end' });
            tileObj.children[0].classList.add('attention');
            this.props.removeAttention();
          }
        }, 500);
      } else {
        if (!attention.from) {
          this.props.removeAttention();
        }
      }
    };
    render() {
      return <Child {...this.props} getAttention={this.getAttention} />;
    }
  };
};

const mapDispatchToProps = (dispatch) => ({
  removeAttention: () => dispatch(removeAttention()),
});

dashboardHOC = compose(connect(null, mapDispatchToProps), dashboardHOC);

export default dashboardHOC;
