import { SHOW_ACTIONSHEET, HIDE_ACTIONSHEET } from '../../constant';
const initialState = {
  actionSheetType: 'taskdue',
  actionSheetProps: {},
};

const actionSheet = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ACTIONSHEET:
      return {
        actionSheetType: action.actionSheetType,
        actionSheetProps: action.actionSheetProps,
      };
    case HIDE_ACTIONSHEET:
      return initialState;
    default:
      return state;
  }
};

export default actionSheet;
