/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { Layout } from './containers';
import routes from './routes';
import { ROUTES_PATH, ROUTES_TYPE } from './constant';
import UpdateAvailable from './components/shared/UpdateAvailable/UpdateAvailable';
import { Userpilot } from 'userpilot';
import { validateUser, updateLastLocation, checkVersion } from './helpers/sessionManager';
import history from './helpers/history';
import { getRedirectUrl, gTagPageView } from './helpers';

import packageJson from '../package.json';
global.appVersion = packageJson.version;

// Initialize Userpilot
Userpilot.initialize(process.env.REACT_APP_USERPILOT_INITIALIZE);

// public routes anyone can access
const PublicRoute = ({ component: Component, type: _, isRestricted, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !(validateUser() && isRestricted) ? <Component {...props} /> : <Redirect to={getRedirectUrl(validateUser())} />
      }
    />
  );
};

// loggedin user can access only
const PrivateRoute = ({ component: Component, type: _, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (validateUser() ? <Component {...props} /> : <Redirect to={ROUTES_PATH.LOGIN} />)}
    />
  );
};
function App() {
  const [isVersionUpdate, setIsVersionUpdate] = useState(false);
  // remember last location and check the version
  history.listen((location) => {
    gTagPageView('location', location.pathname);
    updateLastLocation(location);
    // checkVersion().then(res => {
    //   setIsVersionUpdate(res)
    // });
  });
  const { handleRedirectCallback } = useAuth0();

  useEffect(() => {
    // cordova callback after sso
    window.handleOpenURL = async (url) => {
      if (url.includes('state') && (url.includes('code') || url.includes('error'))) {
        await handleRedirectCallback(url);
      }
      window?.SafariViewController?.hide();
      history.replace(ROUTES_PATH.LOGIN);
    };
  }, [handleRedirectCallback]);

  const validatedUser = validateUser();
  useEffect(() => {
    if (validateUser().code === 200) {
      const identifyOrgReport = 'key-org_report';
      const userInfo = validateUser();
      Userpilot.identify(userInfo.user.id, {
        userId: userInfo.user.id,
        name: userInfo.user.name,
        first_name: userInfo.user.first_name,
        email: userInfo.user.email,
        created_at: userInfo.user.registered,
        role: userInfo.user.Settings[identifyOrgReport],
        company: {
          name: userInfo.user.Org.name,
          id: userInfo.user.Org.id,
          category_name: userInfo.user.Org.category_name,
        },
      });
    }
  }, [validatedUser]);

  const pageLocation = useLocation();
  useEffect(() => {
    if (validateUser().code === 200) {
      Userpilot.reload();
    }
  }, [pageLocation]);

  useEffect(() => {
    checkVersion().then((res) => {
      setIsVersionUpdate(res);
    });
  }, []);

  return (
    <Layout>
      <UpdateAvailable isVersionUpdate={isVersionUpdate} />
      <Route
        render={({ location }) => {
          return (
            <Switch location={location}>
              {routes.map((route, i) => {
                if (route.isProtected)
                  return <PrivateRoute key={i} {...route} path={route.path} type={ROUTES_TYPE.TARGET} />;
                else
                  return (
                    <PublicRoute
                      key={i}
                      {...route}
                      path={route.path}
                      type={ROUTES_TYPE.TARGET}
                      isRestricted={route.isRestricted}
                    />
                  );
              })}
              <Redirect exact from={'/'} to={validateUser() ? getRedirectUrl(validateUser()) : ROUTES_PATH.LOGIN} />
              <Redirect to={validateUser() ? getRedirectUrl(validateUser()) : ROUTES_PATH.LOGIN} />
            </Switch>
          );
        }}
      />
    </Layout>
  );
}

export default App;
