import React from 'react';

import icons from '../../../assets/img/icons.svg';

/**
 * @description display the Firm name
 * @param {object} $firmHeaderTitle - id, key, type and name of the firm
 */
const FirmReportHeader = ({ firmHeaderTitle }) => (
  <dl className="handle org">
    <dt className="avatar">
      {/* this needs to be the icon for firm (org-org) or group (org-group) */}
      <svg>
        <use xlinkHref={firmHeaderTitle.type === 'org' ? `${icons}#org-org` : `${icons}#org-group`} />
      </svg>
    </dt>
    <dd className="name">{firmHeaderTitle.name || 'Firm Report'}</dd>
  </dl>
);
export default FirmReportHeader;
