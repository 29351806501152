import React, { useEffect } from 'react';
import { useRef } from 'react';
import { useTextAreaAdjust } from '../../../../hooks/useTextAreaAdjust';
import '../../../../assets/css/wistia.css';

export const UploaderForm = ({
  id,
  title,
  description,
  onChange,
  handleChangePage,
  updateFirmContents,
  videoAction,
  getFirmContents,
  hideDialog,
  setInput,
}) => {
  const MIN_TEXTAREA_HEIGHT = 80;
  const textareaRef = useRef(null);
  const inputRef = useRef(null);
  const maxLength = 110;

  const isUploadable = !title?.length;

  // auto adjust textarea size
  useTextAreaAdjust(MIN_TEXTAREA_HEIGHT, textareaRef, description);

  const handleUpdateVideo = async (e) => {
    e.preventDefault();
    await updateFirmContents(id, title, description);
    getFirmContents();
    hideDialog();
    setInput({
      title: '',
      description: '',
    });
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <div className="uploader-form">
      <p className="lesson-header">{videoAction === 'CREATE' ? 'Create a New Lesson' : 'Edit Lesson'}</p>
      <hr className="hr-title" />
      <form>
        <span className="label">
          <label>
            Lesson Title <span>*</span>
          </label>
        </span>
        <input
          maxLength={100}
          value={title}
          type="text"
          autoCapitalize="on"
          placeholder="Enter a title"
          name="title"
          onChange={onChange}
          ref={inputRef}
        />
        <br />
        <span className="label">
          <label>Lesson Description</label>
          <label className="characters">{`${description?.length} / ${maxLength} characters`}</label>
        </span>
        <textarea
          ref={textareaRef}
          maxLength={maxLength}
          value={description}
          type="text"
          autoComplete="off"
          autoCapitalize="on"
          placeholder="Enter a brief description"
          name="description"
          onChange={onChange}
          style={{
            minHeight: `${MIN_TEXTAREA_HEIGHT}px`,
            resize: 'none',
          }}
          className="description"
        />

        <span className="cancel">
          {videoAction === 'CREATE' ? (
            <button
              className={`uploader-btn ${isUploadable && 'disabled'}`}
              disabled={isUploadable}
              onClick={() => handleChangePage(1)}
            >
              Next
            </button>
          ) : (
            <button
              className={`uploader-btn ${isUploadable && 'disabled'}`}
              disabled={isUploadable}
              onClick={handleUpdateVideo}
            >
              Save
            </button>
          )}
        </span>
      </form>
    </div>
  );
};
