import React, { useRef, useState } from 'react';
import { UploaderForm } from './UploaderForm.component';
import { VideoUploader } from './Uploader.component';
import { useHideDialog } from '../../../../hooks/useHideDialog';

export const VideoUploaderPopUp = ({
  id,
  title,
  description,
  closeUploaderDialog,
  isUploaderOpen,
  onChange,
  setInput,
  getFirmContents,
  mediaRef,
  videoAction,
  updateFirmContents,
}) => {
  const [page, setPage] = useState(0);
  const node = useRef(null);

  const hideDialog = () => {
    document.removeEventListener('click', handleOutsideClick, true);
    closeUploaderDialog();
  };

  // close filter dialog if click outside of dialog
  const handleOutsideClick = (e) => {
    if (!node?.current?.contains(e.target)) {
      hideDialog();
    }
  };

  const handleChangePage = (index) => setPage(index);

  useHideDialog(isUploaderOpen, handleOutsideClick);

  const uploadVideoPages = (index) => {
    switch (index) {
      case 0:
        return (
          <UploaderForm
            id={id}
            title={title}
            description={description}
            handleChangePage={handleChangePage}
            onChange={onChange}
            videoAction={videoAction}
            updateFirmContents={updateFirmContents}
            getFirmContents={getFirmContents}
            hideDialog={hideDialog}
            setInput={setInput}
          />
        );
      case 1:
        return (
          <VideoUploader
            title={title}
            description={description}
            hideDialog={hideDialog}
            isUploaderOpen={isUploaderOpen}
            onChange={onChange}
            handleChangePage={handleChangePage}
            setInput={setInput}
            getFirmContents={getFirmContents}
            mediaRef={mediaRef}
          />
        );
      default:
        break;
    }
  };

  return (
    <div className={`dialog ${isUploaderOpen ? 'active' : ''} filter-dialog`} id="content">
      <div className="box">
        <div className="firm-content" ref={node}>
          {uploadVideoPages(page)}
        </div>
      </div>
    </div>
  );
};
