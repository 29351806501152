export const timeFormater = (date) => {
  let time = Math.round((new Date() - new Date(date)) / 1000);

  const defaultTimeZone = new Date(new Date().toLocaleString('en', { timeZone: 'America/Los_Angeles' }));
  const hoursDifference = Math.abs(new Date() - defaultTimeZone) / 36e5;
  // subtracting 7 hrs, sets the note creation time to default UTC
  time = time - Math.floor(hoursDifference) * 60 * 60;

  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const month = day * 30;
  const year = month * 12 + day * 5;

  if (time < 30) {
    return 'just now';
  } else if (time < minute) {
    return time + ' seconds ago';
  } else if (time < 2 * minute) {
    return 'a minute ago';
  } else if (time < hour) {
    return Math.floor(time / minute) + ' minutes ago';
  } else if (Math.floor(time / hour) === 1) {
    return '1 hour ago';
  } else if (time < day) {
    return Math.floor(time / hour) + ' hours ago';
  } else if (time < day * 2) {
    return 'yesterday';
  } else if (time < month) {
    return Math.floor(time / day) + ' days ago';
  } else if (time >= month) {
    return Math.floor(time / month) + ` month${Math.floor(time / month) > 1 ? 's' : ''} ago`;
  } else if (time >= year) {
    return Math.floor(time / year) + ` year${Math.floor(time / year) > 1 ? 's' : ''} ago`;
  } else {
    return '';
  }
};

export const convertToHMS = (time) => {
  let HH;
  const mins = 60;
  const hrs = mins * 60;

  let H = Math.floor(time / hrs);
  let M = Math.floor((time % hrs) / 60);
  let S = Math.floor(time % mins);

  if (H < 10) {
    HH = '0' + H;
  }
  if (M < 10) {
    M = '0' + M;
  }
  if (S < 10) {
    S = '0' + S;
  }

  return `${H > 0 ? `${HH}:` : ''}${M}:${S}`;
};
