/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProgressData } from '../../../../actions';
import { NoReports, ProgressTable } from '../../../../components';
import { API_URLS, API_PARAMS, API_METHODS } from '../../../../constant';
import { getUserData, invoke } from '../../../../helpers';

const Progress = () => {
  const [filteredData, setFilteredData] = useState([]);
  const dispatch = useDispatch();
  const ORG_ID = useSelector((state) => state.session.user.Org.id);
  const { data, loading } = useSelector((state) => state.progressTable);
  const userData = getUserData();

  const getUsersMemberships = (status = API_PARAMS.ACTIVE) => {
    return invoke({ url: API_URLS.ORG + userData.user.Org.id + '/groups', type: API_METHODS.GET, params: { status } });
  };
  const getUsersData = (status = API_PARAMS.ACTIVE) => {
    return invoke({
      url: API_URLS.ORG + userData.user.Org.id + '/report/progress',
      type: API_METHODS.GET,
      params: { status },
    });
  };

  useEffect(() => {
    const url = `${API_URLS.ORG}${ORG_ID}${API_URLS.REPORT}${API_URLS.PROGRESS}`;
    dispatch(getProgressData(url));
    getUsersData().then((result) => {
      getUsersMemberships().then((response) => {
        const map = {};
        let j = 1;
        for (let i = 0; i < response.length; i++) {
          if (response[i].member_access.split(',').includes(userData.user.id) && !map[response[i].name]) {
            map[response[i].name] = j++;
          }
        }
        const filterThis = result.filter((element) => map[element.group]);
        setFilteredData(filterThis);
      });
    });
  }, [ORG_ID]);
  return (
    <>
      <section className="panel table progress">
        {data.length ? <ProgressTable progressData={filteredData} /> : <NoReports loading={loading} />}
      </section>
    </>
  );
};

export default Progress;
