import React, { useCallback, useEffect, useState } from 'react';
import { accessToken } from '../../../../services/shared/wistia.service';
import { getFirmProjectId } from '../../../../helpers';
import { useImportScript } from '../../../../hooks/useImportScript';
import { WISTIA_PROJECT_API } from '../../../../services/shared/wistia.service';
import '../../../../assets/css/wistia.css';

export const VideoUploader = ({
  title,
  description,
  hideDialog,
  handleChangePage,
  setInput,
  getFirmContents,
  mediaRef,
}) => {
  useImportScript(WISTIA_PROJECT_API);
  let timer;

  // eslint-disable-next-line no-unused-vars
  const [fileName, setFileName] = useState('');

  const cancelUploader = () => {
    return window?.wistiaUploader.cancel();
  };

  const handleVideoDetails = useCallback(async () => {
    window.wistiaUploader.setFileName(title);
    window.wistiaUploader.setFileDescription(description);
  }, [title, description]);

  const uploadSuccess = () => {
    return window?.wistiaUploader.bind('uploadsuccess', () => {
      getFirmContents();
      mediaRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setInput({
        title: '',
        description: '',
      });
      timer = setTimeout(() => {
        hideDialog();
      }, 2000);
    });
  };

  const wistiaDropZone = () => {
    try {
      window._wapiq = window._wapiq || [];
      window._wapiq.push((W) => {
        window.wistiaUploader = new W.Uploader({
          accessToken,
          dropIn: 'wistia_uploader',
          projectId: getFirmProjectId(),
          beforeUpload: async function () {
            const videoUpload = window.confirm(
              `Are you sure you want to upload ${window.wistiaUploader?._currentFile.name} video?`,
            );

            if (videoUpload) {
              setFileName(window.wistiaUploader?._currentFile.name);
              await handleVideoDetails();
            } else {
              cancelUploader();
            }
          },
        });
        uploadSuccess();
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    wistiaDropZone();

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div id="wistia_uploader" className="wistia-uploader"></div>
      <span className="label">
        <button className="uploader-btn" onClick={() => handleChangePage(0)}>
          Back
        </button>
        <button className="uploader-btn" onClick={hideDialog}>
          Cancel
        </button>
      </span>
    </>
  );
};
