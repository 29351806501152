import React from 'react';
import { Datepicker } from '@mobiscroll/react';
import './styles.css';
import moment from 'moment';
import { MESSAGES } from '../../../constant';

const DatePicker = ({ handleChange, name, error }) => {
  const today = new Date();
  const yesterday = new Date(today.setDate(today.getDate() - 1));

  return (
    <>
      <Datepicker
        onClose={(e) => handleChange(e.value, name)}
        inputStyle="box"
        cssClass="testwrapper"
        error={error}
        errorMessage={MESSAGES.MANDATORY}
        placeholder="Select a Date and Time"
        themeVariant="light"
        dateFormat="MM / DD / YYYY"
        timeFormat="hh:mm A"
        firstDay={1}
        controls={['datetime']}
        selectMultiple={false}
        invalid={[
          {
            start: '100-01-01',
            end: yesterday,
          },
          {
            start: `${moment().format('YYYY-MM-DD')}T00:00`,
            end: `${moment().format('YYYY-MM-DD')}T${today.getHours()}:${today.getMinutes() + 4}`,
          },
        ]}
      />
    </>
  );
};

export default DatePicker;
