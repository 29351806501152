import {
  GET_PEOPLE_TARGETS,
  RESET_PEOPLE_TARGETS,
  EDIT_PEOPLE_TARGETS,
  DELETE_PEOPLE_TARGETS,
  COMPLETE_PEOPLE_TARGETS,
  WORK_ON_PEOPLE_TARGETS,
  API_PARAMS,
  CREATE_PEOPLE_TARGETS,
  ROUTES_PATH,
  CREATE_PEOPLE_TASK,
  sharedStatus,
  UPDATE_YTD_REVENUE_REQUEST,
  RESET_PEOPLE_HUD,
  SHARE_PEOPLE_TARGET_REQUEST,
  REVOKE_PEOPLE_SHARE_TARGET_REQUEST,
  LOADER_PEOPLE_REQUEST,
} from '../../constant';
import {
  getPeopleTargetsAPI,
  editPeopleTargetsAPI,
  changePeopleTargetStatusAPI,
  createPeopleTaskAPI,
  createPeopleTargetsAPI,
  submitPeopleRevenue,
  rejectPeopleSharedTargetAPI,
  sharePeopleTargetAPI,
  revokePeopleTargetAPI,
  acceptPeopleSharedTargetAPI,
} from '../../services/viewer/people.service';
import { hideLoader } from '../shared/loader.action';
import history from '../../helpers/history';
import { addTargetNoteData } from '../targets/dashboard.action';
export const loaderPeople = (loader) => (dispatch) => {
  dispatch({
    type: LOADER_PEOPLE_REQUEST,
    loader,
  });
};

/**
 * action creator for fetching the people specific tiles
 * @param {object} apiParams
 * @param {string} uid
 */
export const getPeopleTargets = (status, range, userId) => (dispatch) => {
  return getPeopleTargetsAPI(status, range, userId).then((response) => {
    // filter targets data based on user Id.
    const data = response.filter(
      (item) => !(item.shared === sharedStatus.rejected || item.shared === sharedStatus.revoked),
    );

    dispatch(hideLoader());
    dispatch({
      type: GET_PEOPLE_TARGETS,
      payload: data,
    });
    return data;
  });
};

/**
 * create people specific targets
 * @param {object} payload
 * @param {string} userId
 */
export const createPeopleTarget = async ({ payload, userId, range, dispatch }) => {
  const response = await createPeopleTargetsAPI(payload, userId);
  if (payload.note) dispatch(addTargetNoteData({ targetId: response.id, note: payload.note }));
  dispatch({
    type: CREATE_PEOPLE_TARGETS,
    payload: response,
  });
  dispatch(getPeopleTargets(API_PARAMS.ACTIVE, range, userId));
};

/**
 * action creator for editing people targets
 * @param {object} data
 * @param {string} userId
 */
export const editPeopleTarget = (data, userId) => (dispatch) => {
  editPeopleTargetsAPI(data.id, data, userId).then((response) => {
    // go back to people targets after edit
    history.goBack();
    dispatch({
      type: EDIT_PEOPLE_TARGETS,
      payload: { ...response },
    });
  });
};

/**
 * dispatch action according to action performed on people target
 * @param {string} action
 * @param {object} payload
 * @param {string} userId
 */
const statusUpdateAction = (action, payload, userId) => (dispatch) => {
  const pathname = `${ROUTES_PATH.VIEWER}${ROUTES_PATH.PIPELINE}${ROUTES_PATH.PEOPLE}/${userId}`;
  switch (action) {
    case 'archive':
      history.replace({ pathname, search: `status=${API_PARAMS.ARCHIVED}` });
      dispatch({
        type: DELETE_PEOPLE_TARGETS,
        payload,
      });
      break;
    case 'complete':
      history.replace({ pathname, search: `status=${API_PARAMS.COMPLETED}` });
      dispatch({
        type: COMPLETE_PEOPLE_TARGETS,
        payload,
      });
      break;
    case 'work':
      history.replace({ pathname, search: `status=${API_PARAMS.ACTIVE}` });
      dispatch({
        type: WORK_ON_PEOPLE_TARGETS,
        payload,
      });
      break;
    default:
      break;
  }
};

/**
 * action creator to change people target status
 * @param {object} data
 * @param {string} action
 * @param {string} userId
 */
export const changePeopleTargetStatus = (data, action, userId) => (dispatch) => {
  changePeopleTargetStatusAPI(data.id, data, action, userId).then((response) =>
    dispatch(statusUpdateAction(action, response || data, userId)),
  );
};

/**
 * action creator to create task for people targets
 * @param {object} data
 * @param {string} userId
 */
export const createPeopleTask = (data, userId) => (dispatch) => {
  createPeopleTaskAPI(data, userId).then((response) => {
    history.goBack();
    dispatch({
      type: CREATE_PEOPLE_TASK,
      payload: response,
    });
  });
};

/**
 * action creator to reset people tiles on change of people selection change
 */
export const resetPeopleTargets = () => (dispatch) => {
  dispatch({ type: RESET_PEOPLE_TARGETS });
  dispatch({ type: RESET_PEOPLE_HUD });
};

export const submitPeopleYTDRevenue = (revenue, userId) => (dispatch) => {
  submitPeopleRevenue(revenue, userId).then((res) => {
    dispatch({
      type: UPDATE_YTD_REVENUE_REQUEST,
      payload: { ...res, userId },
    });
  });
};
export const rejectPeopleSharedTarget = (shareObj, userId) => (dispatch) => {
  dispatch(loaderPeople(true));
  rejectPeopleSharedTargetAPI(shareObj, userId).then(() => {
    history.goBack();
    loaderPeople(false);
  });
};
export const sharePeopleTarget = (payload, shareWith, uid) => (dispatch) => {
  dispatch(loaderPeople(true));
  sharePeopleTargetAPI(payload, shareWith, uid).then((res) => {
    dispatch(loaderPeople(false));
    dispatch({
      type: SHARE_PEOPLE_TARGET_REQUEST,
      payload: res,
    });
  });
};
export const revokePeopleTarget = (payload, shareWith, uid) => (dispatch) => {
  dispatch(loaderPeople(true));
  revokePeopleTargetAPI(payload, shareWith, uid).then((res) => {
    dispatch(loaderPeople(false));
    if (payload.user_id === uid) {
      history.goBack();
    } else {
      dispatch({
        type: REVOKE_PEOPLE_SHARE_TARGET_REQUEST,
        payload: res,
      });
    }
  });
};

export const acceptPeopleSharedTarget = (payload, uid) => (dispatch) => {
  dispatch(loaderPeople(true));
  acceptPeopleSharedTargetAPI(payload, uid).then(() => {
    history.goBack();
    dispatch(loaderPeople(false));
  });
};
