import React, { useState } from 'react';
import '../styles.css';
import Input from '../../input';
import { useDispatch } from 'react-redux';
import { addNewTag } from '../../../../actions';

const NewCategory = ({ handleClose }) => {
  const [inputValue, setInputValue] = useState('');
  const [errors, setErrors] = useState({ new: false });
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    setErrors({ new: false });
    setErrorMessage('');
  };

  const handleClearErrors = (value) => {
    setErrors({ new: value });
    setErrorMessage('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await dispatch(addNewTag(inputValue));
      handleClose();
    } catch (error) {
      setErrors({ new: true });
      setErrorMessage(error?.message);
    }
    setLoading(false);
  };

  return (
    <form className="creation-form" onSubmit={handleSubmit}>
      <Input
        value={inputValue}
        name="new"
        errors={errors}
        handleChange={handleInputChange}
        clearError={handleClearErrors}
        errorMessage={errorMessage || 'Tag Name is required'}
        label="Tag Name"
      />
      <button type="submit" disabled={inputValue.trim() === ''}>
        {loading ? <span className="loader" /> : 'Create Tag'}
      </button>
    </form>
  );
};

export default NewCategory;
