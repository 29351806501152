import React, { useState, useEffect } from 'react';
import ReactIdSwiper from 'react-id-swiper/lib/ReactIdSwiper.custom';

import { swiperParams } from '../../../constant';
import LessonSlide from './LessonSlide.component';
import { clickOutSideSwiper } from '../../../helpers';

const LessonSwiper = (props) => {
  // Swiper instance
  const [swiper, updateSwiper] = useState(null);
  const [virtual, updateSlide] = useState({ slides: [] });

  // Params definition
  const params = {
    ...swiperParams,
    getSwiper: updateSwiper, // Get swiper instance callback
    initialSlide: props.initialSlide, // don't want it to scroll to slide when it first opens
    virtual: {
      slides: [...props.lessons],
      renderExternal(data) {
        // assign virtual slides data
        updateSlide(data);
      },
    },
  };

  // Add eventlisteners for swiper after initializing
  useEffect(() => {
    if (swiper !== null) {
      swiper.on('activeIndexChange', () => {
        let videoId = props.lessons[swiper.realIndex];
        props.setAttention(videoId.id);
        props.history.push(videoId.id);
      });
      swiper.on('click', (e) => {
        clickOutSideSwiper(e.target);
      });
    }
    return () => {
      if (swiper !== null) {
        swiper.off('click', (e) => {
          clickOutSideSwiper(e.target);
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swiper, props.initialSlide]);

  return (
    <ReactIdSwiper {...params}>
      {virtual.slides.map((slide, key) => (
        <LessonSlide key={key} offset={virtual.offset} detail={slide} {...props} />
      ))}
    </ReactIdSwiper>
  );
};

export default LessonSwiper;
