import React from 'react';
import { toastr } from 'react-redux-toastr';

import { calculateDays } from '../../../helpers';
import { MESSAGES, rescindinvite, rescind, withdraw } from '../../../constant';

export const CollaborationItem = (props) => {
  const { collaborator, showRevoke, currentuserid } = props;
  // define the button text
  let btn = '',
    role = 'collaborator';
  switch (true) {
    // if the current user created the target (owner)
    case collaborator.share_id === 0:
      role = 'owner';
      break;

    // if this share is the current user
    case collaborator.user_id === currentuserid:
      btn = withdraw;
      break;
    // if not the current user, and the response is pending
    case collaborator.response === 'pending':
      btn = rescindinvite;
      break;
    // otherwise, always rescind
    default:
      btn = rescind;
  }

  const buttonText = btn;
  const revokeConfirm = () => {
    const msg =
      buttonText === withdraw
        ? MESSAGES.TARGETWITHDRAW
        : MESSAGES.TARGETRESCIND.replace('user', `${collaborator.first_name} ${collaborator.last_name}`);
    toastr.confirm(msg, {
      okText: 'No, cancel',
      cancelText: `Yes, ${buttonText}`,
      onCancel: () => props.revoke(collaborator),
    });
  };

  return (
    <li className={collaborator.response}>
      <div className={`avatar ${collaborator.response}`}>
        <b>
          {collaborator.first_name.charAt(0).toUpperCase()}
          {collaborator.last_name.charAt(0).toUpperCase()}
        </b>
      </div>
      {/* add "owner" if the user id is this current user*/}
      <div className={`name ${collaborator.response} ${role === 'owner' ? 'owner' : ''}`}>
        <span>
          {collaborator.first_name} {collaborator.last_name}
        </span>
      </div>
      <div className="controls">
        <span>
          {showRevoke && (collaborator.from_id === currentuserid || collaborator.user_id === currentuserid) && (
            <a onClick={() => revokeConfirm()}>{buttonText}</a>
          )}
          <time className={`${collaborator.response} ${role === 'owner' ? 'owner' : ''}`}>
            {calculateDays(collaborator.responded || collaborator.shared)}
          </time>
        </span>
      </div>
    </li>
  );
};
