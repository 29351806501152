import Resizer from 'react-image-file-resizer';

export const dataURItoBlob = (dataURI) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

export const getDefaultImage = (image, imageName) =>
  fetch(image)
    .then((res) => res.blob())
    .then((myBlob) => {
      return new File([myBlob], imageName, { type: myBlob.type });
    });

export const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      'WEBP',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64',
    );
  });
