import { invoke } from '../../helpers';
import { API_URLS, API_METHODS } from '../../constant';

/**
 * Function to call api to submit login api
 * @param {username, password} data
 */
export const login = (data) => {
  return invoke({ url: API_URLS.LOGIN, type: API_METHODS.POST }, data);
};

export const validateToken = () => {
  return invoke({ url: API_URLS.VALIDATETOKEN, type: API_METHODS.POST });
};
export const resetPasswordApi = (value) => {
  return invoke({ url: API_URLS.RESETPASSWORD, type: API_METHODS.POST, params: value }, {});
};
export const newPasswordApi = (value) => {
  return invoke({ url: API_URLS.NEWPASSWORD, type: API_METHODS.POST }, { ...value });
};

export const switchUserApi = (user_id) => {
  return invoke({ url: `${API_URLS.SWITCH}${user_id}`, type: API_METHODS.GET });
};
export const submitOTPApi = (data) => {
  return invoke({ url: API_URLS.USER_CHALLENGE, type: API_METHODS.POST }, data);
};
export const resendOTPApi = (data) => {
  return invoke({ url: `${API_URLS.USER_CHALLENGE}?action=resend_code`, type: API_METHODS.POST }, data);
};
export const loginSSO = (data) => {
  return invoke(
    {
      url: API_URLS.LOGIN_SSO,
      type: API_METHODS.POST,
      params: {
        SSO_KEY: process.env.REACT_APP_SSO_KEY,
      },
    },
    data,
  );
};
