import React, { Component } from 'react';
import { connect } from 'react-redux';

import { OTPForm } from '../../components';
import '../../assets/css/login.css';
import { submitOTP, showLoader, hideLoader, saveLoginData } from '../../actions';
import { resendOTPApi } from '../../services/user/user.service';
import { MESSAGES, ROUTES_PATH } from '../../constant';
import { Loader } from '../../components/shared/TileLoader';
import { getRedirectUrl, validateUser } from '../../helpers';
import history from '../../helpers/history';

// Create target container
class AuthByOtp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      reason: '',
      animation: 'active',
    };
  }

  componentDidMount() {
    const validuser = validateUser();
    if (!validuser) {
      const { params } = this.props.match;
      this.setState({ email: decodeURIComponent(params.email), reason: decodeURIComponent(params.reason) });
      this.removeAnimation();
    } else {
      history.push(getRedirectUrl(validuser));
    }
  }

  handleBlur = () => window.cordova && window.cordova.platformId === 'ios' && window.Keyboard.hide();

  submitOTP = (data) => {
    data.email = this.state.email;
    this.props.submitOTP(data).then(
      (_res) => {
        // do nothing on submit success response
      },
      (error) => {
        this.setMessage(error);
      },
    );
  };

  onReturnKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleBlur();
    }
  };

  resendCode = () => {
    this.props.showLoader();
    resendOTPApi({ email: this.state.email }).then(
      (res) => {
        this.setMessage(res.message);
        this.props.hideLoader();
      },
      (_error) => {
        this.setState({ reason: MESSAGES.OTP_RESEND_ERROR });
        this.props.hideLoader();
      },
    );
  };

  setMessage = (reason) => {
    this.setState({ reason, animation: 'active' }, this.removeAnimation);
  };

  removeAnimation = () => {
    this.timeout = setTimeout(() => {
      this.setState({ animation: '' });
    }, 5000);
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  // render function to render the JSX
  render() {
    return (
      <div className="login-wrapper keyboard">
        {this.props.loading && <Loader />}
        <div className="login-center-layout">
          <h1>
            <a></a>
          </h1>
          <p className={`login-para text-animation ${this.state.animation}`}>{this.state.reason}</p>
          <div className="bubble">
            {this.state.email && (
              <OTPForm
                onReturnKeyPress={this.onReturnKeyPress}
                handleBlur={this.handleBlur}
                onSubmit={this.submitOTP}
                initialValues={{ code: '' }}
              />
            )}
            <p>
              {' '}
              Didn’t receive a text?
              <a className="login-lost-pwd resend" onClick={this.resendCode}>
                {' '}
                Try sending it again.
              </a>
            </p>
          </div>
          <p>
            <a className="login-lost-pwd" onClick={() => history.replace(ROUTES_PATH.LOGIN)}>
              ← Try Login Again
            </a>
          </p>
        </div>
      </div>
    );
  }
}
// map dispatch to props
const mapDispatchToProps = (dispatch) => ({
  submitOTP: (data) => dispatch(submitOTP(data)),
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  saveLoginData: (data) => dispatch(saveLoginData(data)),
});
// map state to props
const mapStateToProps = (state) => ({
  loading: state.loader,
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthByOtp);
