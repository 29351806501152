import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'svgxuse/svgxuse.js';
import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactTooltip from 'react-tooltip';
import 'es6-promise/auto';
import 'isomorphic-fetch';
import 'whatwg-fetch';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { HashRouter, Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, persistor, keyboardShowHandler, keyboardHideHandler, initDeepLinks, addBrowserClass } from './helpers';
import { PersistGate } from 'redux-persist/integration/react';
import history from './helpers/history';
import { openDatabase } from './helpers/dbStorage';
import { Toastr, Auth0Provider } from './components';
import { notificationReceived, onRefresh, registerPushServiceWorker } from './services/shared/pushNotification.service';
import init from './services/shared/branch.service';
import { onTargetRefresh, accountInfo } from './actions';

const startApp = () => {
  // these are the environments our app has to support
  const ackert_prefixes = ['app', 'qa', 'staging'];

  // this is the domain name the app is running on
  const host_str = window.location.hostname;

  // grab the first part of the domain
  const host_prefix = host_str.split('.')[0];

  // local prefix can be anything, so assume it's not local if it has ".com" in it
  window.is_local = host_str.indexOf('.com') < 0;

  // app|qa|staging and local are "ackert"; all others just use the prefix
  window.app_instance = ackert_prefixes.includes(host_prefix) || window.is_local ? 'ackert' : host_prefix;

  // and/or store "ackert" or host_prefix in localstorage
  localStorage.host_prefix = host_prefix;
  const container = document.getElementById('root');
  const root = createRoot(container);

  root.render(
    <HashRouter>
      <Provider store={store}>
        <PersistGate loading={'Loading!'} persistor={persistor}>
          <Toastr />
          <Router history={history}>
            <Auth0Provider history={history}>
              <App />
            </Auth0Provider>
          </Router>
          <ReactTooltip
            id="tip"
            placement={'top'}
            multiline={true}
            getContent={(dataTip) => <span dangerouslySetInnerHTML={{ __html: dataTip }}></span>}
            scrollHide={true}
          />
        </PersistGate>
      </Provider>
    </HashRouter>,
  );
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  openDatabase();
  serviceWorker.register({
    onUpdate: (registration) => {
      const waitingServiceWorker = registration.waiting;
      if (waitingServiceWorker) {
        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
      }
    },
  });
  onRefresh();
  // refresh F5 press or reload or first time init
  window.refresh = 1;
};
const onResume = () => {
  store.dispatch(onTargetRefresh());
  initDeepLinks();
  store.dispatch(accountInfo());
};
const startAppCordovaInit = () => {
  if (window.Keyboard) {
    try {
      window.Keyboard.hideFormAccessoryBar(true);
    } catch (error) {
      console.log(error);
    }
  }
  startApp();
  document.addEventListener('resume', onResume, false);
  window.addEventListener('keyboardWillShow', (event) => keyboardShowHandler(event));
  window.addEventListener('keyboardWillHide', keyboardHideHandler);
  initDeepLinks();
  notificationReceived();
};
if (window.cordova) {
  document.addEventListener('deviceready', startAppCordovaInit, false);
} else {
  init();
  startApp();
  registerPushServiceWorker();
  addBrowserClass();
}
