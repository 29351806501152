import { LOAD_REQUEST_COUNT, REDUCE_REQUEST_COUNT } from '../../constant';
const requestCount = (state = 0, action) => {
  switch (action.type) {
    case LOAD_REQUEST_COUNT:
      return action.count;
    case REDUCE_REQUEST_COUNT: {
      let count = state;
      count = count - 1;
      return count;
    }
    default:
      return state;
  }
};

export default requestCount;
