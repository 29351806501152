import React from 'react';

import { tabs } from '../../../constant';

/**
 * @description component for tabs on slider target detail
 * @param {Object} props
 */
const TargetDetailTabs = (props) => {
  const { changeTab, isTemp } = props;

  const TargetTabs = ({ isTemp }) =>
    tabs.map((tab) => {
      if (tab.toLowerCase() === 'collaboration') {
        return (
          <li key={tab} className={props.activeTab === tab ? 'active' : ''} onClick={() => !isTemp && changeTab(tab)}>
            <span className={isTemp && 'disabled-ul'}>{tab}</span>
          </li>
        );
      }
      return (
        <li key={tab} className={props.activeTab === tab ? 'active' : ''} onClick={() => changeTab(tab)}>
          <span>{tab}</span>
        </li>
      );
    });

  return (
    <div className="block tabs">
      <div className="divider info">
        <legend>Details</legend>
      </div>
      <ul className="button-bar ui context">
        <TargetTabs isTemp={isTemp} />
      </ul>
    </div>
  );
};

export default TargetDetailTabs;
