import React from 'react';

import './Alert.css';

const Alert = ({ text, buttonText, onClick }) => {
  return (
    <div className="alert">
      {text} {buttonText && <button onClick={onClick}>{buttonText}</button>}
    </div>
  );
};

export default Alert;
