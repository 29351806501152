import React, { Component } from 'react';

import Alert from '../../alert/Alert';
import './UpdateAvailable.css';

// This component render the prompt if there is a new version available
class UpdateAvailable extends Component {
  refreshPage = () => {
    if (window.isElectron) {
      window.ipcRenderer.send('ping', {
        type: 'update',
      });
    } else {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name);
          }
        });
      }
      window.location.reload();
    }
  };

  render() {
    const { isVersionUpdate } = this.props;
    const web = !window.cordova;
    return (
      <div className="App">
        {isVersionUpdate && web && (
          <div className="App-alert">
            <Alert
              text="There is new version of this app available."
              buttonText="Update Now"
              onClick={this.refreshPage}
            />
          </div>
        )}
      </div>
    );
  }
}

export default UpdateAvailable;
