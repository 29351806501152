import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { ResetPasswordForm } from '../../components';
import '../../assets/css/login.css';
import { resetPassword } from '../../actions';
import { ROUTES_PATH } from '../../constant';

// Create target container
class ResetPassword extends Component {
  componentDidMount() {
    document.getElementsByClassName('login-wrapper')[0].onclick = () => this.handleBlur();
    document.getElementsByClassName('login-center-layout')[0].onclick = (event) => event.stopPropagation();
  }

  handleBlur = () => window.cordova && window.cordova.platformId === 'ios' && window.Keyboard.hide();
  onReturnKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleBlur();
    }
  };

  // render function to render the JSX
  render() {
    const url =
      window.app_instance !== 'ackert'
        ? process.env.REACT_APP_WEB_BASE_URL.split('//')[0] +
          '//' +
          localStorage.host_prefix +
          '.' +
          process.env.REACT_APP_WEB_BASE_URL.split('//')[1]
        : process.env.REACT_APP_WEB_BASE_URL;
    return (
      <div className="login-wrapper keyboard">
        <div className="login-center-layout">
          <h1>
            <a href={url} target="_blank" rel="noreferrer"></a>
          </h1>
          <p className="login-para">Forgot your password?</p>
          <div className="bubble">
            <p>Please enter your email address, and we’ll send you password reset instructions.</p>
            <ResetPasswordForm
              onReturnKeyPress={this.onReturnKeyPress}
              handleBlur={this.handleBlur}
              onSubmit={this.props.resetPassword}
            />
            <p>
              <b>If you don’t see your reset email,</b> be sure to check your spam filter for an email from
              PipelinePlus.com
            </p>
          </div>
          <p>
            <Link className="login-lost-pwd" to={ROUTES_PATH.LOGIN}>
              ← Try Login Again
            </Link>
          </p>
        </div>
      </div>
    );
  }
}

// map dispatch to props
const mapDispatchToProps = (dispatch) => ({
  resetPassword: (data) => dispatch(resetPassword(data)),
});

export default connect(null, mapDispatchToProps)(ResetPassword);
