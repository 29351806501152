import { HIDE_FILTER_MODAL, SHOW_FILTER_MODAL, FILTER_BLOGS, CLEAR_FILTER, BLOG_FILTER_KEYWORD } from '../../constant';
import { modalOpen } from '../../helpers/utils';

const initialState = {
  filterModalProps: {
    open: false,
    filterBy: { filterBy: 'all', value: 'all' },
    keyword: '',
  },
};

const blogsFilter = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_FILTER_MODAL:
    case HIDE_FILTER_MODAL:
      modalOpen(action.filterModalProps.open);
      return Object.assign({}, state, {
        filterModalProps: {
          open: action.filterModalProps.open,
          filterBy: state.filterModalProps.filterBy,
          keyword: state.filterModalProps.keyword,
        },
      });
    case FILTER_BLOGS:
      modalOpen(false);
      return {
        filterModalProps: {
          open: false,
          filterBy: action.filterModalProps.filterBy,
          keyword: state.filterModalProps.keyword,
        },
      };
    case BLOG_FILTER_KEYWORD:
      modalOpen(state.filterModalProps.open);
      return {
        filterModalProps: {
          open: state.filterModalProps.open,
          filterBy: state.filterModalProps.filterBy,
          keyword: action.keyword,
        },
      };
    case CLEAR_FILTER:
      return initialState;
    default:
      return state;
  }
};

export default blogsFilter;
