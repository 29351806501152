import { SHOW_PIPELINE_CONTROLS_MODAL, HIDE_PIPELINE_CONTROLS_MODAL } from '../../constant';

export const showPipelineControlsModal = () => (dispatch) => {
  dispatch({
    type: SHOW_PIPELINE_CONTROLS_MODAL,
    open: true,
  });
};

export const hidePipelineControlsModal = () => (dispatch) => {
  dispatch({
    type: HIDE_PIPELINE_CONTROLS_MODAL,
    open: false,
  });
};
