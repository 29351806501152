import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';

import { editTarget } from '../../../actions';
import { EditTargetForm } from '../../../components';
import { EDIT_CONFIRM, DueDateFormatToDisplay } from '../../../constant';

// Create target container
class EditTarget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editDetails: {
        company: '',
        contact: '',
        taskdue: '',
        goal: '',
        taskdescription: '',
        value: '',
        type: '',
        taskhelp: '',
        revenue: '',
        ['projected_revenue']: '',
      },
    };
    this.footer = React.createRef();
  }
  // will hold access to formikProps.submitForm, to trigger form submission outside of the form
  submitEditForm = null;
  setValueToField = null;

  setFormValues = () => {
    const { ...fields } = this.props.editDetails;
    const taskdue = moment(this.props.editDetails.taskdue).format(DueDateFormatToDisplay);
    let taskhelp = this.props.editDetails.taskhelp === null ? '' : this.props.editDetails.taskhelp;
    this.setState({ editDetails: { ...fields, taskdue, taskhelp } });
  };

  componentDidMount() {
    this.setFormValues();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.valueformActionSheet !== this.props.valueformActionSheet) {
      const { taskdue, goal, taskdescription } = this.props.valueformActionSheet;
      taskdue && this.setValue('taskdue', taskdue);
      goal && this.setValue('goal', goal);
      taskdescription && this.setValue('taskdescription', taskdescription);
    }
  }

  // Callback for when the form is submitted
  onSubmit = (values) => {
    const { isPeopleTarget, editTarget } = this.props;
    const details = { ...this.props.editDetails, ...values };
    if (isPeopleTarget) details.isPeopleTarget = isPeopleTarget;
    editTarget(details);
    this.closeEdit();
    // remove for testing - will remove permanent after testing
    // this.props.updateSlider();
  };

  closeEdit = () => {
    this.props.cancel();
    this.props.editConfirm(false);
  };

  setValue = (field, value) => {
    this.setValueToField(field, value);
    this.props.editConfirm(true);
  };

  bindSubmitForm = (submitForm) => {
    this.submitEditForm = submitForm;
  };

  bindSetValue = (setValue) => {
    this.setValueToField = setValue;
  };

  handleSubmitEditForm = (e) => {
    if (this.submitEditForm) {
      this.submitEditForm(e);
    }
  };
  // render function to render the JSX
  render() {
    const details = { ...this.state.editDetails };
    details.taskhelp = details.taskhelp ? details.taskhelp : '';
    return (
      <div className={`inner edit create`}>
        <EditTargetForm
          toggleActionSheet={this.props.toggleActionSheet}
          editDetails={{ ...details }}
          bindSubmitForm={this.bindSubmitForm}
          editTarget={this.onSubmit}
          bindSetValue={this.bindSetValue}
          editConfirm={(e) => this.props.editConfirm(e)}
        />
        <footer className="actions">
          <button className="cancel" onClick={this.closeEdit}>
            Cancel
          </button>
          <button className="save" onClick={this.handleSubmitEditForm}>
            Save Changes
          </button>
        </footer>
      </div>
    );
  }
}

// map dispatch to props
const mapDispatchToProps = (dispatch, ownProps) => ({
  // api call to create a target
  editTarget: (payload) => dispatch(editTarget(payload, ownProps.uid)),
  editConfirm: (confirm) => dispatch({ type: EDIT_CONFIRM, payload: confirm }),
});

export default connect(null, mapDispatchToProps)(EditTarget);
