import { daysAndWeekLabel, buckets, DueDateFormatForAPI, ROUTES_PATH, API_PARAMS, ROUTES_SUB_PATH } from '../constant';
import * as moment from 'moment';
import history from './history';
import { BLOG, PDF, VIDEO, frequencyOptions } from '../constant/insights';
import { getDefaultImage } from './imageParsing';
import defaultVideoImage from '../assets/img/default-video-image.webp';
import defaultBlogImage from '../assets/img/external_url_default.jpg';
import defaultPdfImage from '../assets/img/pdf_default.jpg';

export const calculateDays = (due, labelCheck = true) => {
  const today = moment().format('YYYY-MM-DD');
  const duration = moment(due).diff(today, 'days');
  if (daysAndWeekLabel[duration] && labelCheck) {
    return daysAndWeekLabel[duration];
  }
  return due ? moment.duration(duration, 'days').locale('en').humanize(true) : '';
};
export const timeHumanize = (due) => {
  return due ? moment(due * 1000).fromNow() : 'N/A';
};

export const calculateBucketDays = (due) => {
  const today = moment().format('YYYY-MM-DD') === due ? moment().endOf('day') : moment().startOf('day');
  return moment(due).diff(today, 'day');
};

export const calculateBucket = (days) => {
  if (days < 0) {
    return buckets.overdue;
  } else if (days > 7) {
    return buckets.later;
  } else {
    return buckets.soon;
  }
};

export const isWeb = () => {
  return !window.cordova;
};

export const modalOpen = (add) => {
  const overflow = add ? 'hidden' : '';
  add
    ? document.documentElement.classList.add('is-form-open')
    : document.documentElement.classList.remove('is-form-open');
  if (document.getElementById('tiles')) {
    document.getElementById('tiles').style.overflow = overflow;
  }
};

export const scrollInView = (currentRef) => {
  currentRef.scrollIntoView({ behavior: 'smooth' });
};
export const keyboardShowHandler = (e) => {
  let kH = e.keyboardHeight;
  const body = document.querySelector('body');
  body.classList.add('is-keyboard-open');
  let bodyMove = document.querySelector('.keyboard') || document.querySelector('.keyBoardInfo'),
    bodyMoveStyle = bodyMove ? bodyMove.style : null;
  if (bodyMoveStyle) {
    if (bodyMove.classList.contains('back')) {
      const x = document.getElementsByClassName('keyboard');
      let i;
      kH = kH - 70;
      for (i = 0; i < x.length; i++) {
        x[i].style.bottom = kH + 'px';
        x[i].scrollTop = bodyMove.scrollHeight + kH;
      }
    } else {
      bodyMoveStyle.paddingBottom = kH + 'px';
      bodyMove.scrollTop = kH;
    }
    setTimeout(function () {
      document.activeElement.scrollIntoViewIfNeeded();
    }, 100);
  }
};
export const keyboardHideHandler = () => {
  const body = document.querySelector('body');
  body.classList.remove('is-keyboard-open');
  const x = document.getElementsByClassName('keyboard');
  for (let i = 0; i < x.length; i++) {
    x[i] && x[i].removeAttribute('style');
  }
};
export const getClass = (code) => {
  if (code.indexOf('UP') > -1) {
    return 'up';
  } else if (code.indexOf('DOWN') > -1) {
    return 'down';
  }
  return '';
};
export const detectPrivateMode = () => {
  return 'MozAppearance' in document.documentElement.style;
};
export const isIE = () => {
  var ua = window.navigator.userAgent;

  // Test values; Uncomment to check result …

  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

  // Edge 12 (Spartan)
  // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

  // Edge 13
  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  // other browser
  return false;
};
export const isMobile = () => {
  return window.cordova || window.navigator.userAgent.indexOf('Mobile') > -1;
};
export const clickOutSideSwiper = (target, cb) => {
  const value = target.classList.value;
  if (value && (value.indexOf('card') > -1 || value.indexOf('swiper-slide') > -1)) {
    if (cb) {
      cb();
    } else {
      history.goBack();
    }
  }
};

export const convertDueDateToAPIFormat = (date) => {
  return moment(new Date(date)).format(DueDateFormatForAPI);
};

export const formatNumber = (n) => {
  const ranges = [
    { divider: 1e18, suffix: 'E' },
    { divider: 1e15, suffix: 'P' },
    { divider: 1e12, suffix: 'T' },
    { divider: 1e9, suffix: 'B' },
    { divider: 1e6, suffix: 'mm' },
    { divider: 1e3, suffix: 'k' },
  ];
  for (var i = 0; i < ranges.length; i++) {
    if (n >= ranges[i].divider) {
      // make sure there is a remainder
      var num = Math.round((n / ranges[i].divider) * 10) / 10;
      // and spit out one decimal place
      return num.toFixed(1) + ranges[i].suffix;
    }
  }
  return n.toString();
};
export const getRandomString = () => {
  var x = 2147483648;
  return Math.floor(Math.random() * x).toString(36) + Math.abs(Math.floor(Math.random() * x) ^ Date.now()).toString(36);
};
export const getNextWeekMondayDate = () => moment().startOf('isoWeek').add(1, 'week').format(DueDateFormatForAPI);
export const addBrowserClass = () => {
  if (!window.isElectron) {
    const vendor = window.navigator.vendor.split(' ')[0];
    switch (vendor) {
      // for safari
      case 'Apple':
        document.body.classList.add('safari_only');
        listenTosomeEvent();
        break;
      case 'Google':
        document.body.classList.add('chrome_only');
        listenTosomeEvent();
        break;
      default:
        document.body.classList.add('other_browser');
    }
  }
};
function listenTosomeEvent() {
  let innerHeight = window.innerHeight;
  document.addEventListener('focusin', () => setHeight(window.innerHeight, innerHeight));
  document.addEventListener('focusout', () => setHeight(innerHeight));
  window.addEventListener('resize', function () {
    innerHeight = window.innerHeight;
    setHeight(innerHeight);
  });
}
function setHeight(height, preHeight) {
  const timout = setTimeout(() => {
    if (preHeight && preHeight - height >= 22) {
      height = preHeight;
    }
    const vh = height * 0.01;
    // let bodyMove = document.querySelector(".keyboard");
    // bodyMove.style.paddingBottom = kH + "px";
    // bodyMove.scrollTop = kH;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    clearTimeout(timout);
  }, 400);
}

/**
 * @desc at login generate the redirect url based on permission
 * @param object $data - validate user response
 * @return string $url - path to redirect
 */

export const getRedirectUrl = (data, a) => {
  //a is used to determine which function call to listen too when redirecting since this is being called 2 times during successfull login process
  let redirectUrl = checkLocalRedirect();
  let lastVisited = localStorage.getItem('lastVisited');
  let locaParams = window.location.href;

  if (redirectUrl && a == undefined) {
    window.location.href = redirectUrl;
    localStorage.removeItem('redirect');
  } else if (
    lastVisited != undefined &&
    lastVisited != '' &&
    lastVisited != '/' &&
    locaParams.endsWith('/#/') == true
  ) {
    return lastVisited;
  } else {
    let url = '';
    let redirectPath = 'targets';
    let roleArr = ['targets', 'insights', 'trainer', 'viewer'];

    const { Subscriptions } = data.user;
    if (Subscriptions) {
      for (let i = 0; i < roleArr.length; i++) {
        if (Subscriptions[roleArr[i]] && Subscriptions[roleArr[i]].on === 'yes') {
          redirectPath = roleArr[i];
          break;
        }
      }
    }
    switch (redirectPath) {
      case 'targets':
        url = '/' + redirectPath + ROUTES_SUB_PATH.DASHBOARD.ACTIVE;
        break;
      case 'insights':
        url = '/' + redirectPath + ROUTES_SUB_PATH.INSIGHTS.NEW;
        break;
      case 'trainer':
        url = '/' + redirectPath + ROUTES_SUB_PATH.TRAINER.LESSONS;
        break;
      case 'viewer':
        url = '/' + redirectPath;
        break;
      default:
        url = '/targets/active';
        break;
    }
    return url;
  }
};

const checkLocalRedirect = () => {
  let redirect = localStorage.getItem('redirect');
  if (redirect != null) {
    return redirect;
  }
  return null;
};

/**
 * @desc check if user has permission to access the specified url
 * @param object $data - validate user response
 * @param string $key - tab to view
 * @return boolean - true or false
 */
export const redirectionAllowed = (data, key) => {
  const { Subscriptions } = data.user;
  return Subscriptions[key].on === 'yes';
};

/**
 * @desc get reports path to redirect based /viewer get API response
 * @param object $data - /viewer get API response
 * @return string $url - report path to redirect
 */
export const getAllowedReportPath = (data) => {
  let url = '';
  if (data) {
    const { reports } = data;
    if (reports['targets']) {
      url = `${ROUTES_PATH.VIEWER}/${API_PARAMS.PIPELINE}/overview`;
    } else {
      url = `${ROUTES_PATH.VIEWER}/${API_PARAMS.PIPELINE}/progress`;
    }
  }
  return url;
};

export const formatMoney = (amount) => {
  if (amount) {
    return (typeof amount === 'number' ? amount : Number(amount?.split('.')?.[0]))
      .toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
      .split('.')[0];
  }
  return '';
};

export const parseMoney = (amount) =>
  typeof amount === 'string' ? Number(amount?.split('.')[0]?.replace(/,/g, '') + '.' + amount?.split('.')[1]) : amount;

export const pluralize = (count, noun, suffix = 's') => {
  return `${count} ${noun}${count !== 1 ? suffix : ''}`;
};

export const secondsToMinutes = (timeInSeconds) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds - minutes * 60;
  return `${minutes.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}:${seconds.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}`;
};

export const iso8601ToSeconds = (timeInIso) => {
  const timeInSeconds = moment.duration(timeInIso).asSeconds();
  return secondsToMinutes(timeInSeconds.toFixed(0));
};

export const getTokenExpiration = (tokenTime) => {
  const tokenExpiration = new Date(tokenTime);
  const endTime = new Date(tokenExpiration.setHours(tokenExpiration.getHours() + 1));
  const secondsLeft = (endTime - new Date()) / 1000;
  return secondsLeft <= 0;
};

export const selectedRecipients = (recipients) => {
  if (!recipients?.length) return;
  let selected = 0;
  recipients?.forEach((recipient) => recipient?.checked && selected++);
  return selected === 0;
};

export const getElementId = (name, array) => {
  const selectedElement = array.filter((element) => element.label === name);
  return selectedElement[0]?.id;
};

export const getElementName = (id, array) => {
  const selectedElement = array.filter((element) => element.id === id);
  return selectedElement[0]?.label || selectedElement[0]?.name;
};

export const blobToFile = (blob, fileName) => {
  return new File([blob], fileName, { type: blob.type });
};

export const getFrequencyId = (name) => {
  if (!name) return;
  const selectedFrequency = frequencyOptions.filter(({ label }) => label === name);
  return selectedFrequency[0].id;
};

export const getVideoInsightsIds = (content) => {
  return content.map(({ allow_external, id }) => ({
    id,
    allow_external: allow_external || false,
  }));
};

export const getUsersIds = (recipients) => {
  const selectedRecipients = [];
  recipients.forEach(({ checked, id }) => checked && selectedRecipients.push(Number(id)));
  return selectedRecipients;
};

export const convertTZ = (date, tzString) => {
  return new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone: tzString }));
};

export const getUserTimeZone = () => {
  //Hardcoded as Server time: Pacific Daylight Time (PDT) is 7 hours behind Coordinated Universal Time (UTC)
  const serverTimezone = -8;
  const difference = serverTimezone + new Date().getTimezoneOffset() / 60;
  return `${difference.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}:00`;
};

export const sortAlphabetically = (array, attribute) => {
  array.sort((a, b) => {
    if (a[attribute].toUpperCase() < b[attribute].toUpperCase()) {
      return -1;
    }
    if (a[attribute].toUpperCase() > b[attribute].toUpperCase()) {
      return 1;
    }
    return 0;
  });
};

export const getContentType = (type) => {
  switch (type) {
    case VIDEO:
      return 'video';
    case PDF:
      return 'pdf';
    case BLOG:
      return 'blog';
  }
};

export const getDefaultThumbnail = async (type) => {
  switch (type) {
    case VIDEO:
      return await getDefaultImage(defaultVideoImage, 'video_image_default.jpg');
    case PDF:
      return await getDefaultImage(defaultPdfImage, 'pdf_image_default.jpg');
    default:
      return await getDefaultImage(defaultBlogImage, 'external_image_default.jpg');
  }
};

export const getEditContentType = (type) => {
  switch (type) {
    case 'pdf':
      return PDF;
    case 'blog':
      return BLOG;
    default:
      return VIDEO;
  }
};
