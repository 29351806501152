import {
  LOAD_TARGET_DATA,
  CREATE_TARGET_REQUEST,
  CREATE_TARGET_COMMIT,
  CREATE_TARGET_ROLLBACK,
  MESSAGES,
  DELETE_TARGET_REQUEST,
  DELETE_TARGET_COMMIT,
  DELETE_TARGET_ROLLBACK,
  DESTROY_TARGET_REQUEST,
  DESTROY_TARGET_COMMIT,
  DESTROY_TARGET_ROLLBACK,
  COMPLETE_TARGET_REQUEST,
  COMPLETE_TARGET_COMMIT,
  COMPLETE_TARGET_ROLLBACK,
  EDIT_TARGET_REQUEST,
  EDIT_TARGET_COMMIT,
  ADD_TASK_REQUEST,
  ADD_TASK_COMMIT,
  SHARE_TARGET_REQUEST,
  SHARE_TARGET_COMMIT,
  REJECT_SHARE_TARGET_REQUEST,
  REVOKE_SHARE_TARGET_REQUEST,
  REVOKE_SHARE_TARGET_COMMIT,
  ACCEPT_SHARE_TARGET_REQUEST,
  ACCEPT_SHARE_TARGET_COMMIT,
  REJECT_SHARE_TARGET_COMMIT,
  WORK_ON_TARGET_COMMIT,
  WORK_ON_TARGET_ROLLBACK,
  WORK_ON_TARGET_REQUEST,
  API_PARAMS,
  sharedStatus,
  REMOVE_ATTENTION,
  LOST_TARGET_REQUEST,
  LOST_TARGET_COMMIT,
  LOST_TARGET_ROLLBACK,
} from '../../constant';
import {
  saveToLocaldb,
  deleteOldAndCreateNew,
  deleteFromlocal,
  getaTileById,
} from '../../services/targets/dashboard.service';
import {
  makePayloadForCreation,
  makePayloadForStatusChange,
  makePayloadForEditTarget,
  makePayloadForAddTask,
  makePayloadForShareTarget,
  makePayloadForShareTargetRevoke,
  updateTargetAfterAccept,
  updateTaskofATile,
} from '../../services/targets/localTargetUpdate.service';
import toaster from '../../services/shared/toaster.service';
import { createAnode } from '../../services/shared/sync.service';

let target, shareobj;
const updateState = (state, payload) => {
  if (payload) {
    return state.targets.map((item) => {
      if (item.id === payload.id) {
        return {
          ...payload,
        };
      }
      return item;
    });
  }
  return state.targets;
};
const updateStateForCreate = (state, action, error) => {
  if (error) {
    return state.targets.map((item) => {
      if (item.id === action.meta.tempId) {
        return {
          ...item,
          error: true,
        };
      }
      return item;
    });
  } else {
    return state.targets.map((item) => {
      if (item.id === action.meta.tempId) {
        return {
          ...item,
          id: action.payload.id,
          isTemp: false,
        };
      }
      return item;
    });
  }
};
const updateShares = (state, collaborators) => {
  return state.targets.map((item) => {
    if (item.id === target.id) {
      item.collaborators = collaborators;
      return { ...item };
    }
    return item;
  });
};
const informMultipleUsers = (collaborators, currentUser) => {
  collaborators.forEach((item) => {
    // notifiy each user colloborating in the tiles
    if (
      (item.response === sharedStatus.pending || item.response === sharedStatus.accepted) &&
      item.user_id !== currentUser
    ) {
      createAnode(item.user_id, API_PARAMS.ACTIVE);
    }
  });
};
const dashBoard = (state = { targets: [], goBack: false }, action) => {
  switch (action.type) {
    case LOAD_TARGET_DATA:
      return {
        targets: action.payload,
      };
    case CREATE_TARGET_REQUEST: {
      const createdTarget = makePayloadForCreation(action.payload);
      saveToLocaldb(createdTarget);
      return {
        targets: [...state.targets, { ...createdTarget }],
      };
    }
    case CREATE_TARGET_COMMIT:
      // After offline target is sync with server sync local db with newly created id
      deleteOldAndCreateNew(action.payload, action.meta.tempId);
      setTimeout(() => {
        action?.meta?.onComplete(action.payload.id);
      }, 1);
      return {
        targets: updateStateForCreate(state, action),
      };
    case CREATE_TARGET_ROLLBACK:
      setTimeout(() => toaster.error(MESSAGES.syncError), 100);
      return {
        targets: updateStateForCreate(state, action),
      };
    case DELETE_TARGET_REQUEST: // move to hold
    case DESTROY_TARGET_REQUEST:
    case LOST_TARGET_REQUEST:
    case COMPLETE_TARGET_REQUEST: // move to completed
    case WORK_ON_TARGET_REQUEST: {
      const target = makePayloadForStatusChange(action.payload);
      const index = state.targets.findIndex((item) => item.id === target.id);
      const targets = state.targets;
      targets.splice(index, 1);
      return {
        targets: [...targets],
        goBack: true,
      };
    }
    case DELETE_TARGET_COMMIT:
    case DESTROY_TARGET_COMMIT:
    case LOST_TARGET_COMMIT:
    case COMPLETE_TARGET_COMMIT:
    case WORK_ON_TARGET_COMMIT: {
      const payload = action.payload;
      saveToLocaldb(payload, payload.id);
      return {
        targets: state.targets,
      };
    }
    case DELETE_TARGET_ROLLBACK:
    case DESTROY_TARGET_ROLLBACK:
    case LOST_TARGET_ROLLBACK:
    case COMPLETE_TARGET_ROLLBACK:
    case WORK_ON_TARGET_ROLLBACK:
      setTimeout(() => toaster.error(MESSAGES.syncError), 100);
      return {
        targets: [...state.targets],
      };
    case EDIT_TARGET_REQUEST: {
      const editedTarget = makePayloadForEditTarget(action.payload);
      saveToLocaldb(editedTarget, editedTarget.id);
      return {
        targets: updateState(state, editedTarget),
        goBack: true,
      };
    }
    case EDIT_TARGET_COMMIT:
    case SHARE_TARGET_COMMIT:
      saveToLocaldb(action.payload, action.payload.id);
      if (action.meta.shareWith.id) {
        informMultipleUsers(action.payload.collaborators, action.meta.shareWith.from_id);
      }
      return {
        targets: [...updateState(state, action.payload)],
      };
    case ADD_TASK_REQUEST: {
      const task = action.payload;
      task.accepted = task.accepted ? task.accepted : action.meta.accepted;
      const tile = makePayloadForAddTask(task, state.targets);
      saveToLocaldb(tile, tile.id);
      return {
        targets: updateState(state, tile),
        goBack: !task.accepted,
      };
    }
    case ADD_TASK_COMMIT: {
      const task = action.payload;
      const localId = action.meta.id;
      let tile = state.targets.find((item) => item.id === task.tile_id);
      if (!tile) {
        getaTileById(task.tile_id).then((doc) => {
          updateTaskofATile(doc, localId, task);
        });
      } else {
        updateTaskofATile(tile, localId, task);
      }
      return {
        targets: updateState(state, tile),
        // goBack: !task.accepted
      };
    }
    case SHARE_TARGET_REQUEST:
      target = makePayloadForShareTarget(action.payload);
      saveToLocaldb(target, target.id);
      return {
        targets: updateState(state, target),
      };
    case REVOKE_SHARE_TARGET_REQUEST: {
      shareobj = action.payload.shareobj;
      target = action.payload.tile;
      const collaborator = makePayloadForShareTargetRevoke(shareobj, 'revoked');
      const collaborators = target.collaborators.map((item) => {
        if (item.share_id === collaborator.share_id) {
          return collaborator;
        }
        return item;
      });
      saveToLocaldb(target, target.id);
      return {
        targets: updateShares(state, collaborators),
      };
    }
    case REVOKE_SHARE_TARGET_COMMIT:
      createAnode(action.meta.shareobj.user_id, API_PARAMS.ACTIVE);
      return {
        targets: [...state.targets],
      };
    case REJECT_SHARE_TARGET_REQUEST: {
      shareobj = action.payload;
      const id = shareobj.tileId;
      deleteFromlocal(id);
      const index = state.targets.findIndex((i) => i.id === id);
      state.targets.splice(index, 1);
      return {
        targets: [...state.targets],
        goBack: true,
      };
    }
    case REJECT_SHARE_TARGET_COMMIT: {
      const id = action.meta.tileId;
      deleteFromlocal(id);
      const index = state.targets.findIndex((i) => i.id === id);
      if (index > -1) {
        state.targets.splice(index, 1);
      }
      createAnode(action.payload.from_id, API_PARAMS.ACTIVE);
      return {
        targets: [...state.targets],
        goBack: true,
      };
    }
    case ACCEPT_SHARE_TARGET_REQUEST:
      target = updateTargetAfterAccept(action.payload, state.targets, 'accepted', action.type);
      return {
        targets: [...updateState(state, target)],
        goBack: true,
      };
    // Handled according to new api response in accept request
    case ACCEPT_SHARE_TARGET_COMMIT:
      informMultipleUsers(action.payload.collaborators, action.meta.user_id);
      return {
        targets: [...updateState(state, action.payload)],
      };
    case REMOVE_ATTENTION:
      return {
        ...state,
        goBack: false,
      };
    default:
      return state;
  }
};

export default dashBoard;
