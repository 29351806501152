import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { NavSecondaryTargetDetail, InsightSwiper } from '../../../components';
import { getInsightsFromlocalDB, markAsViewed, reduceInsightCount } from '../../../actions';
import 'swiper/dist/css/swiper.min.css';
import '../../../assets/css/swiper-overrides.css';
import '../../../assets/css/card.css';
import '../../../assets/css/page-insights.css';
import history from '../../../helpers/history';
import { ROUTES_PATH, MESSAGES } from '../../../constant';
import toaster from '../../../services/shared/toaster.service';

class InsightDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: '',
      inProp: false,
    };
  }

  /**
   * @description set initial slide from URL params
   */
  setInsights = () => {
    const {
      insights,
      match: { params },
    } = this.props;
    let initialSlide = insights.findIndex((item) => {
      return item.id === params.id;
    });
    if (initialSlide === -1) {
      history.push(ROUTES_PATH.INSIGHTS + '/new');
      toaster.error(MESSAGES.NO_INSIGHT_FOUND);
    }
    if (params) {
      this.setState({ initialSlide });
    }
  };

  componentDidMount() {
    const { insights, getInsights } = this.props;
    if (!insights.length) {
      getInsights();
    } else {
      this.setInsights();
    }
    // add a class to the body so we can turn off scrolling
    document.body.classList.add('swiper-on');
    this.setState({ inProp: true });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.insights !== this.props.insights) {
      this.setInsights();
    }
  }

  componentWillUnmount() {
    // add a class to the body so we can turn off scrolling
    document.body.classList.remove('swiper-on');
  }

  render() {
    return (
      <>
        <CSSTransition classNames="fade" in={this.state.inProp} timeout={300}>
          <section className="nav-page active" id="tiles">
            {this.state.initialSlide > -1 && (
              <InsightSwiper
                insights={this.props.insights}
                status={this.state.status}
                initialSlide={this.state.initialSlide}
                markAsViewed={this.props.markAsViewed}
              />
            )}
          </section>
        </CSSTransition>
        <NavSecondaryTargetDetail />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    insights: state.insightDashboard.insights,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getInsights: (status) => dispatch(getInsightsFromlocalDB(status)),
  markAsViewed: (payload) => {
    dispatch(markAsViewed(payload));
    dispatch(reduceInsightCount());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InsightDetail);
