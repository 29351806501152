import {
  API_URLS,
  API_METHODS,
  DELETE_TARGET_REQUEST,
  DELETE_TARGET_COMMIT,
  DELETE_TARGET_ROLLBACK,
  COMPLETE_TARGET_REQUEST,
  DESTROY_TARGET_REQUEST,
  DESTROY_TARGET_COMMIT,
  DESTROY_TARGET_ROLLBACK,
  API_PARAMS,
  COMPLETE_TARGET_ROLLBACK,
  COMPLETE_TARGET_COMMIT,
  WORK_ON_TARGET_REQUEST,
  WORK_ON_TARGET_COMMIT,
  WORK_ON_TARGET_ROLLBACK,
  LOST_TARGET_REQUEST,
  LOST_TARGET_COMMIT,
  LOST_TARGET_ROLLBACK,
} from '../../constant';

import { updateOfflineQueue } from '../../services/targets/updateOfflineQueue.service';
import { changePeopleTargetStatus } from '../viewer/people.action';

/**
 * Action to delete target
 * @param  {object} payload One json tile object
 */
export const deleteATarget = (payload, userId) => (dispatch) => {
  payload.revenue = payload.revenue === null ? '' : payload.revenue;
  if (payload.isTemp) {
    updateOfflineQueue(payload, API_PARAMS.ARCHIVED);
    return dispatch({
      type: DELETE_TARGET_REQUEST,
      payload: { ...payload, movingTo: API_PARAMS.ARCHIVED },
    });
  }
  if (payload.isPeopleTarget) {
    // dispatch action to change people target status
    delete payload.isPeopleTarget;
    return dispatch(changePeopleTargetStatus(payload, 'archive', userId));
  }
  return dispatch({
    type: DELETE_TARGET_REQUEST,
    payload: { ...payload, movingTo: API_PARAMS.ARCHIVED },
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          apiInfo: {
            url: `${API_URLS.TILES}/${payload.id}?action=archive`,
            type: API_METHODS.POST,
          },
          data: payload,
        },
        // action to dispatch when effect succeeds:
        commit: { type: DELETE_TARGET_COMMIT, meta: { payload, movingTo: API_PARAMS.ARCHIVED } },
        // action to dispatch if network action fails permanently:
        rollback: { type: DELETE_TARGET_ROLLBACK, meta: { id: payload.id } },
      },
    },
  });
};

/**
 * Action to lose a target
 * @param  {object} payload One json tile object
 * @param  {string} userId User ID
 */
export const lostTarget = (payload, userId) => (dispatch) => {
  payload.revenue = payload.revenue === null ? '' : payload.revenue;
  if (payload.isTemp) {
    updateOfflineQueue(payload, API_PARAMS.LOST);
    return dispatch({
      type: LOST_TARGET_REQUEST,
      payload: { ...payload, movingTo: API_PARAMS.LOST },
    });
  }
  if (payload.isPeopleTarget) {
    delete payload.isPeopleTarget;
    return dispatch(changePeopleTargetStatus(payload, 'lost', userId));
  }
  return dispatch({
    type: LOST_TARGET_REQUEST,
    payload: { ...payload, movingTo: API_PARAMS.LOST },
    meta: {
      offline: {
        effect: {
          apiInfo: {
            url: `${API_URLS.TILES}/${payload.id}?action=lost&lost_reason=${payload.lostReason}`,
            type: API_METHODS.POST,
          },
          data: payload,
        },
        commit: { type: LOST_TARGET_COMMIT, meta: { payload, movingTo: API_PARAMS.LOST } },
        rollback: { type: LOST_TARGET_ROLLBACK, meta: { id: payload.id } },
      },
    },
  });
};

/**
 * Action to move target to Destroy
 * @param  {object} payload One json tile object
 */
export const destroyATarget = (payload, userId) => (dispatch) => {
  payload.revenue = payload.revenue === null ? '' : payload.revenue;
  if (payload.isTemp) {
    updateOfflineQueue(payload, API_PARAMS.DELETED);
    return dispatch({
      type: DESTROY_TARGET_REQUEST,
      payload: { ...payload, movingTo: API_PARAMS.DELETED },
    });
  }
  if (payload.isPeopleTarget) {
    // dispatch action to change people target status
    delete payload.isPeopleTarget;
    return dispatch(changePeopleTargetStatus(payload, 'delete', userId));
  }
  return dispatch({
    type: DESTROY_TARGET_REQUEST,
    payload: { ...payload, movingTo: API_PARAMS.DELETED },
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          apiInfo: {
            url: `${API_URLS.TILES}/${payload.id}?action=delete`,
            type: API_METHODS.POST,
          },
          data: payload,
        },
        // action to dispatch when effect succeeds:
        commit: { type: DESTROY_TARGET_COMMIT, meta: { payload, movingTo: API_PARAMS.DELETED } },
        // action to dispatch if network action fails permanently:
        rollback: { type: DESTROY_TARGET_ROLLBACK, meta: { id: payload.id } },
      },
    },
  });
};

/**
 * Action to compelete target
 * @param  {object} payload One json tile object
 */
export const completeATarget = (payload, revenue, userId) => (dispatch) => {
  if (payload.isTemp) {
    updateOfflineQueue(payload, API_PARAMS.COMPLETED);
    return dispatch({
      type: COMPLETE_TARGET_REQUEST,
      payload: { ...payload, movingTo: API_PARAMS.COMPLETED },
    });
  }
  if (payload.isPeopleTarget) {
    // dispatch action to change people target status
    delete payload.isPeopleTarget;
    return dispatch(changePeopleTargetStatus(payload, 'complete', userId));
  }
  return dispatch({
    type: COMPLETE_TARGET_REQUEST,
    payload: { ...payload, movingTo: API_PARAMS.COMPLETED },
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          apiInfo: {
            url: `${API_URLS.TILES}/${payload.id}?action=complete`,
            type: API_METHODS.POST,
          },
          data: { revenue },
        },
        // action to dispatch when effect succeeds:
        commit: { type: COMPLETE_TARGET_COMMIT, meta: { payload, movingTo: API_PARAMS.COMPLETED } },
        // action to dispatch if network action fails permanently:
        rollback: { type: COMPLETE_TARGET_ROLLBACK, meta: { id: payload.id } },
      },
    },
  });
};

export const workOnThisTile = (payload, userId) => (dispatch) => {
  payload.revenue = payload.revenue === null ? '' : payload.revenue;
  if (payload.isPeopleTarget) {
    // dispatch action to change people target status
    delete payload.isPeopleTarget;
    return dispatch(changePeopleTargetStatus(payload, 'work', userId));
  }
  return dispatch({
    type: WORK_ON_TARGET_REQUEST,
    payload: { ...payload, movingTo: API_PARAMS.ACTIVE },
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          apiInfo: {
            url: `${API_URLS.TILES}/${payload.id}?action=work`,
            type: API_METHODS.POST,
          },
          data: payload,
        },
        // action to dispatch when effect succeeds:
        commit: { type: WORK_ON_TARGET_COMMIT, meta: { payload, movingTo: API_PARAMS.ACTIVE } },
        // action to dispatch if network action fails permanently:
        rollback: { type: WORK_ON_TARGET_ROLLBACK, meta: { id: payload.id } },
      },
    },
  });
};
