import React, { Component } from 'react';
import { connect } from 'react-redux';
import { submit, change, initialize } from 'redux-form';
import * as moment from 'moment';

import { DueDateFormatToDisplay } from '../../../constant';
import { showActionSheet, hideActionSheet, createATarget, hideModal, firms } from '../../../actions';
import { CreateTargetForm } from '../../../components';
import { ActionSheetContainer } from '../..';
import { getFirstName, getLastName } from '../../../helpers/sessionManager';

// Create target container
class CreateTarget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActionSheetActive: false,
      actionSheetType: null,
      isModalActive: false,
    };
  }
  // toggle action sheet
  toggleActionSheet = ({ type, open }) => {
    if (open) {
      this.setState({ actionSheetType: type });
      this.props.showActionSheet(
        {
          open: true,
          type: type,
        },
        type,
      );
    } else {
      this.props.hideActionSheet();
    }
  };
  // Callback for when the form is submitted
  onSubmit = (value) => {
    value.first_name = getFirstName();
    value.last_name = getLastName();
    this.props.apiCallCreateTarget(value);
  };
  // callback when value is selected from action sheet
  onSelect = (e) => {
    let value;
    if (e.nativeEvent) {
      value = e.nativeEvent.target.value ? e.nativeEvent.target.value : e.nativeEvent.target.parentElement.value;
    } else {
      value = moment(e).format(DueDateFormatToDisplay);
    }
    if (value.indexOf('...') > -1) {
      value = value.replace('...', '');
    }
    this.props.setValueToForm(this.state.actionSheetType, value);
    this.props.hideActionSheet();
  };

  componentDidMount() {
    this.setActiveModal(this.props);
    this.props.firms();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.setActiveModal(nextProps);
    }
    if (nextProps.createTarget2 !== this.props.createTarget2) {
      this.close();
    }
  }

  setActiveModal = (nextProps) => {
    this.setState({
      isModalActive: nextProps.modalProps.open,
      isActionSheetActive: nextProps.actionSheetProps.open,
    });
  };

  // close modal on click
  close = () => {
    this.props.resetForm();
    this.props.hideModal();
  };

  // render function to render the JSX
  render() {
    return (
      <div className={`dialog max create input ${this.state.isModalActive ? 'active' : ''}`} id="tile-create-form">
        <div className={`box ${this.state.isModalActive ? 'keyboard' : ''} add`}>
          {this.state.isModalActive && (
            <div className={`inner create ${this.state.isActionSheetActive ? 'showing-action-sheet' : ''}`}>
              <CreateTargetForm
                toggleActionSheet={(e) => this.toggleActionSheet(e)}
                onSubmit={this.onSubmit}
                focusFirst={this.state.isModalActive}
              />
              <footer className="actions">
                <button className="cancel" onClick={this.close}>
                  Cancel
                </button>
                <button className="save" onClick={this.props.submitTarget} type="submit">
                  Create Target
                </button>
              </footer>
              <ActionSheetContainer onSelect={this.onSelect} close={this.props.hideActionSheet} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

// map dispatch to props
const mapDispatchToProps = (dispatch, { isPeopleTarget, uid, range }) => ({
  hideActionSheet: () => dispatch(hideActionSheet()),
  showActionSheet: (actionSheetProps, actionSheetType) => {
    dispatch(showActionSheet({ actionSheetProps, actionSheetType }));
  },
  submitTarget: () => dispatch(submit('createEditTarget')),
  // set value to form via actionsheets
  setValueToForm: (field, value) => dispatch(change('createEditTarget', field, value)),
  // api call to create a target
  apiCallCreateTarget: (payload) => dispatch(createATarget(payload, isPeopleTarget, uid, range)),
  hideModal: () => dispatch(hideModal()),
  resetForm: () => {
    dispatch(initialize('createEditTarget'));
  },
  firms: () => dispatch(firms('')),
});

// map state to props
const mapStateToProps = (state) => ({
  ...state.createTarget,
  ...state.actionSheet,
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTarget);
