import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { TargetNoteItem, CollaboratorsNotes } from '../../../components';
import { getTileNotesData, addTargetNoteData, editTargetNoteData, trackTargetNote } from '../../../actions';
import { getUserId } from '../../../helpers';

const TargetNotes = (props) => {
  const { addTargetNote, editTargetNote, notes, trackTargetNote, targetNoteTracker } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const targetNote = useSelector((state) => state.targetNote);
  const userTargetNote = useMemo(
    () =>
      notes
        ? notes.filter((note) => note.user_id === getUserId())
        : targetNote.filter((note) => note.user_id === getUserId()),
    [notes, targetNote],
  );
  const collaboratorsNote = useMemo(() => (notes ? notes.filter((note) => note.user_id !== getUserId()) : []), [notes]);

  useEffect(() => {
    dispatch(getTileNotesData(props.tiles));
  }, [targetNoteTracker, dispatch, props.tiles]);

  useEffect(() => {
    const loading = setTimeout(() => {
      setIsLoading(false);
    }, 300);

    return () => {
      clearTimeout(loading);
    };
  }, [userTargetNote, targetNote]);

  return (
    <>
      <TargetNoteItem
        targetNote={userTargetNote}
        addTargetNote={addTargetNote}
        editTargetNote={editTargetNote}
        tiles={props.tiles}
        uid={props.uid}
        trackTargetNote={trackTargetNote}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        targetNoteTracker={targetNoteTracker}
      />
      <CollaboratorsNotes targetNote={collaboratorsNote} counter={targetNoteTracker} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    targetNoteTracker: state.trackTargetNote,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addTargetNote: (target_id, note) => dispatch(addTargetNoteData({ targetId: target_id, note })),
  editTargetNote: (id, note) => dispatch(editTargetNoteData(id, note)),
  trackTargetNote: () => dispatch(trackTargetNote()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TargetNotes);
