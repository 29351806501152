import React from 'react';
import { Link } from 'react-router-dom';

import Table from './Table.component';
import icons from '../../../assets/img/icons.svg';
import { ROUTES_PATH } from '../../../constant';

const GroupList = ({ list = '', row }) => {
  return row.canExpand ? (
    <h1 className="target-name">
      <div className="expand-wrapper" {...row.getToggleRowExpandedProps()}>
        <span className="caret-wrapper">
          <div className="caret" />
        </span>
        {list}
      </div>
    </h1>
  ) : null;
};

// show user initials in uppercase
const UserList = ({ list = '', original }) => {
  if (list) {
    return (
      <div
        className="user"
        onClick={() => localStorage.setItem('userName', original.first_name + ' ' + original.last_name)}
      >
        <Link
          to={{
            pathname: `${ROUTES_PATH.VIEWER}${ROUTES_PATH.PIPELINE}${ROUTES_PATH.USER_WORKSHEETS}/${list}`,
            state: {
              activeReport: 'worksheets',
            },
          }}
        >
          <dl className="handle person">
            <dt className="avatar">
              {original.first_name.charAt(0).toUpperCase()}
              {original.last_name.charAt(0).toUpperCase()}
            </dt>
            <dd className="name">{original.first_name ? original.first_name + ' ' + original.last_name : '~'}</dd>
          </dl>
        </Link>
        <svg className="icon">
          <use xlinkHref={`${icons}#trainer`} />
        </svg>
      </div>
    );
  } else {
    return '~';
  }
};

// show worksheet complete status
const WorksheetComplete = ({ list = {} }) => {
  return list.completed && list.completed.toUpperCase() === 'YES' ? (
    <svg className="icon">
      <use xlinkHref={`${icons}#task-done`} />
    </svg>
  ) : null;
};

/**
 * show worksheet in tabular format
 * @param {*} table props
 */
const WorksheetsTable = ({ worksheetData }) => {
  // memoised worksheet data to show
  const data = React.useMemo(() => {
    if (worksheetData) {
      let groupList = worksheetData.map((ele) => ele.group);
      let uniqueGroup = groupList.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      return uniqueGroup.map((group) => {
        return {
          groupName: group,
          subRows: worksheetData.filter((item) => item.group === group),
        };
      });
    }
    return [];
  }, [worksheetData]);

  // calculate the total number of columns
  const numcols = data.length ? data[0].subRows[0].worksheets.length + 2 : '';

  // table columns
  const columns = React.useMemo(() => {
    let fixColumns = [
      {
        Header: 'Group',
        accessor: 'groupName',
        id: 'groupName',
        Cell: ({ row, value }) => <GroupList list={value} row={row} />,
        className: 'text company',
        disableSortBy: true,
        colGroup: 'now',
        colSpan: numcols,
        colGroupClass: 'text group',
      },
      {
        Header: 'User',
        accessor: 'user_id',
        Cell: ({ row: { original }, value }) => <UserList list={value} original={original} />,
        disableSortBy: true,
        className: 'text user',
        colGroup: 'now',
        colGroupClass: 'text user',
        tooltip: 'Click on a User to see the User’s Worksheets.',
      },
    ];
    let dynamicColumn = [];
    if (worksheetData.length) {
      dynamicColumn = worksheetData[0].worksheets.map((worksheet, index) => ({
        Header: `${worksheet.lesson}`,
        accessor: `worksheets[${index}]`,
        // id: `${worksheet.number}`,
        Cell: ({ value }) => <WorksheetComplete list={value} />,
        disableSortBy: true,
        className: 'progress',
        colGroup: 'data',
        colGroupClass: 'text lesson',
        tooltip: 'A checkmark indicates the User has responded to some or all questions on this Worksheet.',
      }));
    }
    return fixColumns.concat(dynamicColumn);
  }, [numcols, worksheetData]);

  const hrows = data
    .map((row) => {
      if (row) {
        return {
          props: {
            className: 'freeze active',
          },
          columns: [],
        };
      } else return {};
    })
    .filter((anyValue) => typeof anyValue !== 'undefined');

  const defaultExpandedRows = data.map((_element, _index) => {
    return { index: true };
  });

  const initialState = {
    // we don't want to sort this report
    // sortBy: React.useMemo(() => [{id: 'groupName', desc: false}], []),
    expanded: defaultExpandedRows,
  };
  return (
    <>
      <section className="panel table progress worksheets">
        <div className="scroller horz">
          {data.length ? (
            <Table
              data={data}
              columns={columns}
              headRows={hrows}
              initialState={initialState}
              isExpandable
              getRowProps={(row) => ({
                className: `${row.original.groupName ? 'group-head' : 'user'}`,
              })}
            />
          ) : null}
        </div>
      </section>
    </>
  );
};

export default WorksheetsTable;
