import { filteredInsights } from '../../actions';
import {
  ADD_DIFFUSION_LIST,
  DRAG_INSIGHT,
  LOAD_INSIGHTS,
  MARK_INSIGHT_VIEWED_COMMIT,
  MARK_INSIGHT_VIEWED_REQUEST,
  SET_PREVIEW_VALUES,
  SET_DIGEST_VALUES,
  SET_DIGEST_TEMPLATES,
  ADD_NEW_SECTION,
  GET_SECTIONS,
  GET_INSIGHTS,
  GET_TAGS,
  ADD_NEW_TAG,
  DELETE_INSIGHT,
  INSIGHT_TO_DELETE,
  FILTERED_INSIGHTS,
  INSIGHT_SEARCH_VALUES,
  EDIT_INSIGHT,
  ADD_NEW_TEMPLATE,
  ADD_DIGEST,
  GET_DIGESTS,
  NEW_DIFFUSION_LIST,
  GET_DIFFUSION_LIST,
  ADD_FAVORITE,
  POPULATE_CONTENT,
  REMOVE_POPULATE_CONTENT,
  DELETE_DIGEST,
  DELETE_SECTION,
  RESTORE_SEARCH,
  SECTION_TO_DELETE,
  RESET_DIGEST,
} from '../../constant';
import { sortAlphabetically } from '../../helpers';
import { changeStatusOfInsights } from '../../services/insights/insight.service';

const sectionSortValue = 'name';

const initialState = {
  insights: [],
  digestValues: {
    title: '',
    curatedBy: '',
    description: '',
    content: [],
    scheduled: '',
    frequency: '1',
    recipients: [],
    template: '',
    styles: { logo: '', backgroundColor: '', backgroundImage: '' },
    externalContent: [],
  },
  digestTemplates: [],
  sections: [],
  content: [],
  tags: [],
  deleteInsight: null,
  filteredInsights: null,
  filteredSections: null,
  searchValues: { searchValue: '', selectedFilters: {} },
  digestsSent: [],
  diffusionLists: [],
  populatedInsights: [],
  videoInsightsSent: [],
  deleteSection: null,
};

const insightDashboard = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_INSIGHTS:
      return {
        ...state,
        insights: action.payload,
      };
    case MARK_INSIGHT_VIEWED_COMMIT:
    case MARK_INSIGHT_VIEWED_REQUEST: {
      const tile = changeStatusOfInsights(action.payload, action.payload.id);
      return {
        ...state,
        insights: [
          ...state.insights.map((insight) => {
            if (insight.id === tile) {
              return tile;
            }
            return insight;
          }),
        ],
      };
    }
    case DRAG_INSIGHT: {
      return {
        ...state,
        draggedInsight: action.payload,
      };
    }
    case ADD_DIFFUSION_LIST: {
      return { ...state, newDiffusionList: action.payload };
    }
    case SET_PREVIEW_VALUES: {
      return { ...state, previewValues: action.payload };
    }
    case SET_DIGEST_VALUES: {
      return { ...state, digestValues: action.payload };
    }
    case SET_DIGEST_TEMPLATES: {
      return { ...state, digestTemplates: action.payload };
    }
    case ADD_NEW_SECTION: {
      const newSections = [...state.sections, action.payload];
      sortAlphabetically(newSections, sectionSortValue);
      return { ...state, sections: newSections };
    }
    case ADD_NEW_TAG: {
      return { ...state, tags: [...state.tags, action.payload] };
    }
    case GET_SECTIONS: {
      const sections = action.payload;
      sortAlphabetically(sections, sectionSortValue);
      return { ...state, sections };
    }
    case GET_INSIGHTS: {
      return { ...state, content: action.payload };
    }
    case GET_TAGS: {
      return { ...state, tags: action.payload };
    }
    case DELETE_INSIGHT: {
      return {
        ...state,
        content: state.content.filter(({ id }) => id !== action.payload),
        filteredInsights: state.filteredInsights && state.filteredInsights.filter(({ id }) => id !== action.payload),
      };
    }
    case INSIGHT_TO_DELETE: {
      return { ...state, deleteInsight: action.payload, deleteSection: null };
    }
    case FILTERED_INSIGHTS: {
      const sectionsWithContent = action.payload.map(({ section_id }) => section_id);
      const filteredSections = state.sections.filter(({ id }) => sectionsWithContent.includes(id));
      sortAlphabetically(filteredSections, 'name');
      return { ...state, filteredInsights: action.payload, filteredSections };
    }
    case RESTORE_SEARCH: {
      return {
        ...state,
        filteredInsights: null,
        filteredSections: null,
        searchValues: { searchValue: '', selectedFilters: {} },
      };
    }
    case INSIGHT_SEARCH_VALUES: {
      return { ...state, searchValues: action.payload };
    }
    case EDIT_INSIGHT: {
      if (state.filteredInsights) {
        const filteredIds = state.filteredInsights.map(({ id }) => id);
        const filteredInsights = action.payload.filter(({ id }) => filteredIds.includes(id));
        return { ...state, content: action.payload, filteredInsights };
      }
      return { ...state, content: action.payload };
    }
    case ADD_NEW_TEMPLATE: {
      return {
        ...state,
        digestTemplates: [...state.digestTemplates, ...action.payload],
        digestValues: {
          ...state.digestValues,
          template: action.payload[0].name,
          styles: {
            template_id: action.payload[0]?.id,
            logo: action.payload[0]?.logo_image,
            backgroundColor: action.payload[0]?.color,
            backgroundImage: action.payload[0]?.image,
            activeTab: action.payload[0]?.image_selected,
          },
        },
      };
    }
    case ADD_DIGEST: {
      return { ...state, digestsSent: [...state.digestsSent, action.payload], digestValues: initialState.digestValues };
    }
    case GET_DIGESTS: {
      const allInsightsSent = [];
      const digestsSent = action.payload || [];
      digestsSent.forEach(({ videoinsights }) => allInsightsSent.push(videoinsights));
      return {
        ...state,
        digestsSent,
        videoInsightsSent: [...new Set(allInsightsSent)],
      };
    }
    case NEW_DIFFUSION_LIST: {
      return { ...state, diffusionLists: [...state.diffusionLists, action.payload] };
    }
    case GET_DIFFUSION_LIST: {
      return { ...state, diffusionLists: action.payload };
    }
    case ADD_FAVORITE: {
      let content = [...state.content];

      const contentIndex = state.content.findIndex(({ id }) => id === action.payload.id);
      const editedContent = state.content.find(({ id }) => id === action.payload.id);

      delete editedContent['tag_names'];

      content.splice(contentIndex, 1, {
        ...editedContent,
        ...(action.payload.newTags.length && { tag_names: action.payload.newTags.join(', ') }),
      });

      if (state.filteredInsights) {
        let filteredInsights = [...state.filteredInsights];
        const filteredIndex = filteredInsights.findIndex(({ id }) => id === action.payload.id);
        filteredInsights.splice(filteredIndex, 1, {
          ...editedContent,
          ...(action.payload.newTags.length && { tag_names: action.payload.newTags.join(', ') }),
        });
        return { ...state, content, filteredInsights };
      }

      return { ...state, content };
    }
    case POPULATE_CONTENT: {
      return {
        ...state,
        digestValues: {
          ...state.digestValues,
          content: [...state.digestValues.content, ...action.payload],
        },
        populatedInsights: action.payload,
      };
    }
    case REMOVE_POPULATE_CONTENT: {
      return {
        ...state,
        digestValues: {
          ...state.digestValues,
          content: action.payload,
        },
        populatedInsights: [],
      };
    }
    case DELETE_DIGEST: {
      return { ...state, digestsSent: state.digestsSent.filter(({ id }) => id !== action.payload) };
    }
    case DELETE_SECTION: {
      return {
        ...state,
        sections: state.sections.filter(({ id }) => id !== action.payload),
        filteredSections: state.filteredSections && state.filteredSections.filter(({ id }) => id !== action.payload),
      };
    }
    case SECTION_TO_DELETE: {
      return {
        ...state,
        deleteSection: action.payload,
        deleteInsight: null,
      };
    }
    case RESET_DIGEST: {
      return { ...state, digestValues: initialState.digestValues };
    }
    default:
      return state;
  }
};

export default insightDashboard;
