import React from 'react';
import icons from './../../assets/img/icons.svg';

const Confimation = (props) => {
  return (
    <div className={`followup delete ${props.active && 'active'} remove-targets-selection`}>
      {props.active && (
        <>
          <p>What would you like to do?</p>
          <button
            className="action next submit"
            style={{ width: '50%', marginRight: '10px', display: 'inline-block' }}
            onClick={props.confimationCB}
          >
            <svg className="icon" style={{ height: '1em' }}>
              {' '}
              <use xlinkHref={`${icons}#status-archived`} />
            </svg>
            <span>Place on hold</span>
          </button>
          <button
            className="action next submit"
            style={{ width: 'calc(50% - 10px)', display: 'inline-block' }}
            onClick={props.destroyTargetCB}
          >
            <svg className="icon" style={{ height: '1em' }}>
              {' '}
              <use xlinkHref={`${icons}#status-trashed`} />
            </svg>
            <span>Move to trash</span>
          </button>
          <p className="help">
            On Hold will move it to the On Hold section. Move to Trash will move it to the Recently Deleted section.
          </p>
        </>
      )}
    </div>
  );
};

export default Confimation;
