import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import '../../../assets/css/colleague-list.css';
import { Header } from '../../../components';
import { getUserId } from '../../../helpers/sessionManager';
import { Context } from '../../';
import { rejectSharedTarget, acceptSharedTarget } from '../../../actions';
import { gTag } from '../../../helpers';

/**
 * conatiner for context tabs in detail page
 */
class SharedTargetSlide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeAction: false,
      sharedObj: {},
    };
    this.scrollRef = React.createRef();
    this.toggleNextTask = this.toggleNextTask.bind(this);
  }

  componentDidUpdate() {
    if (this.props.activeSlideId === this.props.detail.id) {
      const { taskdue, taskdescription } = this.props.selectedValueFromAS;
      if (taskdue) {
        this.state.activeAction && this.setValueToNewTask('taskdue', taskdue);
      } else if (taskdescription) {
        this.state.activeAction && this.setValueToNewTask('taskdescription', taskdescription);
      }
    }
  }

  componentDidMount() {
    const sharedObj = this.findShareObj() || {};
    this.setState({ sharedObj });
  }

  setValueToNewTask = (key, value) => {
    this.setValueToField(key, value);
    this.props.resetState();
  };

  toggleNextTask() {
    this.setState({ activeAction: !this.state.activeAction });
  }

  bindSetValue = (setValue) => {
    this.setValueToField = setValue;
  };

  bindresetForm = (reset) => {
    this.resetForm = reset;
  };

  /* commeted for client request
    acceptSharing = (values) => {
        const task = {
            description: values.taskdescription,
            due: convertDueDateToAPIFormat(values.taskdue),
            help: values.taskhelp,
            tile_id: this.props.detail.id,
            completed: null,
            done: taskType.incomplete,
            id: Date.now(),
            first_name: getFirstName(),
            last_name: getLastName(),
            user_id: getUserId()
        };
        this.toggleNextTask();
        const res = this.props.createNextTask(task, sharedStatus.accepted);
        if (res) {
            this.props.acceptSharedTarget(this.state.sharedObj, this.props.detail.id);
        }
    };
    */
  accept = () => {
    this.props.acceptSharedTarget(this.state.sharedObj, this.props.detail.id, this.props.uid);
    !this.props.uid && this.props.afterDelete();
  };

  decline = () => {
    this.props.decline(this.state.sharedObj, this.props.detail.id, this.props.uid);
    !this.props.uid && this.props.afterDelete();
  };

  findShareObj = () => {
    const currentUser = this.props.uid ? this.props.uid : getUserId();
    return this.props.detail.collaborators.find((item) => item.user_id === currentUser && !item.responded);
  };

  // render function to render the JSX
  render() {
    const {
      detail,
      match: { params },
    } = this.props;
    return (
      <article className="card shared">
        <div className="front">
          <Header detail={detail} />
          <div className="main">
            <div className="block next">
              <div className="current-step">
                <h3>
                  {this.state.sharedObj.from_first_name} {this.state.sharedObj.from_last_name}{' '}
                  <span>is inviting you to team up on this target.</span>
                </h3>
                <div className="yesno">
                  <button className="action next decline" onClick={this.decline}>
                    <span>Decline</span>
                  </button>
                  <button className="action next accept" onClick={this.accept}>
                    <span>Accept</span>
                  </button>
                </div>
                <p className="help">
                  Accepting this share request will add this Target to your dashboard. Review the Activity tab below to
                  see what your colleague(s) are currently working on.
                </p>
              </div>
              {/* commeted for client request
                            <MarkAsDoneForm active={this.state.activeAction}
                                toggleActionSheet={toggleActionSheet} nextTask={this.acceptSharing}
                                bindSetValue={this.bindSetValue} bindresetForm={this.bindresetForm} />
                            */}
            </div>
            {/* commeted for client request
                        <div className="block action-list">
                            <ul className={`strip ${this.state.activeAction ? 'active' : ''}`}>
                                <li className="action-cancel" onClick={this.toggleNextTask}>
                                    <span className="next-action cancel">
                                        <i className="action">
                                            <svg className="icon nav-icon">
                                                <use xlinkHref={`${icons}#button-cancel`} />
                                            </svg>
                                            Cancel
                                            </i>
                                    </span>
                                </li>
                            </ul>
                        </div >
                        */}
            <Context detail={detail} showSharing={false} shared={true} tile={this.props.detail.id} />
          </div>
          <div
            className="close-dialog"
            onClick={() => {
              this.props.history.goBack();
              gTag('Tile Close', `Target: ${params.id}`);
            }}
          >
            <svg className="icon nav-icon" viewBox="0 0 200 200">
              <polygon
                fill="#2d498d"
                points={`179.34 162.08 117.25 100 179.34 37.92 162.08 20.66 100
                                82.75 37.92 20.66 20.66 37.92 82.75 100 20.66 162.08 37.92 179.34 100 117.25 162.08 179.34 179.34 162.08`}
              />
            </svg>
          </div>
        </div>
      </article>
    );
  }
}

// map dispatch to props
const mapDispatchToProps = (dispatch) => ({
  decline: (collaborator, tileId, uid) => dispatch(rejectSharedTarget(collaborator, tileId, uid)),
  acceptSharedTarget: (collaborator, tileId, uid) => dispatch(acceptSharedTarget(collaborator, tileId, uid)),
});

// map state to props
const mapStateToProps = (_state) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SharedTargetSlide));
