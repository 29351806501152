import { SHOW_MORE_CONTENT } from '../../constant';

const initialState = false;

const showMore = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MORE_CONTENT:
      return action.payload;
    default:
      return state;
  }
};

export default showMore;
