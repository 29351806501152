import { useEffect } from 'react';

export const useHideDialog = (isDialog, handleOutsideClick) => {
  useEffect(() => {
    if (isDialog) {
      document.addEventListener('click', handleOutsideClick, true);
    }
    return () => {
      if (isDialog !== null) {
        document.removeEventListener('click', handleOutsideClick, true);
      }
    };
  }, [isDialog, handleOutsideClick]);
};
