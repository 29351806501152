import { ROUTES_PATH } from '../constant';
import { Login, ResetPassword, Account, NewPassword, SwitchUser, AuthByOtp, Auth0Login } from '../containers';

const userRoutes = [
  {
    path: ROUTES_PATH.LOGIN,
    exact: true,
    component: Login,
    isProtected: false,
    isRestricted: true,
  },
  {
    path: ROUTES_PATH.RESETPASSWORD,
    exact: true,
    component: ResetPassword,
    isProtected: false,
    isRestricted: true,
  },
  {
    path: ROUTES_PATH.ACCOUNT + '/:status',
    exact: false,
    component: Account,
    isProtected: true,
  },
  {
    path: `${ROUTES_PATH.NEWPASSWORD}/:key/:email`,
    exact: true,
    component: NewPassword,
    isProtected: false,
    isRestricted: true,
  },
  {
    path: `${ROUTES_PATH.SWITCH}`,
    exact: true,
    component: SwitchUser,
    isProtected: false,
  },
  {
    path: `${ROUTES_PATH.OTP}/:email/:reason`,
    exact: true,
    component: AuthByOtp,
    isProtected: false,
  },
  {
    path: ROUTES_PATH.SSO,
    exact: true,
    component: Auth0Login,
    isProtected: false,
    isRestricted: true,
  },
];

export default userRoutes;
