import React, { useRef } from 'react';
import { ReactComponent as EmailIcon } from '../../assets/img/email.svg';
import { ReactComponent as LinkIcon } from '../../assets/img/link.svg';
import { ReactComponent as FacebookIcon } from '../../assets/img/facebook.svg';
import { ReactComponent as LinkedinIcon } from '../../assets/img/linkedin.svg';
import { ReactComponent as TwitterIcon } from '../../assets/img/twitter.svg';
import { ReactComponent as DurationIcon } from '../../assets/img/time.svg';
import './styles.css';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useOnClickOutside } from '../../hooks/onClickOutside';
import { getDefaultThumbnail } from '../../helpers';

const LINK_SHARE = 'link';

const PreviewModal = ({ setNewElementModalOpen }) => {
  const previewValues = useSelector((state) => state.insightDashboard.digestValues);
  const modalRef = useRef();
  useOnClickOutside(modalRef, () => setNewElementModalOpen(''));

  const sharingPlatforms = [
    { id: 1, icon: <EmailIcon />, href: 'mailto' },
    { id: 3, icon: <FacebookIcon />, href: 'https://www.facebook.com/' },
    { id: 4, icon: <LinkedinIcon />, href: 'https://www.linkedin.com/' },
    { id: 3, icon: <TwitterIcon />, href: 'https://x.com/?lang=en' },
  ];

  //TODO confirm unsubscribe link
  const footerLinks = [
    { id: 1, name: 'Privacy Policy', href: 'https://pipelineplus.com/privacy-policy/' },
    { id: 2, name: 'Terms of Service', href: 'https://pipelineplus.com/tos/' },
    { id: 3, name: 'Unsubscribe', href: '' },
  ];

  const backgroundImage = !!previewValues?.styles?.backgroundImage;

  return (
    <div className="preview-overlay">
      <div className="preview-modal-wrapper" ref={modalRef}>
        <div className="preview-modal-content">
          <div
            className="preview-modal-header"
            style={{
              background: backgroundImage
                ? `center / cover url(${previewValues?.styles?.backgroundImage})`
                : previewValues?.styles?.backgroundColor,
            }}
          >
            <img src={previewValues?.styles?.logo} alt="Digest company logo" />
            <span>{moment(previewValues?.schedule).format('MMM D, YYYY')}</span>
          </div>
          <div className="preview-modal-body">
            <div className="body-header">
              <h2 style={{ color: previewValues?.styles?.backgroundColor }}>{previewValues?.title}</h2>
              <h3>Curated by: {previewValues?.curatedBy}</h3>
            </div>
            <p className="body-description">{previewValues?.description}</p>
            {previewValues?.content?.map((contentUnit) => (
              <div className="content-item" key={contentUnit?.id}>
                <img
                  src={
                    contentUnit?.thumbnail ||
                    contentUnit?.thumbnail_url ||
                    getDefaultThumbnail(contentUnit.content_type)
                  }
                  alt="Content preview thumbnail"
                />
                <div className="content-data">
                  <div className="content-data-header">
                    <a href={contentUnit?.url} target="_blank" rel="noopener noreferrer">
                      <h4>{contentUnit?.name}</h4>
                    </a>
                    {!!contentUnit?.duration && (
                      <div className="content-duration">
                        <DurationIcon />
                        <span>{contentUnit?.duration}</span>
                      </div>
                    )}
                  </div>
                  {contentUnit?.description ? <p>{contentUnit?.description}</p> : null}
                  {contentUnit?.allow_external || contentUnit?.noExternal ? (
                    <div className="sharing-links">
                      {sharingPlatforms.map((platform, index) => (
                        <>
                          <a
                            key={platform.id}
                            href={platform.link === LINK_SHARE ? contentUnit?.url : platform.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {platform.icon}
                          </a>
                          {!!(index < sharingPlatforms.length - 1) && <div className="external-share-division" />}
                        </>
                      ))}
                    </div>
                  ) : (
                    <div className="private-content-pill">Private Content</div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="body-division" />
          <div className="preview-modal-footer">
            {footerLinks.map((link, index) => (
              <li key={link.id} style={{ listStyle: index === 0 && 'none' }}>
                <a href={link.href} target="_blank" rel="noopener noreferrer">
                  {link.name}
                </a>
              </li>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewModal;
