import ReactGA from 'react-ga';
ReactGA.initialize(process.env.REACT_APP_GA);
// ReactGA.initialize('UA-27709921-2');

ReactGA.set({ checkProtocolTask: null });
ReactGA.set({ checkStorageTask: null });
ReactGA.set({ historyImportTask: null });

export const gTagPageView = (type, value) => {
  ReactGA.pageview(value);
};

export const gTag = (type, value) => {
  ReactGA.event({
    category: type,
    action: value,
  });
};

export const setPlatformOnGA = () => {
  let client = window.device
    ? `${window.device.platform} ${window.device.version} ${window.device.model}`
    : window.navigator.userAgent;
  if (!window.device) {
    client = client.indexOf('Electron') > -1 ? `DESKTOP_${window.navigator.platform} ${client}` : 'web';
  }
  ReactGA.set({ dimension1: client });
  // window.setPlatForm(client)
};
