import React from 'react';
import { withRouter } from 'react-router-dom';

import { targetDetailsAction } from '../../../constant';
import icons from '../../../assets/img/icons.svg';
import { gTag } from '../../../helpers';

/**
 * @description component for Action List on slider target detail
 * @param {function} $toggleAction - perform action on click of complete target, edit target, hold target buttons
 * @param {string} $activeAction - complete/edit/delete
 * @param {function} $cancel
 */
const TargetDetailActionList = ({ toggleAction, activeAction, cancel, match: { params } }) => {
  const tileId = params && params.id;

  return (
    <div className="block action-list">
      <ul className={`strip ${activeAction && 'active'}`}>
        <li
          className="action-edit"
          onClick={(e) => {
            toggleAction(e, targetDetailsAction.edit);
            gTag('Click', `Edit Target: ${tileId}`);
          }}
        >
          <span className="next-action edit">
            <i className="action">
              <svg className="icon nav-icon">
                <use xlinkHref={`${icons}#button-edit`} />
              </svg>
              Edit
            </i>
          </span>
        </li>
        <li
          className="action-complete"
          onClick={(e) => {
            toggleAction(e, targetDetailsAction.complete);
            gTag('Click', `Target Complete: ${tileId}`);
          }}
        >
          <span className="next-action complete">
            <i className="action">
              <svg className="icon nav-icon">
                <use xlinkHref={`${icons}#button-complete`} />
              </svg>
              Won
            </i>
          </span>
        </li>
        <li
          className="action-lost"
          onClick={(e) => {
            toggleAction(e, targetDetailsAction.lost);
            gTag('Click', `Lost Target: ${tileId}`);
          }}
        >
          <span className="next-action lost">
            <i className="action">
              <svg className="icon nav-icon">
                <use xlinkHref={`${icons}#button-lost`} />
              </svg>
              Lost
            </i>
          </span>
        </li>
        <li
          className="action-delete"
          onClick={(e) => {
            toggleAction(e, targetDetailsAction.delete);
            gTag('Click', `Remove Target: ${tileId}`);
          }}
        >
          <span className="next-action delete">
            <i className="action">
              <svg className="icon nav-icon">
                <use xlinkHref={`${icons}#button-delete`} />
              </svg>
              Remove
            </i>
          </span>
        </li>
        <li className="action-cancel" onClick={cancel}>
          <span className="next-action cancel">
            <i className="action">
              <svg className="icon nav-icon">
                <use xlinkHref={`${icons}#button-cancel`} />
              </svg>
              Cancel
            </i>
          </span>
        </li>
      </ul>
    </div>
  );
};

export default withRouter(TargetDetailActionList);
