import React, { useState } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import moment from 'moment';

import icons from '../../../assets/img/icons.svg';
import { dummyFiles, DueDateFormatForAPI } from '../../../constant';
import { downloadContent } from '../../../helpers';

import { addDays, lastDayOfQuarter, startOfDay, endOfMonth, addMonths, addQuarters, endOfWeek } from 'date-fns';
import { gTag } from '../../../helpers';

const { stringify } = queryString;

/**
 * @description Componet to download the report as pdf and exl in target screen on click of control
 * @param {object} $targetSubscriptions - {features, id, name, on}
 * @param {boolean} $isPeopleTarget - true/false
 * @param {string} $uid
 */

const ReportDownload = ({ targetSubscriptions, isPeopleTarget, uid }) => {
  const {
    features: {
      report: { data },
    },
  } = targetSubscriptions;
  const yesterday = addDays(new Date(), -1);

  const [selectionRange, setSelectionRange] = useState({
    startDate: startOfDay(addDays(yesterday, -30)),
    endDate: yesterday,
  });

  const prepareDownloadUrl = (fileType) => {
    let downloadUrl = '';
    const query = {};
    if (selectionRange) {
      query.start = moment(selectionRange.startDate).format(DueDateFormatForAPI);
      query.end = moment(selectionRange.endDate).format(DueDateFormatForAPI);
    }
    const stringifyArgs = stringify(query);
    // find indexof userId
    let id = data[fileType].indexOf('id=');
    // dynamic userid if isPeopleTarget true
    let userId = data[fileType].substring(id);
    let url = isPeopleTarget ? data[fileType].replace(userId, 'id=' + uid) : data[fileType];
    downloadUrl = url + '&' + stringifyArgs;
    return downloadUrl;
  };

  const handleDownload = (fileType) => {
    let url = prepareDownloadUrl(fileType);
    downloadContent(url, 'Generating Activity Report...');
  };

  const handleDate = (event) => {
    setSelectionRange({
      startDate: event.target.value,
      endDate: yesterday,
    });
  };
  return (
    <div className="info-block report">
      <fieldset>
        <legend>
          <svg className="icon nav-icon">
            <use xlinkHref={`${icons}#reports`} />
          </svg>
          Activity Report
        </legend>
        <p>Select a date range and download:</p>
        <ul className="strip">
          <li>
            <select onChange={handleDate} defaultValue={startOfDay(addDays(yesterday, -30))}>
              <option value={endOfWeek(addDays(yesterday, -7))}>Last Week</option>
              <option value={endOfMonth(addMonths(yesterday, -1))}>Last Month</option>
              <option value={lastDayOfQuarter(addQuarters(yesterday, -1))}>Last Quarter</option>
              <option value={startOfDay(addDays(yesterday, -7))}>Last 7 Days</option>
              <option value={startOfDay(addDays(yesterday, -30))}>Last 30 Days</option>
              <option value={startOfDay(addDays(yesterday, -90))}>Last 90 Days</option>
            </select>
          </li>
          {targetSubscriptions.features.report.data.pdf && (
            <li onClick={() => gTag('Download Report', 'PDF')}>
              <a className="button download" onClick={() => handleDownload('pdf')}>
                <svg className="icon nav-icon">
                  <use xlinkHref={`${icons}#button-download`} />
                </svg>
                <span>As</span> PDF
              </a>
            </li>
          )}
          {targetSubscriptions.features.report.data.csv && (
            <li onClick={() => gTag('Download Report', 'CSV')}>
              <a className="button download" onClick={() => handleDownload('csv')}>
                <svg className="icon nav-icon">
                  <use xlinkHref={`${icons}#button-download`} />
                </svg>
                <span>As</span> Excel
              </a>
            </li>
          )}
        </ul>
      </fieldset>
    </div>
  );
};

// map state to props
const mapStateToProps = (state) => ({
  targetSubscriptions:
    state.session.user && state.session.user.Subscriptions
      ? state.session.user.Subscriptions.targets
      : { features: { report: { data: dummyFiles } } },
});

export default connect(mapStateToProps)(ReportDownload);
