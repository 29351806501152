import React, { useState } from 'react';
import { timeHumanize, formatNumber } from '../../../helpers';
import Table from './Table.component';
import icons from '../../../assets/img/icons.svg';

// show name initials in uppercase
const Name = ({ value, className }) => {
  // add person/group/org
  let initials = '';
  if (className && className.indexOf('person') > -1) {
    const parts = value.split(' ');
    initials = parts[0].charAt(0).toUpperCase() + parts[1].charAt(0).toUpperCase();
  }
  return (
    <dl className={`handle ${className}`}>
      <dt className="avatar">
        {initials.length > 0 && <>{initials}</>}
        {initials.length === 0 && (
          <svg>
            <use xlinkHref={`${icons}#org-${className}`} />
          </svg>
        )}
      </dt>
      <dd className="name">{value}</dd>
    </dl>
  );
};
const LatestActivity = ({ value }) => timeHumanize(value.seconds);

const Potential = ({ original, value }) => {
  // handle the difference between firm/group and member
  const v = undefined == value ? original.value : value;
  // make sure we don't get an error (header?)
  if (undefined == v) return '';
  // if we made it here, we have a value we can display
  return (
    <div>
      <small
        className="valuebars poptip"
        data-tip={`Average Active Targets: ${original.average_active}`}
        data-for="tip"
      >
        <b className={`L p${v.L.pos}`}>{v.L.count}</b>
        <b className={`M p${v.M.pos}`}>{v.M.count}</b>
        <b className={`H p${v.H.pos}`}>{v.H.count}</b>
      </small>
    </div>
  );
};

// show formatted revenue
const Revenue = ({ value }) => (value ? `$${formatNumber(+value.replace(/,/g, ''))}` : ``);

/**
 * @description This component render the overview table in viewer tab
 * @param {object} $groups
 * @param {Array} $headRows
 * @param {function} $addLineGraph
 * @param {function} $resetGraph
 */
const OverviewTable = ({ groups, headRows, addLineGraph, resetGraph, group }) => {
  const [activeRow, setactiveRow] = useState();

  const rowType = groups ? 'group' : 'person';
  // memoised overview data to show
  const data = React.useMemo(() => {
    if (groups) {
      setactiveRow(null);
      return Object.entries(groups).map((group) => group[1]);
    } else if (group) {
      setactiveRow(null);
      return group.members;
    }
    return [];
  }, [groups, group]);

  // table columns
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ value, row: { original } }) => <Name value={value} className={rowType} original={original} />,
        className: 'text name',
        colGroup: 'now',
        colGroupClass: 'name text',
        tooltip: 'Click on the rows below to see them graphed above.',
      },
      {
        Header: (
          <>
            Latest
            <br />
            Activity
          </>
        ),
        accessor: 'latest_activity',
        id: 'latest_activity',
        Cell: ({ value }) => <LatestActivity value={value} />,
        className: 'latest-activity',
        colGroup: 'now',
        colGroupClass: 'activity',
        sortType: (rowA, rowB, _columnId, _desc) => {
          return rowA.values.latest_activity.seconds - rowB.values.latest_activity.seconds;
        },
        tooltip: 'The time of the most recent activity by a User. This value is not affected by the date range.',
      },
      {
        Header: (
          <>
            Current
            <br />
            Score
          </>
        ),
        accessor: 'current_score',
        id: 'current_score',
        className: 'current-score',
        colGroup: 'now',
        colGroupClass: 'curscore',
        tooltip: 'The current Score. This value is not affected by the date range.',
      },
      {
        Header: (
          <>
            Average
            <br />
            Score
          </>
        ),
        accessor: 'average_score',
        id: 'average_score',
        className: 'average-score',
        colGroup: 'data',
        colGroupClass: 'avgscore start',
        tooltip: 'The average Score over this date range.',
      },
      {
        Header: (
          <>
            Steps
            <br />
            Taken
          </>
        ),
        accessor: 'total_steps',
        id: 'total_steps',
        className: 'steps-taken',
        colGroup: 'data',
        colGroupClass: 'steps',
        tooltip: 'The total number of Steps taken during this date range.',
      },
      {
        Header: (
          <>
            Companies
            <br />
            Pursued
          </>
        ),
        accessor: 'clients_pursued',
        id: 'clients_pursued',
        className: 'clients-pursued',
        colGroup: 'data',
        colGroupClass: 'clients',
        tooltip: 'The total number of Companies pursued during this date range.',
      },
      {
        Header: (
          <>
            Average Potential
            <br />
            <small className="value">
              <b className="L">LOW</b> | <b className="M">MED</b> | <b className="H">HIGH</b>
            </small>
          </>
        ),
        accessor: 'val2',
        id: 'average_low',
        disableSortBy: true,
        className: 'average-potential',
        Cell: ({ row: { original }, value }) => <Potential original={original} value={value} />,
        colGroup: 'data',
        colGroupClass: 'potential',
        tooltip: 'The average potential Value of each Target during this date range, based on Low / Medium / High.',
      },
      {
        Header: (
          <>
            Close Ratio <br />
            <small>
              <b>Created/Won</b>
            </small>
          </>
        ),
        accessor: 'total_created',
        id: 'total_created',
        disableSortBy: true,
        Cell: ({ row: { original }, value }) => (
          <>
            {value} / {original.total_closed}
          </>
        ),
        className: 'close-ratio',
        colGroup: 'data',
        colGroupClass: 'closeratio',
        tooltip: 'The total number of Targets created and completed during this date range.',
      },
      {
        Header: (
          <>
            Projected
            <br />
            Revenue
          </>
        ),
        accessor: 'projected_revenue',
        id: 'projected_revenue',
        Cell: ({ value }) => <Revenue value={value} />,
        className: 'projected-revenue numeric',
        colGroup: 'data',
        colGroupClass: 'revenue numeric',
        tooltip:
          'The Projected Revenue recorded over Pursuing Targets in this date range. Revenue is entered by the User when creating or editing a Target.',
      },
      {
        Header: (
          <>
            Won
            <br />
            Revenue
          </>
        ),
        accessor: 'total_revenue',
        id: 'total_revenue',
        Cell: ({ value }) => <Revenue value={value} />,
        className: 'total-revenue numeric',
        colGroup: 'data',
        colGroupClass: 'revenue numeric',
        tooltip:
          'The Won Revenue recorded over all Targets in this date range. Revenue is entered by the User when editing or completing a Target.',
      },
    ],
    [rowType],
  );

  // onclick on a row reset the graph
  const hrows = headRows
    .map((row) => {
      if (row) {
        return {
          props: {
            onClick: () => {
              setactiveRow(null);
              resetGraph();
            },
            className: 'freeze active',
          },
          columns: [
            {
              value: <Name value={row.name} className={row.type} />,
              className: 'text',
            },
            {
              value: <LatestActivity value={row.latest_activity} />,
            },
            {
              value: <>{row.current_score}</>,
            },
            {
              value: <>{row.average_score}</>,
            },
            {
              value: <>{row.total_steps}</>,
            },
            {
              value: <>{row.clients_pursued}</>,
            },
            {
              value: <Potential original={{ average_active: row.average_active }} value={row.value} />,
            },
            {
              value: (
                <>
                  {row.total_created} / {row.total_closed}
                </>
              ),
            },
            {
              value: <Revenue value={row.projected_revenue} />,
              className: 'numeric',
            },
            {
              value: <Revenue value={row.total_revenue} />,
              className: 'numeric',
            },
          ],
        };
      } else {
        return { columns: [] };
      }
    })
    .filter((anyValue) => typeof anyValue !== 'undefined');

  const initialState = {
    sortBy: React.useMemo(() => [{ id: 'name', desc: false }], []),
  };
  return (
    <section className="panel table overview">
      <div className="scroller horz">
        <Table
          data={data}
          columns={columns}
          headRows={hrows}
          initialState={initialState}
          getRowProps={(row) => ({
            onClick: () => {
              if (row.id !== activeRow) {
                setactiveRow(row.id);
                const { gid, uid, name } = row.original;
                addLineGraph({ id: gid || uid, type: rowType, name });
              }
            },
            className: `${row.id === activeRow ? 'active' : ''}`,
          })}
        />
      </div>
    </section>
  );
};

export default React.memo(OverviewTable);
