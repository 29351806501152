import { SHOW_FILTER_MODAL, HIDE_FILTER_MODAL, CLEAR_FILTER } from '../../constant';

export const showFilterModal = () => (dispatch) => {
  dispatch({
    type: SHOW_FILTER_MODAL,
    filterModalProps: { open: true },
  });
};

export const hideFilterModal = () => (dispatch) => {
  dispatch({
    type: HIDE_FILTER_MODAL,
    filterModalProps: { open: false },
  });
};

export const clearFilter = () => (dispatch) => {
  dispatch({
    type: CLEAR_FILTER,
  });
};
