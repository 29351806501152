import React, { useState } from 'react';
import '../styles.css';
import Input from '../../input';
import { useDispatch, useSelector } from 'react-redux';
import { createDiffusionList } from '../../../../actions';

const regExp = /^(?=.*[a-zA-Z\d].*)[a-zA-Z\d:,;-\s]{1,}$/;

const NewDiffusionList = ({ handleClose }) => {
  const newDiffusionList = useSelector((state) => state.insightDashboard.newDiffusionList);
  const [inputValue, setInputValue] = useState('');
  const [errors, setErrors] = useState({ new: false });
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  const handleClearErrors = (value) => {
    setErrors({ new: value });
  };

  const checkErrors = (value) => {
    if (!regExp.test(value) && value.trim() !== '') {
      setErrorMessage('Name can only include letters, numbers or special characters: ": , ;"');
      setErrors({ new: true });
    } else if (value.length > 60) {
      setErrorMessage('Maximum length is 60 characters');
      setErrors({ new: true });
    } else {
      setErrors({ new: false });
      setErrorMessage('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!regExp.test(inputValue) || inputValue > 60) {
      checkErrors(inputValue);
      return;
    }
    setLoading(true);

    const data = {
      name: inputValue,
      user_ids: newDiffusionList.map(({ id }) => Number(id)),
    };

    try {
      await dispatch(createDiffusionList(data));
      handleClose();
    } catch (error) {
      setErrors({ new: true });
      setErrorMessage(error?.message);
    }
    setLoading(false);
  };

  return (
    <form className="creation-form" onSubmit={handleSubmit}>
      <Input
        value={inputValue}
        name="new"
        errors={errors}
        handleChange={handleInputChange}
        clearError={handleClearErrors}
        errorMessage={errorMessage || 'Name is required'}
        label="Name"
      />

      <button className="distribution-list-btn" type="submit">
        {loading ? <span className="loader" /> : 'Create Distribution List'}
      </button>
    </form>
  );
};

export default NewDiffusionList;
