import { invoke } from '../../helpers';
import { API_URLS, API_METHODS } from '../../constant';

/**
 * Viewer Pipeline people target(only Online)
 */
export const getPeopleTargetsAPI = (status, range, userId) => {
  const options = { proxyUser: userId };
  const params = { status, ...range, userid: userId };
  const url = API_URLS.TILES;
  return invoke({ url, type: API_METHODS.GET, params }, {}, options);
};

/**
 * Viewer Pipeline edit people targets
 */
export const createPeopleTargetsAPI = (data, userId) => {
  const options = { proxyUser: userId };
  const url = API_URLS.TILES;
  return invoke({ url, type: API_METHODS.POST }, data, options);
};

/**
 * Viewer Pipeline edit people targets
 */
export const editPeopleTargetsAPI = (targetId, data, userId) => {
  const options = { proxyUser: userId };
  const url = `${API_URLS.TILES}/${targetId}`;
  return invoke({ url, type: API_METHODS.PUT }, data, options);
};

/**
 * Viewer Pipeline change people targets status
 */
export const changePeopleTargetStatusAPI = (targetId, data, action, userId) => {
  const options = { proxyUser: userId };
  const url = `${API_URLS.TILES}/${targetId}?action=${action}`;
  return invoke({ url, type: API_METHODS.POST }, data, options);
};

/**
 * Viewer Pipeline create task for people targets
 */
export const createPeopleTaskAPI = (data, userId) => {
  const options = { proxyUser: userId };
  return invoke({ url: API_URLS.TASKS, type: API_METHODS.POST }, data, options);
};

export const submitPeopleRevenue = (data, userId) => {
  const options = { proxyUser: userId };
  return invoke({ url: API_URLS.SETYTDREVENUE, type: API_METHODS.POST }, data, options);
};
export const rejectPeopleSharedTargetAPI = (data, userId) => {
  const options = { proxyUser: userId };
  return invoke({ url: `${API_URLS.SHARE}/${data.share_id}${API_URLS.REJECT}`, type: API_METHODS.POST }, data, options);
};
export const sharePeopleTargetAPI = (payload, shareWith, uid) => {
  const options = { proxyUser: uid };
  return invoke(
    {
      url: `${API_URLS.TILES}/${payload.id}${API_URLS.SHAREWITH}${shareWith.id}`,
      type: API_METHODS.POST,
    },
    payload,
    options,
  );
};
export const revokePeopleTargetAPI = (payload, shareWith, uid) => {
  const options = { proxyUser: uid };
  return invoke(
    {
      url: `${API_URLS.SHARE}/${payload.share_id}${API_URLS.REVOKE}`,
      type: API_METHODS.POST,
    },
    payload,
    options,
  );
};
export const acceptPeopleSharedTargetAPI = (shareobj, uid) => {
  const options = { proxyUser: uid };
  return invoke(
    {
      url: `${API_URLS.SHARE}/${shareobj.share_id}${API_URLS.ACCEPT}`,
      type: API_METHODS.POST,
    },
    shareobj,
    options,
  );
};
