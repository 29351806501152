import React from 'react';
import { Formik, Field } from 'formik';
import NumberFormat from 'react-number-format';
import * as Yup from 'yup';

import { required } from '../../../helpers';
import { MESSAGES } from '../../../constant';

const completeTarget = Yup.object().shape({
  revenue: Yup.string().required(MESSAGES.noRevenue),
});

let CompleteTargetForm = (props) => {
  const { company, contact, index, handleSubmit, init } = props;
  return (
    <div className={`followup revenue ${props.active && 'active'}`}>
      {props.active && (
        <>
          <Formik
            initialValues={init}
            enableReinitialize="true"
            onSubmit={(values, _actions) => {
              handleSubmit(values);
            }}
            validationSchema={completeTarget}
            setFieldValue
            render={(props) => (
              <form onSubmit={props.handleSubmit}>
                <p>Enter the amount of revenue estimated from this objective.</p>
                <div className="yesno">
                  <div className="radio">
                    <Field
                      name="revenueOpts"
                      type="radio"
                      value="-1"
                      id={`revenue-intangible${index}`}
                      validate={required}
                      onChange={(e) => {
                        props.handleChange(e);
                        props.setFieldValue('revenue', '-1');
                        props.setFieldTouched('revenue', false);
                      }}
                    />
                    <label htmlFor={`revenue-intangible${index}`}>
                      <span>Intangible Value</span>
                    </label>
                    <div className="check" />
                  </div>
                  <div className="radio">
                    <Field
                      name="revenueOpts"
                      type="radio"
                      value="money"
                      id={`revenue-amount${index}`}
                      validate={required}
                      onChange={(e) => {
                        props.handleChange(e);
                        props.setFieldValue('revenue', '');
                      }}
                    />
                    <label
                      htmlFor={`revenue-amount${index}`}
                      onClick={(e) => {
                        props.handleChange(e);
                        props.setFieldValue('revenue', '');
                      }}
                    >
                      <span>Estimate your Won Revenue</span>
                    </label>
                    <div className="check" />
                    {props.values.revenueOpts === 'money' && (
                      <>
                        <NumberFormat
                          id={`revenue${index}`}
                          name="revenue"
                          placeholder="Enter revenue ($)"
                          isNumericString
                          prefix="$"
                          thousandSeparator
                          fixedDecimalScale
                          decimalScale={2}
                          value={props.values.revenue}
                          autoFocus
                          maxLength={13}
                          onValueChange={(vals) => {
                            props.setFieldValue('revenue', vals.value);
                          }}
                        />
                        {props.errors.revenue && props.touched.revenue && (
                          <div className="error-msg">{props.errors.revenue}</div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <label className="custom-checkbox">
                  <Field
                    name="dupe"
                    component="input"
                    type="checkbox"
                    className="input-lg"
                    onChange={() => {
                      props.setFieldValue('dupe', !props.values.dupe, false);
                    }}
                  />
                  <span></span>
                </label>
                <label htmlFor={`dupe${index}`}>
                  Pursue another objective with <b>{company}</b> <b>{contact}</b>?
                </label>
                <button className="action next" type="submit">
                  <span>Submit</span>
                </button>
              </form>
            )}
          />
        </>
      )}
    </div>
  );
};
export default CompleteTargetForm;
