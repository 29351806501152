import React from 'react';

import icons from '../../../assets/img/icons.svg';
import '../../../assets/css/task-list.css';
import { calculateDays } from '../../../helpers';
import { taskType } from '../../../constant';

/**
 * @description component for Activity tabs on slider target detail
 * @param {Array} tasks - detail of the target
 */
const TargetDetailActivity = ({ tasks }) => {
  const completedTasks = tasks.filter((task) => task.done === taskType.done);
  const currentTask = tasks.filter((task) => task.done === taskType.incomplete);

  return (
    <div className="activity">
      {currentTask.length ? (
        <>
          <h4>Current Steps:</h4>
          <ol className="task-list">
            {currentTask.map((task, index) => (
              <li className="incomplete" key={index}>
                <div className="avatar">
                  <b>
                    <svg className="icon">
                      <use xlinkHref={`${icons}#task-incomplete`} />
                    </svg>
                  </b>
                </div>
                <div className="description">
                  <span>{task.description}</span>
                </div>
                <div className="info">
                  <span>
                    <p>
                      <time className="due" dateTime={task.due}>
                        {calculateDays(task.due)}
                      </time>
                    </p>
                    <p>
                      {' '}
                      <b>by</b> {task.first_name} {task.last_name}
                    </p>
                  </span>
                </div>
              </li>
            ))}
          </ol>
        </>
      ) : (
        <></>
      )}
      {completedTasks.length > 0 && (
        <>
          <h4>Completed Steps:</h4>
          <ol className="task-list">
            {completedTasks.map((task, index) => (
              <li className="done" key={index}>
                <div className="avatar">
                  <b>
                    <svg className="icon">
                      <use xlinkHref={`${icons}#task-done`} />
                    </svg>
                  </b>
                </div>
                <div className="description">
                  <span>{task.description}</span>
                </div>
                <div className="info">
                  <span>
                    <p>
                      <time className="completed" dateTime={task.completed}>
                        {calculateDays(task.completed)}
                      </time>
                    </p>
                    <p>
                      {' '}
                      <b>by</b> {task.first_name} {task.last_name}
                    </p>
                  </span>
                </div>
              </li>
            ))}
          </ol>
        </>
      )}
    </div>
  );
};

export default TargetDetailActivity;
