import React from 'react';

import icons from '../../../assets/img/icons.svg';

let FilterTargetForm = (props) => {
  const { standing, value, type, filterBy, keyword, sortBy = { type: 'due', isAscending: false }, sortData } = props;

  /**
   * @description filter targets
   * @param {object} $filterBy - {filterBy, value}
   */
  const filterTarget = (filterBy) => {
    if (filterBy) props.filterTarget(filterBy);
  };

  return (
    <>
      <div className="info-block search">
        <fieldset>
          <legend>
            <svg className="icon">
              <use xlinkHref={`${icons}#action-search`} />
            </svg>
            Search
          </legend>
          <div className="search">
            <input
              type="text"
              placeholder="Search"
              className="clearable kwrd-srch"
              onChange={props.updateSearchedKeyword}
              value={props.keyword}
              onKeyUp={props.searchData}
            />
            <svg className="icon">
              <use xlinkHref={`${icons}#action-search`} />
            </svg>
          </div>
        </fieldset>
      </div>

      <div className="info-block sort">
        <fieldset>
          <legend>
            <svg className="icon">
              <use xlinkHref={`${icons}#action-sort`} />
            </svg>
            Sort Targets
          </legend>

          <ul className="button-bar ui full-width sort">
            <li
              className={sortBy.type === 'due' ? 'active has-marker' : 'has-marker'}
              onClick={() => sortData({ type: 'due', isAscending: !sortBy.isAscending })}
            >
              <a href={undefined}>
                Due Date{' '}
                {sortBy.type === 'due' && (
                  <label className="marker">
                    <svg className="icon">
                      <use xlinkHref={`${icons}${sortBy.isAscending ? '#action-up' : '#action-down'}`} />
                    </svg>
                  </label>
                )}
              </a>
            </li>
            <li
              className={sortBy.type === 'company' ? 'active has-marker' : 'has-marker'}
              onClick={() => sortData({ type: 'company', isAscending: !sortBy.isAscending })}
            >
              <a href={undefined}>
                Company{' '}
                {sortBy.type === 'company' && (
                  <label className="marker">
                    <svg className="icon">
                      <use xlinkHref={`${icons}${sortBy.isAscending ? '#action-up' : '#action-down'}`} />
                    </svg>
                  </label>
                )}
              </a>
            </li>
            <li
              className={sortBy.type === 'contact' ? 'active has-marker' : 'has-marker'}
              onClick={() => sortData({ type: 'contact', isAscending: !sortBy.isAscending })}
            >
              <a href={undefined}>
                Contact{' '}
                {sortBy.type === 'contact' && (
                  <label className="marker">
                    <svg className="icon">
                      <use xlinkHref={`${icons}${sortBy.isAscending ? '#action-up' : '#action-down'}`} />
                    </svg>
                  </label>
                )}
              </a>
            </li>
          </ul>
        </fieldset>
      </div>

      <div className="info-block filter">
        <fieldset>
          <legend>
            <svg className="icon">
              <use xlinkHref={`${icons}#action-filter`} />
            </svg>
            Filter
          </legend>

          <ul className="button-bar ui full-width due">
            <li
              onClick={() => filterTarget({ filterBy: 'bucket', value: 'overdue' })}
              className={`${filterBy.value === 'overdue' ? 'active has-marker' : 'has-marker'}`}
            >
              <a href={undefined}>
                Overdue <label className="marker overdue">{standing.overdue}</label>
              </a>
            </li>
            <li
              onClick={() => filterTarget({ filterBy: 'bucket', value: 'soon' })}
              className={`${filterBy.value === 'soon' ? 'active has-marker' : 'has-marker'}`}
            >
              <a href={undefined}>
                Due Soon <label className="marker soon">{standing.soon}</label>
              </a>
            </li>
            <li
              onClick={() => filterTarget({ filterBy: 'bucket', value: 'later' })}
              className={`${filterBy.value === 'later' ? 'active has-marker' : 'has-marker'}`}
            >
              <a href={undefined}>
                Due Later <label className="marker later">{standing.later}</label>
              </a>
            </li>
          </ul>

          <ul className="button-bar ui full-width value">
            <li
              onClick={() => filterTarget({ filterBy: 'value', value: 'L' })}
              className={`${filterBy.value === 'L' ? 'active has-marker' : 'has-marker'}`}
            >
              <a href={undefined}>
                Low Value <label className="marker">{value.L}</label>
              </a>
            </li>
            <li
              onClick={() => filterTarget({ filterBy: 'value', value: 'M' })}
              className={`${filterBy.value === 'M' ? 'active has-marker' : 'has-marker'}`}
            >
              <a href={undefined}>
                Medium Value <label className="marker">{value.M}</label>
              </a>
            </li>
            <li
              onClick={() => filterTarget({ filterBy: 'value', value: 'H' })}
              className={`${filterBy.value === 'H' ? 'active has-marker' : 'has-marker'}`}
            >
              <a href={undefined}>
                High Value <label className="marker">{value.H}</label>
              </a>
            </li>
          </ul>

          <ul className="button-bar ui full-width type">
            <li
              onClick={() => filterTarget({ filterBy: 'type', value: 'client' })}
              className={`${filterBy.value === 'client' ? 'active has-marker' : 'has-marker'}`}
            >
              <a href={undefined}>
                Client <label className="marker">{type.client}</label>
              </a>
            </li>
            <li
              onClick={() => filterTarget({ filterBy: 'type', value: 'prospect' })}
              className={`${filterBy.value === 'prospect' ? 'active has-marker' : 'has-marker'}`}
            >
              <a href={undefined}>
                Prospect <label className="marker">{type.prospect}</label>
              </a>
            </li>
            <li
              onClick={() => filterTarget({ filterBy: 'type', value: 'refsource' })}
              className={`${filterBy.value === 'refsource' ? 'active has-marker' : 'has-marker'}`}
            >
              <a href={undefined}>
                Referral Source <label className="marker">{type.refsource}</label>
              </a>
            </li>
          </ul>

          <ul className="button-bar ui full-width reset">
            <li
              onClick={() => filterTarget({ filterBy: 'all', value: 'all' })}
              className={`show-all ${filterBy.value === 'all' && !keyword ? 'active has-marker' : 'has-marker'}`}
            >
              <a href={undefined}>
                <svg className="icon">
                  <use xlinkHref={`${icons}#action-reset`} />
                </svg>{' '}
                Show All Targets
                {`${filterBy.value !== 'all' ? ' (Reset)' : ''}`}
                <label className="marker">{standing.total}</label>
              </a>
            </li>
          </ul>
        </fieldset>
      </div>
    </>
  );
};

export default FilterTargetForm;
