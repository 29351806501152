import React from 'react';
import { connect } from 'react-redux';

import { FilterTargetForm } from '../../../components';
import { hideFilterModal, getSearchedTargets } from '../../../actions';
import getCountTargets from '../../../selectors/targets/getCountTargets.selector';
import icons from '../../../assets/img/icons.svg';
import { FILTER_DATA, SORT_DATA, ENTERKEY, ESCAPEKEY, ALL } from '../../../constant';

// container for filtering targets options
class FilterTarget extends React.Component {
  state = {
    isFilterOpen: false,
    filterBy: {},
    keyword: '',
  };

  // called when any props has changed
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState(
        {
          isFilterOpen: this.props.filterModalProps.open,
          filterBy: this.props.filterModalProps.filterBy,
          keyword: this.props.filterModalProps.keyword,
        },
        () => {
          if (this.state.isFilterOpen) {
            document.addEventListener('click', this.handleOutsideClick, true);
          }
        },
      );
    }
  }

  // close filter dialog if click outside of dialog
  handleOutsideClick = (e) => {
    if (!this?.node?.contains(e.target)) {
      this.hideDialog();
    }
  };

  hideDialog = () => {
    document.removeEventListener('click', this.handleOutsideClick, true);
    window.cordova && window.Keyboard && window.Keyboard.hide();
    this.props.hideDialog();
  };

  // Search targets for the input provided
  searchTargets = (e) => {
    const keyword = e.target.value;
    this.props.searchData(keyword);
    if (e.keyCode === ESCAPEKEY || e.keyCode === ENTERKEY) {
      this.hideDialog();
    }
  };

  /**
   * update the keyword in state input
   */
  updateSearchedKeyword = (e) => {
    const keyword = e.target.value;
    this.setState({ keyword });
  };

  //Filter targets for option selected
  filterData = (e) => {
    this.props.filterData(e);
    // reset the serach if click on all target
    if (e.filterBy === ALL && this.state.keyword) {
      const value = { target: { value: '' } };
      this.updateSearchedKeyword(value); // reset the state
      this.searchTargets(value); // update the search
    }
    document.removeEventListener('click', this.handleOutsideClick, false);
  };

  sortData = (e) => {
    document.removeEventListener('click', this.handleOutsideClick, false);
    this.props.sortData(e);
  };

  // Render function to render html
  render() {
    return (
      <div className={`dialog ${this.state.isFilterOpen ? 'active' : ''} filter-dialog`} id="filter">
        <div className="box">
          <div className="close-dialog" onClick={this.hideDialog}>
            <svg className="icon nav-icon">
              <use xlinkHref={`${icons}#action-close`} />
            </svg>
          </div>
          <div className="inner" ref={(node) => (this.node = node)}>
            <FilterTargetForm
              // filter
              filterBy={this.state.filterBy}
              standing={this.props.standing}
              value={this.props.value}
              type={this.props.type}
              filterTarget={(e) => this.filterData(e)}
              // sort
              sortBy={this.props.filterModalProps.sortBy}
              sortData={this.sortData}
              // search
              keyword={this.state.keyword}
              updateSearchedKeyword={this.updateSearchedKeyword}
              searchData={this.searchTargets}
            />
          </div>
        </div>
      </div>
    );
  }
}

// Map redux state to props
const mapStateToProps = (state, ownProps) => {
  return {
    ...state.filterTarget,
    ...state.sortTarget,
    ...getCountTargets(state, ownProps.isPeopleTarget, ownProps.status),
  };
};

// Map redux dispatch to props
const mapDispatchToProps = (dispatch) => ({
  sortData: (sortBy) => {
    dispatch({
      type: SORT_DATA,
      sortBy,
    });
  },
  filterData: (filterBy) => {
    if (!filterBy) filterBy = 'all';
    dispatch({
      type: FILTER_DATA,
      filterModalProps: { filterBy },
    });
  },
  hideDialog: () => {
    dispatch(hideFilterModal());
  },
  searchData: (keyword) => {
    dispatch(getSearchedTargets(keyword));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterTarget);
