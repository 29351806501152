import React, { useEffect, useMemo, useState } from 'react';

import { MESSAGES, loaderArray } from '../../../constant';
import TileLoader from '../../../components/shared/TileLoader';
import { gTag, isMobile, sortAlphabetically } from '../../../helpers';
import { capitalize } from 'lodash';
import { InsightCard } from '../../../components/insights/card/Card.component';
import hamburger from '../../../assets/img/hamburger.svg';
import { ReactComponent as AddIcon } from '../../../assets/img/add.svg';
import './styles.css';
import { CREATE_MODALS } from '../../../constant/insights';
import { useDispatch, useSelector } from 'react-redux';
import { editContent, editVideoInsight, removeSection, sectionToBeDeleted } from '../../../actions';
import toaster from '../../../services/shared/toaster.service';
import CreateModal from '../../../components/common/createModal';

/**
 * @description This component render the list of lessons
 * @param {object} $lessons - {done, percent, seconds, when}
 * @param {boolean} $loading - true/false
 */
const InsightPodcast = ({ loading, setModalType, setEditContent, setNewElementModalOpen }) => {
  const [show, showHide] = useState({});
  const [showAll, setShowAll] = useState(null);
  const [isDragging, setIsDragging] = useState(null);
  const [loadingDrag, setLoadingDrag] = useState(false);
  const [width, setWidth] = useState(null);
  const [showAddSection, setShowAddSection] = useState(true);
  const sections = useSelector((state) => state.insightDashboard.sections);
  const content = useSelector((state) => state.insightDashboard.content);
  const filteredContent = useSelector((state) => state.insightDashboard.filteredInsights);
  const filteredSections = useSelector((state) => state.insightDashboard.filteredSections);

  const insights = filteredContent || content;
  const insightSections = filteredSections || sections;
  const draggedInsight = useSelector((state) => state.insightDashboard.draggedInsight);

  const dispatch = useDispatch();

  const newBlogs = useMemo(() => {
    const initialValue = {};
    return insightSections.reduce((obj, item) => {
      return { ...obj, [item.name]: item.content };
    }, initialValue);
  }, [insightSections]);

  const sectionsWithContent = useMemo(() => {
    return insightSections?.map((section) => ({
      ...section,
      content: insights?.filter((insight) => insight?.section_id === section?.id),
    }));
  }, [insightSections, insights]);

  const toggleShowClose = (blogs, status) =>
    Object.keys(blogs).reduce((prevValue, currValue) => {
      return {
        ...prevValue,
        [currValue]: status,
      };
    }, {});

  useEffect(() => {
    let show = localStorage.getItem('showPodcast') && JSON.parse(localStorage.getItem('showPodcast'));
    let showAll = localStorage.getItem('showAllPodcast') && JSON.parse(localStorage.getItem('showAllPodcast'));
    showAll && setShowAll(showAll);
    show && showHide(show);
    if (showAll) {
      showHide(toggleShowClose(newBlogs, true));
    } else if (show && !showAll) {
      showHide(show);
    } else if (!showAll) {
      showHide(toggleShowClose(newBlogs, false));
    }
  }, [newBlogs]);

  useEffect(() => {
    if (showAll !== null && Object.values(show).every((value) => !value)) setShowAll(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handleShowHide = (item) => {
    let BlogTypes;
    show ? (BlogTypes = { ...show, [item]: !show[item] }) : (BlogTypes = { [item]: !show[item] });
    showHide(BlogTypes);
    localStorage.setItem('showPodcast', JSON.stringify(BlogTypes));
  };

  const showHideAll = () => {
    setShowAll(!showAll);
    localStorage.setItem('showAllPodcast', JSON.stringify(!showAll));
    showHide(toggleShowClose(newBlogs, !showAll));
    !!showAll && localStorage.removeItem('showPodcast');
  };

  const handleNewSection = () => {
    setNewElementModalOpen(CREATE_MODALS.SECTION);
  };

  const handleDragEnter = (e, tab) => {
    e.preventDefault();
    setIsDragging(tab);
  };

  const handleTabEnter = (e, tab) => {
    if (!show[tab.name]) handleShowHide(tab.name);
  };

  const handleDragEnd = async (e, tab) => {
    e.preventDefault();
    if (isDragging?.id === tab?.id) {
      setIsDragging(null);
      return;
    }
    const bodyFormData = new FormData();
    bodyFormData.append('section_id', isDragging.id);
    try {
      setLoadingDrag(true);
      const response = await editVideoInsight(bodyFormData, draggedInsight.id);
      dispatch(editContent(response, content));
    } catch (error) {
      console.error(error);
    }
    setIsDragging(null);
    setLoadingDrag(false);
  };

  const handleDeleteConfirmation = (section) => {
    setNewElementModalOpen(CREATE_MODALS.DELETE);
    dispatch(sectionToBeDeleted(section));
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth);
      const updateWindowDimensions = () => {
        setWidth(window.innerWidth);
      };
      window.addEventListener('resize', updateWindowDimensions);
    }
  }, []);

  useEffect(() => {
    setShowAddSection(width > 768 && !isMobile());
  }, [width]);

  return (
    <div>
      <header className="insights-header">
        <h1>Insight Assets</h1>
        {!loading && !!showAddSection && (
          <button onClick={handleNewSection}>
            <AddIcon />
            Add new section
          </button>
        )}
      </header>
      <ol className="ai-dashboard lesson-container" id="trainer-list">
        {!loading && sectionsWithContent.length === 0 ? (
          <div className="no-section-content">
            <p>It looks like there is no content to show, try updating your search values</p>
          </div>
        ) : (
          !loading && (
            <li className="break collapsible" onClick={showHideAll}>
              <h2 className="open-close-all">
                <label>
                  <span>{showAll ? 'Close All' : 'Open All'}</span>
                  <span className="caret-wrapper">
                    <span className="caret" />
                  </span>
                </label>
              </h2>
            </li>
          )
        )}
        {loading || !sectionsWithContent
          ? loaderArray.map((i) => (
              <li key={i}>
                <TileLoader />
              </li>
            ))
          : sectionsWithContent.map((tab, index) => {
              return (
                <React.Fragment key={index}>
                  <li
                    className="break collapsible"
                    onClick={() => {
                      handleShowHide(tab.name);
                      gTag(`Lesson Category: ${capitalize(tab.name)}`, !show[tab.name] ? 'Expanded' : 'Collapsed');
                    }}
                    onDragEnter={(e) => handleTabEnter(e, tab)}
                  >
                    <h2>
                      <label>
                        <img src={hamburger} />
                        <span>{tab.name}</span>
                        <span className="caret-wrapper">
                          <span className="caret" />
                        </span>
                      </label>
                    </h2>
                  </li>
                  {show[tab.name] && (
                    <div
                      className={`insights-list ${isDragging?.name === tab.name && 'isDragging'}`}
                      onDragEnter={(e) => handleDragEnter(e, tab)}
                      onDragEnd={(e) => handleDragEnd(e, tab)}
                    >
                      {tab?.content?.length ? (
                        tab?.content?.map((insight) => (
                          <InsightCard
                            insight={insight}
                            key={insight.id}
                            setModalType={setModalType}
                            setEditContent={setEditContent}
                            setNewElementModalOpen={setNewElementModalOpen}
                          />
                        ))
                      ) : (
                        <span className="no-content">
                          Content has not been added to this section. To delete it, click
                          <button className="delete-section-button" onClick={() => handleDeleteConfirmation(tab)}>
                            here
                          </button>
                          .
                        </span>
                      )}
                      {!!loadingDrag && isDragging?.name === tab.name && (
                        <div className="loader-dragging-wrapper">
                          <span className="loader" />
                        </div>
                      )}
                    </div>
                  )}
                </React.Fragment>
              );
            })}
      </ol>
    </div>
  );
};

export default InsightPodcast;
