import React from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES_SUB_PATH, TILES_STATUS } from '../../../constant';

/* Pipeline / Active Tiles Targets page functionality */
const HUD = (props) => {
  const {
    hud: { steps, score, revenue },
    status,
  } = props;
  const { pathname } = useLocation();

  const getRevenueValue = () => {
    if (status === TILES_STATUS.ACTIVE || pathname.includes(ROUTES_SUB_PATH.DASHBOARD.ACTIVE)) {
      return revenue?.you?.ytd_projected_revenue_active_formatted;
    }
    if (status === TILES_STATUS.ARCHIVED || pathname.includes(ROUTES_SUB_PATH.DASHBOARD.ARCHIVED)) {
      return revenue?.you?.ytd_projected_revenue_archived_formatted;
    }
    if (status === TILES_STATUS.LOST || pathname.includes(ROUTES_SUB_PATH.DASHBOARD.LOST)) {
      return revenue?.you?.ytd_projected_revenue_lost_formatted;
    }
    if (status === TILES_STATUS.COMPLETED || pathname.includes(ROUTES_SUB_PATH.DASHBOARD.COMPLETED)) {
      return revenue?.you?.ytd_projected_revenue_completed_formatted;
    }
    return revenue?.you?.ytd_projected_revenue_active_formatted;
  };

  const showWonRevenueByPath = () => {
    return (
      pathname.includes(ROUTES_SUB_PATH.DASHBOARD.ACTIVE) || pathname.includes(ROUTES_SUB_PATH.DASHBOARD.COMPLETED)
    );
  };

  const showWonRevenueByStatus = () => {
    return status === TILES_STATUS.ACTIVE || status === TILES_STATUS.COMPLETED;
  };

  return (
    <ul className="hud">
      <li className="score">
        <dl>
          <dt>
            Score <br className="visible-xs" />
            <small />
          </dt>
          <dd>
            <div className="stat">{score.you.current.score}</div>
          </dd>
        </dl>
      </li>
      <li className="steps">
        <dl>
          <dt>
            Steps <br className="visible-xs" />
            <small>This Week</small>
          </dt>
          <dd>
            <div className="stat">{steps.you.this_week}</div>
          </dd>
        </dl>
      </li>
      <li className="projected-revenue">
        <dl>
          <dt>
            Projected <br className="visible-xs" />
            <small>Revenue</small>
          </dt>
          <dd>
            <div className="stat">
              <small>$</small>
              {getRevenueValue()}
            </div>
          </dd>
        </dl>
      </li>
      {(showWonRevenueByPath() || showWonRevenueByStatus()) && (
        <li className="actual-revenue">
          <dl>
            <dt>
              Won <br className="visible-xs" />
              <small>Revenue</small>
            </dt>
            <dd>
              <div className="stat">
                <small>$</small>
                {revenue.you.ytd_formatted}
              </div>
            </dd>
          </dl>
        </li>
      )}
    </ul>
  );
};

export default HUD;
