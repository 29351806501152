import React from 'react';

import icons from '../../assets/img/icons.svg';

const ReactivateText = () => {
  return (
    <>
      <div className="info">
        <span className="icon">
          <svg className="icon">
            <use xlinkHref={`${icons}#info`}></use>
          </svg>
        </span>
        <span>This Target has been re-activated. Choose a new Step.</span>
      </div>
    </>
  );
};
export default ReactivateText;
