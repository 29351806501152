import PouchDB from 'pouchdb';
import cordovaSqlitePlugin from 'pouchdb-adapter-cordova-sqlite';
import pouchdbAdapterMemory from 'pouchdb-adapter-memory';
// import pouchdbAdapterWebsql from 'pouchdb-adapter-websql';
import 'es6-promise/auto';
import 'isomorphic-fetch';
import plugin from 'pouchdb-find';
import 'whatwg-fetch';

import { accountInfo } from '../actions';
import { API_STATUS_CODE, MESSAGES, ROUTES_PATH } from '../constant';
import toaster from '../services/shared/toaster.service';
import history from './history';
import { getToken, removeSession } from './sessionManager';
import { store } from './store';
import { detectPrivateMode } from './utils';
PouchDB.plugin(plugin);
PouchDB.plugin(cordovaSqlitePlugin);
PouchDB.plugin(pouchdbAdapterMemory);
// PouchDB.plugin(pouchdbAdapterWebsql);
let fromlogout = false;
let db = null;
let changes;
let closingDB = false;
let synctimeout;
let olddb = null;
export const syncWithServer = () => {
  synctimeout = setTimeout(() => {
    clearTimeout(synctimeout);
    if (getToken()) {
      store.dispatch(accountInfo());
    }
  }, 100);
};
export const openDBforSwitchedUser = (userId) => {
  let usedDB = { auto_compaction: true };
  if (detectPrivateMode()) {
    usedDB.adapter = 'memory';
  }
  window.refresh = 1;
  localStorage.switchUserdb = userId;
  const dbName = `AckertPipeline_${userId}.db`;
  if (db) {
    olddb = db;
  }
  db = new PouchDB(dbName, usedDB);
  if (db) {
    syncWithServer(true);
    history.replace(ROUTES_PATH.DASHBOARD);
    closingDB = false;
  }
  return db;
};

export const openDatabase = () => {
  if (localStorage.switchUserdb) {
    return openDBforSwitchedUser(localStorage.switchUserdb);
  } else {
    let usedDB = { auto_compaction: true };
    if (window.cordova) {
      usedDB = {
        adapter: 'cordova-sqlite',
        iosDatabaseLocation: 'Library',
        androidDatabaseImplementation: 2,
        auto_compaction: true,
        revs_limit: 50,
      };
    } else if (detectPrivateMode()) {
      usedDB.adapter = 'memory';
    } else if (window.isElectron) {
      // usedDB.adapter = 'websql';
    }
    fromlogout = false;
    db = new PouchDB('AckertPipeline.db', usedDB);
    closingDB = false;
    // if local data is cleared and there is an error
    changes = db
      .changes({
        since: 'now',
        live: true,
      })
      .on('error', function (error) {
        if (error.code === API_STATUS_CODE.DATABASE_CLOSE && !fromlogout) {
          history.replace(ROUTES_PATH.LOGIN);
          toaster.error(MESSAGES.UnknownError); // test opss error
        } else {
          fromlogout = false;
        }
      });
    syncWithServer();
    return db;
  }
};

/**
 * @description check db instance and create new if required
 */
export const dataBaseLoginFallBack = () => {
  if (!db || db._destroyed) {
    openDatabase();
  }
};

const dataBaseCloseError = (error) => {
  if (error.code === API_STATUS_CODE.DATABASE_CLOSE && !fromlogout) {
    if (changes) {
      changes.cancel();
      changes = '';
    }
    removeSession();
    history.replace(ROUTES_PATH.LOGIN);
  }
};

const errorBlock = (e, tile) => {
  if (e.status === 404) {
    insertTile(tile);
  } else {
    dataBaseCloseError(e);
  }
};

export const updateATile = (tile, tempid) => {
  // fetch tile
  if (db && !closingDB) {
    db.get(tempid)
      .then(
        (doc) => {
          // update the id
          tile._id = doc._id;
          tile._rev = doc._rev;
          // put them back
          db.put(tile).then(
            (_tileup) => {},
            (err) => {
              errorBlock(err, tile);
            },
          );
        },
        (e) => {
          errorBlock(e, tile);
        },
      )
      .catch((e) => {
        errorBlock(e, tile);
      });
  }
};
export const insertTile = (tile) => {
  if (db && !closingDB) {
    tile._id = tile.id;
    db.put(tile)
      .then((_res) => {
        db.compact();
      })
      .catch((err) => {
        if (err.status === 409) {
          updateATile(tile, tile.id);
        }
      });
  }
};

export const insertDocs = (docs) => {
  if (db && !closingDB) {
    docs.map((tile, key) => (docs[key]._id = tile.id));
    db.bulkDocs(docs)
      .then((s) => {
        s.forEach((element) => {
          if (element.name === 'conflict') {
            const tile = docs.find((tile) => tile.id === element.id);
            tile && updateATile(tile, element.id);
          }
        });
      })
      .catch((err) => {
        dataBaseCloseError(err);
      });
  }
};

export const deleteAndCreateNew = (tile, tempid) => {
  if (db && !closingDB) {
    // fetch tile
    db.get(tempid).then(
      function (doc) {
        // delete the doc with tempid
        return db.remove(doc).then((_res) => {
          insertTile(tile);
        });
      },
      (err) => {
        dataBaseCloseError(err);
      },
    );
  }
};
export const deleteDoc = (id) => {
  if (db && !closingDB) {
    db.get(id).then(
      function (doc) {
        // delete the doc with tempid
        return db.remove(doc);
      },
      (err) => {
        // do nothing
        dataBaseCloseError(err);
      },
    );
  }
};

const findTiles = async () => {
  try {
    await db.createIndex({
      index: { fields: ['status'] },
    });
    const tiles = await db.find({
      selector: { $or: [{ status: 'active' }, { status: 'archived' }, { status: 'completed' }] },
    });
    return tiles;
  } catch (error) {
    dataBaseCloseError(error);
  }
};

export const getTiles = () => {
  if (db && !closingDB) {
    return findTiles();
  }
};

const findInsights = async (status) => {
  return db
    .find({
      selector: { status },
    })
    .catch((error) => {
      dataBaseCloseError(error);
    });
};

export const getInsight = (status) => {
  if (db && !closingDB) {
    return findInsights(status);
  }
};

export const getUsersfromLocaldb = () => {
  if (db && !closingDB) {
    return db
      .find({
        selector: { user: true },
      })
      .catch((error) => dataBaseCloseError(error));
  }
};
export const getHUDfromLocaldb = () => {
  return new Promise((resolve, rejected) => {
    if (db && !closingDB) {
      rejected();
    } else {
      return db.get('idhud').then(
        (s) => {
          resolve(s);
        },
        (error) => error,
      );
    }
  });
};
export const getAtile = (id) => {
  if (db && !closingDB) {
    return db.get(id).then(
      (s) => {
        return s;
      },
      (error) => error,
    );
  }
};
export const getCountByStatus = (status) => {
  if (db) {
    db.allDocs({
      include_docs: true,
      selector: { status },
    }).then((entries) => console.log(entries));
  }
};
export const logoFromdb = () => {
  if (db && !closingDB) {
    return db.get('logo').then(
      (s) => {
        return s;
      },
      (err) => {
        return err;
      },
    );
  }
};

export const clearDatabase = (logout = false, switchBack = false) => {
  fromlogout = logout;
  if (db && !closingDB && !db._destroyed) {
    if (changes) {
      changes.cancel();
      changes = '';
    }
    closingDB = true;
    db.destroy()
      .then(function () {
        db = null;
        if (switchBack) {
          const dbname = 'pouch_AckertPipeline' + localStorage.switchUserdb + '.db';
          localStorage.removeItem('switchUserdb');
          localStorage.removeItem(dbname);
          window.location.reload();
          if (olddb) {
            olddb.destroy().then(() => {
              olddb = null;
            });
          }
        } else {
          history.replace(ROUTES_PATH.LOGIN);
        }
      })
      .catch(() => {
        window.location.reload();
      });
  }
};

// update date range in local storage
export const saveDateRange = (range) => {
  localStorage.daterange = JSON.stringify(range);
};

// update preset in local storage
export const saveDateRangePreset = (preset) => {
  localStorage.preset = preset;
};

/**
 * @desc fetch date range and preset from local storage
 * @return object - {dateRange, preset}
 */
export const getDateRange = () => {
  const dateRange = localStorage.daterange && JSON.parse(localStorage.daterange);
  const preset = localStorage.preset;
  return {
    dateRange,
    preset,
  };
};

// store the open/closed status of the report header: "open" "closed"
export const saveReportParamsView = (view) => {
  console.log('db.saveReportParamsView', 'received', view);
  localStorage.viewerParamsView = view;
};
// it defaults to "open"
export const getReportParamsView = () => {
  const view = localStorage.viewerParamsView;
  console.log('db.getReportParamsView', 'sending', view);
  return view;
};
