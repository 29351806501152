import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';

import { Graph, OverviewTable } from '../../../../components';
import TileLoader, { LineChartLoader } from '../../../../components/shared/TileLoader';
import { getOverviewCharts, getOverviewTable } from '../../../../actions';
import { overviewTabs, DueDateFormatForAPI, REPORT_CASES_KEYVALUE, UPDATE_REPORT_WHICH } from '../../../../constant';
import { gTag } from '../../../../helpers';

// Overview container
class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartsData: {
        series: [],
      },
      currentGraph: overviewTabs[0].which,
      resetActiveRow: false,
    };
    this.addedGraph = '';
    this.key = '';
  }

  componentDidMount() {
    if (this.props.graphOf.id && this.props.dateRange) {
      this.key = this.props.graphOf.id;
      this.callAPI();
    }
  }

  componentDidUpdate(prevprops) {
    const { dateRange, graphOf } = this.props;
    const {
      dateRange: { startDate, endDate },
    } = prevprops;
    if (graphOf.id || dateRange) {
      if (this.key !== graphOf.id) {
        this.key = graphOf.id;
        this.callAPI();
      } else if (
        moment(startDate).format('MM/DD/YYYY') !== moment(dateRange.startDate).format('MM/DD/YYYY') ||
        moment(endDate).format('MM/DD/YYYY') !== moment(dateRange.endDate).format('MM/DD/YYYY')
      ) {
        this.callAPI();
      }
    }
  }

  /**
   * function for change the graph according to score/steps/revenue/activity
   * {currentGraph} {score/steps/revenue/activity}
   */
  changeGraphFor = ({ which, name }) => {
    if (this.state.currentGraph !== which) {
      this.setState({ currentGraph: which });
    }
    const chartsData = { ...this.state.chartsData };
    chartsData.which = which;

    let updatedSeries = chartsData.series.slice(0, 2).map((item) => {
      const type = this.props.chartsData[item.type];
      return {
        ...item,
        name: item.name?.replace('Won Revenue', ''),
        data: type ? type[which] : this.props.chartsData[REPORT_CASES_KEYVALUE[item.type]][item.id][which],
      };
    });

    if (which === 'total_revenue') {
      updatedSeries = updatedSeries.map((item) => ({
        ...item,
        name: `${item.name} Won Revenue`,
      }));

      const projectedSeries = updatedSeries.map((item) => ({
        ...item,
        name: item.name?.replace('Won Revenue', 'Projected Revenue'),
        data: this.props.chartsData[item.type]
          ? this.props.chartsData[item.type]['projected_revenue']
          : this.props.chartsData[REPORT_CASES_KEYVALUE[item.type]][item.id]['projected_revenue'],
      }));

      chartsData.series = updatedSeries.concat(projectedSeries);
    } else {
      chartsData.series = updatedSeries.filter(
        ({ name }) => !name?.includes('Projected Revenue') && !name?.includes('Won Revenue'),
      );
    }

    this.setState({ chartsData });
    this.props.changeReportWhich(name.toLowerCase());
  };

  /**
   * funtion to prepare grapgh data after the get chart api called
   * {currentGraph} {score/steps/revenue/activity}
   */
  prepareGraphData = (currentGraph) => {
    let series = Object.entries(this.props.chartsData.legend).map(([type, legendItem]) => {
      return {
        type: type,
        id: legendItem.id,
        name: legendItem.name,
        data: this.props.chartsData[type][currentGraph],
      };
    });

    if (currentGraph === 'total_revenue') {
      let updatedSeries = series.map((item) => ({
        ...item,
        name: `${item.name} Won Revenue`,
      }));

      let projectedSeries = updatedSeries.map((item) => ({
        ...item,
        name: item.name?.replace('Won Revenue', 'Projected Revenue'),
        data: this.props.chartsData[item.type]['projected_revenue'],
      }));

      // Concatenate the updated series and projected series
      series = updatedSeries.concat(projectedSeries);
    }

    const chartsData = {
      labels: this.props.chartsData.labels,
      which: currentGraph,
      series,
    };

    this.setState({ chartsData });
  };
  /**
   * call Api to get data for making line Chart and score table
   */
  callAPI = () => {
    const range = {
      start: moment(this.props.dateRange.startDate).format(DueDateFormatForAPI),
      end: moment(this.props.dateRange.endDate).format(DueDateFormatForAPI),
    };
    const chartpromise = this.props.getOverviewCharts(this.props.graphOf, range);
    this.props.getOverviewTable(this.props.graphOf, range);
    chartpromise.then(() => {
      setTimeout(() => {
        this.prepareGraphData(this.state.currentGraph);
      }, 100);
    });
  };

  /**
   * Add line graph on click of rows in score table
   */
  addLineGraph = ({ id, type, name }) => {
    const data = this.props.chartsData[REPORT_CASES_KEYVALUE[type]][id][this.state.currentGraph];
    const series = {
      type,
      id,
      name,
      data,
    };
    const chartsData = this.state.chartsData;
    const ifExit = chartsData.series.findIndex((item) => item.type === type);
    if (ifExit === -1) {
      const l = chartsData.series.push(series);
      this.addedGraph = l - 1;
    } else {
      chartsData.series[ifExit] = series;
      this.addedGraph = ifExit;
    }
    this.setState({ chartsData });
  };

  /**
   * reset graph after click of header rows
   */
  resetGraph = () => {
    if (this.addedGraph) {
      const chartsData = this.state.chartsData;
      chartsData.series.splice(this.addedGraph, 1);
      this.setState({ chartsData });
    }
  };

  render() {
    return (
      <>
        {!overviewTabs.length && <TileLoader />}
        <section className="panel chart">
          <header>
            <ul className="button-bar ui">
              {overviewTabs.map((item) => (
                <li
                  className={item.which === this.state.currentGraph ? 'active' : ''}
                  onClick={() => {
                    if (this.state.currentGraph !== item.which) {
                      this.changeGraphFor(item);
                      gTag('Viewer:Graph Modes', item.name);
                    }
                  }}
                  key={item.which}
                >
                  <a>{item.name}</a>
                </li>
              ))}
            </ul>
          </header>
          {this.state.chartsData.series.length ? (
            <Graph type={'Line'} className={'chart'} graphdata={this.state.chartsData} />
          ) : (
            <LineChartLoader />
          )}
        </section>
        <OverviewTable
          groups={this.props.tableData.groups}
          headRows={[this.props.tableData.org, this.props.tableData.group]}
          group={this.props.tableData.group}
          addLineGraph={this.addLineGraph}
          resetGraph={this.resetGraph}
        />
      </>
    );
  }
}

// map dispatch to props
const mapDispatchToProps = (dispatch) => ({
  getOverviewCharts: (id, range) => dispatch(getOverviewCharts(id, range)),
  getOverviewTable: (id, range) => dispatch(getOverviewTable(id, '/overview', range)),
  changeReportWhich: (which) =>
    dispatch({
      type: UPDATE_REPORT_WHICH,
      payload: which,
    }),
});

// map state to props
const mapStateToProps = (state) => ({
  loading: state.viewer.loading,
  graphOf: state.viewer.graphOf,
  chartsData: state.overviewCharts,
  tableData: state.overviewTable,
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(Overview));
