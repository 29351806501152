import { invoke } from '../../helpers';
import { API_URLS, API_METHODS, API_PARAMS, MESSAGES, API_STATUS_CODE } from '../../constant';
import { isOnline } from '../../helpers/sessionManager';
import {
  getTiles,
  insertTile,
  updateATile,
  deleteAndCreateNew,
  getUsersfromLocaldb,
  deleteDoc,
  getHUDfromLocaldb,
  getAtile,
} from '../../helpers/dbStorage';
import toaster from '../shared/toaster.service';

/**
 * Api call to get targets by status
 * @param {string} status get targets by status
 */
export const getTargets = (status = API_PARAMS.ACTIVE, after = '') => {
  const url = `${API_URLS.TILES}`;
  return invoke({ url, type: API_METHODS.GET, params: { status, after } });
};
/**
 * get all targets
 */
export const getAllStatusTargets = async (after = '') => {
  const url = `${API_URLS.TILES}`;
  return invoke({ url, type: API_METHODS.GET, params: { after } });
};

/**
 * Api call to get all notes related by tiles
 * @param {string} status get targets by status
 * @param {string} target_id get targets by target_id
 * @param {string} id - get by tile id
 */
export const getTileNotes = (target_id = '') => {
  const url = `${API_URLS.TILES}/${target_id}/notes`;
  return invoke({ url, type: API_METHODS.GET, params: { target_id } });
};

/**
 * Api call to get all notes for referenced targets
 * @param {string} target_id get targets by target_id
 */
export const getTargetNotes = (target_id = '') => {
  const url = `${API_URLS.NOTES}`;
  return invoke({ url, type: API_METHODS.GET, params: { target_id } });
};

/**
 * Api call to get all notes for referenced targets
 * @param {string} target_id get targets by target_id
 */
export const addTargetNote = (target_id = '', note, token = false) => {
  const url = `${API_URLS.NOTES}`;
  return invoke({ url, type: API_METHODS.POST, params: { target_id, note } }, {}, {}, token);
};

/**
 * Api call to get all notes for referenced targets
 * @param {string} target_id get targets by target_id
 * @param {string} id - edit by tile id
 */
export const editTargetNote = (id, note) => {
  const url = `${API_URLS.NOTES}/${id}`;
  return invoke({ url, type: API_METHODS.PUT, params: { note } });
};

/**
 * empty trash targets
 */
export const emptyTrash = (_data) => {
  return invoke({ url: `${API_URLS.EMPTY_TRASH}`, type: API_METHODS.POST }).then(
    (_res) => {
      toaster.success(MESSAGES.DELETEDTRASH);
    },
    (err) => {
      const error = err.response.data.message || err.message;
      toaster.error(error);
      if (!err.response && err.message === MESSAGES.NETWORKERR) {
        toaster.error(MESSAGES.NOINTERNET);
      } else if (err.response.data.code === API_STATUS_CODE.UNAUTHORIZED) {
        toaster.error(err.response.data.message);
      }
    },
  );
};

/**
 * Function to hit the api to get counts of active tiles
 */
export const getStanding = () => {
  return invoke({ url: API_URLS.STANDING, type: API_METHODS.GET });
};

export const getTargetFromLocalDb = () => {
  return getTiles();
};
export const saveToLocaldb = (tile, id) => {
  id ? updateATile(tile, id) : insertTile(tile);
};
/**
 * call database function to delete the offline created target and create the new one with id sync with server
 * @param {object} tile
 * @param {string} id
 */
export const deleteOldAndCreateNew = (tile, id) => {
  deleteAndCreateNew(tile, id);
};

/**
 * get users of current firm
 */
export const getUsers = (uid) => {
  if (isOnline() || uid) {
    return invoke({ url: API_URLS.USERS, type: API_METHODS.GET }, {}, { proxyUser: uid });
  } else {
    return getUsersfromLocaldb();
  }
};
export const getHUD = (userId) => {
  if (isOnline() || userId) {
    const options = userId ? { proxyUser: userId } : {};
    return invoke({ url: API_URLS.HUD, type: API_METHODS.GET }, null, options);
  } else {
    return getHUDfromLocaldb();
  }
};

export const deleteFromlocal = (id) => {
  deleteDoc(id);
};
export const getFirms = (query) => {
  return invoke({ url: API_URLS.FIRMS, type: API_METHODS.GET, params: { q: query } });
};
export const getaTileById = (id) => {
  return getAtile(id);
};
