import React, { useState } from 'react';

import Table from './Table.component';
import ColleagueList from '../../shared/colleagueList';

/**
 * component to show users in tabular format
 * @param {*} props
 */
const OverlapsTable = ({ overlaps, headRows, getFilterProps }) => {
  const [activeRow, setactiveRow] = useState();

  // memoised data to show
  const data = React.useMemo(() => {
    if (overlaps) {
      setactiveRow(null);
      return Object.entries(overlaps).map((overlap) => overlap[1]);
    }
    return [];
  }, [overlaps]);

  // table columns
  const columns = React.useMemo(
    () => [
      {
        Header: 'Client',
        accessor: 'target',
        id: 'target',
        Cell: ({ value }) => <h1 className="target-name">{value}</h1>,
        colGroup: 'now',
        colGroupClass: 'name text',
        className: 'text client',
        sortType: 'basic',
        tooltip: 'The Company or Contact of the Target.',
      },
      {
        Header: 'User',
        accessor: 'members',
        id: 'members',
        Cell: ({ value }) => <ColleagueList list={value} type="collaborators" />,
        disableSortBy: true,
        colGroup: 'now',
        colGroupClass: 'activity',
        className: 'user-list',
        tooltip: 'Users who  may be pursuing but not officially collaborating on the Company or Contact.',
      },
    ],
    [],
  );

  // onclick on a row reset the active class
  const hrows = headRows
    .map((row) => {
      if (row) {
        return {
          props: {
            onClick: () => setactiveRow(null),
            className: 'freeze active',
          },
          columns: [],
        };
      } else return {};
    })
    .filter((anyValue) => typeof anyValue !== 'undefined');

  const initialState = {
    sortBy: React.useMemo(() => [{ id: 'target', desc: false }], []),
  };

  return (
    <Table
      data={data}
      columns={columns}
      headRows={hrows}
      initialState={initialState}
      getRowProps={(row) => ({
        onClick: () => {
          if (row.id !== activeRow) {
            setactiveRow(row.id);
          }
        },
        className: `${row.id === activeRow ? 'active' : ''}`,
      })}
      getFilterProps={getFilterProps}
    />
  );
};

export default React.memo(OverlapsTable);
