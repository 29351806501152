import { store, getRandomString } from '.';
import { clearDatabase } from './dbStorage';
import { unsubScribeFirebase } from '../services/shared/sync.service';
import { setBadgeCount } from '../services/shared/badgeCount.service';
import { yes, API_URLS, API_METHODS } from '../constant';
import { invoke } from '../helpers';

export const getUserData = () => {
  if (localStorage['persist:root']) {
    return store && store.getState().session;
  }
  return {};
};

export const getToken = () => {
  return getUserData().token;
};

export const removeSession = () => {
  store.dispatch({ type: 'INIITIAL_SESSION' });
  typeof localStorage !== 'undefined' && localStorage.removeItem('persist:root');
  unsubScribeFirebase();
  setBadgeCount(0);
};

const switchSession = (switchBackTo) => {
  store.dispatch({ type: 'INIITIAL_SESSION_SWITCHBACK', payload: { token: switchBackTo.revert.token } });
};

export const destroy = (fromLogout = false, switchBack = null) => {
  if (switchBack) {
    switchSession(switchBack);
    clearDatabase(fromLogout, true);
  } else {
    removeSession();
    clearDatabase(fromLogout);
  }
};

export const handleLogout = () => {
  return invoke({ url: API_URLS.LOGOUT, type: API_METHODS.POST });
};

export const getUserId = (user) => {
  const userData = user || getUserData();
  return userData && userData.user && userData.user.id;
};
export const getCompanyId = (user) => {
  const userData = user || getUserData();
  return userData && userData.user.org_id;
};

export const getEmailID = (user) => {
  const userData = user || getUserData();
  return userData && userData.user.email;
};

export const getFirstName = () => {
  const userData = getUserData();
  return userData.user.first_name;
};
export const getLastName = () => {
  const userData = getUserData();
  return userData.user.last_name;
};

export const validateUser = () => {
  const userData = getUserData();
  checkRedirectParamater(userData);
  if (userData.token) {
    return userData;
  } else {
    return false;
  }
};

export const isOnline = () => {
  return window.navigator.onLine;
};

export const updateInfo = (values) => {
  const session = getUserData();
  session.user.fullname = `${values.first_name} ${values.last_name}`;
  session.user.email = values.email;
  session.user.first_name = values.first_name;
  session.user.last_name = values.last_name;
  session.user.phone = values.phone;
  store.dispatch({ type: 'SAVE_LOGIN_DATA', payload: { ...session, ...values } });
};

export const getBrowserUUID = () => {
  //if ()
  const session = getUserData();
  if (session.uuid) {
    return session.uuid;
  } else {
    const uuid = getRandomString();
    session.uuid = uuid;
    store.dispatch({ type: 'SAVE_LOGIN_DATA', payload: { ...session } });
    return uuid;
  }
};

export const updateSetting = (data) => {
  const session = getUserData();
  session.user.Settings = { ...session.user.Settings, [data.name]: +data.value };
  store.dispatch({ type: 'SAVE_LOGIN_DATA', payload: { ...session } });
};
export const checkSub = (tab) => {
  const session = getUserData();
  const user = session.user;
  if (tab === 'account') {
    return true;
  }
  if (user && user.Subscriptions[tab]) {
    return user.Subscriptions[tab].on === yes;
  }
  return false;
};

export const updateLastLocation = ({ pathname, search }) => {
  if (
    pathname.includes('login') == false &&
    pathname.includes('otp-password') == false &&
    pathname.includes('getresetPasswordLink') == false &&
    pathname != '/'
  ) {
    localStorage.setItem('lastVisited', pathname + search);
  }
};

export const checkVersion = async () => {
  let versionUpdate = false;
  // make sure meta.json cannot be cached by the browser
  const cachebuster = Math.floor(Math.random() * 100000);
  const meta = await fetch('/meta.json?r=' + cachebuster).then((response) => response.json());
  const latestVersion = meta.version;
  const currentVersion = window.appVersion;
  console.log('Version :: ', latestVersion, currentVersion);
  if (!(latestVersion === currentVersion)) {
    versionUpdate = true;
  } else {
    versionUpdate = false;
  }
  return versionUpdate;
};

export const getLastLocation = () => localStorage.getItem('lastVisited');

const checkRedirectParamater = (userData) => {
  if (window && window.location) {
    let url = window.location.href;
    if (url && url.includes('?')) {
      let redirect_url = url.split('?')[1];
      const urlParams = new URLSearchParams('?' + redirect_url);
      const redirect = urlParams.get('redirect');
      if (redirect && localStorage && localStorage.getItem('redirect') != redirect) {
        localStorage.setItem('redirect', redirect);
      }
    } else if (
      userData.token == undefined &&
      localStorage &&
      url.includes('login') == false &&
      url.includes('otp-password') == false &&
      localStorage.getItem('redirect') != url
    ) {
      localStorage.setItem('redirect', url);
    }
  }
};

export const getFirmProjectId = () => getUserData().user.Org.ugc?.project_id;
