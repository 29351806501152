/**
 * Action for opening actionsheet
 */

import { SHOW_ACTIONSHEET, HIDE_ACTIONSHEET } from '../../constant';
export const showActionSheet =
  ({ actionSheetProps, actionSheetType }) =>
  (dispatch) => {
    dispatch({
      type: SHOW_ACTIONSHEET,
      actionSheetProps,
      actionSheetType,
    });
  };

export const hideActionSheet = () => (dispatch) => {
  dispatch({
    type: HIDE_ACTIONSHEET,
  });
};
