import React, { Component } from 'react';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { withRouter } from 'react-router-dom';

import {
  calculateDays,
  convertDueDateToAPIFormat,
  getLastName,
  getFirstName,
  getUserId,
  formatNumber,
  gTag,
} from '../../../helpers';
import {
  SnoozeForm,
  MarkAsDoneForm,
  Confimation,
  CompleteTargetForm,
  TargetDetailActionList,
  Header,
  ReactivateText,
  MarkAsLostForm,
} from '../../../components';
import { EditTarget, Context } from '../../';
import {
  deleteATarget,
  destroyATarget,
  completeATarget,
  editTarget,
  createTask,
  showActionSheet,
  hideActionSheet,
  showModal,
  workOnThisTile,
  setAttention,
  updateSteps,
  onlyCallHudOnce,
  lostTarget,
} from '../../../actions';
import { targetDetailsAction, tabs, taskType, taskFilter, reactive } from '../../../constant';
import icons from '../../../assets/img/icons.svg';
import { capitalize } from 'lodash';

class TargetSlide extends Component {
  constructor(props) {
    super(props);
    this.scrollableDiv = React.createRef();
    this.state = {
      activeAction: '',
      editAction: { goal: '', taskdue: '', taskdescription: '', lostReason: '' },
      activeTab: tabs[0],
      scrolled: false,
      goal: '',
      taskdue: '',
      taskdescription: '',
      lostReason: '',
    };
    this.setDoneValueToField = null;
    this.resetDoneForm = null;
    this.setLostValueToField = null;
    this.resetLostForm = null;
    this.form = null;
    this.snoozeTarget = this.snoozeTarget.bind(this);
    this.slidenode = React.createRef();
  }

  componentDidMount() {
    this.scrollableDiv.current.addEventListener('scroll', this.listenToScroll);
  }

  componentWillUnmount() {
    this.scrollableDiv.current.removeEventListener('scroll', this.listenToScroll);
  }

  listenToScroll = (e) => {
    if (e.target.scrollTop > 0) {
      !this.state.scrolled && this.setState({ scrolled: true });
    } else {
      this.setState({ scrolled: false });
    }
  };

  toggleAction = (e, activeAction) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (this.scrollableDiv && this.scrollableDiv.current) {
      this.scrollableDiv.current.scrollTop = 0;
    }
    this.setState({ activeAction });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.activeSlideId === this.props.detail.id) {
      const { taskdue, goal, taskdescription, lostReason } = this.props.selectedValueFromAS;
      if (taskdue) {
        this.state.activeAction === targetDetailsAction.snooze && this.snoozeTarget(taskdue);
        this.state.activeAction === targetDetailsAction.next && this.setValueToNewTask('taskdue', taskdue);
        this.state.activeAction === targetDetailsAction.edit &&
          this.setValuesFromActionSheet('taskdue', taskdue, 'editAction');
      } else if (goal) {
        this.state.activeAction === targetDetailsAction.edit &&
          this.setValuesFromActionSheet('goal', goal, 'editAction');
      } else if (lostReason) {
        this.state.activeAction === targetDetailsAction.lost && this.setValueToNewTask('lostReason', lostReason);
      } else if (taskdescription) {
        this.state.activeAction === targetDetailsAction.next &&
          this.setValueToNewTask('taskdescription', taskdescription);
        this.state.activeAction === targetDetailsAction.edit &&
          this.setValuesFromActionSheet('taskdescription', taskdescription, 'editAction');
      }
    }
  }

  setValuesFromActionSheet = (key, value, action) => {
    const values = this.state[action];
    values[key] = value;
    this.setState({ [action]: { ...values } }, () => {
      this.props.resetState();
    });
  };
  setValueToNewTask = (key, value) => {
    key === 'lostReason' ? this.setLostValueToField(key, value) : this.setDoneValueToField(key, value);

    setTimeout(() => this.props.resetState(), 500);
  };
  /**
   * Delete target
   */
  holdTarget = () => {
    const {
      detail,
      deleteTarget,
      afterDelete,
      isPeopleTarget,
      match: { params },
    } = this.props;
    this.deletedIndex = this.props.index;
    const data = { ...detail };
    if (isPeopleTarget) data.isPeopleTarget = isPeopleTarget;
    deleteTarget(data, params.uid);
    afterDelete();
    this.cancel();
  };
  destroyTargetCB = () => {
    const {
      detail,
      destroyTarget,
      afterDelete,
      isPeopleTarget,
      match: { params },
    } = this.props;
    this.deletedIndex = this.props.index;
    const data = { ...detail };
    if (isPeopleTarget) data.isPeopleTarget = isPeopleTarget;

    destroyTarget(data, params.uid);
    afterDelete();
    this.cancel();
  };
  /**
   * function to make target active from hold and completed
   */
  workOnTile = () => {
    const {
      detail,
      workOnThisTile,
      afterDelete,
      isPeopleTarget,
      match: { params },
    } = this.props;
    this.deletedIndex = this.props.index;
    const data = { ...detail };
    if (isPeopleTarget) data.isPeopleTarget = isPeopleTarget;
    workOnThisTile(data, params.uid);
    afterDelete();
  };
  /**
   * Click on cancel button
   */
  cancel = (e) => {
    this.toggleAction(e, targetDetailsAction.cancel);
  };
  /**
   * function to make the target completed from active
   */
  completeTarget = (values) => {
    const {
      isPeopleTarget,
      detail,
      match: { params },
    } = this.props;
    const tempDetailObj = { ...detail };
    const reactiveTarget = tempDetailObj.phase === reactive && !this.getLatestTask(detail).description;
    if (isPeopleTarget) tempDetailObj.isPeopleTarget = isPeopleTarget;
    tempDetailObj.revenue = values.revenue;
    tempDetailObj.revenue_formatted = values.revenue > -1 ? `$${formatNumber(parseInt(values.revenue))}` : null;
    this.props.completeTarget(tempDetailObj, values.revenue, reactiveTarget, params.uid);
    this.cancel();
    if (values.dupe) {
      const fillValue = {
        company: tempDetailObj.company,
        contact: tempDetailObj.contact,
        type: tempDetailObj.type,
        value: tempDetailObj.value,
      };
      this.props.openCreateTargetModal({ open: true }, fillValue);
      this.props.history.goBack();
    } else {
      this.props.afterDelete();
    }
  };
  /**
   * Function to Snooze target
   * @param {*} date
   */
  snoozeTarget(date) {
    const {
      detail,
      editTarget,
      resetState,
      isPeopleTarget,
      match: { params },
    } = this.props;
    const target = { ...detail };
    target.taskdue = date;
    if (isPeopleTarget) target.isPeopleTarget = isPeopleTarget;
    editTarget(target, params.uid);
    this.cancel();
    resetState();
  }
  /*
   * Function to call api for making next task
   */
  nextTask = (data) => {
    const {
      isPeopleTarget,
      match: { params },
    } = this.props;
    const task = {
      description: data.taskdescription,
      due: convertDueDateToAPIFormat(data.taskdue),
      help: data.taskhelp,
      tile_id: this.props.detail.id,
      completed: null,
      done: taskType.incomplete,
      id: Date.now(),
      first_name: getFirstName(),
      last_name: getLastName(),
      user_id: getUserId(),
    };
    this.cancel();
    const reactiveTarget = this.props.detail.phase === reactive && !this.getLatestTask(this.props.detail).description;
    if (isPeopleTarget) task.isPeopleTarget = isPeopleTarget;
    this.props.createNextTask(task, reactiveTarget, params.uid);
  };
  /**
   * bind set value of the forms
   */
  // TODO: remove this anti pattern and control the input field values from parent component
  bindSetDoneValue = (setValue) => {
    this.setDoneValueToField = setValue;
  };
  /**
   * bind set value of the forms
   */
  bindSetLostValue = (setValue) => {
    this.setLostValueToField = setValue;
  };
  /**
   * bind reset of done form
   */
  bindresetDoneForm = (reset) => {
    this.resetDoneForm = reset;
  };
  /**
   * bind reset of lost form
   */
  bindresetLostForm = (reset) => {
    this.resetLostForm = reset;
  };
  /**
   * Bind edit form
   */
  bindForm = (form) => {
    this.form = form;
  };
  /**
   * get
   */
  getLatestTask = (detail) => {
    return (
      detail.tasks.find((item) => {
        return item.done === taskFilter[detail.status] && this.props.currentUser === item.user_id;
      }) || {}
    );
  };
  /**
   * render function to show UI
   */
  render() {
    const {
      detail,
      toggleActionSheet,
      match: { params },
    } = this.props;
    const isPeopleTarget = !!(params && params.uid);
    const active = detail.status === 'active';
    const task = this.getLatestTask(detail);
    detail.taskdescription = task.description;
    detail.taskhelp = task.help;
    detail.taskdue = task.due;
    detail.taskid = task.id;
    detail.taskUserId = task.user_id;
    const reactiveTarget = detail.phase === reactive && !task.description;
    const nextStep = !reactiveTarget ? (
      <>
        <span>Done? Take the</span> <span>Next Step</span>
      </>
    ) : (
      <span>Take A Step</span>
    );
    return (
      <article
        className={`card ${active ? detail.bucket : ''} ${detail.status}
            ${this.state.activeAction === targetDetailsAction.edit ? 'flipped' : ''}
            `}
      >
        <div className="front" ref={this.slidenode}>
          <Header detail={detail} />
          <div ref={this.scrollableDiv} className="main">
            <div className="block next">
              <div className="current-step">
                <h3>{reactiveTarget ? <ReactivateText /> : <span>{task.description}</span>}</h3>
                {active ? (
                  <>
                    {!reactiveTarget && (
                      <div className="due-date">
                        <time className="due">{calculateDays(detail.due)}</time>
                        <i
                          className="action"
                          onClick={(e) => {
                            this.toggleAction(e, targetDetailsAction.snooze);
                            gTag('Click', `Snooze: ${params.id}`);
                          }}
                        >
                          <svg>
                            <use xlinkHref={`${icons}#button-snooze`} />
                          </svg>
                          Snooze
                        </i>
                      </div>
                    )}
                    <button
                      className="action next"
                      onClick={(e) => {
                        this.toggleAction(e, targetDetailsAction.next);
                        gTag('Click', `Done? Take the Next Step: ${params.id}`);
                      }}
                    >
                      {nextStep}
                    </button>
                  </>
                ) : (
                  <div>
                    {detail.status === 'deleted' ? <p>This Target is scheduled to be deleted soon.</p> : null}
                    <button className="action next" onClick={this.workOnTile}>
                      <span>Pursue this Target</span>
                    </button>
                    {detail.status === 'deleted' ? <p>This will move this Target to your Pursuing category.</p> : null}
                  </div>
                )}
              </div>
              {active && (
                <>
                  <SnoozeForm
                    active={this.state.activeAction === targetDetailsAction.snooze}
                    toggleActionSheet={toggleActionSheet}
                    snooze={this.snoozeTarget}
                  />
                  <MarkAsDoneForm
                    active={this.state.activeAction === targetDetailsAction.next}
                    toggleActionSheet={toggleActionSheet}
                    nextTask={this.nextTask}
                    bindSetValue={this.bindSetDoneValue}
                    bindresetForm={this.bindresetDoneForm}
                  />
                  <MarkAsLostForm
                    toggleActionSheet={toggleActionSheet}
                    lostTarget={(lostReason) => this.props.lostTarget({ ...detail, lostReason }, params.uid)}
                    active={this.state.activeAction === targetDetailsAction.lost}
                    bindSetValue={this.bindSetLostValue}
                    bindresetForm={this.bindresetLostForm}
                  />
                  <Confimation
                    active={this.state.activeAction === targetDetailsAction.delete}
                    confimationCB={this.holdTarget}
                    destroyTargetCB={this.destroyTargetCB}
                  />
                  <CompleteTargetForm
                    active={this.state.activeAction === targetDetailsAction.complete}
                    contact={detail.contact}
                    company={detail.company}
                    index={detail.id}
                    handleSubmit={this.completeTarget}
                    init={{ revenueOpts: '', revenue: '', dupe: false }}
                  />
                </>
              )}
            </div>
            {active && (
              <TargetDetailActionList
                cancel={this.cancel}
                toggleAction={this.toggleAction}
                activeAction={this.state.activeAction}
              />
            )}
            <Context
              detail={detail}
              updateSlider={this.props.updateSlider}
              showSharing={active}
              afterDelete={this.props.afterDelete}
              isPeopleTarget={isPeopleTarget}
              uid={params.uid}
            />
          </div>
          <div
            className="close-dialog"
            onClick={() => {
              let currentEndpoint = '';
              for (let i = 0; i < 6; i++) {
                currentEndpoint += window.location.hash[i];
              }
              if (currentEndpoint === '#/view') {
                const personsViewer = '/viewer/pipeline/people/' + this.props.detail.user_id;
                this.props.history.push(personsViewer);
              }
              // this.props.history.push('/viewer/pipeline/overview');
              else if (currentEndpoint === '#/targ') {
                this.props.history.push('/target');
              }
              gTag('Tile Close', `${capitalize(detail.status)}: ${params.id}`);
            }}
          >
            <svg className="icon nav-icon">
              <use xlinkHref={`${icons}#action-close`}></use>
            </svg>
          </div>
        </div>
        {active && (
          <div className="back keyboard">
            {this.state.activeAction === targetDetailsAction.edit && (
              <EditTarget
                toggleActionSheet={toggleActionSheet}
                editDetails={detail}
                cancel={this.cancel}
                valueformActionSheet={this.state.editAction}
                updateSlider={this.props.updateSlider}
                isPeopleTarget={isPeopleTarget}
                uid={params.uid}
              />
            )}
          </div>
        )}
      </article>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  deleteTarget: (payload, uid) => {
    dispatch(onlyCallHudOnce());
    dispatch(deleteATarget(payload, uid));
  },
  lostTarget: (payload, uid) => {
    dispatch(onlyCallHudOnce());
    dispatch(lostTarget(payload, uid));
  },
  destroyTarget: (payload, uid) => {
    dispatch(onlyCallHudOnce());
    dispatch(destroyATarget(payload, uid));
  },
  completeTarget: (payload, revenue, reactiveTarget, uid) => {
    !reactiveTarget && updateSteps();
    dispatch(onlyCallHudOnce());
    dispatch(completeATarget(payload, revenue, uid));
  },
  editTarget: (payload, uid) => {
    dispatch(onlyCallHudOnce());
    dispatch(editTarget(payload, uid));
  },
  createNextTask: (data, reactiveTarget, uid) => {
    !reactiveTarget && updateSteps();
    dispatch(onlyCallHudOnce());
    dispatch(createTask(data, uid));
  },
  hideActionSheet: () => dispatch(hideActionSheet()),
  showActionSheet: (actionSheetProps, actionSheetType) =>
    dispatch(showActionSheet({ actionSheetProps, actionSheetType })),
  openCreateTargetModal: (modalProps, values) => {
    dispatch(initialize('createEditTarget', values));
    dispatch(showModal({ modalProps }));
  },
  workOnThisTile: (payload, uid) => {
    dispatch(onlyCallHudOnce());
    dispatch(workOnThisTile(payload, uid));
    dispatch(setAttention({ id: payload.id, from: 'work' }));
  },
});
export default withRouter(connect(null, mapDispatchToProps)(TargetSlide));
