import React, { useState } from 'react';

import { formatNumber } from '../../../helpers';
import Table from './Table.component';
import icons from '../../../assets/img/icons.svg';
import { ColleagueList } from '../../../components';

// show company & contact details
const TargetName = ({ original }) => {
  const { contact, company, collaborators } = original;
  return (
    <>
      <h1 className="target-name">
        {collaborators.length > 1 && (
          <span className="icon">
            <svg className="icon">
              <use xlinkHref={`${icons}#shared`} />
            </svg>
          </span>
        )}
        {contact ? <span className="contact">{contact}</span> : ''}
        {company ? <span className="company">{company}</span> : ''}
      </h1>
    </>
  );
};

// show target status with icon
const Status = ({ original }) => {
  const { status, statusterm, completed_formatted } = original;
  return (
    <>
      {statusterm}{' '}
      {status == 'completed' && (
        <svg className="icon poptip" data-tip={'Completed ' + completed_formatted} data-for="tip">
          <use xlinkHref={`${icons}#info`} />
        </svg>
      )}
    </>
  );
};

// show revenue in number format
const Revenue = ({ value }) => (value ? `$${formatNumber(+value.replace(/,/g, ''))}` : ``);

/**
 * show targets details in tabular format
 * @param {*} table props
 */
const TargetsTable = ({ targets, headRows, getFilterProps }) => {
  const [activeRow, setactiveRow] = useState();

  // memoised targets data to show
  const data = React.useMemo(() => {
    if (targets) {
      setactiveRow(null);
      return Object.entries(targets).map((target) => target[1]);
    }
    return [];
  }, [targets]);

  // table columns
  const columns = React.useMemo(
    () => [
      {
        Header: 'Target',
        accessor: 'targetName',
        Cell: ({ row: { original }, value }) => <TargetName original={original} value={value} />,
        colGroup: 'now',
        colGroupClass: 'name text',
        className: 'text target',
        tooltip: 'The Contact » Company of the Target.',
      },
      {
        Header: 'User',
        accessor: 'collaborators',
        id: 'collaborators',
        Cell: ({ value }) => <ColleagueList list={value} type="collaborators" />,
        disableSortBy: true,
        colGroup: 'data',
        colGroupClass: 'users text',
        className: 'text user-list',
        tooltip: 'If it’s a Personal Target, the Owner of the Target. If it’s shared, all Collaborators.',
      },
      {
        Header: 'Desired Outcome',
        accessor: 'goal',
        id: 'goal',
        sortType: 'basic',
        colGroup: 'data',
        colGroupClass: 'goal text',
        className: 'text objective',
        tooltip: 'The ultimate Objective of the Target.',
      },
      {
        Header: 'Status',
        accessor: 'statusterm',
        id: 'status',
        Cell: ({ row: { original }, value }) => <Status original={original} value={value} />,
        sortType: 'basic',
        colGroup: 'data',
        colGroupClass: 'status text',
        className: 'text status',
        tooltip: 'The <i>current</i> Status of the Target.',
      },
      {
        Header: 'Value',
        accessor: 'valueterm',
        id: 'valueterm',
        Cell: ({ value }) => value?.replace(' Value', ''),
        colGroup: 'data',
        colGroupClass: 'potential text',
        className: 'text center value',
        tooltip: 'The potential Value of the Target (Low | Medium | High).',
      },
      {
        Header: 'Type',
        accessor: 'typeterm',
        id: 'typeterm',
        sortType: 'basic',
        colGroup: 'data',
        colGroupClass: 'clients text',
        className: 'text center type',
        tooltip: 'The Type of the Target (Client | Prospect | Referral Source).',
      },
      {
        Header: (
          <>
            Steps
            <br />
            Taken
          </>
        ),
        accessor: 'steps',
        id: 'steps_taken',
        colGroup: 'data',
        colGroupClass: 'steps_taken text',
        className: 'text center steps',
        tooltip: 'The total number of Steps taken during this date range.',
      },
      {
        Header: (
          <>
            Won
            <br />
            Revenue
          </>
        ),
        accessor: 'revenue',
        id: 'revenue',
        Cell: ({ value }) => (value ? <Revenue value={value} /> : '~'),
        colGroup: 'data',
        colGroupClass: 'revenue numeric',
        className: 'numeric revenue',
        tooltip: 'The current amount of Revenue assigned to the Target.',
      },
    ],
    [],
  );

  // onclick on a row reset the active class
  const hrows = headRows
    .map((row) => {
      if (row) {
        return {
          props: {
            onClick: () => setactiveRow(null),
            className: 'freeze active',
          },
          columns: [],
        };
      } else return {};
    })
    .filter((anyValue) => typeof anyValue !== 'undefined');

  const initialState = {
    sortBy: React.useMemo(() => [{ id: 'targetName', desc: false }], []),
  };
  return (
    <Table
      data={data}
      columns={columns}
      headRows={hrows}
      initialState={initialState}
      getRowProps={(row) => ({
        onClick: () => {
          if (row.id !== activeRow) {
            setactiveRow(row.id);
          }
        },
        className: `${row.id === activeRow ? 'active' : ''}`,
      })}
      getFilterProps={getFilterProps}
    />
  );
};
export default React.memo(TargetsTable);
