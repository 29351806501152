import React from 'react';
import { ReactComponent as DeleteIcon } from '../../../assets/img/delete.svg';

import Table from './Table.component';
import { convertTZ, getUserTimeZone, pluralize } from '../../../helpers';
import moment from 'moment';
import { PDT_TIMEZONE } from '../../../constant/insights';
import { MESSAGES } from '../../../constant';

/**
 * show targets details in tabular format
 * @param {*} table props
 */
const DigestTable = ({ tableData, headRows = [], handleDelete, openDistributionModal }) => {
  // memoised targets data to show
  const data = React.useMemo(() => {
    if (tableData) {
      return Object.entries(tableData).map((target) => target[1]);
    }
    return [];
  }, [tableData]);

  // table columns
  const columns = React.useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
        colGroup: 'now',
        sortType: 'basic',
        colGroupClass: 'name text',
        className: 'text target',
        tooltip: 'The name of the scheduled Digest',
      },
      {
        Header: 'Curated by',
        accessor: 'curated_by',
        sortType: 'basic',
        colGroup: 'data',
        colGroupClass: 'goal text',
        className: 'text objective',
        tooltip: 'Who created the Digest',
      },

      {
        Header: 'Schedule',
        accessor: 'date',
        Cell: ({ row: { original } }) => {
          const timeZone = getUserTimeZone();
          const parsedHour = new Date(original.date).toLocaleString('en-US', { timeZone });
          return (
            <div className="schedule-cell">
              <span>{moment(original.date).format('L')}</span>
              <span>{moment(parsedHour).format('LT')}</span>
            </div>
          );
        },
        sortType: (rowA, rowB, _columnId, _desc) => {
          const dateComparison = new Date(rowB.values.date) - new Date(rowA.values.date);
          if (dateComparison === 0) {
            if (rowA.values.frequency_name < rowB.values.frequency_name) return -1;
            if (rowA.values.frequency_name > rowB.values.frequency_name) return 1;
            return 0;
          } else {
            return dateComparison;
          }
        },
        colGroup: 'data',
        colGroupClass: 'goal text',
        className: 'text objective',
        tooltip: MESSAGES.VIEWER_DIGEST_SCHEDULE_TOOLTIP,
      },
      {
        Header: 'Recipients',
        accessor: 'users',
        Cell: ({ row: { original } }) => (
          <span className="recipient-cell" onClick={() => openDistributionModal({ recipients: original.users })}>
            {pluralize(original.users?.length, 'contact')}
          </span>
        ),
        sortType: (rowA, rowB, _columnId, _desc) => {
          if (rowA.values.users?.length < rowB.values.users?.length) return -1;
          if (rowA.values.users?.length > rowB.values.users?.length) return 1;
          return 0;
        },
        colGroup: 'data',
        colGroupClass: 'goal text',
        className: 'text objective',
        tooltip: MESSAGES.VIEWER_DIGEST_RECIPIENTS_TOOLTIP,
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row: { original } }) => (
          <span className="digest-delete-icon" onClick={() => handleDelete({ id: original.id, name: original.title })}>
            <DeleteIcon />
          </span>
        ),
        colGroup: 'data',
        colGroupClass: 'goal right text',
        className: 'text right objective',
        disableSortBy: true,
      },
      {
        accessor: 'frequency',
        colGroup: 'data',
        colGroupClass: 'hidden',
        className: 'hidden',
        disableSortBy: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // onclick on a row reset the active class
  const hrows = headRows
    .map((row) => {
      if (row) {
        return {
          props: {
            className: 'freeze active',
          },
          columns: [],
        };
      } else return {};
    })
    .filter((anyValue) => typeof anyValue !== 'undefined');

  const initialState = {
    sortBy: React.useMemo(() => [{ id: 'title', desc: false }], []),
  };
  return (
    <>
      <Table data={data} columns={columns} headRows={hrows} initialState={initialState} />
    </>
  );
};
export default React.memo(DigestTable);
