import React, { useCallback, useState } from 'react';

export const ShowMore = (props) => {
  const { text, length, showMoreToggle } = props;
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = useCallback(() => {
    setIsReadMore(!isReadMore);
    showMoreToggle(isReadMore);
  }, [isReadMore, showMoreToggle]);

  return (
    <>
      {isReadMore ? text.slice(0, length) : text}
      {text.length > length ? (
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? ' ...show more' : ' show less'}
        </span>
      ) : null}
    </>
  );
};
