import React from 'react';

import Tags from '../shared/Tags.component';
import { API_PARAMS } from '../../../constant';

/* Pipeline / Archived and Completed Tiles Targets */
const ArchivedAndCompletedTile = (props) => {
  const {
    due: _due,
    completed: _completed,
    status,
    goal,
    valueterm,
    type,
    typeterm,
    revenue,
    revenue_formatted,
    collaborators,
    overlaps,
  } = props.tile;
  let new_status = status;
  if (new_status == 'deleted') {
    new_status = 'archived';
  }
  const taskStatus = { completed: 'done', archived: 'incomplete' };
  const task = props.tile.tasks.find(
    (item) => item.done === taskStatus[new_status] && props.currentuser === item.user_id,
  );

  return (
    <>
      <h2>{status === API_PARAMS.COMPLETED ? goal : task && task.description}</h2>
      {/* <time className={status}>{time ? moment(time).format('MMM Do YYYY') : ''}</time> */}
      <time className={status} />
      <Tags
        detail={status === API_PARAMS.ARCHIVED ? true : false}
        type={type}
        valueterm={valueterm}
        typeterm={typeterm}
        collaborators={collaborators}
        overlaps={overlaps}
        revenue={revenue}
        revenue_formatted={revenue_formatted}
      />
    </>
  );
};

export default ArchivedAndCompletedTile;
