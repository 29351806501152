import React, { useState } from 'react';
import icons from '../../../assets/img/icons.svg';

/**
 * resuable component for keyword based search filter
 * @param {*} props
 */
const TableFilter = ({ searchGlobal, customFilter }) => {
  const [searchKeyword, setSearchKeyword] = useState('');

  // Search targets for the input provided
  const searchTargets = (keyword) => {
    searchGlobal && searchGlobal(keyword);
  };

  // clear search filters
  const clearSearchFilter = () => {
    setSearchKeyword('');
    searchTargets('');
    customFilter('');
  };

  return (
    <>
      <div className="table-filter">
        <input
          placeholder="Filter by keyword"
          className="clearable kwrd-srch"
          value={searchKeyword}
          onChange={(event) => setSearchKeyword(event.target.value)}
          onKeyUp={(event) => {
            searchTargets(event.target.value);
            customFilter(event.target.value);
          }}
        />
        <svg className="icon">
          <use xlinkHref={`${icons}#action-search`} />
        </svg>
        <div className="clear-table-filter" onClick={clearSearchFilter}>
          <svg className="icon">
            <use xlinkHref={`${icons}#action-close`} />
          </svg>
        </div>
      </div>
    </>
  );
};

export default TableFilter;
