import { invoke, updateSetting } from '../../helpers';
import { API_URLS, API_METHODS, API_STATUS_CODE, MESSAGES } from '../../constant';
import toaster from '../shared/toaster.service';

/**
 * Function to call api to for account info update
 * @param {Object} data
 */
export const setNotificationPreferences = (setting) => {
  return invoke({ url: `${API_URLS.SETTING}`, type: API_METHODS.POST }, setting).then(
    (_res) => {
      updateSetting(setting);
      toaster.success(MESSAGES.SETTINGUPDATE);
    },
    (err) => {
      if (!err.response && err.message === MESSAGES.NETWORKERR) {
        toaster.error(MESSAGES.NOINTERNET);
      } else if (err.response.data.code === API_STATUS_CODE.UNAUTHORIZED) {
        toaster.error(err.response.data.message);
      }
    },
  );
};
