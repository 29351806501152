import React, { useState } from 'react';
import './styles.css';

import { pdfjs, Document, Page } from 'react-pdf';
import { getElementName } from '../../../helpers';
import { useSelector } from 'react-redux';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function PdfInsight({ pdfFile, insight }) {
  const [scale, setScale] = useState(1.0);
  const [numPages, setNumPages] = useState();
  const sections = useSelector((state) => state.insightDashboard.sections);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onPageLoad = (page) => {
    const parentDiv = document.querySelector('#pdfDocument');
    let pageScale = parentDiv.clientWidth / (page.originalWidth + 80);
    if (scale !== pageScale) {
      setScale(pageScale);
    }
  };

  return (
    <div id={'pdfDocument'}>
      <header className="pdf-insight-wrapper-header">
        <h1>{insight?.name}</h1>
        <h2>{getElementName(insight?.section_id, sections)}</h2>
      </header>
      <p className="pdf-insight-wrapper">{insight?.description}</p>
      <div className="pdf-wrapper">
        <Document file={{ url: pdfFile }} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.apply(null, Array(numPages))
            .map((x, i) => i + 1)
            .map((page, i) => {
              return (
                <>
                  <p>
                    Page {i + 1} of {numPages}
                  </p>
                  <Page
                    pageNumber={page}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    scale={scale}
                    onLoadSuccess={onPageLoad}
                  />
                </>
              );
            })}
        </Document>
      </div>
    </div>
  );
}
export default PdfInsight;
