import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import { useStore } from 'react-redux';

import '../../assets/css/auto-suggest.css';
import { renderFieldSuggest } from '../../components/targets/targetDetail/InputField';

const RenderFieldWithSuggestion = (inputProps) => {
  if (inputProps.meta) {
    const {
      value,
      place,
      type,
      label,
      name,
      customclass,
      onReturnKeyPress,
      meta: { touched, error, active },
      companyContactError,
    } = inputProps;
    const hasValue = value && value.trim() && !active;
    const showError = error && touched && companyContactError;
    delete inputProps.autoFocus;
    delete inputProps.companyContactError;
    return (
      <div
        className={`field-line ${customclass} ${active ? 'focused' : ''} ${hasValue ? 'has-value' : ''} ${
          showError ? 'error ' : ''
        }`}
      >
        <label>{hasValue ? label : place}</label>
        <input
          type={type}
          maxLength={100}
          autoComplete="off"
          name={name}
          onKeyDown={onReturnKeyPress}
          {...inputProps}
        />
      </div>
    );
  } else {
    return renderFieldSuggest(inputProps);
  }
};

export default RenderFieldWithSuggestion;
/**
 * component for Auto suggest company
 * @param {Object} props
 */
// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => {
  return <>{suggestion}</>;
};

export const AutoSuggestCompany = (props) => {
  let input = {};
  const store = useStore(0);
  const users = store.getState().firms;
  const [items, updateSuggestion] = useState(users);
  let cursorPosition = null;

  const getSuggestionValue = (suggestion) => suggestion;

  const onSuggestionsFetchRequested = ({ value }) => {
    const inputValue = value ? value.trim().toLowerCase() : value;
    const inputLength = inputValue.length;
    const suggestions =
      inputLength === 0 ? [] : users.filter((lang) => lang && lang.toLowerCase().indexOf(inputValue) > -1);
    updateSuggestion(suggestions);
  };

  const onSuggestionsClearRequested = () => {
    updateSuggestion([]);
  };

  const onSuggestionSelected = (event, { method, suggestion }) => {
    props.field && props.form.setFieldValue('company', suggestion);
    if (method === 'click') {
      props.field && props.form.setFieldValue('company', suggestion);
      input.blur();
      event.preventDefault();
    }
    onSuggestionsClearRequested();
  };

  useEffect(() => {
    // used to show cursor on create from open first time
    props.autoFocus && input && input.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.autoFocus]);

  return (
    <Autosuggest
      suggestions={items}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      ref={(autosuggest) => {
        if (autosuggest !== null) {
          input = autosuggest.input;
        }
      }}
      inputProps={{
        ...props,
        ...props.input,
        ...props.field,
        onChange: (event, { newValue, method }) => {
          props.field && props.form.setFieldValue('company', newValue);
          if (props.input) {
            cursorPosition = input.selectionStart;
            props.input.onChange(newValue);
            // this is done for bug id ACKERTV2-449
            // cursor jump to last on type so we reset the cursor positon on create target
            if (method === 'type') {
              const timeout = setTimeout(() => {
                input.setSelectionRange(cursorPosition, cursorPosition);
                clearTimeout(timeout);
              }, 0);
            }
          }
        },
        onBlur: (e, { highlightedSuggestion }) => {
          e.persist();
          if (highlightedSuggestion) {
            props.field && props.form.setFieldValue('company', highlightedSuggestion);
          }
          props.field && props.field.onBlur();
          props.input && props.input.onBlur();
        },
      }}
      onSuggestionSelected={onSuggestionSelected}
      renderInputComponent={RenderFieldWithSuggestion}
    />
  );
};
