import React from 'react';
import '../../assets/css/dialog.css';
import '../../assets/css/forms.css';
import icons from '../../assets/img/icons.svg';
import { yes } from '../../constant';
import { Field, reduxForm } from 'redux-form';
import { setNotificationPreferences } from '../../services/account/setNotifications.service';

/**
 * @description This component render notification screen for user
 * @param {object} $input - {name, onBlur, onChange, onDragStart, onFocus, value, checked}
 * @param {string} $type
 * @param {string} $id
 */
const renderField = ({ input, type, id }) => {
  return (
    <div className="onoffswitch">
      <input {...input} type={type} className="onoffswitch-checkbox" id={id} />
      <label className="onoffswitch-label" htmlFor={id}>
        <span className="onoffswitch-inner"></span>
        <span className="onoffswitch-switch"></span>
      </label>
    </div>
  );
};

let NotificationsForm = (props) => {
  const changeValue = (event) => {
    setNotificationPreferences({ name: event.target.name, value: (+!JSON.parse(event.target.value)).toString() });
  };
  return (
    <>
      <p>
        Control how you want to be notified when certain events occur. Push means that your mobile devices will receive
        a notification (not SMS).
      </p>
      <form>
        <fieldset>
          <ol className="prefs-matrix">
            <li className="header">
              <label>General Notifications</label>
              <span>Email</span>
              <span>Push</span>
            </li>
            <li>
              <label>Weekly Performance Alerts</label>
              <span>
                <Field
                  component={renderField}
                  type="checkbox"
                  name="ping-weekly_alerts-email"
                  id="ping-weekly_alerts-email"
                  onChange={changeValue}
                />
              </span>
              <span>&nbsp;</span>
            </li>
            <li>
              <small>
                <svg className="icon">
                  <use xlinkHref={`${icons}#info`} />
                </svg>
                You will receive a message once a week providing concrete suggestions for actions to take next.
              </small>
            </li>
            <li>
              <label>Daily Nudges</label>
              <span>
                <Field
                  component={renderField}
                  type="checkbox"
                  name="ping-daily_nudges-email"
                  id="ping-daily_nudges-email"
                  onChange={changeValue}
                />
              </span>
              <span>
                <Field
                  component={renderField}
                  type="checkbox"
                  name="ping-daily_nudges-push"
                  id="ping-daily_nudges-push"
                  onChange={changeValue}
                />
              </span>
            </li>
            <li>
              <small>
                <svg className="icon">
                  <use xlinkHref={`${icons}#info`} />
                </svg>
                You will receive a reminder once a day if you have overdue Targets.
              </small>
            </li>
          </ol>
          {props.subscription.targets && props.subscription.targets.features.sharing.on === yes && (
            <>
              <ol className="prefs-matrix">
                <li className="header">
                  <label>Collaboration Notifications</label>
                  <span>Email</span>
                  <span>Push</span>
                </li>
                <li>
                  <label>Personal collaboration requests and responses</label>
                  <span>
                    <Field
                      component={renderField}
                      type="checkbox"
                      name="ping-personal_collaboration_requests-email"
                      id="ping-personal_collaboration_requests-email"
                      onChange={changeValue}
                    />
                  </span>
                  <span>
                    <Field
                      component={renderField}
                      type="checkbox"
                      name="ping-personal_collaboration_requests-push"
                      id="ping-personal_collaboration_requests-push"
                      onChange={changeValue}
                    />
                  </span>
                </li>
                <li>
                  <small>
                    <svg className="icon">
                      <use xlinkHref={`${icons}#info`} />
                    </svg>
                    You will be notified when you are invited to collaborate on a Target, or when a collaborator has
                    responded to your invitation.
                  </small>
                </li>
                <li>
                  <label>Collaboration requests and responses from other colleagues</label>
                  <span>
                    <Field
                      component={renderField}
                      type="checkbox"
                      name="ping-group_collaboration_requests-email"
                      id="ping-group_collaboration_requests-email"
                      onChange={changeValue}
                    />
                  </span>
                  <span>
                    <Field
                      component={renderField}
                      type="checkbox"
                      name="ping-group_collaboration_requests-push"
                      id="ping-group_collaboration_requests-push"
                      onChange={changeValue}
                    />
                  </span>
                </li>
                <li>
                  <small>
                    <svg className="icon">
                      <use xlinkHref={`${icons}#info`} />
                    </svg>
                    You will be notified when one of the people with whom you share a Target accepts or withdraws from
                    any mutual Target.
                  </small>
                </li>
                <li>
                  <label>Status updates on shared Targets</label>
                  <span>
                    <Field
                      component={renderField}
                      type="checkbox"
                      name="ping-shared_target_updates-email"
                      id="ping-shared_target_updates-email"
                      onChange={changeValue}
                    />
                  </span>
                  <span>
                    <Field
                      component={renderField}
                      type="checkbox"
                      name="ping-shared_target_updates-push"
                      id="ping-shared_target_updates-push"
                      onChange={changeValue}
                    />
                  </span>
                </li>
                <li>
                  <small>
                    <svg className="icon">
                      <use xlinkHref={`${icons}#info`} />
                    </svg>
                    You will be notified when a Target you share has been edited, or if a colleague has taken a step to
                    advance that Target.
                  </small>
                </li>
              </ol>
            </>
          )}
          {/*
                        <li className="header">
                            <label>Insights Notifications</label>
                            <span>Email</span>
                            <span>Push</span>
                        </li>
                        <li>
                            <label>
                                New Insights
                                <small>
                                    <svg className="icon">
                                        <use xlinkHref={`${icons}#info`} />
                                    </svg>
                                    You will be notified when you receive a new Insight about growing your pipeline.
                                </small>
                            </label>
                            <span>
                                <Field
                                    component={renderField}
                                    type="checkbox"
                                    name="ping-new_insight-email"
                                    id="ping-new_insight-email"
                                    onChange={changeValue}
                                />
                            </span>
                            <span>
                                <Field
                                    component={renderField}
                                    type="checkbox"
                                    name="ping-new_insight-push"
                                    id="ping-new_insight-push"
                                    onChange={changeValue}
                                />
                            </span>
                        </li>
                        */}
        </fieldset>
      </form>
    </>
  );
};
NotificationsForm = reduxForm({
  // a unique name for the form
  form: 'NotificationsForm',
})(NotificationsForm);
export default NotificationsForm;
