import { LOAD_WORKSHEETS, SAVE_WORKSHEET_REQUEST, SAVE_WORKSHEET_COMMIT } from '../../constant';
import { changeWorksheetsDataInDB } from '../../services/trainer/trainer.service';

const worksheets = (state = [], { type, payload }) => {
  switch (type) {
    case LOAD_WORKSHEETS:
      return payload;
    case SAVE_WORKSHEET_REQUEST:
    case SAVE_WORKSHEET_COMMIT: {
      const tile = changeWorksheetsDataInDB(payload, payload.id);
      return [...state.map((item) => (item.id === tile.id ? tile : item))];
    }
    default:
      return state;
  }
};

export default worksheets;
