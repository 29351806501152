import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import icons from '../../../assets/img/icons.svg';
import renderFieldNormal from '../../shared/RenderFieldNormal';
import RenderFieldMultiline from '../../shared/RenderFieldMultiline';
import RenderFieldNumber from '../../shared/RenderFieldNumber';
import RenderFieldWithActionSheet from '../../shared/RenderFieldWithActionSheet';
import { AutoSuggestCompany } from '../../shared/RenderFieldWithSuggestion';
import renderFieldRadio from '../../shared/RenderFieldRadio';
import { required, requiredCompany, requiredContact } from '../../../helpers';

import '../../../assets/css/dialog.css';
import '../../../assets/css/forms.css';

const DISABLE_MAIL_FIELD = true;

let CreateTargetForm = (props) => {
  const { handleSubmit, hasType, hasValue, submitFailed, onSubmit } = props;
  const [selectedRevenue, setSelectedRevenue] = useState(null);
  const [estimatedValue, setEstimatedValue] = useState('');

  const [companyContactError, setError] = useState(true);

  const toggleActionSheet = (type) => {
    props.toggleActionSheet({ type, open: true });
  };

  const vError = !hasValue && submitFailed;
  const tError = !hasType && submitFailed;

  const focusedCompyContact = (value) => {
    setError(value);
  };

  const renderIntangibleField = () => (
    <div className="radio revenue-radio">
      <label>
        <input
          type="radio"
          value="intangible"
          checked={selectedRevenue === 'intangible'}
          onChange={() => {
            setSelectedRevenue('intangible');
            setEstimatedValue('');
          }}
        />
        <span>Intangible Value</span>
        <div className="check" />
      </label>
    </div>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset className="target" data-chunk="Target">
        <legend>
          <svg className="icon">
            <use xlinkHref={`${icons}#person`} />
          </svg>{' '}
          Identify Your Target
        </legend>
        <p>Select a Company/Contact with strong business development potential.</p>
        <div id={!DISABLE_MAIL_FIELD && 'inline-inputs'}>
          <Field
            component={AutoSuggestCompany}
            type="text"
            id="field-company"
            place="Enter the Company"
            customclass="company"
            maxLength="100"
            name="company"
            label="Company"
            validate={[requiredCompany]}
            autoFocus={props.focusFirst}
            companyContactError={companyContactError}
            onFocus={() => focusedCompyContact(false)}
            onBlur={() => focusedCompyContact(true)}
          />
          <Field
            component={renderFieldNormal}
            type="text"
            id="field-contact"
            placeholder="Enter the name of your Contact"
            customclass="contact"
            maxLength="100"
            name="contact"
            label="Contact"
            validate={[requiredContact]}
            companyContactError={companyContactError}
            onFocus={() => focusedCompyContact(false)}
            onBlur={() => focusedCompyContact(true)}
          />
          {!DISABLE_MAIL_FIELD && (
            <Field
              component={renderFieldNormal}
              type="text"
              id="field-contact"
              placeholder="Enter the email of your Contact"
              customclass="contact"
              maxLength="100"
              name="email"
              label="E-mail"
              validate={[requiredContact]}
              companyContactError={companyContactError}
              onFocus={() => focusedCompyContact(false)}
              onBlur={() => focusedCompyContact(true)}
            />
          )}
        </div>
        <p>Choose the type of Target.</p>
        <ul className={`button-bar input type ${tError && 'error'}`}>
          <Field
            component={renderFieldRadio}
            name="type"
            type="radio"
            value="client"
            label="Client"
            validate={[required]}
          />
          <Field
            component={renderFieldRadio}
            name="type"
            type="radio"
            value="prospect"
            label="Prospect"
            validate={[required]}
          />
          <Field
            component={renderFieldRadio}
            name="type"
            type="radio"
            value="refsource"
            label="Referral Source"
            validate={[required]}
          />
        </ul>
        <p>Assign a value to the Target based on their ability to positively influence your book of business.</p>
        <ul className={`button-bar input value ${vError && 'error'}`}>
          <Field
            component={renderFieldRadio}
            name="value"
            type="radio"
            value="L"
            label="Low Value"
            validate={[required]}
          />
          <Field
            component={renderFieldRadio}
            name="value"
            type="radio"
            value="M"
            label="Medium Value"
            validate={[required]}
          />
          <Field
            component={renderFieldRadio}
            name="value"
            type="radio"
            value="H"
            label="High Value"
            validate={[required]}
          />
        </ul>
      </fieldset>
      <fieldset className="objective" data-chunk="Objective">
        <legend>
          <svg className="icon">
            <use xlinkHref={`${icons}#objective`} />
          </svg>{' '}
          Define Your Desired Outcome
        </legend>
        <p>
          This should be a worthy and practical goal requiring several steps to achieve. Select the lightbulb for
          suggestions, then add your specifics.
        </p>
        <Field
          component={RenderFieldWithActionSheet}
          type="text"
          id="field-objective"
          placeholder="Describe your desired outcome"
          customclass="decorated"
          maxLength="250"
          name="goal"
          label="Objective"
          props={{ toggleActionSheet }}
          icon="action-suggest"
          validate={[required]}
        />
      </fieldset>
      <fieldset className="revenue" data-chunk="Revenue">
        <legend>
          <svg className="icon">
            <use xlinkHref={`${icons}#objective`} />
          </svg>
          Define Your Projected Revenue (optional)
        </legend>
        <p>Enter the amount of revenue estimated from this objective.</p>
        <div className="followup yesno">
          <Field component={renderIntangibleField} name="intangible_revenue" />
          {selectedRevenue !== 'estimated' ? (
            <div className="radio revenue-radio">
              <label onClick={() => setSelectedRevenue('estimated')}>
                <span>Estimate your Projected Revenue</span>
                <div className="check" />
              </label>
            </div>
          ) : (
            <Field component={RenderFieldNumber} name="projected_revenue" value={estimatedValue} />
          )}
        </div>
      </fieldset>
      <fieldset className="step" data-chunk="First Step">
        <legend>
          <svg className="icon">
            <use xlinkHref={`${icons}#first-step`} />
          </svg>{' '}
          Choose Your First Step
        </legend>
        <p>Make it practical, something you can accomplish in the near term. Select the lightbulb for suggestions.</p>
        <Field
          component={RenderFieldWithActionSheet}
          type="text"
          id="field-step"
          placeholder="Describe your first Step"
          enterKeyhint="send"
          customclass="decorated"
          maxLength="250"
          name="taskdescription"
          label="First Step"
          props={{ toggleActionSheet }}
          icon="action-suggest"
          validate={[required]}
        />
        <Field
          component={RenderFieldWithActionSheet}
          type="text"
          id="field-due"
          placeholder="When will you complete this Step?"
          enterKeyhint="send"
          customclass="decorated"
          maxLength="50"
          name="taskdue"
          label="Due Date"
          props={{ toggleActionSheet }}
          icon="action-calendar"
          readOnly={true}
          validate={[required]}
        />
        <Field
          component={RenderFieldMultiline}
          type="text"
          id="field-notes"
          placeholder="Notes (optional)"
          customclass="field-notes decorated"
          maxLength="300"
          name="note"
          label="Notes"
        />
      </fieldset>
    </form>
  );
};

CreateTargetForm = reduxForm({
  // a unique name for the form
  form: 'createEditTarget',
})(CreateTargetForm);
const selector = formValueSelector('createEditTarget'); // <-- same as form name
CreateTargetForm = connect((state) => {
  // can select values individually
  const hasType = selector(state, 'type');
  const hasValue = selector(state, 'value');
  return {
    hasType,
    hasValue,
  };
})(CreateTargetForm);

export default CreateTargetForm;
