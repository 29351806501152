import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as EditIcon } from '../../../assets/img/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/img/delete.svg';
import defaultVideoImage from '../../../assets/img/default-video-image.webp';
import defaultPDFImage from '../../../assets/img/pdf_default.jpg';
import defaultBlogImage from '../../../assets/img/external_url_default.jpg';
import MoreV from '../../../assets/img/More-V.svg';
import Favorite from '../../../assets/img/Heart-Outline.svg';
import FavoriteFilled from '../../../assets/img/Heart-Filled.svg';
import TimeIcon from '../../../assets/img/Time-Circle.png';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addFavorite, contentToDelete, editContent, editVideoInsight, updateDraggedInsight } from '../../../actions';
import './styles.css';
import { useOnClickOutside } from '../../../hooks/onClickOutside';
import { INSIGHTS_MODAL_TYPES } from '../../../constant/modaltypes';
import { getElementId, isMobile, pluralize } from '../../../helpers';
import { BLOG, CREATE_MODALS, FAVORITE, PDF, VIDEO } from '../../../constant/insights';

const EDIT = 'Edit';
const DELETE = 'Delete';

export const InsightCard = ({ insight, setModalType, setEditContent, setNewElementModalOpen }) => {
  const [openOptions, setOpenOptions] = useState(false);
  const [isDragged, setIsDragged] = useState(false);
  const [width, setWidth] = useState(null);
  const tags = useSelector((state) => state.insightDashboard.tags);
  const dispatch = useDispatch();

  const optionsRef = useRef(null);
  const imgRef = useRef(null);

  useOnClickOutside(optionsRef, () => setOpenOptions(false));

  const cardOptions = [
    { id: 1, name: EDIT, onClick: () => handleEdit(), icon: <EditIcon /> },
    { id: 2, name: DELETE, onClick: () => handleDelete(), icon: <DeleteIcon /> },
  ];

  const tagsOptions = useMemo(() => {
    return tags?.map((tag) => ({
      ...tag,
      label: tag?.name,
      name: 'category',
    }));
  }, [tags]);

  const handleDragStart = () => {
    setIsDragged(true);
    dispatch(
      updateDraggedInsight({
        id: insight?.id || insight?.name,
        name: insight?.name,
        thumbnail: insight?.thumbnail_url || getDefaultImage(insight?.content_type),
        duration: insight?.duration,
        url: insight?.file_url,
        externalShare: true,
        description: insight?.description,
        content_type: insight?.content_type,
      }),
    );
  };

  const handleDragEnd = () => {
    dispatch(updateDraggedInsight(null));
    setIsDragged(false);
  };

  const handleEdit = () => {
    setModalType(INSIGHTS_MODAL_TYPES.EDIT);
    setEditContent(insight);
    setOpenOptions(false);
  };

  const handleDelete = () => {
    setNewElementModalOpen(CREATE_MODALS.DELETE);
    dispatch(contentToDelete({ id: insight?.id, name: insight?.name }));
  };

  const isFavorite = (tagNames) => tagNames && tagNames.includes(FAVORITE);

  const handleFavorite = async (id, tagNames) => {
    let newTags = [];

    if (isFavorite(tagNames)) {
      newTags = tagNames.filter((name) => name !== FAVORITE);
    } else {
      newTags = [...tagNames, FAVORITE];
    }

    const bodyFormData = new FormData();
    newTags.length
      ? newTags.forEach((category, index) =>
          bodyFormData.append(`tags_id[${index}]`, getElementId(category, tagsOptions)),
        )
      : bodyFormData.append(`tags_id`, []);

    dispatch(addFavorite({ id, newTags }));
    await editVideoInsight(bodyFormData, id);
  };

  const getDefaultImage = (contentType) => {
    switch (contentType) {
      case VIDEO:
        return defaultVideoImage;
      case PDF:
        return defaultPDFImage;
      default:
        return defaultBlogImage;
    }
  };

  const handleLink = (e, insight) => {
    e.preventDefault();
    window.open(insight.blog_url);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth);
      const updateWindowDimensions = () => {
        setWidth(window.innerWidth);
      };
      window.addEventListener('resize', updateWindowDimensions);
    }
  }, []);

  const cardOption = (option) => (
    <li className="option-li" key={option.id} onClick={option.onClick}>
      {option.icon}
      {option.name}
    </li>
  );

  return (
    <article
      className={`insights-card ${!!isDragged && 'dragged'}`}
      draggable
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragOver={(e) => e.preventDefault()}
    >
      <header>
        {/* Removing name and leaving it blank until interactions are defined  */}
        <span />
        <img src={MoreV} className="card-options" onClick={() => setOpenOptions(true)} />
        <div
          ref={optionsRef}
          className={`options-container ${!!openOptions && 'card-options-open'}`}
          style={{ left: `${width - optionsRef?.current?.getBoundingClientRect()?.right > 130 ? 0 : '-150px'}` }}
        >
          <ul className="option-list">
            {cardOptions.map((option) =>
              option.name === EDIT ? (width > 768 && !isMobile() ? cardOption(option) : null) : cardOption(option),
            )}
          </ul>
        </div>
      </header>
      <Link
        to={`content?id=${insight?.id}`}
        draggable={false}
        target="_blank"
        rel="noopener noreferrer"
        onClick={
          insight.content_type === 'blog' || insight.content_type === BLOG ? (e) => handleLink(e, insight) : null
        }
      >
        <img
          ref={imgRef}
          src={insight?.thumbnail_url}
          draggable={false}
          onError={() => (imgRef.current.src = defaultVideoImage)}
        />
      </Link>
      <section>
        <header>
          <span>{insight?.name}</span>
          <img
            src={isFavorite(insight?.tag_names) ? FavoriteFilled : Favorite}
            onClick={() => handleFavorite(insight.id, insight?.tag_names?.split(', ') || [])}
          />
        </header>
        <span className={insight.description.length > 80 ? 'description-ellipsis' : undefined}>
          {insight?.description}
        </span>
        <footer className="card-footer">
          <div className="time" style={{ visibility: insight?.duration ? 'visible' : 'hidden' }}>
            <img src={TimeIcon} />
            <span>{insight?.duration}</span>
          </div>

          {!!insight?.tag_names?.length && (
            <>
              {/*eslint-disable-next-line react/no-unknown-property*/}
              <div tooltip={insight?.tag_names} tooltip-position="top" className="content-tags">
                <span>{pluralize(insight?.tag_names?.split(',').length, 'Tag')}</span>
              </div>
            </>
          )}
        </footer>
      </section>
    </article>
  );
};
