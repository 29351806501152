import React from 'react';
import { ReactComponent as SearchIcon } from '../../assets/img/search.svg';

const NewSearchButton = ({ onClick }) => (
  <button className="new-search-btn" onClick={onClick}>
    <SearchIcon />
    Search
  </button>
);

export default NewSearchButton;
