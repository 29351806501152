import { createSelector } from 'reselect';
import * as moment from 'moment';
import { sortingOrder } from '../../constant';
import { calculateDays, getUserId } from '../../helpers';

// a varible to check the targets to be filtered
// in case of people targets it will be true.
let filterPeopleTargets = false;

// get applied filter
const getVisibilityFilter = (state) => {
  return state.filterTarget.filterModalProps.filterBy;
};
// get initial data for filter(based on filterPeopleTargets value)
const getTargets = (state) => (filterPeopleTargets ? state.people.targets : state.dashBoard.targets);

const getSortBy = (state) => state.filterTarget.filterModalProps.sortBy;

const getSearchedKeyword = (state) => state.filterTarget.filterModalProps.keyword;

const sortByDueDate = (targets, isAscending) => {
  const currentuser = getUserId();

  return targets.sort((a, b) => {
    const isTileOwnerCurrentUserA = currentuser === a.owner_id;
    const isTileOwnerCurrentUserB = currentuser === b.owner_id;
    if (a.shared === 'pending' && !isTileOwnerCurrentUserA) {
      a.due = null;
    }
    if (b.shared === 'pending' && !isTileOwnerCurrentUserB) {
      b.due = null;
    }
    const f = a.due ? a.due : new Date();
    const l = b.due ? b.due : new Date();
    if (isAscending) {
      return moment(l).format('YYYYMMDD') - moment(f).format('YYYYMMDD');
    } else {
      return moment(f).format('YYYYMMDD') - moment(l).format('YYYYMMDD');
    }
  });
};

/**
 * Search Targets by a value
 */
const getSearchedTargets = createSelector([getSearchedKeyword, getTargets], (keyword, targets) => {
  // reset the filterPeopleTargets value to default;
  filterPeopleTargets = false;
  if (!keyword) return targets;
  const data = [];
  keyword = keyword.toLowerCase();
  for (let i = 0; i < targets.length; i++) {
    const item = targets[i];
    item.days = `due ${calculateDays(item.due)}`;
    const values = `${item.contact} ${item.company} ${item.goal} ${item.days}`;
    if (values.toLowerCase().indexOf(keyword) > -1) {
      data.push(targets[i]);
    }
  }
  return data;
});

/**
 * A selector to select data from redux store without changing the original data
 * Used for filtering the data by value, type and backet
 */
const getFilteredTargets = createSelector(
  [getVisibilityFilter, getSearchedTargets],
  (visibilityFilter = { filterBy: '' }, targets) => {
    switch (visibilityFilter.filterBy) {
      case 'all':
        return targets;
      case 'bucket':
      case 'value':
      case 'type':
        return targets.filter((target) => {
          return target[visibilityFilter.filterBy] === visibilityFilter.value;
        });
      default:
        return targets;
    }
  },
);
/***
 * A selector to sort targets by company, contact and due date
 */
const getSortedTargets = createSelector([getSortBy, getFilteredTargets], (sortBy = { type: 'due' }, targets) => {
  switch (sortBy.type) {
    case 'company':
    case 'contact':
      return targets.sort((a, b) => {
        let f = a[sortBy.type] || '';
        let l = b[sortBy.type] || '';
        if (f.toUpperCase() > l.toUpperCase()) {
          return sortBy.isAscending ? sortingOrder[sortBy.type].lower : sortingOrder[sortBy.type].upper;
        } else if (f.toUpperCase() < l.toUpperCase()) {
          return sortBy.isAscending ? sortingOrder[sortBy.type].upper : sortingOrder[sortBy.type].lower;
        }
        return 0;
      });
    default:
      return sortByDueDate(targets, sortBy.isAscending);
  }
});

// target selection based on isPeopleTarget
const getVisibleTargets = (state, isPeopleTarget) => {
  filterPeopleTargets = Boolean(isPeopleTarget);
  return getSortedTargets(state);
};

export default getVisibleTargets;
