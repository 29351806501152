import React from 'react';

const renderFieldNormal = ({
  input,
  placeholder,
  type,
  label,
  customclass,
  maxLength,
  onReturnKeyPress,
  autoFocus,
  showError,
  companyContactError,
  enterkeyhint,
  meta: { touched, error, active },
}) => {
  const hasValue = input.value && input.value.trim() && !active;

  if (autoFocus) {
    setTimeout(() => {
      window.cordova && window.Keyboard.show();
    }, 1000);
  }

  const showRed = input.name === 'contact' ? error && touched && companyContactError : error && touched;
  return (
    <>
      <div
        className={`field-line ${customclass} ${active ? 'focused' : ''} ${hasValue ? 'has-value' : ''} ${
          showRed ? 'error ' : ''
        }`}
      >
        <label>{hasValue ? label : placeholder}</label>
        <input
          type={type}
          maxLength={maxLength}
          autoComplete="nope"
          {...input}
          onKeyDown={onReturnKeyPress}
          autoFocus={autoFocus}
          enterKeyHint={enterkeyhint}
        />
      </div>
      {showError && touched && error && <span className="error">{error}</span>}
    </>
  );
};

export default renderFieldNormal;
