import React, { useRef } from 'react';
import { capitalize } from 'lodash';

import icons from '../../assets/img/icons.svg';
import { yes, RESOURCE_TYPES } from '../../constant';
import { gTag } from '../../helpers';

const TileCollapse = ({ children, detail }) => {
  const articleRef = useRef(null);

  const handleExpand = () => {
    const element = articleRef.current;
    const allExpandedNodes = document.querySelectorAll('.expanded');
    gTag(`${capitalize(detail.status)}: ${element.id}`, 'Expanded');
    allExpandedNodes.forEach((node) => {
      if (node.id !== element.id) node.classList.remove('expanded');
      gTag(`${capitalize(detail.status)}: ${element.id}`, 'Collapsed');
    });
    element.classList.contains('expanded') ? element.classList.remove('expanded') : element.classList.add('expanded');
  };

  const renderIndicator = () => {
    if (detail.number) return detail.number;

    switch (detail.type) {
      case RESOURCE_TYPES.VIDEO:
        return (
          <svg>
            <use xlinkHref={`${icons}#video`}></use>{' '}
          </svg>
        );
      case RESOURCE_TYPES.AUDIO:
        return (
          <svg>
            <use xlinkHref={`${icons}#audio`}></use>
          </svg>
        );
      case RESOURCE_TYPES.BLOG:
        return (
          <svg>
            <use xlinkHref={`${icons}#blog`}></use>
          </svg>
        );
      default:
        return null;
    }
  };

  return (
    <li onClick={handleExpand}>
      <article ref={articleRef} id={detail.id} className={`lesson worksheet ${detail.type ? 'resource' : ''}`}>
        <h1>
          {!detail.number ? <span className="indicator">{renderIndicator()}</span> : ''}
          <p>{detail.title}</p>
        </h1>
        <div className="sub">
          <div className="tags">
            {detail.duration && (
              <span className="tag">
                <svg className="icon">
                  <use xlinkHref={`${icons}#clock`}></use>
                </svg>
                {detail.duration}
              </span>
            )}
            {detail.worksheet && detail.worksheet.complete === yes ? <span className="tagWork">Complete</span> : <></>}
          </div>
          <span className="caret"></span>
        </div>
        <main onClick={(event) => event.stopPropagation()}>{children}</main>
      </article>
    </li>
  );
};

export default TileCollapse;
