import React from 'react';

import '../../assets/css/action-sheet.css';

/**
 * @desc add class showing-action-sheet
 * @param {object} $children
 * @param {string} $customCssClass
 * @param {string} $title
 * @param {boolean} $isOpen - true/false
 * @param {function} $onRequestClose
 *
 */
const ActionSheet = ({ children, customCssClass, title, isOpen, onRequestClose }) => {
  return (
    <div className={`action-sheet ${customCssClass} ${isOpen ? 'active' : 'd-none'}`}>
      <div className="action-sheet-inr">
        <div className="title">{title}</div>
        {children}
        <button className="action-cancel" onClick={onRequestClose}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ActionSheet;
