export const HIDE_ACTIONSHEET = 'HIDE_ACTIONSHEET';
export const SHOW_ACTIONSHEET = 'SHOW_ACTIONSHEET';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const LOAD_TARGET_DATA = 'LOAD_TARGET_DATA';

export const HIDE_FILTER_MODAL = 'HIDE_FILTER_MODAL';
export const SHOW_FILTER_MODAL = 'SHOW_FILTER_MODAL';

export const LOAD_STANDING = 'LOAD_STANDING';
export const LOAD_FILTERED_DATA = 'LOAD_FILTERED_DATA';
export const FILTER_DATA = 'FILTER_DATA';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const SEARCH = 'SEARCH';
export const SAVE_LOGIN_DATA = 'SAVE_LOGIN_DATA';
export const INIITIAL_SESSION = 'INIITIAL_SESSION';
export const INIITIAL_SESSION_SWITCHBACK = 'INIITIAL_SESSION_SWITCHBACK';

export const SHOW_SORTING_MODAL = 'SHOW_SORTING_MODAL';
export const HIDE_SORTING_MODAL = 'HIDE_SORTING_MODAL';
export const SORT_DATA = 'SORT_DATA';

export const CREATE_TARGET_REQUEST = 'CREATE_TARGET_REQUEST';
export const CREATE_TARGET_COMMIT = 'CREATE_TARGET_COMMIT';
export const CREATE_TARGET_ROLLBACK = 'CREATE_TARGET_ROLLBACK';

export const LOADING_TARGET = 'LOADING_TARGET';
export const UPDATE_SLIDER = 'UPDATE_SLIDER';

export const LOST_TARGET_REQUEST = 'LOST_TARGET_REQUEST';
export const LOST_TARGET_COMMIT = 'LOST_TARGET_COMMIT';
export const LOST_TARGET_ROLLBACK = 'LOST_TARGET_ROLLBACK';

export const DELETE_TARGET_REQUEST = 'DELETE_TARGET_REQUEST';
export const DELETE_TARGET_COMMIT = 'DELETE_TARGET_COMMIT';
export const DELETE_TARGET_ROLLBACK = 'DELETE_TARGET_ROLLBACK';

export const DESTROY_TARGET_REQUEST = 'DESTROY_TARGET_REQUEST';
export const DESTROY_TARGET_COMMIT = 'DESTROY_TARGET_COMMIT';
export const DESTROY_TARGET_ROLLBACK = 'DESTROY_TARGET_ROLLBACK';

export const COMPLETE_TARGET_REQUEST = 'COMPLETE_TARGET_REQUEST';
export const COMPLETE_TARGET_COMMIT = 'COMPLETE_TARGET_COMMIT';
export const COMPLETE_TARGET_ROLLBACK = 'COMPLETE_TARGET_ROLLBACK';

export const EDIT_TARGET_REQUEST = 'EDIT_TARGET_REQUEST';
export const EDIT_TARGET_COMMIT = 'EDIT_TARGET_COMMIT';
export const EDIT_TARGET_ROLLBACK = 'EDIT_TARGET_ROLLBACK';

export const ADD_TASK_REQUEST = 'ADD_TASK_REQUEST';
export const ADD_TASK_COMMIT = 'ADD_TASK_COMMIT';
export const ADD_TASK_ROLLBACK = 'ADD_TASK_ROLLBACK';

export const LOAD_USERS = 'LOAD_USERS';
export const LOAD_FIRMS = 'LOAD_FIRMS';

export const SHARE_TARGET_REQUEST = 'SHARE_TARGET_REQUEST';
export const SHARE_TARGET_COMMIT = 'SHARE_TARGET_COMMIT';
export const SHARE_TARGET_ROLLBACK = 'SHARE_TARGET_ROLLBACK';

export const REJECT_SHARE_TARGET_REQUEST = 'REJECT_SHARE_TARGET_REQUEST';
export const REJECT_SHARE_TARGET_COMMIT = 'REJECT_SHARE_TARGET_COMMIT';
export const REJECT_SHARE_TARGET_ROLLBACK = 'REJECT_SHARE_TARGET_ROLLBACK';

export const REVOKE_SHARE_TARGET_REQUEST = 'REVOKE_SHARE_TARGET_REQUEST';
export const REVOKE_SHARE_TARGET_COMMIT = 'REVOKE_SHARE_TARGET_COMMIT';
export const REVOKE_SHARE_TARGET_ROLLBACK = 'REVOKE_SHARE_TARGET_ROLLBACK';

export const ACCEPT_SHARE_TARGET_REQUEST = 'ACCEPT_SHARE_TARGET_REQUEST';
export const ACCEPT_SHARE_TARGET_COMMIT = 'ACCEPT_SHARE_TARGET_COMMIT';
export const ACCEPT_SHARE_TARGET_ROLLBACK = 'ACCEPT_SHARE_TARGET_ROLLBACK';

export const WORK_ON_TARGET_REQUEST = 'WORK_ON_TARGET_REQUEST';
export const WORK_ON_TARGET_COMMIT = 'WORK_ON_TARGET_COMMIT';
export const WORK_ON_TARGET_ROLLBACK = 'ACCEPT_SHARE_TARGET_ROLLBACK';

export const LOAD_HUD = 'LOAD_HUD';
export const EDIT_CONFIRM = 'EDIT_CONFIRM';

export const HIDE_PIPELINE_CONTROLS_MODAL = 'HIDE_PIPELINE_CONTROLS_MODAL';
export const SHOW_PIPELINE_CONTROLS_MODAL = 'SHOW_PIPELINE_CONTROLS_MODAL';

export const LOAD_INSIGHTS = 'LOAD_INSIGHTS';
export const MARK_INSIGHT_VIEWED_REQUEST = 'MARK_INSIGHT_VIEWED_REQUEST';
export const MARK_INSIGHT_VIEWED_COMMIT = 'MARK_INSIGHT_VIEWED_COMMIT';
export const MARK_INSIGHT_VIEWED_ROLLBACK = 'MARK_INSIGHT_VIEWED_ROLLBACK';
export const DRAG_INSIGHT = 'DRAG_INSIGHT';
export const ADD_DIFFUSION_LIST = 'ADD_DIFFUSION_LIST';
export const SET_PREVIEW_VALUES = 'SET_PREVIEW_VALUES';
export const SET_DIGEST_VALUES = 'SET_DIGEST_VALUES';
export const SET_DIGEST_TEMPLATES = 'SET_DIGEST_TEMPLATES';
export const ADD_NEW_SECTION = 'ADD_NEW_SECTION';
export const GET_SECTIONS = 'GET_SECTIONS';
export const GET_INSIGHTS = 'GET_INSIGHTS';
export const GET_TAGS = 'GET_TAGS';
export const ADD_NEW_TAG = 'ADD_NEW_TAG';
export const DELETE_INSIGHT = 'DELETE_INSIGHT';
export const INSIGHT_TO_DELETE = 'INSIGHT_TO_DELETE';
export const FILTERED_INSIGHTS = 'FILTERED_INSIGHTS';
export const INSIGHT_SEARCH_VALUES = 'INSIGHT_SEARCH_VALUES';
export const EDIT_INSIGHT = 'EDIT_INSIGHT';
export const ADD_NEW_TEMPLATE = 'ADD_NEW_TEMPLATE';
export const ADD_DIGEST = 'ADD_DIGEST';
export const GET_DIGESTS = 'GET_DIGESTS';
export const NEW_DIFFUSION_LIST = 'NEW_DIFFUSION_LIST';
export const GET_DIFFUSION_LIST = 'GET_DIFFUSION_LIST';
export const ADD_FAVORITE = 'ADD_FAVORITE';
export const POPULATE_CONTENT = 'POPULATE_CONTENT';
export const REMOVE_POPULATE_CONTENT = 'REMOVE_POPULATE_CONTENT';
export const DELETE_DIGEST = 'DELETE_DIGEST';
export const DELETE_SECTION = 'DELETE_SECTION';
export const RESTORE_SEARCH = 'RESTORE_SEARCH';
export const SECTION_TO_DELETE = 'SECTION_TO_DELETE';
export const RESET_DIGEST = 'RESET_DIGEST';

export const HIDE_LOADING = 'HIDE_LOADING';
export const SHOW_LOADING = 'SHOW_LOADING';
export const LOAD_INSIGHTS_COUNT = 'LOAD_INSIGHTS_COUNT';
export const REDUCE_INSIGHTS_COUNT = 'REDUCE_INSIGHTS_COUNT';

export const SHOW_COLLABORATORS_DIALOG = 'SHOW_COLLABORATORS_DIALOG';
export const HIDE_COLLABORATORS_DIALOG = 'HIDE_COLLABORATORS_DIALOG';
export const USER_SELECTION = 'USER_SELECTION';
export const UPDATE_LOGO = 'UPDATE_LOGO';

export const UPDATE_YTD_REVENUE_ROLLBACK = 'UPDATE_YTD_REVENUE_ROLLBACK';
export const UPDATE_YTD_REVENUE_REQUEST = 'UPDATE_YTD_REVENUE_REQUEST';
export const UPDATE_YTD_REVENUE_COMMIT = 'UPDATE_YTD_REVENUE_COMMIT';
export const SHOW_NEW_LOOK_INFO = 'SHOW_NEW_LOOK_INFO';
export const HIDE_NEW_LOOK_INFO = 'HIDE_NEW_LOOK_INFO';
export const REMOVE_ATTENTION = 'REMOVE_ATTENTION';
export const GET_ATTENTION = 'GET_ATTENTION';
export const LOAD_REQUEST_COUNT = 'LOAD_REQUEST_COUNT';
export const REDUCE_REQUEST_COUNT = 'REDUCE_REQUEST_COUNT';
export const UPDATE_STEP = 'UPDATE_STEP';
export const CALL_HUD = 'CALL_HUD';
export const RESET_PEOPLE_HUD = 'RESET_PEOPLE_HUD';

export const LOAD_LESSONS = 'LOAD_LESSONS';
export const LOAD_LESSON_DETAILS = 'LOAD_LESSON_DETAILS';
export const LOAD_WORKSHEETS = 'LOAD_WORKSHEETS';
export const SAVE_WORKSHEET_REQUEST = 'SAVE_WORKSHEET_REQUEST';
export const SAVE_WORKSHEET_COMMIT = 'SAVE_WORKSHEET_COMMIT';
export const SAVE_WORKSHEET_ROLLBACK = 'SAVE_WORKSHEET_ROLLBACK';
export const LOAD_RESOURCES = 'LOAD_RESOURCES';

export const LOAD_BLOGS = 'LOAD_BLOGS';
export const LOAD_BLOG_DETAILS = 'LOAD_BLOG_DETAILS';
// export const LOAD_WORKSHEETS = 'LOAD_WORKSHEETS';
export const BLOG_FILTER_KEYWORD = 'BLOG_FILTER_KEYWORD';
export const FILTER_BLOGS = 'FILTER_BLOGS';

export const VIEWER_DATA_REQUEST = 'VIEWER_DATA_REQUEST';
export const VIEWER_DATA_RECEIVED = 'VIEWER_DATA_RECEIVED';
export const LOAD_VIEWER = 'LOAD_VIEWER';
export const LOAD_OVERVIEW_CHARTS = 'LOAD_OVERVIEW_CHARTS';
export const LOAD_OVERVIEW_TABLE = 'LOAD_OVERVIEW_TABLE';
export const SELECTED_GROUP_OR_FIRM = 'SELECTED_GROUP_OR_FIRM';
export const UPDATE_REPORT_OF = 'UPDATE_REPORT_OF';
export const RESET_REPORT_OF = 'RESET_REPORT_OF';
export const LOAD_HIGHLIGHTS_DATA = 'LOAD_HIGHLIGHTS_DATA';
export const HIGHLIGHTS_DATA_FAILURE = 'HIGHLIGHTS_DATA_FAILURE';
export const TARGETS_TABLE_REQUEST = 'TARGETS_TABLE_REQUEST';
export const TARGETS_TABLE_SUCCESS = 'TARGETS_TABLE_SUCCESS';
export const TARGETS_TABLE_FAILURE = 'TARGETS_TABLE_FAILURE';
export const CLIENTS_TABLE_REQUEST = 'CLIENTS_TABLE_REQUEST';
export const CLIENTS_TABLE_SUCCESS = 'CLIENTS_TABLE_SUCCESS';
export const CLIENTS_TABLE_FAILURE = 'CLIENTS_TABLE_FAILURE';
export const OVERLAPS_TABLE_REQUEST = 'OVERLAPS_TABLE_REQUEST';
export const OVERLAPS_TABLE_SUCCESS = 'OVERLAPS_TABLE_SUCCESS';
export const OVERLAPS_TABLE_FAILURE = 'OVERLAPS_TABLE_FAILURE';
export const PROGRESS_TABLE_SUCCESS = 'PROGRESS_TABLE_SUCCESS';
export const PROGRESS_TABLE_FAILURE = 'PROGRESS_TABLE_FAILURE';
export const WORKSHEETS_TABLE_SUCCESS = 'WORKSHEETS_TABLE_SUCCESS';
export const WORKSHEETS_TABLE_FAILURE = 'WORKSHEETS_TABLE_FAILURE';
export const USER_WORKSHEETS_REQUEST = 'USER_WORKSHEETS_REQUEST';
export const USER_WORKSHEETS_SUCCESS = 'USER_WORKSHEETS_SUCCESS';
export const USER_WORKSHEETS_FAILURE = 'USER_WORKSHEETS_FAILURE';

export const GET_PEOPLE_TARGETS = 'GET_PEOPLE_TARGETS';
export const CREATE_PEOPLE_TARGETS = 'CREATE_PEOPLE_TARGETS';
export const EDIT_PEOPLE_TARGETS = 'EDIT_PEOPLE_TARGETS';
export const DELETE_PEOPLE_TARGETS = 'DELETE_PEOPLE_TARGETS';
export const COMPLETE_PEOPLE_TARGETS = 'COMPLETE_PEOPLE_TARGETS';
export const WORK_ON_PEOPLE_TARGETS = 'WORK_ON_PEOPLE_TARGETS';
export const CREATE_PEOPLE_TASK = 'CREATE_PEOPLE_TASKS';
export const RESET_PEOPLE_TARGETS = 'RESET_PEOPLE_TARGETS';
export const UPDATE_REPORT_WHICH = 'UPDATE_REPORT_WHICH';
export const SHARE_PEOPLE_TARGET_REQUEST = 'SHARE_PEOPLE_TARGET_REQUEST';
export const REVOKE_PEOPLE_SHARE_TARGET_REQUEST = 'REVOKE_PEOPLE_SHARE_TARGET_REQUEST';
export const LOADER_PEOPLE_REQUEST = 'LOADER_PEOPLE_REQUEST';

export const SWITCHED_USER = 'SWITCHED_USER';

export const SW_INIT = 'SW_INIT';

export const GET_TARGET_NOTES = 'GET_TARGET_NOTES';
export const GET_TILES_NOTES = 'GET_TILES_NOTES';
export const EDIT_TARGET_NOTE = 'EDIT_TARGET_NOTE';
export const ADD_TARGET_NOTE = 'ADD_TARGET_NOTE';
export const GET_FIRM_CONTENT = 'GET_FIRM_CONTENT';
export const REMOVE_FIRM_CONTENT = 'REMOVE_FIRM_CONTENT';
export const UPDATE_FIRM_CONTENT = 'UPDATE_FIRM_CONTENT';
export const SHOW_MORE_CONTENT = 'SHOW_MORE_CONTENT';
export const FILTER_LESSONS = 'FILTER_LESSONS';
export const LESSON_FILTER_KEYWORD = 'LESSON_FILTER_KEYWORD';
export const TRACK_TARGET_NOTE = 'TRACK_TARGET_NOTE';
