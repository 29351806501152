import React from 'react';

import icons from '../../../assets/img/icons.svg';

/* Pipeline / Active Tiles Targets page functionality */
const SharedTile = (props) => {
  return (
    <>
      <h2>{props.tile.goal}</h2>
      <footer>
        <p>
          <span className="icon">
            <svg className="icon">
              <use xlinkHref={`${icons}#shared`} />
            </svg>
          </span>
        </p>
        <p>You have been invited to collaborate on this Target. Open it to review &amp; respond.</p>
      </footer>
    </>
  );
};

export default SharedTile;
