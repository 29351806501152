import React from 'react';
import { createPortal } from 'react-dom';
import ContentLoader from 'react-content-loader';

import '../../assets/css/loader.css';

const TileLoader = () => (
  <ContentLoader height={200} width={400} speed={2} primaryColor="#e8e8e8" secondaryColor="#d6d6d6">
    <rect x="0" y="0" rx="3" ry="3" width="145" height="30" />
    <rect x="150" y="0" rx="3" ry="3" width="300" height="30" />
    <rect x="0" y="40" rx="10" ry="10" width="400" height="400" />
  </ContentLoader>
);
export default TileLoader;

export const LineChartLoader = () => (
  <ContentLoader speed={1}>
    <path d="M5.56,93.77c43.7,0,61.47-21.16,90.08-21.16,19.39,0,32.08,16.21,55.45,16.21,29.08,0,42.57-28.56,72.77-28.56,23.93,0,54.22,15.33,72.77,15.33,46.26,0,76-39.32,103.08-39.32.66,0,0,88.64,0,88.64H5.56Z" />
    <rect width="2.09" height="127.91" />
    <rect y="127.91" width="399.71" height="2.09" />
  </ContentLoader>
);

/**
 * Default: full page loader
 * Customize: Pass style object to customize height & width
 * @param {object} stlye containing { height, width}
 */
export const Loader = ({ style = {} }) => {
  return createPortal(
    <div className="spinner-container" style={style}>
      <div className="spinner"></div>
    </div>,
    document.getElementById('root'),
  );
};
