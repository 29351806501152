import { LOAD_BLOGS, LOAD_BLOG_DETAILS } from '../../constant';

const initialState = {
  data: [],
  listing: {},
  details: {},
};

const blogs = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BLOGS:
      return {
        ...state,
        ...action.payload,
      };
    case LOAD_BLOG_DETAILS:
      return {
        ...state,
        details: action.payload,
      };
    default:
      return state;
  }
};

export default blogs;
