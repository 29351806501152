import React, { Component } from 'react';
import { connect } from 'react-redux';

import dashboardHOC from '../../shared/DashboardHOC.container';
import { Listing, NavSecondary } from '../../../components';
import { API_PARAMS, BLOG_STATUS, MESSAGES } from '../../../constant';
import {
  getInsightsFromlocalDB,
  getBlogData,
  saveWorksheetMatrix,
  deleteContent,
  contentToDelete,
  removeSection,
  sectionToBeDeleted,
} from '../../../actions';
// /insights/insights.action';
import { showLoader, showFilter, setAttention } from '../../../../src/actions';
import { groupBy } from 'lodash';
import PreviewModal from '../../../components/previewModal';
import { EXPANDED_CONTENT, PREVIEW_MODAL } from '../../../constant/insights';
import { SEARCH_MODAL } from '../../../constant/modaltypes';
import SearchInsights from '../../../components/common/searchModal/SearchInsights';
import ExpandedInsight from '../expandedInsight';
import CreateModal from '../../../components/common/createModal';
import toaster from '../../../services/shared/toaster.service';
// import BlogFilter from '../../Insights/listing/BlogFilter.container';
// import { getUserData } from '../../src/helpers';

// import {
//     getTrainerData,
//     saveWorksheetMatrix,
//     showLoader,
//     showFilter
// } from '../../../actions';

/** Dashboard container to wrap all the components avalible on dashboard */
class Insights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // status: ''
      status: API_PARAMS,
      shared: [],
      targets: [],
      activeHUD: '',
      activeTabs: [],
      modalType: '',
      newElementModalOpen: '',
      showInsightContent: false,
    };
  }

  componentDidMount() {
    this.defaultTab();
    const {
      match: { params },
    } = this.props;
    // this.showTabs();
    if (params) {
      let { status } = params;
      this.init(status);
      this.setState({ showInsightContent: params.status === EXPANDED_CONTENT });
    } else {
      this.init(API_PARAMS.BLOGCONTENT);
    }
  }
  x;
  componentDidUpdate(prevProps) {
    const { match: { params: prevParams = {} } = {} } = prevProps;
    const { match: { params = {} } = {} } = this.props;
    if (params.status !== prevParams.status) {
      this.defaultTab();
      this.setState({ showInsightContent: params.status === EXPANDED_CONTENT });
    }
  }

  /**
   * @description open insight status page
   * @param {string} status - new || viewed
   */
  getInsights = (status) => {
    // this is for scrolling to the recently created item
    if (this.props.attention.id) {
      this.props.getAttention({
        list: this.props.insights,
        attention: this.props.attention,
      });
    }
    this.setState({ status }, () => {
      this.props.getInsights(status, this.props.attention.id);
    });
  };

  /**
   * @description set default tab view to new, if no params
   */
  defaultTab = () => {
    const {
      match: { params },
    } = this.props;
    if (params) {
      let { status } = params;

      if (this.state.status !== status) this.getInsights(status);
    } else {
      this.getInsights(API_PARAMS.BLOGCONTENT);
    }
  };

  init = (status) => {
    const { attention, blogData, getAttention, getBlogData } = this.props;

    const showLoader = blogData.length || attention.id;
    getBlogData(status, showLoader);
    if (attention.id) {
      getAttention({ list: blogData, attention });
    }
    this.setState({ status });
  };

  filteredBlog = () => {
    const { blogs, blogData, filterBy } = this.props;
    if (blogData)
      return groupBy(
        blogData.filter((blog) => (blog.catgeory !== null && blog.type !== null ? true : false)),
        'category',
      );

    switch (filterBy.value) {
      case BLOG_STATUS.NOT_STARTED:
        return groupBy(
          blogData.filter((blog) => blog.watched?.seconds === 0),
          'category',
        );
      case BLOG_STATUS.NOT_COMPLETED:
        return groupBy(
          blogData.filter((blog) => blog.watched?.seconds > 0 && blog.watched?.done === 'no'),
          'category',
        );
      case BLOG_STATUS.COMPLETED:
        return groupBy(
          blogData.filter((blog) => blog.watched?.done === 'yes'),
          'category',
        );
      case BLOG_STATUS.WORKSHEET:
        return groupBy(
          blogData.filter((blog) => blog.worksheet !== ''),
          'category',
        );
      case BLOG_STATUS.NO_WORKSHEET:
        return groupBy(
          blogData.filter((blog) => blog.worksheet === ''),
          'category',
        );
      default:
        return blogs;
    }
  };

  openInsightsModal = (modalType) => {
    this.setState({ modalType });
  };

  setNewElementModalOpen = (newElementModalOpen) => {
    this.setState({ newElementModalOpen });
  };

  handleContentDelete = async () => {
    try {
      if (this.props.insightToDelete) {
        await this.props.deleteSelectedContent(this.props.insightToDelete?.id);
        this.props.removeInsightToDelete();
      } else {
        await this.props.deleteSection(this.props.sectionToDelete?.id);
        this.props.removeSectionToDelete();
      }
      toaster.success('Element was deleted successfully');
    } catch (error) {
      console.error(error);
      toaster.error(MESSAGES.ERROR_REQUEST);
    }
  };

  render() {
    const { worksheets, resources, saveWorksheetMatrix, getAttention, openFilter } = this.props;
    const filteredBlog = this.filteredBlog();
    return (
      <>
        {!!this.state.newElementModalOpen &&
          (this.state.newElementModalOpen === PREVIEW_MODAL ? (
            <PreviewModal setNewElementModalOpen={this.setNewElementModalOpen} />
          ) : (
            <CreateModal
              newElementModalOpen={this.state.newElementModalOpen}
              setNewElementModalOpen={this.setNewElementModalOpen}
              deleteAction={this.handleContentDelete}
              deleteItem={this.props.insightToDelete?.name || this.props.sectionToDelete?.name}
            />
          ))}

        {this.state.showInsightContent ? (
          <ExpandedInsight />
        ) : (
          <>
            <Listing
              insights={this.props.insights}
              status={this.state.status}
              loading={this.props.loading}
              setAttention={this.props.setAttention}
              saveWorksheetMatrix={saveWorksheetMatrix}
              getAttention={getAttention}
              blogs={filteredBlog}
              worksheets={worksheets}
              resources={resources}
              ref={this.trainerRef}
              modalType={this.state.modalType}
              setModalType={this.openInsightsModal}
              newElementModalOpen={this.state.newElementModalOpen}
              setNewElementModalOpen={this.setNewElementModalOpen}
            />
            <NavSecondary
              status={this.state.status}
              type="insights"
              openFilter={openFilter}
              setModalType={this.openInsightsModal}
              modalType={this.state.modalType}
              loading={this.props.loading}
            />
            {this.state.modalType === SEARCH_MODAL.INSIGHTS && (
              <SearchInsights modalType={this.state.modalType} setModalType={this.openInsightsModal} />
            )}
          </>
        )}

        {/* <BlogFilter></BlogFilter> */}
      </>
    );
  }
}

// map dispatch to props
const mapDispatchToProps = (dispatch) => ({
  getInsights: (status, attention) => {
    dispatch(getInsightsFromlocalDB(status, attention));
  },
  getBlogData: (status, attention) => {
    if (!attention) dispatch(showLoader());
    return dispatch(getBlogData(status));
  },
  setAttention: (id) => dispatch(setAttention({ id })),
  openFilter: () => dispatch(showFilter()),
  showLoader: () => dispatch(showLoader()),
  saveWorksheetMatrix: (lessonId, matrix) => dispatch(saveWorksheetMatrix(lessonId, matrix)),
  deleteSelectedContent: (id) => dispatch(deleteContent(id)),
  deleteSection: (id) => dispatch(removeSection(id)),
  removeInsightToDelete: () => dispatch(contentToDelete(null)),
  removeSectionToDelete: () => dispatch(sectionToBeDeleted(null)),
});

// map state to props
const mapStateToProps = (state) => ({
  insights: state.insightDashboard.insights,
  loading: state.loader,
  attention: state.attention,

  blogData: state.blogs.data,
  blogs: state.blogs.listing || {},
  resources: state.resources,
  filterBy: state.blogsFilter.filterModalProps.filterBy,
  keyword: state.blogsFilter.filterModalProps.keyword,
  worksheets: state.worksheets,
  insightToDelete: state.insightDashboard.deleteInsight,
  sectionToDelete: state.insightDashboard.deleteSection,
});

export default dashboardHOC(connect(mapStateToProps, mapDispatchToProps)(Insights));
