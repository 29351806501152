import {
  login,
  resetPasswordApi,
  newPasswordApi,
  switchUserApi,
  submitOTPApi,
  loginSSO,
} from '../../services/user/user.service';
import toaster from '../../services/shared/toaster.service';
import { askPermissionToSendNotification } from '../../services/shared/pushNotification.service';
import { initialize } from 'redux-form';
import { SAVE_LOGIN_DATA, API_STATUS_CODE, MESSAGES, ROUTES_PATH, SWITCHED_USER } from '../../constant';
import { showLoader, getAllInsights, getAllUsers } from '..';
import history from '../../helpers/history';
import { dataBaseLoginFallBack, openDBforSwitchedUser, syncWithServer } from '../../helpers/dbStorage';
import dowloadLogo from '../shared/logo.action';
import { listenToFirebaseDB } from '../../services/shared/sync.service';
import { getLastName, getFirstName, getRedirectUrl, emailValidation } from '../../helpers';
import { hideLoader } from '../shared/loader.action';
import { resetGraphOf } from '../../actions/';
import { getAllTiles } from '../targets/dashboard.action';

export const setSwitchedUser = (switchedUser = false) => ({
  type: SWITCHED_USER,
  payload: switchedUser,
});

/**
 * action to get and save data for switching the user
 * @param { user_id } decoded user id
 */
export const switchUser =
  (user_id, fromLogin = false) =>
  (dispatch) => {
    return switchUserApi(user_id).then(
      (response) => {
        response.fullname = `${response.first_name} ${response.last_name}`;
        const adminName = `${getFirstName()} ${getLastName()}`;
        const session = { user: response, token: response.Switch.token };
        session.user.Switch.revert.name = adminName;
        dispatch(resetGraphOf());
        dispatch({
          type: 'SAVE_LOGIN_DATA',
          payload: {
            ...session,
          },
        });
        if (!fromLogin) {
          openDBforSwitchedUser(user_id);
        } else {
          history.push(ROUTES_PATH.DASHBOARD);
          syncWithServer(fromLogin);
          localStorage.removeItem('switchUser');
        }
        toaster.success(`${MESSAGES.SWITCHEDUSER}${response.fullname}`);
      },
      (error) => {
        let msg = MESSAGES.UnknownError;
        if (error.response && error.response.data && error.response.data.data.status === API_STATUS_CODE.UNAUTHORIZED) {
          msg = error.response.data.message;
        }
        toaster.error(msg);
      },
    );
  };
/**
 * @description save login data
 * @param {*} response
 */
export const saveLoginData = (response) => (dispatch) => {
  response.user.fullname = `${response.user.first_name} ${response.user.last_name}`;
  dispatch(resetGraphOf());
  dispatch({
    type: SAVE_LOGIN_DATA,
    payload: response,
  });
  dispatch(showLoader());
  if (!localStorage.switchUser || localStorage.switchUser === response.user.id) {
    dispatch(getAllTiles(true));
    dispatch(getAllUsers(true, '', response));
    dispatch(getAllInsights());
    listenToFirebaseDB();
    // store the token as a cookie ... don't do this yet! possible ripple effects
    // document.cookie = 'ackauth=' + (response.token) + ';';
    askPermissionToSendNotification();
    history.push(getRedirectUrl(response, 'saveLoginData'));
  } else {
    dispatch(switchUser(localStorage.switchUser, true));
  }
  window.refresh = 1;
  const logo = response.user.Org.logo;
  if (logo) {
    dispatch(dowloadLogo(logo));
  }
};
const loginErrorHandle = (error) => (dispatch) => {
  let msg = MESSAGES.UnknownError;
  if (!error.response && error.message === MESSAGES.NETWORKERR) {
    msg = MESSAGES.NOINTERNET;
  } else if (
    error.response &&
    error.response.data &&
    error.response.data.data &&
    error.response.data.data.status === API_STATUS_CODE.FORBIDDEN
  ) {
    msg = MESSAGES.invalidUsername;
  }
  dispatch(hideLoader());
  toaster.error(msg);
};
/**
 * action to send data to login service and handle response
 * @param {object} data  {username, password}
 */
export const submitLoginDetails = (data) => (dispatch) => {
  dataBaseLoginFallBack();
  return login(data)
    .then((response) => {
      if (!response.challenge) {
        dispatch(saveLoginData(response));
      } else {
        history.push(`${ROUTES_PATH.OTP}/${response.email}/${response.message}`);
        dispatch(hideLoader());
      }
    })
    .catch((error) => {
      dispatch(loginErrorHandle(error));
    });
};

/**
 * action to send data to login service and handle response
 * @param {object} data  {username, password}
 */
export const resetPassword = (data) => (dispatch) => {
  if (emailValidation(data.email)) {
    toaster.error('Invalid Email');
  } else {
    return resetPasswordApi(data).then(
      (response) => {
        toaster.success(response.message);
        dispatch(initialize('ResetPasswordForm'));
        history.goBack();
      },
      (error) => {
        let msg = MESSAGES.UnknownError;
        if (error.response && error.response.data && error.response.data.data.status === API_STATUS_CODE.UNAUTHORIZED) {
          msg = error.response.data.message;
        }
        toaster.error(msg);
        dispatch(initialize('ResetPasswordForm'));
      },
    );
  }
};
/**
 * action to send data to new passwors service and handle response
 * @param {object} data  {username, password}
 */
export const newPassword = (data) => (dispatch) => {
  dispatch(showLoader());
  return newPasswordApi(data).then(
    () => {
      dispatch(hideLoader());
      toaster.success(MESSAGES.PWDCHANGE);
      dispatch(initialize('NewPasswordForm'));
      history.replace(ROUTES_PATH.LOGIN);
    },
    (error) => {
      let msg = MESSAGES.UnknownError;
      if (error.response && error.response.data && error.response.data.data.status === API_STATUS_CODE.UNAUTHORIZED) {
        msg = error.response.data.message;
      }
      dispatch(hideLoader());
      toaster.error(msg);
      dispatch(initialize('NewPasswordForm'));
    },
  );
};

export const submitOTP = (data) => (dispatch) => {
  dispatch(showLoader());
  return new Promise((resolve, rejected) => {
    return submitOTPApi(data).then(
      (response) => {
        if (response.error) {
          rejected(response.message);
          dispatch(hideLoader());
        } else {
          dispatch(hideLoader());
          resolve(response.message);
          dispatch(saveLoginData(response));
        }
      },
      (error) => {
        dispatch(initialize('OtpForm', { code: '', email: data.email }));
        dispatch(loginErrorHandle(error));
      },
    );
  });
};

export const submitAuth0LoginDetails = (data) => (dispatch) => {
  dataBaseLoginFallBack();

  return loginSSO(data)
    .then((response) => {
      if (!response.challenge) {
        dispatch(saveLoginData(response));
      }
    })
    .catch((error) => {
      dispatch(loginErrorHandle(error));
    });
};
