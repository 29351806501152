import React from 'react';
import ChartistGraph from 'react-chartist';
import Chartist from 'chartist';
import ReactTooltip from 'react-tooltip';
import { overviewTabs } from '../../../constant';

const seriesNumber = {
  0: 'a',
  1: 'b',
  2: 'c',
  3: 'd',
};

// the months changed
const isNewMonth = function (date, index, _labels) {
  const dt = new Date(date);

  // handle zero-based day parsing
  dt.setDate(dt.getDate() + 1);

  return dt.getDate() === 1 || index === 0;
};

// should we display the label or point?
const shouldDisplayPoint = (value, index, labels) => {
  if (!value) return false;

  // last item in the series doesn't pass in a labels array
  if (!labels) labels = [];

  // calculate based on width of chart
  const w = document.getElementById('graph').clientWidth,
    itemw = w / labels.length,
    spacer = Math.floor(w / itemw / 10);

  // if our divisor says so, or we have a new month
  return index % spacer === 0 || isNewMonth(value, index, labels);
};

// track if we've switched to a new year
const showDateLabel = (value, index, _labels) => {
  const ts = Date.parse(value),
    dt = new Date(ts);

  // handle zero-based day parsing
  dt.setDate(dt.getDate() + 1);

  // if it's january 1, show the year
  if (dt.getDate() === 1 && dt.getMonth() === 0) {
    return dt.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  }

  // if it's a new month, show the month name
  if (dt.getDate() === 1 || index === 0) {
    return dt.toLocaleString('en-US', { month: 'short', day: 'numeric' });
  }

  // otherwise, just return the day
  return dt.getDate().toString();
};

// format numbers according for american financial standards
const commaSeparatedNumber = (val) => {
  while (/(\d+)(\d{3})/.test(val.toString())) {
    val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
  }
  return val;
};

// main options for the chart
const graphOptions = {
  showPoint: true,
  showArea: true,
  low: 0,
  fullWidth: true,
  lineSmooth: Chartist.Interpolation.monotoneCubic({
    fillHoles: true,
    tension: 0.1,
  }),
  chartPadding: {
    left: 0,
    top: 10,
    right: 10,
    bottom: 20,
  },
  axisX: {
    showGrid: true,
    showLabel: true,
    labelOffset: {
      x: -5,
      y: 5,
    },
    labelInterpolationFnc: (value, index, labels) => {
      return shouldDisplayPoint(value, index, labels) ? showDateLabel(value, index, labels) : null;
    },
  },
  axisY: {
    showGrid: true,
    showLabel: true,
    onlyInteger: true,
    labelOffset: {
      x: 0,
      y: 8,
    },
    labelInterpolationFnc: (number) => {
      if (number >= 1e15) {
        return (number / 1e15).toFixed(1).replace(/\.0$/, '') + 'Q';
      } else if (number >= 1e12) {
        // If number is in the trillions
        return (number / 1e12).toFixed(1).replace(/\.0$/, '') + 'T';
      } else if (number >= 1e9) {
        // If number is in the billions
        return (number / 1e9).toFixed(1).replace(/\.0$/, '') + 'B';
      } else if (number >= 1e6) {
        // If number is in the millions
        return (number / 1e6).toFixed(1).replace(/\.0$/, '') + 'M';
      } else if (number >= 1e3) {
        // If number is in the thousands
        return (number / 1e3).toFixed(1).replace(/\.0$/, '') + 'K';
      } else {
        return number.toString();
      }
    },
  },
};

// data elements to points
const pointDataElements = (data, graphdata, pointShowing, newMonth) => {
  // if we don't want this point, remove it
  if (!pointShowing) {
    data.element.remove();
  } else {
    // if we're on a new month, mark with month-start
    let pointclasses = data.element.attr('class') + ' poptip';
    if (newMonth) pointclasses += ' month-start';
    data.element.attr({
      class: pointclasses,
    });

    // determine how to display our value will use this

    let value = data.value.y;
    if (graphdata.which === overviewTabs[1].which) {
      value = '$' + commaSeparatedNumber(value);
    }
    data.element.attr({
      'data-tip': data.series.name + '<br>' + value,
      'data-for': 'tip',
    });
    ReactTooltip.rebuild();
  }
};

// graph draw listener
const onGraphDraw = (data, graphdata) => {
  // determine if we're showing this point
  const pointShowing = shouldDisplayPoint(graphdata.labels[data.index], data.index, graphdata.labels),
    newMonth = isNewMonth(graphdata.labels[data.index], data.index, graphdata.labels);

  // add data elements to points for use in poptips
  if (data.type === 'point') {
    pointDataElements(data, graphdata, pointShowing, newMonth);
  }

  // make our main axes stylable by css
  if (data.type === 'grid' && data.index === 0) {
    data.element.attr({ class: data.element.attr('class') + ' main-axis' });
  }

  // style a month change vertical grid line
  if (data.type === 'grid' && newMonth) {
    data.element.attr({ class: data.element.attr('class') + ' new-month-axis' });
  }

  // style a label for a new month
  if (data.type === 'label' && data.axis.units.dir === 'horizontal' && newMonth) {
    const $span = data.element._node.firstChild.classList;
    $span.add('new-month');
  }
};

// build the graph
const Graph = ({ graphdata, type, className }) => {
  const options = graphOptions;
  const event = {
    draw: (data) => onGraphDraw(data, graphdata),
  };
  // if we're showing score, enforce the scale
  if (graphdata.which === overviewTabs[0].which) {
    options.high = 100;
    options.scaleMinSpace = 25;
  } else {
    options.high = null;
    options.scaleMinSpace = null;
  }
  return (
    <>
      <div id="graph">
        <ChartistGraph data={graphdata} type={type} className={className} options={options} listener={event} />
      </div>
      <footer>
        <ul className="ct-legend">
          {graphdata.series.map((item, index) => (
            <li className={`ct-series-${seriesNumber[index]}`} key={index}>
              {item.name}
            </li>
          ))}
        </ul>
      </footer>
    </>
  );
};
export default Graph;
