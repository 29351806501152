import React, { useEffect } from 'react';
import { useTable, useSortBy, useGlobalFilter, useExpanded } from 'react-table';
import { groupBy } from 'lodash';
import ReactTooltip from 'react-tooltip';
import { formatMoney } from '../../../helpers';

// Create a default prop getter
const defaultPropGetter = () => ({});
const subRows = (row) => row.subRows || [];

/**
 * reusable table component based on react-table
 * @param {*} table props
 */
const Table = ({
  columns,
  data,
  headRows,
  initialState,
  getRowProps = defaultPropGetter,
  getFilterProps = defaultPropGetter,
  getSubRows = subRows,
  isExpandable = false,
  isClientTable = false,
}) => {
  // useTable hook to bind columns, data, sorting & other functionalities
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState,
      defaultCanSort: true,
      disableSortRemove: true,
      disableMultiSort: true,
      getSubRows,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
  );

  // effect to handle global filter
  useEffect(() => {
    getFilterProps({
      preGlobalFilteredRows,
      setGlobalFilter,
      globalFilter: state.globalFilter,
    });
    ReactTooltip.rebuild();
  }, [getFilterProps, preGlobalFilteredRows, setGlobalFilter, state.globalFilter]);

  return (
    <table className="mgmt org" {...getTableProps()}>
      {Object.entries(groupBy(allColumns, (col) => col.colGroup)).map((item) => (
        <colgroup className={item[0]} key={item[0]}>
          {item[1].map((i) => (
            <col className={`${i.colGroupClass} ${i.isSorted ? 'sorted' : ''}`} key={i.id} />
          ))}
        </colgroup>
      ))}
      <thead>
        {headerGroups.map((headerGroup, hgIndex) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={hgIndex}>
            {headerGroup.headers.map((column, clmIndex) => {
              const sortOrderClassName = column.isSortedDesc ? 'descending' : 'ascending';
              return (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={clmIndex}
                  title=""
                  className={`${column.className || ''} ${column.isSorted ? `sorted ${sortOrderClassName}` : ''} ${
                    column.disableSortBy ? '' : 'sortable'
                  }`}
                >
                  {column.Header && column.tooltip && <u className="poptip" data-tip={column.tooltip} data-for="tip" />}
                  <br />
                  {column.Header}
                  <span className={`sort ${column.isSortedDesc ? 'desc' : 'asc'}`} />
                </th>
              );
            })}
          </tr>
        ))}
        {headRows.map((row, key) => {
          return (
            <tr {...row.props} key={key}>
              {row.columns.map((item, i) => (
                <td className={item.className} key={i}>
                  {item.value}
                </td>
              ))}
            </tr>
          );
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <React.Fragment key={i}>
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell, index) => {
                  if (cell.row.subRows.length && index > 0) return null;
                  return (
                    <td
                      {...cell.getCellProps({
                        className: cell.column.className,
                      })}
                      colSpan={cell.value && cell.column.colSpan}
                      key={index}
                    >
                      {cell.render('Cell')}
                      {isClientTable && (
                        <div className="client-table-pills-container">
                          <div className="tag">
                            Projected revenue:{' '}
                            {formatMoney(
                              cell.row.subRows
                                .reduce((acc, cv) => Number(acc) + Number(cv.original.projected_revenue), 0)
                                .toFixed(2),
                            )}
                          </div>
                          <div className="tag">
                            Won revenue:{' '}
                            {formatMoney(
                              cell.row.subRows.reduce((acc, cv) => acc + Number(cv.original.revenue), 0).toFixed(2),
                            )}
                          </div>
                        </div>
                      )}
                    </td>
                  );
                })}
              </tr>
              {!isExpandable &&
                row.subRows.map((subr, index) => {
                  prepareRow(subr);
                  return (
                    <tr key={index}>
                      {subr.cells.map((cell, cellIndex) => {
                        return (
                          <td
                            {...cell.getCellProps({
                              className: cell.column.className,
                            })}
                            key={cellIndex}
                          >
                            {cellIndex === 0 ? '' : cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
