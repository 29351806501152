import React, { useState } from 'react';
import Table from './Table.component';
import icons from '../../../assets/img/icons.svg';
import { formatMoney } from '../../../helpers';

/**
 * show company name or ~
 * @param {array} list of companies
 */
const CompanyList = ({ list = [] }) => {
  if (list) {
    const str = list.join(' <i>aka</i> ');
    return <h1 className="target-name" dangerouslySetInnerHTML={{ __html: str }} />;
  } else {
    return '~';
  }
};

/**
 * show First and last name first characters in uppercase
 * & other details
 * @param {array} list of details includes names and description
 */
const StepList = ({ value = [], row }) => {
  if (value) {
    return (
      <ul className="cell-boxes">
        {value.map((step) => {
          return (
            <li key={step.first_name} className={'step ' + step.bucket}>
              <div className="user">
                <dl className={'handle person ' + step.role}>
                  <dt className="avatar">
                    {step.first_name.charAt(0).toUpperCase()}
                    {step.last_name.charAt(0).toUpperCase()}
                  </dt>
                  <dd className="name">
                    {step.first_name ? step.first_name + ' ' + step.last_name : '~'}
                    {step.role == 'initiator' ? (
                      <svg className="icon poptip" data-tip="This User initiated this Target." data-for="tip">
                        <use xlinkHref={`${icons}#info`}></use>
                      </svg>
                    ) : (
                      ''
                    )}
                  </dd>
                </dl>
              </div>
              <div className="step">
                {step.description}{' '}
                <svg className="icon poptip" data-tip={'Due ' + step.due} data-for="tip">
                  <use xlinkHref={`${icons}#info`} />
                </svg>
              </div>
              <div className="step">
                {'Revenue'}{' '}
                <svg
                  className="icon poptip"
                  data-tip={
                    'Projected: ' +
                    formatMoney(row?.original?.projected_revenue) +
                    '\n Won: ' +
                    formatMoney(row?.original?.revenue)
                  }
                  data-for="tip"
                >
                  <use xlinkHref={`${icons}#info`} />
                </svg>
              </div>
            </li>
          );
        })}
      </ul>
    );
  } else {
    return '~';
  }
};

/**
 * create a table with clients details
 * @param {*} param0
 */
const ClientsTable = ({ clients, headRows, getFilterProps }) => {
  const [activeRow, setactiveRow] = useState();

  // client memoised data to show
  const data = React.useMemo(() => {
    if (clients) {
      setactiveRow(null);
      return Object.entries(clients).map((target) => target[1]);
    }
    return [];
  }, [clients]);

  // table columns
  const columns = React.useMemo(
    () => [
      {
        Header: 'Company',
        accessor: 'companies',
        Cell: ({ value }) => <CompanyList list={value} />,
        className: 'text company',
        sortType: 'basic',
        colGroup: 'client',
        colSpan: '4',
        colGroupClass: 'company text',
        tooltip: 'The Company being pursued.',
      },
      {
        Header: 'Contact',
        accessor: 'contact',
        // Cell: ({ row: { original }, value }) => <TargetName original={original} value={value} />,
        disableSortBy: true,
        className: 'text contact',
        colGroup: 'data',
        colGroupClass: 'text contact',
        tooltip: 'The Contact for this Desired Outcome.',
      },
      {
        Header: 'Desired Outcome',
        accessor: 'goal',
        id: 'goal',
        disableSortBy: true,
        colGroup: 'data',
        className: 'text objective',
        colGroupClass: 'text objective',
        tooltip: 'The specific Objective in the context of this Client.',
      },
      {
        Header: 'Current Steps',
        accessor: 'steps',
        id: 'steps',
        Cell: (props) => <StepList {...props} />,
        disableSortBy: true,
        colGroup: 'data',
        className: 'text steps',
        colGroupClass: 'text steps',
        tooltip: 'All current Steps for this Desired Outcome.',
      },
    ],
    [],
  );

  // onclick on a row reset the active class
  const hrows = headRows
    .map((row) => {
      if (row) {
        return {
          props: {
            onClick: () => setactiveRow(null),
            className: 'freeze active',
          },
          columns: [],
        };
      } else return {};
    })
    .filter((anyValue) => typeof anyValue !== 'undefined');

  const initialState = {
    sortBy: React.useMemo(() => [{ id: 'companies', desc: false }], []),
  };

  const getSubRows = (row) => row.targets || [];

  return (
    <Table
      data={data}
      columns={columns}
      isClientTable
      headRows={hrows}
      initialState={initialState}
      getFilterProps={getFilterProps}
      getSubRows={getSubRows}
      getRowProps={(row) => ({
        onClick: () => {
          if (row.id !== activeRow) {
            setactiveRow(row.id);
          }
        },
        className: `${row.id === activeRow ? ' target-head active' : ' target-head'}`,
      })}
    />
  );
};

export default React.memo(ClientsTable);
