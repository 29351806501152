import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { NewPasswordForm } from '../../components';
import '../../assets/css/login.css';
import { newPassword } from '../../actions';
import { ROUTES_PATH } from '../../constant';

// New Password container
class NewPassword extends Component {
  componentDidMount() {
    document.getElementsByClassName('login-wrapper')[0].onclick = () => this.handleBlur();
    document.getElementsByClassName('login-center-layout')[0].onclick = (event) => event.stopPropagation();
  }

  // handle enter keypress event
  onReturnKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleBlur();
    }
  };

  handleBlur = () => window.cordova && window.cordova.platformId === 'ios' && window.Keyboard.hide();

  submitNewPassword = (value) => {
    const { params } = this.props.match;
    params.email = decodeURIComponent(params.email);
    this.props.newPassword({ password: value.password, ...params });
  };

  // render function to render the JSX
  render() {
    const url =
      window.app_instance !== 'ackert'
        ? process.env.REACT_APP_WEB_BASE_URL.split('//')[0] +
          '//' +
          localStorage.host_prefix +
          '.' +
          process.env.REACT_APP_WEB_BASE_URL.split('//')[1]
        : process.env.REACT_APP_WEB_BASE_URL;
    return (
      <div className="login-wrapper keyboard">
        <div className="login-center-layout">
          <h1>
            <a href={url} target="_blank" rel="noreferrer"></a>
          </h1>
          <p className="login-para">Please enter your Password.</p>
          <NewPasswordForm
            onReturnKeyPress={this.onReturnKeyPress}
            handleBlur={this.handleBlur}
            isLoading={this.props.isLoading}
            onSubmit={this.submitNewPassword}
          />
          <Link className="login-lost-pwd" to={ROUTES_PATH.LOGIN}>
            Login
          </Link>
        </div>
      </div>
    );
  }
}

// map dispatch to props
const mapDispatchToProps = (dispatch) => ({
  newPassword: (data) => dispatch(newPassword(data)),
});

// map state to props
const mapStateToProps = (state) => ({
  isLoading: state.loader,
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);
