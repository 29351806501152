import React from 'react';

import { getClass, calculateDays } from '../../../helpers';
import DataValue from '../shared/DataValue.component';

/* Pipeline / Insight Tile view*/
const InsightTile = (props) => {
  const { code, data_label, created, category, status } = props.tile;
  return (
    <>
      <div className={`nudge ${category}`}>
        <div className="data">
          <DataValue detail={props.tile} />
        </div>
        <p className={`label ${getClass(code)}`}>{data_label}</p>
      </div>
      <time className={status}>{calculateDays(created)}</time>
      {/*
            <div className="tags">
                <span className="icon type"><svg className="icon"><use xlinkHref={`${icons}#${type}`}></use></svg></span>
            </div>
            */}
    </>
  );
};

export default InsightTile;
