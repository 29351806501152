export const INSIGHTS = 'insights';
export const FAVORITE = 'Favorite';

export const DigestSections = ['General Information', 'Content', 'Schedule', 'Recipients', 'Styles'];

export const CREATE_MODALS = {
  CATEGORY: 'category',
  STYLES: 'styles',
  CONTENT: 'content',
  DIFFUSION: 'diffusion',
  SECTION: 'section',
  DELETE: 'delete',
  DISTRIBUTION: 'distribution',
};

export const PREVIEW_MODAL = 'preview_modal';

export const frequencyOptions = [
  { id: 1, name: 'once', label: 'Once' },
  { id: 2, name: 'weekly', label: 'Weekly' },
  { id: 3, name: 'bi-weekly', label: 'Bi-Weekly' },
  { id: 4, name: 'monthly', label: 'Monthly' },
  { id: 5, name: 'bi-monthly', label: 'Bi-Monthly' },
];

export const contentTypes = [
  { id: 1, name: 'content_type', label: 'Video' },
  { id: 2, name: 'content_type', label: 'PDF' },
  { id: 3, name: 'content_type', label: 'External URL' },
];

export const MAX_POPULATE = 4;
//Server time: Pacific Daylight Time (PDT) is 7 hours behind Coordinated Universal Time (UTC)
export const PDT_TIMEZONE = '-07:00';

export const EXPANDED_CONTENT = 'content';

export const VIDEO = 'Video';
export const PDF = 'PDF';
export const BLOG = 'External URL';
