import { ROUTES_PATH } from '../constant';
import { DashBoardContainer, TargetDetailContainer } from '../containers';

const targetRoutes = [
  {
    path: ROUTES_PATH.DASHBOARD + '/:status',
    exact: false,
    component: DashBoardContainer,
    isProtected: true,
  },
  {
    path: `${ROUTES_PATH.TARGETDETAIL}/:id`,
    exact: true,
    component: TargetDetailContainer,
    isProtected: true,
  },
];

export default targetRoutes;
