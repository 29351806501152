import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import dashBoard from './targets/dashboard.reducer';
import actionSheet from './shared/actionSheet.reducer';
import createTarget from './targets/createTarget.reducer';
import filterTarget from './targets/filterTarget.reducer';
import pipelineControls from './targets/pipelineControls.reducer';
import standing from './targets/standing.reducer';
import login from './login/login.reducer';
import users from './targets/users.reducer';
import hud, { hudUpdate } from './targets/hud.reducer';
import insightDashboard from './insight/insightDashboard.reducer';
import insightCount from './insight/insightCount.reducer';
import loader from './shared/loader.reducer';
import firms from './targets/firms.reducer';
import editConfirm from './targets/editConfirm.reducer';
import selectUserAutoSuggest from './targets/selectUserAutoSuggest.reducer';
import logo from './shared/logo.reducer';
import newLookPopUp from './shared/newLookPopUp.reducer';
import attention from './shared/attention.reducer';
import showMore from './shared/showMore.reducer';
import requestCount from './targets/requestCount.reducer';
import lessons from './trainer/lessons.reducer';
import firmContent from './trainer/firmContent.reducer';
import worksheets from './trainer/worksheets.reducer';
import resources from './trainer/resources.reducer';
import viewer from './viewer/viewer.reducer';
import people from './viewer/people.reducer';
import targetsTable from './viewer/targetsTable.reducer';
import clientsTable from './viewer/clientsTable.reducer';
import overlapsTable from './viewer/overlapsTable.reducer';
import progressTable from './viewer/progressTable.reducer';
import worksheetsTable from './viewer/worksheetsTable.reducer';
import overviewCharts, { overviewTable } from './viewer/overviewCharts.reducer';
import targetNote from './targets/notes.reducer';
import lessonsFilter from './trainer/lessonsFilter.reducer';
import trackTargetNote from './targets/trackTargetNote.reducer';
import { reducer as toastrReducer } from 'react-redux-toastr';
import blogs from './insight/blog.reducer';
import blogsFilter from './insight/blogsFilter.reducer';

const reducers = combineReducers({
  dashBoard,
  form: formReducer,
  actionSheet,
  createTarget,
  filterTarget,
  pipelineControls,
  standing,
  session: login,
  toastr: toastrReducer, // <- Mounted at toastr.
  users,
  hud,
  insightDashboard,
  loader,
  insightCount,
  firms,
  editConfirm,
  selectUser: selectUserAutoSuggest,
  logo,
  newLookPopUp,
  attention,
  requestCount,
  hudUpdate,
  lessons,
  worksheets,
  resources,
  viewer,
  people,
  overviewCharts,
  overviewTable,
  targetsTable,
  clientsTable,
  overlapsTable,
  progressTable,
  worksheetsTable,
  targetNote,
  firmContent,
  showMore,
  lessonsFilter,
  trackTargetNote,
  blogs,
  blogsFilter,
});

export default reducers;
