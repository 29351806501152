import React, { useEffect, useState } from 'react';
import Accordion from '../../../../components/common/accordion';
import { ReactComponent as PreviewIcon } from '../../../../assets/img/eye.svg';
import General from './general';
import Styling from './styling';
import Content from './content';
import '../styles.css';
import Schedule from './schedule';
import { convertTZ, getUsersIds, getVideoInsightsIds, pluralize, selectedRecipients } from '../../../../helpers';
import Recipients from './recipients';
import { useDispatch, useSelector } from 'react-redux';
import _, { isEmpty } from 'lodash';
import { PDT_TIMEZONE, PREVIEW_MODAL } from '../../../../constant/insights';
import {
  createNewDigest,
  getDiffusionList,
  getStyleTemplates,
  setDigestValues,
  setPreviewValues,
} from '../../../../actions';
import toaster from '../../../../services/shared/toaster.service';
import { MESSAGES } from '../../../../constant';
import moment from 'moment';

const NewDigestForm = ({ resetFields, setNewElementModalOpen, handleClose, setAutoPopulate }) => {
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const digestValues = useSelector((state) => state.insightDashboard.digestValues);
  const initialRecipients = useSelector((state) => state.viewer.allMembers);

  const digestValuesCopy = { ...digestValues };
  delete digestValuesCopy.externalContent;

  const previewDisabled = _.values(digestValuesCopy).some((value) => {
    if (value instanceof Date) return;
    if (value === digestValues['recipients']) return selectedRecipients(value);
    return value !== digestValues['diffusionLists'] && isEmpty(value);
  });

  const digestErrors = _.values(formErrors).some((error) => error === true);

  const getRecipientsSelected = () => {
    let selected = 0;
    digestValues.recipients.forEach((recipient) => recipient.checked && selected++);
    return selected > 0 ? `${selected} selected` : '';
  };

  const setValues = (values) => {
    dispatch(setDigestValues(values));
  };

  const sections = [
    {
      title: 'General Information',
      children: (
        <General values={digestValues} setValues={setValues} formErrors={formErrors} setFormErrors={setFormErrors} />
      ),
      errors: [formErrors.title, formErrors.curatedBy, formErrors.description],
      checkErrors: () =>
        setFormErrors({
          ...formErrors,
          title: digestValues.title === '' || digestValues.title.length > 60,
          curatedBy: digestValues.curatedBy === '' || digestValues.curatedBy.length > 60,
          description: digestValues.description === '' || digestValues.description.length > 300,
        }),
    },
    {
      title: 'Content',
      children: <Content values={digestValues} setValues={setValues} setNewElementModalOpen={setNewElementModalOpen} />,
      digestContentLength: digestValues?.content?.length,
      label: digestValues?.content?.length && `${digestValues?.content?.length} selected`,
    },
    {
      title: 'Schedule',
      children: (
        <Schedule values={digestValues} setValues={setValues} formErrors={formErrors} setFormErrors={setFormErrors} />
      ),
      errors: [formErrors.scheduled],
      label: digestValues?.scheduled && moment(digestValues.scheduled).format('MMMM Do YYYY, h:mm A'),
      checkErrors: () =>
        setFormErrors({
          ...formErrors,
          scheduled: digestValues.scheduled === '' || digestValues.scheduled === undefined,
        }),
    },
    {
      title: 'Recipients',
      children: (
        <Recipients
          values={digestValues}
          setValues={setValues}
          initialRecipients={initialRecipients}
          setNewElementModalOpen={setNewElementModalOpen}
        />
      ),
      label: getRecipientsSelected(),
    },
    {
      title: 'Styles',
      children: (
        <Styling
          values={digestValues}
          setValues={setValues}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          setNewElementModalOpen={setNewElementModalOpen}
        />
      ),
      label: digestValues.template,
      errors: [formErrors.template],
      checkErrors: () =>
        setFormErrors({
          ...formErrors,
          template: digestValues.template === '',
        }),
    },
  ];

  const clearErrors = () => {
    setValues({
      title: '',
      curatedBy: '',
      description: '',
      content: [],
      scheduled: '',
      recipients: [],
      template: '',
      styles: { logo: '', background: '' },
    });
    setFormErrors({});
    setError(false);
  };

  const handlePreview = () => {
    setNewElementModalOpen(PREVIEW_MODAL);
    dispatch(setPreviewValues(digestValues));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const {
      title,
      curatedBy: curated_by,
      description,
      content,
      scheduled,
      recipients,
      frequency,
      styles: { template_id },
      externalContent: external_content,
    } = digestValues;

    const body = {
      title,
      description,
      curated_by,
      date: moment(convertTZ(scheduled, PDT_TIMEZONE)).format('ddd MMM D HH:mm:ss [PDT] YYYY'),
      template_id,
      videoinsight_ids: getVideoInsightsIds(content),
      user_ids: getUsersIds(recipients),
      frequency,
      external_content,
    };
    if (!moment(scheduled).isSameOrAfter(new Date(new Date().getTime() + 4 * 60000))) {
      toaster.error(MESSAGES.DIGEST_TIME_ERROR);
      setLoading(false);
      return;
    }
    try {
      await dispatch(createNewDigest(body));
      toaster.success(MESSAGES.DIGEST_SUCCESS);
      setAutoPopulate(false);
    } catch (error) {
      console.error(error);
      toaster.error(MESSAGES.ERROR_REQUEST);
    }
    setLoading(false);
    handleClose();
  };

  useEffect(() => {
    if (resetFields) clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFields]);

  useEffect(() => {
    initialRecipients?.length &&
      !digestValues?.recipients?.length &&
      dispatch(setDigestValues({ ...digestValues, recipients: initialRecipients }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialRecipients, digestValues]);

  useEffect(() => {
    dispatch(getStyleTemplates());
    dispatch(getDiffusionList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form id="videoInsight" className="insights-form" onSubmit={(e) => handleSubmit(e)}>
      {sections.map(({ title, children, errors, label, digestContentLength = undefined, checkErrors = () => {} }) => (
        <Accordion
          key={title}
          title={title}
          errors={errors}
          label={label}
          values={digestValues}
          digestContentLength={digestContentLength}
          checkErrors={checkErrors}
        >
          {children}
        </Accordion>
      ))}
      <button
        type="button"
        className="preview-button"
        onClick={handlePreview}
        disabled={previewDisabled || digestErrors}
      >
        <PreviewIcon /> View digest preview
      </button>
      <button
        form="videoInsight"
        type="submit"
        className="create-digest-button"
        disabled={previewDisabled || digestErrors}
      >
        {loading ? <span className="loader" /> : 'Create Digest'}
      </button>
    </form>
  );
};

export default NewDigestForm;
