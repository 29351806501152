import { SHOW_MODAL, HIDE_MODAL } from '../../constant';
import { modalOpen } from '../../helpers/utils';
const initialState = {
  modalProps: {},
};

const createTarget = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      modalOpen(action.modalProps);
      return {
        modalProps: action.modalProps,
      };
    case HIDE_MODAL:
      modalOpen(false);
      return initialState;
    default:
      return state;
  }
};

export default createTarget;
