import React from 'react';
import moment from 'moment';

import icons from '../../../../assets/img/icons.svg';
import { DueDateFormatToDisplay, ROUTES_PATH } from '../../../../constant';
import history from '../../../../helpers/history';
import { gTag } from '../../../../helpers';

/**
 * @description This component contain the detail of the lessons
 * @param {string} $id - lesson id
 * @param {string} $title - lesson title
 * @param {string} $description - lesson description
 * @param {string} $duration - lesson duration
 * @param {object} $watched - {done, percent, seconds, when}
 * @param {string} $latest_watched - yes/no
 */

// which icon to show ... "latest watched" takes precedence over "done"
export const LessonIcon = ({ latest_watched, watched = {} }) => {
  switch (true) {
    case latest_watched === 'yes' && watched.done === 'yes':
    case latest_watched === 'yes':
      return (
        <span className="indicator">
          <svg className="icon">
            <use xlinkHref={`${icons}#bookmark`} />
          </svg>
        </span>
      );
    case watched.done === 'yes':
      return (
        <span className="indicator">
          <svg className="icon">
            <use xlinkHref={`${icons}#task-done`} />
          </svg>
        </span>
      );
    default:
      return null;
  }
};

const Lesson = ({ id, title, description, duration, watched, worksheet, latest_watched, setAttention }) => {
  const handleLessonClick = () => {
    setAttention(id);
    gTag('Tile Open', `Trainer: ${id}`);
    history.push({
      pathname: `${ROUTES_PATH.TRAINER_DETAIL}/${id}`,
    });
  };
  return (
    <li
      className=""
      key={id}
      id={id}
      data-key={id}
      data-handle="bdoqfapuqd"
      data-title="Orientation"
      data-viewed="false"
      onClick={handleLessonClick}
    >
      <article
        className={`lesson ${latest_watched === 'yes' ? 'bookmark' : ''} ${watched.done === 'yes' ? 'watched' : ''}`}
        data-bucket="lesson"
      >
        <h1
          className={watched.percent === 100 ? 'completed' : 'incomplete'}
          style={{ '--percent-watched': `${watched.percent}%` }}
        >
          <LessonIcon latest_watched={latest_watched} watched={watched} />
          <p>{title}</p>
        </h1>
        <div className="description">{description}</div>
        <div className="tags">
          {duration && (
            <span className="tag">
              <svg className="icon">
                <use xlinkHref={`${icons}#clock`} />
              </svg>
              {duration}
            </span>
          )}
          {worksheet && <span className="tagW">worksheet</span>}
        </div>
        {watched.when !== '0' && (
          <time className="watched" dateTime={watched.when}>
            {moment(watched.when).format(DueDateFormatToDisplay)}
          </time>
        )}
      </article>
    </li>
  );
};

export default React.memo(Lesson);
