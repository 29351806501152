import React from 'react';
import launchPBI from '../../../helpers/powerbi';
import { invoke } from '../../../helpers';
import { API_METHODS, API_URLS } from '../../../constant';

export default class RPIDashboard extends React.Component {
  async componentDidMount() {
    var $kpi = document.getElementById('ackert-kpis');

    let data = await invoke({ url: API_URLS.KPIDASHBOARD, type: API_METHODS.GET }, null, {
      baseURL: 'https://api.pipelineplus.com/api/',
    });
    if (data.token.error) {
      $kpi.innerHTML =
        '<div id="powerbi-error"><div><label>We got an error...</label><p>Code: ' +
        data.token.error +
        '</p><p>Description: ' +
        data.token.error_description +
        '</p></div></div>';
      return false;
    }
    launchPBI(data);
  }
  render() {
    return <div style={{ height: window.innerHeight - 150 }} id="ackert-kpis"></div>;
  }
}
