import { invoke, mediaActions } from '../../helpers';
import { API_URLS, API_METHODS, API_PARAMS } from '../../constant';
import { getInsight, updateATile } from '../../helpers/dbStorage';

/**
 * Api call to get insights by status
 * @param {string} status
 */
export const getInsights = (status = API_PARAMS.ACTIVE) => {
  return invoke({ url: API_URLS.INSIGHTCONTENT, type: API_METHODS.GET, params: { status } });
};

export const getInsightsFromLocalDb = (status = API_PARAMS.NEW) => {
  return getInsight(status);
};
export const changeStatusOfInsights = (tile) => {
  tile.status = API_PARAMS.VIEWED;
  tile.created = new Date();
  updateATile(tile, tile.id);
  return tile;
};

/**
 * Api call to get blogs
 * @param {string} status
 */
export const getBlogs = () => {
  return invoke({ url: API_URLS.INSIGHTCONTENT, type: API_METHODS.GET });
};

/**
 * Api call to get lessons details
 * @param {string} status
 */
export const getBlogDetails = (_blogId) => {
  const url = API_URLS.INSIGHTCONTENT;
  return invoke({ url, type: API_METHODS.GET });
};

/**
 * Api call update lesson watched time
 * @param {number} time
 */
// export const updateWatchTime = (lessonId, time) => {
//     const url = API_URLS.INSIGHTCONTENT
//     return invoke({ url, type: API_METHODS.POST, params: { watched: time } });
// };

/**
 * Api call to get worksheets
 * @param {string} status
 */
export const getWorksheets = () => {
  return invoke({ url: API_URLS.WORKSHEETS, type: API_METHODS.GET });
};

/**
 * Save updated worksheet data in DB
 * @param {object} tile
 */
export const changeWorksheetsDataInDB = (tile) => {
  tile.status = API_PARAMS.WORKSHEETS;
  tile.modified = new Date();
  updateATile(tile, tile.id);
  return tile;
};

/**
 * Api call to get resources
 * @param {string} status
 */
export const getResources = () => {
  return invoke({ url: API_URLS.RESOURCES, type: API_METHODS.GET });
};

/**
 * @params {string} url
 */
export const getFirmVideos = (id) => {
  return mediaActions(`${id}.json`, 'GET', 'projects');
};

export const removeFirmVideo = (id) => mediaActions(`${id}.json`, 'DELETE');

export const updateFirmVideo = (id, title, description) =>
  mediaActions(
    `${id}.json?${title && `name=${encodeURIComponent(title)}&`}${description && `description=${description}`}`,
    'PUT',
  );

export const createNewVideoInsight = (data) => {
  const url = API_URLS.CREATEINSIGHTS;
  return invoke({ url, type: API_METHODS.POST }, data);
};
export const editNewVideoInsight = (data, id) => {
  const url = `${API_URLS.CREATEINSIGHTS}/${id}`;
  return invoke({ url, type: API_METHODS.POST }, data);
};

export const createNewSection = (data) => {
  const url = API_URLS.SECTION;
  return invoke({ url, type: API_METHODS.POST }, data);
};

export const createNewTemplate = (data) => {
  const url = API_URLS.TEMPLATE;
  return invoke({ url, type: API_METHODS.POST }, data);
};

export const getTemplates = () => {
  const url = API_URLS.TEMPLATE;
  return invoke({ url, type: API_METHODS.GET });
};

export const getSections = () => {
  const url = API_URLS.SECTION;
  return invoke({ url, type: API_METHODS.GET });
};

export const getContent = () => {
  const url = API_URLS.CREATEINSIGHTS;
  return invoke({ url, type: API_METHODS.GET });
};

export const getContentTags = () => {
  const url = API_URLS.TAGS;
  return invoke({ url, type: API_METHODS.GET });
};

export const createNewTag = (data) => {
  const url = API_URLS.TAGS;
  return invoke({ url, type: API_METHODS.POST }, data);
};

export const deleteVideoInsight = (id) => {
  const url = `${API_URLS.CREATEINSIGHTS}/${id}`;
  return invoke({ url, type: API_METHODS.DELETE });
};

export const createDigest = (data) => {
  const url = API_URLS.DIGEST;
  return invoke({ url, type: API_METHODS.POST }, data);
};

export const getDigests = () => {
  const url = API_URLS.DIGEST;
  return invoke({ url, type: API_METHODS.GET });
};

export const createNewDiffusionList = (data) => {
  const url = API_URLS.DIFFUSION_LIST;
  return invoke({ url, type: API_METHODS.POST }, data);
};

export const getDiffusionLists = () => {
  const url = API_URLS.DIFFUSION_LIST;
  return invoke({ url, type: API_METHODS.GET });
};

export const deleteDigest = (id) => {
  const url = `${API_URLS.DIGEST}/${id}`;
  return invoke({ url, type: API_METHODS.DELETE });
};

export const deleteSection = (id) => {
  const url = `${API_URLS.SECTION}/${id}`;
  return invoke({ url, type: API_METHODS.DELETE });
};

export const getNewAccessToken = () =>
  fetch(`${process.env.REACT_APP_REFRESH_TOKEN_URL}`, {
    method: 'POST',
    'Content-Type': '/application / x - www - form - urlencoded',
    body: JSON.stringify({
      client_id: process.env.REACT_APP_DRIVE_CLIENT_ID,
      client_secret: process.env.REACT_APP_DRIVE_CLIENT_SECRET,
      refresh_token: process.env.REACT_APP_DRIVE_REFRESH_TOKEN,
      grant_type: 'refresh_token',
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      localStorage.setItem('driveToken', JSON.stringify({ ...json, date: new Date() }));
      return json?.access_token;
    });
