import { TRACK_TARGET_NOTE } from '../../constant';

const trackTargetNote = (state = { counter: 0 }, action) => {
  switch (action.type) {
    case TRACK_TARGET_NOTE:
      return {
        counter: state.counter + 1,
      };
    default:
      return state;
  }
};

export default trackTargetNote;
