import React, { useRef, useCallback, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import WistiaPlayer from '../../../trainer/details/WistiaPlayer.component';
import { LessonIcon } from '../lesson/Lesson.component';
import { updateLessonWatchedTime } from '../../../../actions/trainer/trainer.action';
import { ShowMore } from '../../../shared/ShowMore';
import { useHideDialog } from '../../../../hooks/useHideDialog';

const watchedDefault = {
  when: '0',
  seconds: 0,
  percent: 0,
  done: 'no',
};

export const VideoWrapper = ({
  id,
  title,
  description,
  watched = watchedDefault,
  latest_watched = 'no',
  hashedId,
  removeFirmContents,
  getFirmContents,
  getAttention,
  setAttention,
  videoEditAction,
  medias,
  isAddLesson,
  showMoreToggle,
  showMore,
  setInput,
  attention,
  time = 0,
}) => {
  const [editAction, setEditAction] = useState(false);
  const node = useRef(null);

  const handleRemoveVideo = async () => {
    const videoRemove = window.confirm(`Are you sure you want to delete ${title} video?`);

    setEditAction(!editAction);
    if (videoRemove) {
      await removeFirmContents(hashedId);
      getFirmContents();
    } else {
      getAttention({ list: medias, attention: { id } });
    }
  };

  const removeDialogListener = () => {
    document.removeEventListener('click', handleOutsideClick, true);
    setEditAction(false);
  };

  // close filter dialog if click outside of dialog
  const handleOutsideClick = (e) => {
    if (!node?.current?.contains(e.target)) {
      removeDialogListener();
    }
  };

  const onVideoAction = isAddLesson === 1;

  const handleUpdateVideo = () => {
    if (onVideoAction) {
      setAttention(hashedId);
      videoEditAction();
      setInput({
        title,
        description: description.replace(/(<([^>]+)>)/gi, ''),
      });
    }
    setEditAction(!editAction);
  };

  const handleEditAction = useCallback(() => {
    setEditAction(!editAction);
    setAttention(hashedId);
  }, [hashedId, editAction, setAttention]);

  useHideDialog(editAction, handleOutsideClick);
  return (
    <li key={id} id={id} data-key={id} data-title="Orientation" data-viewed="false">
      <article
        className={`lesson ${latest_watched === 'yes' ? 'bookmark' : ''} ${watched.done === 'yes' ? 'watched' : ''}`}
        data-bucket="lesson"
      >
        <h1 style={{ '--percent-watched': `${watched.percent}%` }}>
          <LessonIcon latest_watched={latest_watched} watched={watched} />
          <span className="video-title">
            <p className="title-text">{title}</p>
            {onVideoAction && (
              <span className="edit-options" onClick={handleEditAction}>
                &#x22EE;
              </span>
            )}
          </span>
        </h1>
        {editAction && hashedId === attention?.id && (
          <div id="edit-action" className="edit-menu" ref={node}>
            <a href={undefined} onClick={handleUpdateVideo}>
              Edit
            </a>
            <a href={undefined} onClick={handleRemoveVideo}>
              Delete
            </a>
          </div>
        )}
        <div className="description">
          <ShowMore text={description.replace(/(<([^>]+)>)/gi, '')} length={30} showMoreToggle={showMoreToggle} />
        </div>
        <div className="block video">
          <div className="vid">
            {hashedId && (
              <WistiaPlayer
                key={hashedId}
                hashedId={hashedId}
                time={time} // attempt to set the start point to where the user left off
                updateLessonWatchedTime={(userId, time) => {
                  updateLessonWatchedTime(userId, time);
                }}
                showMore={showMore}
              />
            )}
          </div>
        </div>
      </article>
      <ReactTooltip />
    </li>
  );
};
