import React, { useEffect, useState } from 'react';

import defaultPdf from '../../../../assets/img/pdf_default.jpg';
import { ReactComponent as NewUploadIcon } from '../../../../assets/img/new-upload.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/img/close.svg';
import { ReactComponent as CircleClose } from '../../../../assets/img/circle-close.svg';
import { ReactComponent as ImageIcon } from '../../../../assets/img/image.svg';
import './dragAndDrop.css';
import { dataURItoBlob } from '../../../../helpers/imageParsing';
import { PDF, VIDEO } from '../../../../constant/insights';
import { secondsToMinutes } from '../../../../helpers';

const DragAndDrop = ({ handleChange, blob, thumbnail, editContent, contentType, image = false }) => {
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [videoDuration, setVideoDuration] = useState('');

  const handleVideoFrame = (videoUrl) => {
    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');
    let video = document.createElement('video');

    video.preload = 'auto';
    video.addEventListener('loadeddata', function () {
      video.currentTime = video.duration * 0.25;
    });

    video.addEventListener('seeked', function () {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      handleChange(dataURItoBlob(canvas.toDataURL()), 'thumbnail');
      setVideoDuration(secondsToMinutes(Math.floor(video.duration)));
    });

    video.src = videoUrl;
    video.load();
  };

  const onChange = async (e) => {
    const files = e.target.files;
    files?.length > 0 && setFile(URL.createObjectURL(files[0]));
    handleChange && handleChange(files[0], 'blob');
    setFileName(files[0]?.name);
  };

  const getPdfDefaultImage = async () => {
    handleChange(await getDefaultThumbnail(PDF), 'thumbnail');
  };

  const handleMetaData = () => {
    handleVideoFrame(file);
  };

  const handleClearFiles = () => {
    handleChange('');
    setFileName('');
    setFile('');
  };

  const getSupportedFiles = () => {
    if (image) {
      return 'JPEG, PNG, WEBP, SVG';
    } else {
      switch (contentType) {
        case VIDEO:
          return 'MP4';
        case PDF:
          return 'PDF';
        default:
          return '';
      }
    }
  };

  useEffect(() => {
    blob === '' && setFile('');
  }, [blob]);

  useEffect(() => {
    if (editContent) {
      if (contentType === PDF && editContent?.file_url) {
        const urlSplitted = editContent?.file_url?.split('/');
        const pdfName = urlSplitted[urlSplitted?.length - 1]?.replaceAll('%20', ' ');
        setFileName(pdfName);
      }
      setFile(editContent.file_url);
    }
  }, [editContent]);

  useEffect(() => {
    if (blob && !thumbnail && contentType === PDF) {
      getPdfDefaultImage();
    }
  }, [blob]);

  useEffect(() => {
    if (videoDuration) {
      handleChange(videoDuration, 'duration');
    }
  }, [videoDuration]);

  useEffect(() => {
    return () => {
      setVideoDuration('');
    };
  }, []);

  return (
    <>
      <div className={`${file && image ? 'image-wrapper' : 'image-container'}`}>
        <div className={`${file && image ? 'upload-image' : 'upload-container'}`}>
          {file ? (
            image || contentType === PDF ? (
              <div className="upload-image-container">
                <span>
                  <ImageIcon />
                  <span className="file-name">{fileName}</span>
                </span>
                <span onClick={handleClearFiles}>
                  <CloseIcon />
                </span>
              </div>
            ) : (
              <div className="video-preview-wrapper">
                <span className="video-close" onClick={handleClearFiles}>
                  <CircleClose />
                </span>
                <video width="400" controls className="video-preview" onLoadedMetadata={handleMetaData}>
                  <source src={file} id="video_preview" />
                </video>
              </div>
            )
          ) : (
            <>
              <input
                name="blob"
                type="file"
                className="input-file"
                accept={
                  image ? 'image/*' : contentType === VIDEO ? 'video/mp4' : contentType === PDF && 'application/pdf'
                }
                onChange={onChange}
              />
              <p className="bold">Drag and drop your files here</p>
              <p>Supported files: {getSupportedFiles()}</p>

              <span className="browse">
                <NewUploadIcon />
                Select files
              </span>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DragAndDrop;
