import {
  LOAD_HUD,
  UPDATE_YTD_REVENUE_REQUEST,
  UPDATE_YTD_REVENUE_COMMIT,
  UPDATE_STEP,
  CALL_HUD,
  RESET_PEOPLE_HUD,
} from '../../constant';
import { formatNumber } from '../../helpers';
import { updateATile } from '../../helpers/dbStorage';

const initialState = {
  steps: { you: { this_week: '~' }, firm: {} },
  score: { you: { current: { score: '~', tile_points: '~', bonus_points: '~' }, events: [] }, firm: {} },
  revenue: { you: { ytd_formatted: '~', ytd_targets_formatted: '~' }, firm: {} },
  id: 'idhud',
};

const hud = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_HUD:
      return { ...action.payload };
    case UPDATE_YTD_REVENUE_REQUEST:
    case UPDATE_YTD_REVENUE_COMMIT: {
      const hud = state;
      const _ytd = action.payload.revenue ? action.payload.revenue : action.payload.current_revenue;

      hud.revenue.you.ytd = parseInt(hud.revenue.you.ytd_targets) + parseInt(hud.revenue.you.ytd_override);
      hud.revenue.you.ytd_formatted = hud.revenue.you.ytd ? formatNumber(parseInt(hud.revenue.you.ytd)) : '0';

      !action.payload.userId && updateATile(hud, 'idhud');
      return { ...hud };
    }
    case UPDATE_STEP: {
      const hud = state;
      const step = +hud.steps.you.this_week + 1;
      hud.steps.you.this_week = step;
      updateATile(hud, 'idhud');
      return { ...hud };
    }
    case RESET_PEOPLE_HUD: {
      return initialState;
    }
    default:
      return state;
  }
};

export default hud;

export const hudUpdate = (state = true, action) => {
  if (action.type === CALL_HUD) {
    return action.payload;
  }
  return state;
};
