import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { PullToRefresh, PullDownContent, ReleaseContent, RefreshContent } from 'react-js-pull-to-refresh';
import { CSSTransition } from 'react-transition-group';

import Tile from '../../shared/Tile';
import { ActiveTile, ArchivedAndCompletedTile, TileLoader, HUD, NoResult, NavSecondaryTargets } from '../..';
import { getUserId } from '../../../helpers';
import { API_PARAMS, ROUTES_PATH, loaderArray, yes } from '../../../constant';
import history from '../../../helpers/history';
import '../../../assets/css/create.css';
import SharedTile from './SharedTile.component';

/* Pipeline / Targets page functionality */

/**
 * @desc render tiles
 * @param {object} $sharing - specify weather the target is shared by another user
 * @param {array} $shared
 * @param {function} $setAttention
 * @param {string} $nextUrl
 * @param {array} $targets - list of the targets
 * @param {string} $status - { active, archive, completed } status of tiles
 * @param {string} $currentuser - current uer id
 * @param {object} $filter - contain filter value
 * @param {string} $searchKeyword - contain search value
 * @param {boolean} $isPeopleTarget - true/false
 */
const RenderTiles = ({
  sharing,
  shared,
  setAttention,
  nextUrl,
  targets,
  status,
  currentuser,
  filter,
  searchKeyword,
  isPeopleTarget,
  onRefresh,
}) => {
  return (
    <>
      {sharing.on === yes &&
        shared.map((target, i) => {
          return (
            <Tile
              tile={target}
              key={i}
              goTo={() => {
                setAttention(target.id);
                history.push(`${nextUrl}/${target.id}`);
              }}
              target={true}
            >
              <SharedTile tile={target} />
            </Tile>
          );
        })}
      {targets.length > 0
        ? targets.map((target, i) => {
            return (
              <Tile
                tile={target}
                key={i}
                goTo={() => {
                  setAttention(target.id);
                  if (!target.isTemp) history.push(`${nextUrl}/${target.id}`);
                  else onRefresh(status);
                }}
                target={true}
              >
                {status === API_PARAMS.ACTIVE ? (
                  <ActiveTile tile={target} id={i} currentuser={currentuser} />
                ) : (
                  <ArchivedAndCompletedTile tile={target} currentuser={currentuser} />
                )}
              </Tile>
            );
          })
        : targets.length === 0 &&
          shared.length === 0 && (
            <NoResult status={status} filter={filter} searchKeyword={searchKeyword} isPeopleTarget={isPeopleTarget} />
          )}
    </>
  );
};

const StatusWrapper = (props) => {
  const {
    isPeopleTarget,
    sharing,
    shared,
    setAttention,
    nextUrl,
    targets,
    status,
    currentuser,
    loading,
    filter,
    searchKeyword,
  } = props;

  const [statusBasedTargets, setStatusBasedTargets] = useState([]);
  const [statusBasedSharedTargets, setStatusBasedSharedTargets] = useState([]);

  useEffect(() => {
    setStatusBasedSharedTargets(shared.filter((target) => status === target.status));
  }, [shared, status]);

  useEffect(() => {
    setStatusBasedTargets(targets.filter((target) => status === target.status));
  }, [targets, status]);
  return (
    <div className={isPeopleTarget ? 'status-wrapper' : 'block-wrapper'}>
      <div className={`status-${props.status} active`} id={`tile-status-${props.status}`}>
        {isPeopleTarget && (
          <NavSecondaryTargets
            openFilter={props.openFilter}
            openPipelineControls={props.openPipelineControls}
            getTargetByStatus={props.getTargetByStatus}
            status={props.status}
            isPeopleTarget={props.isPeopleTarget}
          />
        )}

        <ol className={`ai-dashboard tile-container`} id={`pipeline-${props.status}`}>
          {!props.targets.length &&
            props.loading &&
            loaderArray.map((i) => (
              <li key={i}>
                <TileLoader />
              </li>
            ))}
          {props.targets.length && (
            <RenderTiles
              sharing={sharing}
              shared={statusBasedSharedTargets}
              setAttention={setAttention}
              nextUrl={nextUrl}
              targets={statusBasedTargets}
              status={status}
              currentuser={currentuser}
              loading={loading}
              filter={filter}
              searchKeyword={searchKeyword}
              onRefresh={props.onRefresh}
              isPeopleTarget={isPeopleTarget}
            />
          )}
        </ol>
      </div>
    </div>
  );
};

/**
 * @description render OwnerDashboard if isPeopleTarget is false
 * @param {*} props
 * @returns react node
 */
const OwnerDashboard = (props) => {
  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    setInProp(true);
  }, []);

  return (
    <CSSTransition classNames="fade" in={inProp} timeout={300}>
      <section className="nav-page" id="tiles">
        <HUD hud={props.hud} openHUD={props.openHUD} />
        <PullToRefresh
          pullDownContent={<PullDownContent />}
          releaseContent={<ReleaseContent />}
          refreshContent={<RefreshContent />}
          pullDownThreshold={50}
          onRefresh={props.onRefresh}
          triggerHeight={50}
          startInvisible={true}
        >
          <StatusWrapper {...props} />
        </PullToRefresh>
      </section>
    </CSSTransition>
  );
};

/**
 * @description render people dashboard if isPeopleTarget is true
 * @param {*} props
 * @returns react node
 */
const PeopleDashboard = (props) => {
  return (
    <>
      <HUD hud={props.hud} openHUD={props.openHUD} status={props.status} />
      <div className="dashboard-container">
        <StatusWrapper {...props} />
      </div>
    </>
  );
};

const DashBoard = React.forwardRef(function DashboardContainer(props) {
  const currentuser = props.isPeopleTarget ? props.uid : getUserId();
  const {
    features: { sharing },
  } = props.targetSubscriptions ? props.targetSubscriptions : { features: { sharing: { on: 'no' } } };
  const { url } = useRouteMatch();
  const nextUrl = props.isPeopleTarget ? `${url}${ROUTES_PATH.TARGETDETAIL}` : ROUTES_PATH.TARGETDETAIL;
  const peopleTargetUrlParams = props.isPeopleTarget ? '&peopleTarget=1' : '';
  return props.isPeopleTarget ? (
    <PeopleDashboard
      {...props}
      currentuser={currentuser}
      sharing={sharing}
      peopleTargetUrlParams={peopleTargetUrlParams}
      nextUrl={nextUrl}
    />
  ) : (
    <OwnerDashboard
      {...props}
      currentuser={currentuser}
      sharing={sharing}
      peopleTargetUrlParams={peopleTargetUrlParams}
      nextUrl={nextUrl}
    />
  );
});

export default DashBoard;
