import React from 'react';
import '../../styles.css';
import DatePicker from '../../../../../components/common/datePicker';

const Schedule = ({ values, setValues, formErrors, setFormErrors }) => {
  const handleChange = (value, name) => {
    setValues({ ...values, [name]: value });
    setFormErrors({ ...formErrors, [name]: value === '' || value === undefined });
  };
  return (
    <div className="general-wrapper">
      <DatePicker handleChange={handleChange} name="scheduled" error={formErrors['scheduled']} />
    </div>
  );
};

export default Schedule;
