import { ROUTES_PATH } from '../constant';
import { Trainer, LessonDetail } from '../containers';

const trainerRoutes = [
  {
    path: ROUTES_PATH.TRAINER + '/:status',
    exact: false,
    component: Trainer,
    isProtected: true,
  },
  {
    path: `${ROUTES_PATH.TRAINER_DETAIL}/:id`,
    exact: false,
    component: LessonDetail,
    isProtected: true,
  },
];

export default trainerRoutes;
