import React, { useState, useRef } from 'react';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';

import '../../../assets/css/dialog.css';
import '../../../assets/css/forms.css';
import icons from '../../../assets/img/icons.svg';
import { renderFieldNormal, RenderFieldNumber, renderFieldRadio } from './InputField';
import { AutoSuggestCompany } from '../../shared/RenderFieldWithSuggestion';
import { requiredCompany, requiredContact } from '../../../helpers';
// import RenderFieldNumber from '../../shared/RenderFieldNumber';

const DISABLE_MAIL_FIELD = true;

const targetSchema = Yup.object().shape({
  goal: Yup.string().required(),
  taskdue: Yup.string().required(),
  company: Yup.string().test('customRequireCompany', '', function (value) {
    // your logic
    const res = requiredCompany(value, this.parent);
    if (res) {
      return this.createError({ path: 'company', message: res });
    }
    return !!res;
  }),
  contact: Yup.string().test('customRequireContact', '', function (value) {
    // your logic
    const res = requiredContact(value, this.parent);
    if (res) {
      return this.createError({ path: 'contact', message: res });
    }
    return !!res;
  }),
  taskdescription: Yup.string().required(),
  type: Yup.string().required(),
  value: Yup.string().required(),
});

const renderFieldWithActionSheet = ({ field, form: { touched, errors }, ...props }) => {
  const { active, type, toggleActionSheet, customclass, maxLength, readOnly, icon, onBlur, onFocus, placeholderfloat } =
    props;
  const openActionSheet = (e) => {
    e.preventDefault();
    toggleActionSheet(field.name);
  };
  const hasValue = field.value && field.value.trim() && !active;
  return (
    <div
      className={`field-line ${customclass} ${active ? 'focused' : ''} ${hasValue ? 'has-value' : ''}
            ${errors[field.name] && touched[field.name] ? 'error ' : ''}`}
    >
      <label>{placeholderfloat}</label>
      <input
        {...field}
        type={type}
        readOnly={readOnly}
        maxLength={maxLength}
        autoComplete="off"
        onBlur={onBlur}
        onFocus={onFocus}
        onClick={(e) => {
          readOnly && openActionSheet(e);
        }}
      />
      <button
        className="action-sheet-btn"
        onClick={(e) => {
          openActionSheet(e);
        }}
        type="button"
      >
        <svg className="icon nav-icon">
          <use xlinkHref={`${icons}#${icon}`} />
        </svg>
        <span className="caret" />
      </button>
    </div>
  );
};

let EditTargetForm = (props) => {
  const toggleActionSheet = (type) => {
    props.toggleActionSheet({ type, open: true });
  };
  const messagesEndRef = useRef(null);
  const [focused, focusEl] = useState({});
  const { bindSubmitForm, bindSetValue } = props;
  return (
    <Formik
      initialValues={{ ...props.editDetails }}
      enableReinitialize="true"
      validationSchema={targetSchema}
      onSubmit={(values) => {
        props.editTarget(values);
      }}
      render={(fprops) => {
        // bind the submission handler remotely
        bindSubmitForm(fprops.submitForm);
        bindSetValue(fprops.setFieldValue);
        return (
          <form
            onKeyDown={(e) => {
              props.editConfirm(true);
              if (e.key === 'Enter' && e.target.name !== 'company') {
                e.stopPropagation();
                window.cordova && window.Keyboard && window.Keyboard.hide();
                setTimeout(() => fprops.handleSubmit(), 100);
              }
            }}
          >
            <fieldset className="target">
              <legend>
                <svg className="icon">
                  <use xlinkHref={`${icons}#person`} />
                </svg>{' '}
                Identify Your Target
              </legend>
              <p>Select a Company/Contact with strong business development potential.</p>
              <div id={!DISABLE_MAIL_FIELD && 'inline-inputs'}>
                <Field
                  component={AutoSuggestCompany}
                  type="text"
                  id="field-company"
                  placeholderfloat="Enter the company"
                  customclass="company"
                  name="company"
                  label="Company"
                  maxLength="100"
                  onFocus={() => focusEl({ company: true })}
                  onBlur={() => focusEl({ company: false })}
                  active={!!focused.company}
                  autoFocus={true}
                />
                <Field
                  component={renderFieldNormal}
                  type="text"
                  id="field-contact"
                  placeholderfloat="Enter the name of your contact"
                  customclass="contact"
                  name="contact"
                  label="Contact"
                  maxLength="100"
                  onFocus={() => focusEl({ contact: true })}
                  onBlur={() => focusEl({ contact: false })}
                  active={!!focused.contact}
                />
                {!DISABLE_MAIL_FIELD && (
                  <Field
                    component={renderFieldNormal}
                    type="text"
                    id="field-contact"
                    placeholder="Enter the email of your Contact"
                    customclass="contact"
                    maxLength="100"
                    name="email"
                    label="E-mail"
                    onFocus={() => focusEl({ email: false })}
                    onBlur={() => focusEl({ email: true })}
                  />
                )}
              </div>
              <p>Choose the type of Target.</p>
              <ul className="button-bar input type">
                <Field component={renderFieldRadio} name="type" type="radio" value="client" label="Client" />
                <Field component={renderFieldRadio} name="type" type="radio" value="prospect" label="Prospect" />
                <Field
                  component={renderFieldRadio}
                  name="type"
                  type="radio"
                  value="refsource"
                  label="Referral Source"
                />
              </ul>
              <p>Assign a value to the Target based on their ability to positively influence your book of business.</p>
              <ul className="button-bar input value">
                <Field component={renderFieldRadio} name="value" type="radio" value="L" label="Low Value" />
                <Field component={renderFieldRadio} name="value" type="radio" value="M" label="Medium Value" />
                <Field component={renderFieldRadio} name="value" type="radio" value="H" label="High Value" />
              </ul>
            </fieldset>
            <fieldset className="objective" data-chunk="Objective">
              <legend>
                <svg className="icon">
                  <use xlinkHref={`${icons}#objective`} />
                </svg>{' '}
                Define Your Desired Outcome
              </legend>
              <p>
                This should be a worthy and practical goal requiring several steps to achieve. Select the lightbulb for
                suggestions, then add your specifics.
              </p>
              <Field
                component={renderFieldWithActionSheet}
                type="text"
                id="field-objective"
                placeholderfloat="Describe your desired outcome"
                customclass="decorated"
                name="goal"
                label="Objective"
                maxLength="250"
                toggleActionSheet={toggleActionSheet}
                icon="action-suggest"
                onFocus={() => focusEl({ goal: true })}
                onBlur={() => setTimeout(() => focusEl({ goal: false }), 500)}
                active={!!focused.goal}
              />
            </fieldset>
            <fieldset className="revenue" data-chunk="Revenue">
              <legend>
                <svg className="icon">
                  <use xlinkHref={`${icons}#objective`} />
                </svg>
                Define Your Projected Revenue (optional)
              </legend>
              <div className="field-line decorated revenue-container">
                <Field
                  component={RenderFieldNumber}
                  id="field-projected-contact"
                  placeholderfloat="Update your Projected Revenue"
                  name="projected_revenue"
                  label="Projected Revenue"
                  onFocus={() => focusEl({ ['projected-revenue']: true })}
                  onBlur={() => focusEl((focused) => ({ ...focused, ['projected-revenue']: false }))}
                  active={!!focused['projected-revenue']}
                />
                <Field
                  component={RenderFieldNumber}
                  id="field-revenue"
                  placeholderfloat="Enter your Won Revenue"
                  name="revenue"
                  label="Won Revenue"
                  onFocus={() => focusEl({ ['revenue']: true })}
                  onBlur={() => focusEl((focused) => ({ ...focused, ['revenue']: false }))}
                  active={!!focused['revenue']}
                />
              </div>
            </fieldset>
            <fieldset className="step">
              <legend>
                <svg className="icon">
                  <use xlinkHref={`${icons}#first-step`} />
                </svg>{' '}
                Choose Your Next Step
              </legend>
              <p>
                Make it practical, something you can accomplish in the near term. Select the lightbulb for suggestions.
              </p>
              <Field
                component={renderFieldWithActionSheet}
                type="text"
                id="field-step"
                placeholderfloat="Describe your next Step"
                customclass="decorated"
                name="taskdescription"
                label="Next Step"
                maxLength="250"
                autoFocus={false}
                toggleActionSheet={toggleActionSheet}
                icon="action-suggest"
                onFocus={() => {
                  focusEl({ taskdescription: true });
                }}
                onBlur={() => setTimeout(() => focusEl({ taskdescription: false }), 500)}
                active={!!focused.taskdescription}
              />
              <Field
                component={renderFieldWithActionSheet}
                type="text"
                id="field-due"
                placeholderfloat="When will you complete this step?"
                customclass="decorated"
                maxLength="50"
                name="taskdue"
                label="Due Date"
                toggleActionSheet={toggleActionSheet}
                icon="action-calendar"
                readOnly={true}
                onFocus={() => focusEl({ taskdue: true })}
                onBlur={() => focusEl({ taskdue: false })}
                active={!!focused.taskdue}
              />
            </fieldset>
            <input type="hidden" ref={messagesEndRef}></input>
          </form>
        );
      }}
    />
  );
};

export default EditTargetForm;
