import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { useAuth0 } from '@auth0/auth0-react';

import '../../assets/css/dialog.css';
import '../../assets/css/forms.css';
import { required, emailValidation } from '../../helpers';
import { accountTab, yes } from '../../constant';
import { changeAccountInfo } from '../../services/account/accountUpdate.service';
import icons from '../../assets/img/icons.svg';
import { useLogout } from '../../services/user/auth0';

/**
 * @description This component render account detail of user
 * @param {object} $input - {name, onBlur, onChange, onDragStart, onFocus, value}
 * @param {string} $label
 * @param {string} $type
 * @param {string} $placeholder
 * @param {boolean} $required - true/false
 * @param {string} $description
 */
const renderField = ({
  input,
  label,
  type,
  placeholder,
  required,
  description,
  disabled,
  meta: { touched, error },
}) => {
  const disableField = disabled ? { disabled: true } : null;
  return (
    <li>
      <label>
        {label}
        <small>{required ? ' *' : ''}</small>
      </label>
      <input
        {...input}
        type={type}
        placeholder={placeholder}
        {...disableField}
        className={touched && error ? 'error' : ''}
      />
      {touched && error && <span className="error">{error}</span>}
      {description ? <p className="description">{description}</p> : ''}
    </li>
  );
};

let AccountForm = (props) => {
  const { handleSubmit, submitting } = props;
  const { isAuthenticated } = useAuth0();
  const logout = useLogout();

  const userLogout = () => {
    if (isAuthenticated) {
      props.logout();
      logout();
    } else {
      props.logout();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(changeAccountInfo)}>
        <fieldset>
          <ol>
            <li className="org">
              <dl className="handle org">
                <dt className="avatar">
                  <svg>
                    <use xlinkHref={`${icons}#org-org`}></use>
                  </svg>
                </dt>
                <dd className="name">{props.org.name}</dd>
              </dl>
            </li>
            <Field
              component={renderField}
              type="email"
              placeholder="Email"
              name="email"
              label="Email Address"
              disabled={true}
              required={false}
              validate={[required, emailValidation]}
              description="If you would like to change your email address, please contact your administrator."
            />
            <Field
              component={renderField}
              type="text"
              placeholder="First Name"
              name="first_name"
              label="First Name"
              required={true}
              validate={[required]}
            />
            <Field
              component={renderField}
              type="text"
              placeholder="Last Name"
              name="last_name"
              label="Last Name"
              required={true}
              validate={[required]}
            />
            {props.subscription.license &&
              props.subscription.license.features['2fa'].on === yes &&
              props.settings['account-2fa'] > 0 && (
                <Field
                  component={renderField}
                  type="tel"
                  placeholder="Mobile Phone Number"
                  name="phone"
                  label="Mobile Phone Number"
                  required={true}
                  description="For account security purposes only."
                />
              )}
            <li>
              <input className="save-account" type="submit" disabled={submitting} value="Save All Changes" />
            </li>
          </ol>
        </fieldset>
      </form>
      <hr />
      <div className="actions-block">
        <div>
          <p className="account">
            Want to change your password? <a onClick={() => props.changeTab(accountTab.password)}>Click here</a>
          </p>
        </div>
        <div>
          <input className="log-out button secondary" type="button" value={props.logOutText} onClick={userLogout} />
        </div>
      </div>
      <hr />
      {props.org.category_name === 'Individual' && (
        <div className="actions-block">
          <div>
            <p className="account">
              Want to cancel your subscription?{' '}
              <a href={accountTab.link} target="_blank" rel="noreferrer">
                Click here
              </a>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

// a unique name for the form
AccountForm = reduxForm({ form: 'AccountForm' })(AccountForm);

export default AccountForm;
