import { SHOW_MORE_CONTENT } from '../../constant';
/**
 * Show more toggle status
 */

export const showMoreToggle = (status) => (dispatch) => {
  return dispatch({
    type: SHOW_MORE_CONTENT,
    payload: status,
  });
};
