import React from 'react';
import * as moment from 'moment';

import { nextWeek, tomorrow, DueDateFormatToDisplay } from '../../../constant';
import icons from '../../../assets/img/icons.svg';

/**
 * component for SnoozeForm in slider target detail
 */
const SnoozeForm = (props) => {
  const snooze = (day) => {
    props.snooze(moment().add(day, 'days').format(DueDateFormatToDisplay));
  };

  const toggleActionSheet = (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    props.toggleActionSheet({ type, open: true });
  };

  return (
    <div className={`followup snooze ${props.active && 'active'}`}>
      {props.active && (
        <>
          <h3>
            <span>Snooze due date until...</span>
          </h3>
          <div className="yesno">
            <button className="action next" onClick={() => snooze(tomorrow)}>
              <span>Tomorrow</span>
            </button>
            <button className="action next" onClick={() => snooze(nextWeek)}>
              <span>Next Week</span>
            </button>
          </div>
          <button className="action next action-sheet-btn" onClick={(e) => toggleActionSheet(e, 'taskdue')}>
            <span>Choose a Date </span>
            <svg className="icon nav-icon">
              <use xlinkHref={`${icons}#action-calendar`} />
            </svg>
          </button>
        </>
      )}
    </div>
  );
};

export default SnoozeForm;
