import React from 'react';
import { getElementName } from '../../../helpers';
import { useSelector } from 'react-redux';

const VideoInsight = ({ insight }) => {
  const sections = useSelector((state) => state.insightDashboard.sections);
  return (
    <div className="content-insight-wrapper">
      <video src={insight?.file_url} width="100%" controls></video>
      <header>
        <h1>{insight?.name}</h1>
        <h2>{getElementName(insight?.section_id, sections)}</h2>
      </header>
      <p>{insight?.description}</p>
    </div>
  );
};

export default VideoInsight;
