import React, { useMemo } from 'react';
import '../../styles.css';
import StyledSelect from '../../../../../components/common/select';
import { CREATE_MODALS } from '../../../../../constant/insights';
import { useSelector } from 'react-redux';

const Styling = ({ values, setValues, formErrors, setFormErrors, setNewElementModalOpen }) => {
  const templates = useSelector((state) => state.insightDashboard.digestTemplates);
  const options = useMemo(() => templates.map((template) => ({ ...template, label: template.name })), [templates]);

  const handleChange = (value) => {
    const selectedTemplate = templates.filter((template) => template?.name === value);
    setValues({
      ...values,
      template: value,
      styles: {
        template_id: selectedTemplate[0]?.id,
        logo: selectedTemplate[0]?.logo_image,
        backgroundColor: selectedTemplate[0]?.color,
        backgroundImage: selectedTemplate[0]?.image,
        activeTab: selectedTemplate[0]?.image_selected,
      },
    });
    setFormErrors({ ...formErrors, template: false });
  };
  return (
    <StyledSelect
      value={values.template}
      options={options}
      defaultOption="Select Template"
      handleChange={handleChange}
      error={formErrors.template}
      addNew="Add new template"
      handleCreate={() => setNewElementModalOpen(CREATE_MODALS.STYLES)}
      isSearchable
      isCreatable
    />
  );
};

export default Styling;
