import { getTokenExpiration, iso8601ToSeconds, secondsToMinutes } from '.';
import { getNewAccessToken } from '../services/insights/insight.service';

export const getYtbContent = (inputValue, setVideoData, setLoading, setVideoIssue) => {
  let videoId = inputValue.split('v=')[1];
  var ampersandPosition = videoId.indexOf('&');
  if (ampersandPosition != -1) {
    videoId = videoId.substring(0, ampersandPosition);
  }

  const ytbVideoURL = `${process.env.REACT_APP_YTB_API_URL}?id=${videoId}&part=contentDetails,snippet&key=${process.env.REACT_APP_YTB_API_KEY}`;

  fetch(ytbVideoURL)
    .then((response) => {
      if (!response.ok) setVideoIssue(true);
      return response.json();
    })
    .then((json) => {
      const { items } = json;
      const { snippet } = items[0];
      const { contentDetails } = items[0];
      setVideoData({
        thumbnail: snippet?.thumbnails?.medium?.url,
        title: snippet?.title,
        description: snippet?.description,
        duration: iso8601ToSeconds(contentDetails?.duration),
      });
      setLoading(false);
    });
};

export const getVimeoContent = (inputValue, setVideoData, setLoading, setVideoIssue) => {
  const regExp = /(?:http:|https:|)\/\/(?:player.|www.)?vimeo\.com\/(?:video\/|embed\/|watch\?\S*v=|v\/)?(\d*)/;
  const videoId = inputValue.match(regExp);

  const vimeoVideo = `${process.env.REACT_APP_VIMEO_API_URL}${videoId[1]}`;
  fetch(vimeoVideo, { headers: { Authorization: `Bearer ${process.env.REACT_APP_VIMEO_API_KEY}` } })
    .then((response) => {
      if (!response.ok) setVideoIssue(true);
      return response.json();
    })
    .then((json) => {
      setVideoData({
        thumbnail: json?.pictures?.sizes[2]?.link,
        title: json?.name,
        description: json?.description,
        duration: secondsToMinutes(json?.duration),
        url: json?.player_embed_url,
      });
      setLoading(false);
    });
};

export const getOneDriveContent = (inputValue, setVideoData, setLoading, setVideoIssue) => {
  const videoId = btoa(inputValue);
  const oneDriveVideo = `https://api.onedrive.com/v1.0/shares/u!${videoId}/root?expand=thumbnails`;

  fetch(oneDriveVideo)
    .then((response) => {
      if (!response.ok) setVideoIssue(true);
      return response.json();
    })
    .then((json) => {
      setVideoData({
        thumbnail: json?.thumbnails[0]?.medium?.url,
        title: json?.name,
        description: json?.description,
        duration: iso8601ToSeconds(json?.video?.duration),
        url: json?.webUrl,
      });
      setLoading(false);
    });
};

export const getGoogleDriveContent = async (inputValue, setVideoData, setLoading, setVideoIssue) => {
  let token = '';
  const bearer = localStorage.getItem('driveToken') && JSON.parse(localStorage.getItem('driveToken'));

  if (!bearer || getTokenExpiration(bearer?.date)) {
    const newAccessToken = await getNewAccessToken();
    token = newAccessToken;
  } else {
    token = bearer?.access_token;
  }

  const regExp = /\/file\/d\/([^/]+)/;
  const videoId = inputValue.match(regExp);

  const uri = `${process.env.REACT_APP_DRIVE_API_URL}${videoId[1]}?fields=id,name,mimeType,thumbnailLink,description,videoMediaMetadata`;
  fetch(uri, { headers: { Authorization: `Bearer ${token}` } })
    .then((response) => {
      if (!response.ok) setVideoIssue(true);
      return response.json();
    })
    .then((json) => {
      setVideoData({
        thumbnail: `${json?.thumbnailLink}`,
        title: json?.name,
        description: json?.description,
        duration: iso8601ToSeconds(json?.videoMediaMetadata?.durationMillis),
        url: inputValue,
      });
      setLoading(false);
    });
};
