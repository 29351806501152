import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import '../../assets/css/nav-main.css';
import icons from '../../assets/img/icons.svg';
import logoPP from '../../assets/img/PipelinePlus_Horizontal_Logo_RGB_Full_Color.svg';
import { mainTabs } from '../../constant';
import { getlogoFromdb, getViewer } from '../../actions';
import { getUserData, checkSub } from '../../helpers/';
import { Redirect } from 'react-router-dom';
import { ROUTES_PATH } from '../../constant';

/* This Component render primary navigation menu i.e. Pipeline, Insight, Trainer, Viewer */

const NavMain = (props) => {
  const [logo, setLogo] = useState(props.logo);
  const dispatch = useDispatch();
  const userData = props.userData;
  const { pathname } = props.history.location;
  useEffect(() => {
    if (!window.cordova) {
      const obj = props.getlogoFromdb();
      obj &&
        obj.then((url) => {
          if (url) {
            setLogo(url);
          } else {
            const user = getUserData().user;
            let liveUrl = user && user.Org.logo.url;
            liveUrl = liveUrl ? liveUrl : logoPP;
            setLogo(liveUrl);
          }
        });
    } else {
      const user = getUserData().user;
      let liveUrl = user && user.Org.logo.url;
      liveUrl = liveUrl ? liveUrl : logoPP;
      setLogo(liveUrl);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLogo(props.logo);
  }, [props.logo]);

  /**
   * @desc get viewer data on viewer tab click
   * @param {object} $tab - tabData
   */
  const handleTabClick = (tab, activeTab) => {
    if ((tab.name === 'Viewer' && activeTab !== '/viewer') || tab.name === 'Insights') {
      dispatch(getViewer());
    }
  };
  const keyOrg = 'key-org_report';

  if (!userData) {
    return <Redirect to={ROUTES_PATH.LOGIN} />;
  }
  return (
    <nav
      id="nav-main"
      className={
        'app nav-primary show' +
        (getUserData() && getUserData().user && getUserData().user && getUserData().user.Switch ? ' switched' : '')
      }
    >
      <ul>
        <li className={'logo'} id="branding">
          <img src={logo} className="PipelinePlus logo" alt="PipelinePlus" />
        </li>
        {getUserData() &&
          getUserData() &&
          getUserData().user &&
          getUserData().user.Switch &&
          getUserData().user &&
          getUserData().user.Switch.revert && (
            <li id="switch-warning">
              <svg className="icon nav-icon">
                <use xlinkHref={`${icons}#action-reset`} />
              </svg>
              <a href="#/account">{getUserData().user.first_name + ' ' + getUserData().user.last_name}</a>
            </li>
          )}
        {mainTabs
          .filter((tabs) => (userData.Settings[keyOrg] === 0 ? tabs.name !== 'Insights' : tabs))
          .map((tab) => {
            const activeTab = `/${pathname.split('/')[1]}`;
            if (checkSub(tab.permission)) {
              return (
                <li
                  className={`${activeTab.includes(tab.activeOn) ? 'active' : ''} ${tab.icon === 'insights' ? 'insights-tab' : ''}`}
                  id={tab.id}
                  key={tab.name}
                  onClick={() => handleTabClick(tab, activeTab)}
                >
                  <Link to={tab.link}>
                    <svg className="icon nav-icon">
                      <use xlinkHref={`${icons}#${tab.icon}`} />
                    </svg>
                    <svg className="icon nav-icon on">
                      <use xlinkHref={`${icons}#${tab.icon}-on`} />
                    </svg>
                    <span className="label">{tab.name}</span>
                  </Link>
                </li>
              );
            } else {
              return '';
            }
          })}
      </ul>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  logo: state.logo,
});

const mapDispatchToProps = (dispatch) => ({
  getlogoFromdb: () => dispatch(getlogoFromdb()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavMain));
