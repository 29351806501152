import React from 'react';

import icons from '../../../assets/img/icons.svg';

let CreateTargetButton = (props) => {
  return (
    <>
      {/* Create Target Button - Pipeline */}
      <div id="tile-create-button" className="create-button" onClick={props.openCreateTargetModal}>
        <div>
          <svg className="nav-icon">
            <use xlinkHref={`${icons}#action-create`} />
          </svg>
        </div>
      </div>
    </>
  );
};

export default CreateTargetButton;
