import React, { forwardRef, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import Lessons from './listing/lesson/Lessons.component';
import Worksheets from './listing/worksheet/Worksheets.component';
import FirmContentContainer from '../../containers/Trainer/FirmContent/FirmContent.container';
import { API_PARAMS } from '../../constant';
import { getUserData } from '../../helpers';

const TrainerComp = (props, ref) => {
  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    setInProp(true);
  }, []);

  return (
    <CSSTransition classNames="fade" in={inProp} timeout={300}>
      <section ref={ref} className="nav-page active" id="trainer">
        <div className="block-wrapper">
          <div className={`trainer-${props.status} active`} id={`trainer-${props.status}`}>
            {props.status === API_PARAMS.LESSONS && <Lessons {...props} />}
            {props.status === API_PARAMS.WORKSHEETS && <Worksheets {...props} />}
            {props.status === API_PARAMS.FIRMCONTENT && <FirmContentContainer {...props} user={getUserData()?.user} />}
          </div>
        </div>
      </section>
    </CSSTransition>
  );
};

export default forwardRef(TrainerComp);
