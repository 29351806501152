import { GET_TARGET_NOTES, GET_TILES_NOTES, ADD_TARGET_NOTE, EDIT_TARGET_NOTE } from '../../constant';

const initialState = [];

const targetNote = (state = initialState, action) => {
  switch (action.type) {
    case GET_TARGET_NOTES:
      return action.payload;
    case GET_TILES_NOTES:
      return action.payload;
    case ADD_TARGET_NOTE:
    case EDIT_TARGET_NOTE:
      return [action.payload];
    default:
      return state;
  }
};

export default targetNote;
