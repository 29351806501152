// set up vars and configure the embed
var launchPBI = function (conf) {
  var $kpi = document.getElementById('ackert-kpis');
  // now deal with the main powerbi js file and config
  $kpi.innerHTML = '<div id="powerbi-dashboard" style="position:absolute;left:0;top:0;right:0;bottom:0;"></div>';
  var $pbielm = document.getElementById('powerbi-dashboard');
  // load the main powerbi script
  var script = document.createElement('script');
  script.onload = function () {
    console.log('PBI', 'main script loaded');

    // pointer to the powerbi-client object
    var PBIcore = window.powerbi;
    var PBIclient = window['powerbi-client'];

    var models = PBIclient.models;
    var permissions = models.Permissions.All;

    var tokenType = conf.tokenType == '0' ? models.TokenType.Aad : models.TokenType.Embed;

    // base pageView on the width of the window
    var pageView = window.innerWidth <= 800 ? 'oneColumn' : 'fitToWidth';

    // set up the configuration object that determines what to embed and how to embed it.
    var dashconfig = {
      accessToken: conf.token.access_token,
      embedUrl: conf.urls.embed,
      id: conf.reportId,
      // pageView:       conf.pageView,
      pageView: pageView,
      type: conf.type,
      settings: conf.settings,
      tokenType: tokenType,
      permissions: permissions,
    };

    // add the access token to the main pbi object
    PBIcore.accessToken = conf.token.access_token;
    // get a reference to the HTML element that contains the embedded dashboard.
    var report = PBIcore.embed($pbielm, dashconfig);
    report.on('error', function (event) {
      console.log('PBI', 'error', event, arguments);
      report.off('error');
    });

    // set timeout to refresh token
    report.on('loaded', function (event) {
      console.log('PBI', 'dashboard loaded', event, 'report=', report);
      function cycleToken(report) {
        setTimeout(
          function () {
            updateToken()
              .then(function (data) {
                console.log('PBI', 'Resetting token:', report.getAccessToken());
                report
                  .setAccessToken(data)
                  .then(function (resp) {
                    console.log('PBI', 'New token:', report.getAccessToken(), resp);
                    sessionStorage.setItem('access_token', report.getAccessToken());
                  })
                  .catch(function (error) {
                    console.log('PBI', 'ERROR report.setAccessToken', error);
                  });
                cycleToken(report);
              })
              .catch(function (error) {
                console.log('PBI', 'ERROR on updateToken', error);
              });
            // 1000 is 1 second, so this is one hour
          },
          1000 * 60 * 10,
        );
      }

      cycleToken(report);
    });

    function updateToken() {
      var restURL = conf.urls.getToken;
      // console.log('PBI', 'updateToken', restURL, conf);
      return new Promise(function (resolve, reject) {
        fetch(restURL)
          .then((response) => response.json())
          .then((response) => resolve(response))
          .catch((error) => {
            console.log('PBI', 'ERROR on updateToken', error);
            reject(error);
          });
      });
    }
  };
  // now that we have handled the loaded event, add the src so the script downloads
  script.src = conf.urls.js;
  // and add it to the header
  document.head.appendChild(script);
};

export default launchPBI;
