import { SHOW_PIPELINE_CONTROLS_MODAL, HIDE_PIPELINE_CONTROLS_MODAL } from '../../constant';
import { modalOpen } from '../../helpers/utils';

import { LOAD_HUD, UPDATE_STEP, CALL_HUD, RESET_PEOPLE_HUD } from '../../constant';
import { updateATile } from '../../helpers/dbStorage';

const initialState = {
  controlsModalProps: {
    open: false,
    steps: { you: { this_week: '~' }, firm: {} },
    score: {
      you: {
        current: { score: '~', tile_points: '~', bonus_points: '~' },
        events: [],
      },
      firm: {},
    },
    revenue: {
      you: { ytd_formatted: '~', ytd_targets_formatted: '~' },
      firm: {},
    },
    id: 'idhud',
  },
};

const pipelineControls = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_HUD:
      return { ...state, ...action.payload };
    case UPDATE_STEP: {
      const hud = state;
      const step = +hud.steps.you.this_week + 1;
      hud.steps.you.this_week = step;
      updateATile(hud, 'idhud');
      return { ...hud };
    }
    case RESET_PEOPLE_HUD: {
      return initialState;
    }
    case SHOW_PIPELINE_CONTROLS_MODAL:
    case HIDE_PIPELINE_CONTROLS_MODAL: {
      let tempObj = state.controlsModalProps;
      tempObj.open = action.open;
      modalOpen(action.open);
      return {
        ...state,
        ...tempObj,
      };
    }
    default:
      return state;
  }
};

export default pipelineControls;

export const hudUpdate = (state = true, action) => {
  if (action.type === CALL_HUD) {
    return action.payload;
  }
  return state;
};
