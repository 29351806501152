import {
  getInsights,
  getInsightsFromLocalDb,
  getBlogs,
  getWorksheets,
  getResources,
  getBlogDetails,
  getFirmVideos,
  removeFirmVideo,
  updateFirmVideo,
  createNewVideoInsight,
  createNewSection,
  getSections,
  getContent,
  getContentTags,
  createNewTag,
  editNewVideoInsight,
  deleteVideoInsight,
  createNewTemplate,
  getTemplates,
  createDigest,
  getDigests,
  createNewDiffusionList,
  getDiffusionLists,
  deleteDigest,
  deleteSection,
} from '../../services/insights/insight.service';
import {
  LOAD_INSIGHTS,
  API_PARAMS,
  MARK_INSIGHT_VIEWED_COMMIT,
  MARK_INSIGHT_VIEWED_ROLLBACK,
  MARK_INSIGHT_VIEWED_REQUEST,
  LOAD_INSIGHTS_COUNT,
  REDUCE_INSIGHTS_COUNT,
  LOAD_BLOGS,
  LOAD_BLOG_DETAILS,
  LOAD_WORKSHEETS,
  LOAD_RESOURCES,
  SAVE_WORKSHEET_REQUEST,
  SAVE_WORKSHEET_ROLLBACK,
  SAVE_WORKSHEET_COMMIT,
  API_URLS,
  API_METHODS,
  GET_FIRM_CONTENT,
  REMOVE_FIRM_CONTENT,
  UPDATE_FIRM_CONTENT,
  BLOG_FILTER_KEYWORD,
  DRAG_INSIGHT,
  ADD_DIFFUSION_LIST,
  SET_PREVIEW_VALUES,
  SET_DIGEST_VALUES,
  SET_DIGEST_TEMPLATES,
  ADD_NEW_SECTION,
  GET_SECTIONS,
  GET_INSIGHTS,
  GET_TAGS,
  ADD_NEW_TAG,
  DELETE_INSIGHT,
  INSIGHT_TO_DELETE,
  FILTERED_INSIGHTS,
  INSIGHT_SEARCH_VALUES,
  EDIT_INSIGHT,
  ADD_NEW_TEMPLATE,
  ADD_DIGEST,
  GET_DIGESTS,
  NEW_DIFFUSION_LIST,
  GET_DIFFUSION_LIST,
  ADD_FAVORITE,
  POPULATE_CONTENT,
  REMOVE_POPULATE_CONTENT,
  DELETE_DIGEST,
  DELETE_SECTION,
  RESTORE_SEARCH,
  SECTION_TO_DELETE,
  RESET_DIGEST,
} from '../../constant';
import { showLoader, hideLoader } from '../shared/loader.action';
import { insertDocs, deleteDoc, getInsight } from '../../helpers/dbStorage';
import { getToken, isOnline } from '../../helpers/sessionManager';
import { differenceBy, groupBy } from 'lodash';
import { getFirmProjectId } from '../../helpers';

export const updateInsightCount = (count) => (dispatch) => {
  dispatch({
    type: LOAD_INSIGHTS_COUNT,
    payload: count,
  });
};
/**
 * get from server and save all tiles (targets) to local db
 * @param { string } status - { active, archive, completed } status of tiles
 * @param { boolean } fromLogin - { true, false } is api called from after logged i.e. first time after logged on device
 */
export const getAndSaveInsights =
  (status = 'viewed', fromLogin = true) =>
  (dispatch) => {
    return getInsights(status).then((response) => {
      if (status === API_PARAMS.NEW) {
        dispatch(updateInsightCount(response.length));
      }
      if (response.length) {
        response.map((item) => (item.status = status));
        insertDocs(response);
      }
      const localPromise = getInsightsFromLocalDb(status);
      localPromise &&
        localPromise.then((insights) => {
          if (insights) {
            const needtoDeleted = differenceBy(insights.docs, response, 'id');
            needtoDeleted.forEach((insight) => deleteDoc(insight.id));
          }
        });
      if (!fromLogin) {
        dispatch(hideLoader());
        dispatch({
          type: LOAD_INSIGHTS,
          payload: response,
        });
      }
    });
  };
/**
 * call the api to get all three types of insights
 */
export const getAllInsights = () => (dispatch) => {
  if (getToken()) {
    dispatch(getAndSaveInsights(API_PARAMS.NEW));
    dispatch(getAndSaveInsights(API_PARAMS.VIEWED));
  }
};

export const getInsightsFromlocalDB = (status, attention) => (dispatch) => {
  !attention && dispatch(showLoader());
  if (isOnline() && !attention) {
    dispatch(getAndSaveInsights(status, false));
  } else {
    getInsight(status).then((res) => {
      if (status === API_PARAMS.NEW) {
        dispatch(updateInsightCount(res.docs.length));
      }
      res.docs.sort((a, b) => b.id - a.id); // sort by id
      dispatch(hideLoader());

      dispatch({
        type: LOAD_INSIGHTS,
        payload: res.docs,
      });
    });
  }
};

export const markAsViewed = (payload) => ({
  type: MARK_INSIGHT_VIEWED_REQUEST,
  payload: { ...payload },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        apiInfo: { url: `${API_URLS.INSIGHTS}/${payload.id}${API_URLS.VIEWINSIGHT}`, type: API_METHODS.POST },
        data: payload,
      },
      // action to dispatch when effect succeeds:
      commit: { type: MARK_INSIGHT_VIEWED_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: { type: MARK_INSIGHT_VIEWED_ROLLBACK },
    },
  },
});
export const reduceInsightCount = () => (dispatch) => {
  dispatch({
    type: REDUCE_INSIGHTS_COUNT,
  });
};

// adding in trainer actions
/**
 * Get trainer data from Local DB based on status
 * @param {string} status Trainer tabs
 */
const getBlogDataFromLocalDB = (status) => getInsight(status);

/**
 * get lessons
 * @param {string} status
 */
export const getAndSaveBlogs = (status) => async (dispatch) => {
  let response = [];

  if (isOnline()) {
    // get lessons from server
    response = await getBlogs();
  } else {
    // get lessons from local DB
    const localData = await getBlogDataFromLocalDB(status);
    response = localData.docs || [];
  }
  if (response.length) {
    response.map((item) => {
      item.status = status;
      // if (item.description) item.description = item.description.replace(/<div>\r\n\r\n|\r\n\r\n<\/div>|<div>|<\/div>/g, '');
      return item;
    });
    insertDocs(response);

    const localPromise = getBlogDataFromLocalDB(status);
    localPromise &&
      localPromise.then((blogs) => {
        if (blogs) {
          const needtoDeleted = differenceBy(blogs.docs, response, 'id');
          needtoDeleted.forEach((blog) => deleteDoc(blog.id));
        }
      });

    const blogs = [...response];

    // sort lessons by a fixed order
    // the server sends the payload already sorted
    // response.sort((a, b) => LESSONS_ORDER.indexOf(a.focus) - LESSONS_ORDER.indexOf(b.focus));

    dispatch(hideLoader());
    dispatch({
      type: LOAD_BLOGS,
      payload: {
        listing: groupBy(response, 'focus'),
        data: blogs,
      },
    });
  }
};

export const getBlogFilterKeyword = (keyword) => (dispatch) => {
  dispatch({
    type: BLOG_FILTER_KEYWORD,
    keyword,
  });
};

/**
 * get Worksheets
 * @param {string} status
 */
export const getAndSaveWorksheets = (status) => async (dispatch) => {
  let response = [];

  if (isOnline()) {
    // get worksheet from server
    response = await getWorksheets();
  } else {
    // get worksheet from local DB
    const localData = await getBlogDataFromLocalDB(status);
    response = localData.docs || [];
    // sort response by lesson number
    response.sort((a, b) => a.number - b.number);
  }

  if (response.length) {
    response.map((item) => (item.status = API_PARAMS.WORKSHEETS));
    insertDocs(response);

    const localPromise = getBlogDataFromLocalDB(status);
    localPromise &&
      localPromise.then((worksheets) => {
        if (worksheets) {
          const needtoDeleted = differenceBy(worksheets.docs, response, 'id');
          needtoDeleted.forEach((worksheet) => deleteDoc(worksheet.id));
        }
      });

    dispatch({
      type: LOAD_WORKSHEETS,
      payload: response,
    });
  }
};

/**
 * get Resources
 * @param {string} status
 */
export const getAndSaveResources = (status) => async (dispatch) => {
  let response = [];

  if (isOnline()) {
    // get resources from server
    response = await getResources();
  } else {
    // get resources from local DB
    const localData = await getBlogDataFromLocalDB(status);
    response = localData.docs || [];
    // sort resouces by date
    response.sort((a, b) => new Date(b.date) - new Date(a.date));
  }

  if (response.length) {
    response.map((item) => (item.status = API_PARAMS.RESOURCES));
    insertDocs(response);

    const localPromise = getBlogDataFromLocalDB(status);
    localPromise &&
      localPromise.then((resources) => {
        if (resources) {
          const needtoDeleted = differenceBy(resources.docs, response, 'id');
          needtoDeleted.forEach((resource) => deleteDoc(resource.id));
        }
      });

    dispatch({
      type: LOAD_RESOURCES,
      payload: response,
    });
  }
};

/**
 * funtion to get trainer data according to type
 * @param {string} status Trainer tabs
 */
export const getBlogData = (status) => (dispatch) => {
  switch (status) {
    case API_PARAMS.WORKSHEETS:
      dispatch(getAndSaveWorksheets(status));
      break;
    case API_PARAMS.RESOURCES:
      dispatch(getAndSaveResources(status));
      break;
    default:
      dispatch(getAndSaveBlogs(status));
      break;
  }
};

/**
 * Get lesson details
 * @param {string} lessonId
 */
export const getBlogDetailsData = (BlogId) => (dispatch) => {
  return getBlogDetails(BlogId).then((response) => {
    dispatch({
      type: LOAD_BLOG_DETAILS,
      payload: response,
    });
  });
};

/**
 * update watch time of lesson video
 * @param {string} lessonId
 * @param {number} time
 */
// export const updateLessonWatchedTime = (lessonId, time) => () => updateWatchTime(lessonId, time);

/**
 * Action for saving worksheet matrix form
 * @param {string} lessonId
 * @param {object} matrix
 */
export const saveWorksheetMatrix = (lessonId, matrix) => (dispatch, getState) => {
  // get lesson from redux store to handle offline mode
  const {
    worksheets,
    lessons: { data },
  } = getState();
  const allLessons = worksheets.length ? worksheets : data;
  const payload = allLessons.find((item) => item.id === lessonId);
  payload.worksheet.matrix = [...matrix];
  // // show success message
  // setTimeout(() => {
  //     toaster.success(MESSAGES.saveWorksheet);
  //     // close all expanded worksheets
  //     const allExpandedNodes = document.querySelectorAll('.expanded');
  //     allExpandedNodes.forEach(node => node.classList.remove('expanded'));
  // }, 1000);
  return dispatch({
    type: SAVE_WORKSHEET_REQUEST,
    payload,
    meta: {
      offline: {
        // the network action to execute:
        // stringify data to send as query parameter
        effect: {
          apiInfo: { url: `${API_URLS.WORKSHEETS}${lessonId}`, type: API_METHODS.POST },
          data: { matrix: JSON.stringify(matrix) },
        },
        // action to dispatch when effect succeeds:
        commit: { type: SAVE_WORKSHEET_COMMIT },
        // action to dispatch if network action fails permanently:
        rollback: { type: SAVE_WORKSHEET_ROLLBACK },
      },
    },
  });
};

/**
 * Fetch User generated content
 */
export const getFirmContents = () => (dispatch) => {
  return getFirmVideos(getFirmProjectId()).then((response) =>
    dispatch({
      type: GET_FIRM_CONTENT,
      payload: response,
    }),
  );
};

/**
 * Delete User generated content
 */
export const removeFirmContents = (hashed_id) => (dispatch) => {
  return removeFirmVideo(hashed_id).then((response) =>
    dispatch({
      type: REMOVE_FIRM_CONTENT,
      payload: response,
    }),
  );
};

/**
 * Update User generated content details
 */
export const updateFirmContents = (hashed_id, title, description) => (dispatch) => {
  return updateFirmVideo(hashed_id, title, description).then((response) =>
    dispatch({
      type: UPDATE_FIRM_CONTENT,
      payload: response,
    }),
  );
};

export const createVideoInsight = async (data) => {
  return await createNewVideoInsight(data);
};

export const editVideoInsight = async (data, id) => {
  return await editNewVideoInsight(data, id);
};

export const updateDraggedInsight = (insight) => (dispatch) => {
  dispatch({
    type: DRAG_INSIGHT,
    payload: insight,
  });
};

export const setSelectedRecipients = (data) => (dispatch) => {
  dispatch({
    type: ADD_DIFFUSION_LIST,
    payload: data,
  });
};

export const setPreviewValues = (data) => (dispatch) => {
  dispatch({
    type: SET_PREVIEW_VALUES,
    payload: data,
  });
};

export const setDigestValues = (data) => (dispatch) => {
  dispatch({
    type: SET_DIGEST_VALUES,
    payload: data,
  });
};

export const getStyleTemplates = () => (dispatch) => {
  return getTemplates()
    .then((response) =>
      dispatch({
        type: SET_DIGEST_TEMPLATES,
        payload: response,
      }),
    )
    .catch((error) => {
      throw new Error(error?.response?.data?.message);
    });
};

export const setNewTemplate = (body) => (dispatch) => {
  return createNewTemplate(body)
    .then((response) =>
      dispatch({
        type: ADD_NEW_TEMPLATE,
        payload: response,
      }),
    )
    .catch((error) => {
      throw new Error(error?.response?.data?.message);
    });
};

export const addNewSection = (name) => (dispatch) => {
  return createNewSection({ name })
    .then((response) =>
      dispatch({
        type: ADD_NEW_SECTION,
        payload: response,
      }),
    )
    .catch((error) => {
      throw new Error(error?.response?.data?.message);
    });
};

export const addNewTag = (name) => (dispatch) => {
  return createNewTag({ name })
    .then((response) =>
      dispatch({
        type: ADD_NEW_TAG,
        payload: response,
      }),
    )
    .catch((error) => {
      throw new Error(error?.response?.data?.message);
    });
};

export const getInsightSections = (name) => (dispatch) => {
  return getSections({ name }).then((response) =>
    dispatch({
      type: GET_SECTIONS,
      payload: response,
    }),
  );
};

export const getNewContent = () => (dispatch) => {
  return getContent()
    .then((response) =>
      dispatch({
        type: GET_INSIGHTS,
        payload: response,
      }),
    )
    .catch((error) => {
      throw new Error(error?.response?.data?.message);
    });
};

export const editContent =
  (editedContent, insights, add = false) =>
  (dispatch) => {
    let updatedInsights = [];
    if (add) {
      updatedInsights = [...insights, editedContent];
    } else {
      updatedInsights = insights.map((insight) => {
        if (insight?.id === editedContent?.id) {
          return editedContent;
        } else {
          return insight;
        }
      });
    }

    dispatch({
      type: EDIT_INSIGHT,
      payload: updatedInsights,
    });
  };

export const getTags = () => (dispatch) => {
  return getContentTags()
    .then((response) =>
      dispatch({
        type: GET_TAGS,
        payload: response,
      }),
    )
    .catch((error) => {
      throw new Error(error?.response?.data?.message);
    });
};

export const contentToDelete = (insight) => (dispatch) => {
  dispatch({
    type: INSIGHT_TO_DELETE,
    payload: insight,
  });
};

export const deleteContent = (id) => (dispatch) => {
  return deleteVideoInsight(id).then(() =>
    dispatch({
      type: DELETE_INSIGHT,
      payload: id,
    }),
  );
};

export const filteredInsights = (insight) => (dispatch) => {
  dispatch({
    type: FILTERED_INSIGHTS,
    payload: insight,
  });
};

export const insightSearchValues = (values) => (dispatch) => {
  dispatch({
    type: INSIGHT_SEARCH_VALUES,
    payload: values,
  });
};
export const restoreSearch = () => (dispatch) => {
  dispatch({
    type: RESTORE_SEARCH,
  });
};

export const createNewDigest = (data) => (dispatch) => {
  return createDigest(data)
    .then((response) =>
      dispatch({
        type: ADD_DIGEST,
        payload: response,
      }),
    )
    .catch((error) => {
      throw new Error(error?.response?.data?.message);
    });
};

export const getDigestData = () => (dispatch) => {
  return getDigests()
    .then((response) =>
      dispatch({
        type: GET_DIGESTS,
        payload: response,
      }),
    )
    .catch((error) => {
      throw new Error(error?.response?.data?.message);
    });
};

export const createDiffusionList = (data) => (dispatch) => {
  return createNewDiffusionList(data)
    .then((response) =>
      dispatch({
        type: NEW_DIFFUSION_LIST,
        payload: response,
      }),
    )
    .catch((error) => {
      throw new Error(error?.response?.data?.message);
    });
};

export const getDiffusionList = (data) => (dispatch) => {
  return getDiffusionLists(data)
    .then((response) =>
      dispatch({
        type: GET_DIFFUSION_LIST,
        payload: response,
      }),
    )
    .catch((error) => {
      throw new Error(error?.response?.data?.message);
    });
};

export const addFavorite = (values) => (dispatch) => {
  dispatch({
    type: ADD_FAVORITE,
    payload: values,
  });
};

export const setPopulatedContent = (content) => (dispatch) => {
  dispatch({
    type: POPULATE_CONTENT,
    payload: content,
  });
};

export const removePopulatedData = (content) => (dispatch) => {
  dispatch({
    type: REMOVE_POPULATE_CONTENT,
    payload: content,
  });
};

export const removeDigest = (id) => (dispatch) => {
  return deleteDigest(id).then(() =>
    dispatch({
      type: DELETE_DIGEST,
      payload: id,
    }),
  );
};

export const removeSection = (id) => (dispatch) => {
  return deleteSection(id).then(() =>
    dispatch({
      type: DELETE_SECTION,
      payload: id,
    }),
  );
};

export const sectionToBeDeleted = (section) => (dispatch) => {
  dispatch({
    type: SECTION_TO_DELETE,
    payload: section,
  });
};

export const resetDigestData = (section) => (dispatch) => {
  dispatch({
    type: RESET_DIGEST,
    payload: section,
  });
};
