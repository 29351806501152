import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import { FilterLessons } from '../../../components';
import { hideFilterModal, getLessonFilterKeyword } from '../../../actions';
import getCountTargets from '../../../selectors/targets/getCountTargets.selector';
import icons from '../../../assets/img/icons.svg';
import { FILTER_LESSONS, SORT_DATA, ENTERKEY, ESCAPEKEY, ALL } from '../../../constant';

// container for filtering targets options
const LessonFilter = (props) => {
  const [keyword, setKeyword] = useState('');
  const node = useRef({});

  // close filter dialog if click outside of dialog
  const handleOutsideClick = (e) => {
    if (!node?.current?.contains(e.target)) {
      hideDialog();
    }
  };

  const hideDialog = () => {
    document.removeEventListener('click', handleOutsideClick, true);
    window.cordova && window.Keyboard && window.Keyboard.hide();
    props.hideDialog();
  };

  // Search targets for the input provided
  const searchTargets = (e) => {
    const keyword = e.target.value;
    props.searchData(keyword);
    if (e.keyCode === ESCAPEKEY || e.keyCode === ENTERKEY) {
      props.filterLessons(ALL);
      hideDialog();
    }
  };

  /**
   * update the keyword in state input
   */
  const updateSearchedKeyword = (e) => {
    const keyword = e.target.value;
    setKeyword(keyword);
  };

  //Filter targets for option selected
  const filterLessons = (e) => {
    props.filterLessons(e);
    // reset the search if click on all target
    if (e.filterBy === ALL && keyword) {
      const value = { target: { value: '' } };
      updateSearchedKeyword(value); // reset the state
      searchTargets(value); // update the search
    }
    document.removeEventListener('click', handleOutsideClick, true);
  };

  const sortData = (e) => {
    document.removeEventListener('click', handleOutsideClick, true);
    props.sortData(e);
  };

  // called when any props has changed
  useEffect(() => {
    if (props.filterModalProps.open) {
      document.addEventListener('click', handleOutsideClick, true);
    }
    return () => {
      if (props.filterModalProps.open !== null) {
        document.removeEventListener('click', handleOutsideClick, true);
      }
    };
  });

  // Render function to render html
  return (
    <div className={`dialog ${props.filterModalProps.open ? 'active' : ''} filter-dialog`} id="filter">
      <div className="box">
        <div className="close-dialog" onClick={hideDialog}>
          <svg className="icon nav-icon">
            <use xlinkHref={`${icons}#action-close`} />
          </svg>
        </div>
        <div className="inner" ref={node}>
          <FilterLessons
            // filter
            filterBy={props.filterModalProps.filterBy}
            standing={props.standing}
            value={props.value}
            type={props.type}
            filterTarget={(e) => filterLessons(e)}
            // sort
            sortBy={props.filterModalProps.sortBy}
            sortData={sortData}
            // search
            keyword={keyword}
            updateSearchedKeyword={updateSearchedKeyword}
            searchData={searchTargets}
            lessons={props.data}
          />
        </div>
      </div>
    </div>
  );
};

// Map redux state to props
const mapStateToProps = (state, ownProps) => {
  return {
    ...state.lessonsFilter,
    ...state.sortTarget,
    ...getCountTargets(state, ownProps.isPeopleTarget, ownProps.status),
    ...state.lessons,
  };
};

// Map redux dispatch to props
const mapDispatchToProps = (dispatch) => ({
  sortData: (sortBy) => {
    dispatch({
      type: SORT_DATA,
      sortBy,
    });
  },
  filterLessons: (filterBy) => {
    if (!filterBy) filterBy = 'all';
    dispatch({
      type: FILTER_LESSONS,
      filterModalProps: { filterBy },
    });
  },
  hideDialog: () => {
    dispatch(hideFilterModal());
  },
  searchData: (keyword) => {
    dispatch(getLessonFilterKeyword(keyword));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LessonFilter);
