import { useAuth0 } from '@auth0/auth0-react';
import { redirect_uri } from '../../components/providers/Auth0Provider';
import { useCallback } from 'react';

function openInBrowser(url) {
  if (!window.cordova) {
    window.open(url, '_system');
    return;
  }
  window?.SafariViewController?.isAvailable(function (available) {
    if (available) {
      window.SafariViewController.show(
        {
          url: url,
        },
        function (_result) {},
        function (msg) {
          console.log('KO: ' + JSON.stringify(msg));
        },
      );
    } else {
      window.open(url, '_system');
    }
  });
}

async function openUrl(url) {
  try {
    openInBrowser(url);
  } catch (err) {
    console.log(err);
    window.SafariViewController.hide();
  }
}

export const useLoginWithRedirect = () => {
  const { loginWithRedirect } = useAuth0();

  console.log('auth0', window.location.origin, window.SafariViewController);
  return useCallback(
    async () =>
      await loginWithRedirect({
        connection: 'Jenner',
        prompt: 'login',
        openUrl,
      }),
    [loginWithRedirect],
  );
};

export const useLogout = () => {
  const { logout } = useAuth0();

  const doLogout = useCallback(async () => {
    await logout({
      logoutParams: {
        returnTo: redirect_uri,
      },
      openUrl,
    });
  }, [logout]);

  return doLogout;
};
