import { invoke } from './apiRequest';
import { toastr } from 'react-redux-toastr';

export function downloadContent(url, name) {
  toastr.success(name, {
    timeOut: 0,
    position: 'bottom-right',
  });
  invoke({ url: url, type: 'GET' })
    .then((data) => {
      toastr.removeByType('success');
      if (data.error) {
        toastr.error(data.error, {
          position: 'top-center',
        });
      } else {
        let fileType = data.type == 'pdf' ? 'application/pdf' : data.type == 'csv' ? 'application/vnd.ms-excel' : '';

        let DataBlob = b64toBlob(data.file, fileType);
        const url2 = URL.createObjectURL(DataBlob);

        if (window.cordova) {
          savebase64AsPDF(
            window.cordova.file.documentsDirectory
              ? window.cordova.file.documentsDirectory
              : window.cordova.file.externalDataDirectory,
            data.name,
            data.file,
            fileType,
            DataBlob,
          );
        } else {
          if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
            window.open(url2, '_blank');
          } else {
            let a = document.createElement('a');
            a.setAttribute('href', url2);
            a.setAttribute('download', data.name);
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        }
      }
    })
    .catch((error) => {
      toastr.removeByType('success');
      toastr.error('Whoops! Something went wrong generating your pdf', {
        position: 'top-center',
      });
      console.log(error);
    });
}

function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;
  var byteCharacters = atob(b64Data);
  var byteArrays = [];
  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

function savebase64AsPDF(folderpath, filename, content, contentType, DataBlob) {
  window.resolveLocalFileSystemURL(folderpath, function (dir) {
    dir.getFile(filename, { create: true }, function (file) {
      console.log('File created succesfully.');
      file.createWriter(
        function (fileWriter) {
          fileWriter.write(DataBlob);
          var finalPath = folderpath + filename;

          window.cordova.plugins.fileOpener2.open(finalPath, contentType);
        },
        function () {
          toastr.error('Whoops! Something went wrong generating your pdf', {
            position: 'top-center',
          });
        },
      );
    });
  });
}
