import {
  API_URLS,
  API_METHODS,
  SHOW_MODAL,
  HIDE_MODAL,
  CREATE_TARGET_REQUEST,
  CREATE_TARGET_COMMIT,
  CREATE_TARGET_ROLLBACK,
  ADD_TARGET_NOTE,
} from '../../constant';
import { initialize } from 'redux-form';
import { getUserId, convertDueDateToAPIFormat, parseMoney } from '../../helpers';
import { addTargetNoteData, setAttention, getUsersHUD } from '../../actions';
import { createPeopleTarget } from '../viewer/people.action';

/**
 * Action to create target
 * @param  {payload} payload of create edit target
 */
export const createATargetWithId = (payload, tempId, onComplete, token) => ({
  type: CREATE_TARGET_REQUEST,
  payload: { ...payload, id: tempId },
  meta: {
    offline: {
      // the network action to execute:
      effect: { apiInfo: { url: API_URLS.TILES, type: API_METHODS.POST, token }, data: payload },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_TARGET_COMMIT,
        meta: {
          tempId,
          onComplete,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: { type: CREATE_TARGET_ROLLBACK, meta: { tempId } },
    },
  },
});

export const createATarget = (payload, isPeopleTarget, userId, range) => async (dispatch, getState) => {
  const tempId = Date.now().toString();
  const token = getState()?.session?.token;
  const dueDate = convertDueDateToAPIFormat(payload.taskdue);
  payload.due = dueDate;
  payload.description = payload.taskdescription;
  payload.task = {
    due: dueDate,
    description: payload.taskdescription,
    help: payload.taskhelp ? payload.taskhelp : null,
    user_id: getUserId(),
  };
  payload.company = payload.company ? payload.company : '';
  payload.projected_revenue = parseMoney(payload.projected_revenue);

  payload.contact = payload.contact ? payload.contact : '';
  delete payload.taskdescription;
  delete payload.taskdue;

  if (isPeopleTarget) createPeopleTarget({ payload, userId, range, dispatch });
  else {
    if (payload.note) dispatch(addTargetNoteData({ targetId: tempId, note: payload.note, token }));
    dispatch(
      createATargetWithId(
        payload,
        tempId,
        (id) => {
          try {
            if (payload.note) {
              dispatch({
                type: ADD_TARGET_NOTE,
                payload: {
                  id: new Date().getTime(),
                  user_id: userId,
                  target_id: id,
                  note: payload.note,
                  created: new Date().toISOString().replace('T', ' ').substring(0, 19),
                },
              });
              dispatch(addTargetNoteData({ targetId: id, note: payload.note, token, userId }));
            }
          } catch (error) {
            console.log(error);
          }
        },
        token,
      ),
    );
  }

  dispatch(setAttention({ id: tempId }));
  dispatch(initialize('createEditTarget'));
  dispatch(getUsersHUD(userId));
  dispatch({
    type: HIDE_MODAL,
  });
};

export const showModal =
  ({ modalProps }) =>
  (dispatch) => {
    dispatch({
      type: SHOW_MODAL,
      modalProps,
    });
  };

export const hideModal = () => (dispatch) => {
  dispatch({
    type: HIDE_MODAL,
  });
};
