import React from 'react';

import icons from '../../assets/img/icons.svg';

const ColleagueList = ({ list, type }) => (
  <ul className="colleague-list just-info">
    {list.map((item, i) => {
      // if there's a "role" key, we're in the context of sharing
      const isSharing = 'role' in item ? true : false;

      const isInitiator = item.role === 'initiator' || !item.role;

      let title =
        isSharing && isInitiator ? 'This User initiated this Target.' : 'This User is collaborating on this Target.';

      if (type === 'overlaps') title = '';

      return (
        <li key={i}>
          <dl className={isSharing && isInitiator ? 'handle person initiator' : 'handle person'} title={title}>
            <dt className="avatar">
              {item.first_name.charAt(0).toUpperCase()}
              {item.last_name.charAt(0).toUpperCase()}
            </dt>
            <dd className="name">
              {item.first_name} {item.last_name}{' '}
              {isSharing && isInitiator ? (
                <svg className="icon poptip" data-tip="This User initiated this Target." data-for="tip">
                  <use xlinkHref={`${icons}#info`} />
                </svg>
              ) : (
                ''
              )}
            </dd>
          </dl>
        </li>
      );
    })}
  </ul>
);

export default ColleagueList;
