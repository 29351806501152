import {
  LOAD_USERS,
  SHARE_TARGET_REQUEST,
  SHARE_TARGET_COMMIT,
  SHARE_TARGET_ROLLBACK,
  REJECT_SHARE_TARGET_REQUEST,
  REJECT_SHARE_TARGET_COMMIT,
  REJECT_SHARE_TARGET_ROLLBACK,
  REVOKE_SHARE_TARGET_REQUEST,
  REVOKE_SHARE_TARGET_COMMIT,
  REVOKE_SHARE_TARGET_ROLLBACK,
  ACCEPT_SHARE_TARGET_REQUEST,
  ACCEPT_SHARE_TARGET_COMMIT,
  ACCEPT_SHARE_TARGET_ROLLBACK,
  API_URLS,
  API_METHODS,
} from '../../constant';
import { getEmailID } from '../../helpers/sessionManager';
import { getUsers } from '../../services/targets/dashboard.service';
import { insertDocs } from '../../helpers/dbStorage';
import { store, getUserId } from '../../helpers';
import {
  rejectPeopleSharedTarget,
  sharePeopleTarget,
  revokePeopleTarget,
  acceptPeopleSharedTarget,
} from '../viewer/people.action';

export const getAllUsers = (fromLogin, uid, loginRes) => (dispatch) => {
  return getUsers(uid).then((response) => {
    let data = response;
    if (!data.docs) {
      data = data.map((item) => {
        return {
          ...item,
          user: true,
          fullname: `${item.first_name} ${item.last_name}`,
        };
      });
      insertDocs(data);
    } else {
      data = response.docs;
    }
    if (!fromLogin) {
      const email = getEmailID(loginRes);
      const i = data.findIndex((item) => item.email === email);
      data.splice(i, 1);
      dispatch({
        type: LOAD_USERS,
        payload: data,
      });
    }
  });
};
export const shareTarget = (payload, shareWith, uid) => (dispatch) => {
  if (uid) {
    return dispatch(sharePeopleTarget(payload, shareWith, uid));
  }
  return dispatch({
    type: SHARE_TARGET_REQUEST,
    payload: { ...payload, shareWith },
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          apiInfo: {
            url: `${API_URLS.TILES}/${payload.id}${API_URLS.SHAREWITH}${shareWith.id}`,
            type: API_METHODS.POST,
          },
          data: payload,
        },
        //action to dispatch when effect succeeds:
        commit: { type: SHARE_TARGET_COMMIT, meta: { shareWith } },
        // action to dispatch if network action fails permanently:
        rollback: { type: SHARE_TARGET_ROLLBACK },
      },
    },
  });
};
export const revokeSharedTarget = (shareobj, tile, uid) => (dispatch) => {
  if (uid) {
    return dispatch(revokePeopleTarget(shareobj, tile, uid));
  }
  let req = REVOKE_SHARE_TARGET_REQUEST;
  if (shareobj.user_id === getUserId()) {
    req = REJECT_SHARE_TARGET_REQUEST;
  }
  return dispatch({
    type: req,
    payload: { shareobj, tile },
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          apiInfo: {
            url: `${API_URLS.SHARE}/${shareobj.share_id}${API_URLS.REVOKE}`,
            type: API_METHODS.POST,
          },
          data: shareobj,
        },
        //action to dispatch when effect succeeds:
        commit: { type: REVOKE_SHARE_TARGET_COMMIT, meta: { shareobj } },
        // action to dispatch if network action fails permanently:
        rollback: { type: REVOKE_SHARE_TARGET_ROLLBACK },
      },
    },
  });
};
export const rejectSharedTarget = (shareobj, tileId, uid) => (dispatch) => {
  if (uid) {
    return dispatch(rejectPeopleSharedTarget({ ...shareobj, tileId }, uid));
  }
  return dispatch({
    type: REJECT_SHARE_TARGET_REQUEST,
    payload: { ...shareobj, tileId },
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          apiInfo: {
            url: `${API_URLS.SHARE}/${shareobj.share_id}${API_URLS.REJECT}`,
            type: API_METHODS.POST,
          },
          data: shareobj,
        },
        //action to dispatch when effect succeeds:
        commit: { type: REJECT_SHARE_TARGET_COMMIT, meta: { tileId, user_id: shareobj.user_id } },
        // action to dispatch if network action fails permanently:
        rollback: { type: REJECT_SHARE_TARGET_ROLLBACK, meta: { tileId, user_id: shareobj.user_id } },
      },
    },
  });
};
export const acceptSharedTarget = (shareobj, tileId, uid) => (dispatch) => {
  if (uid) {
    return dispatch(acceptPeopleSharedTarget(shareobj, uid));
  }
  return dispatch({
    type: ACCEPT_SHARE_TARGET_REQUEST,
    payload: { ...shareobj, tileId },
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          apiInfo: {
            url: `${API_URLS.SHARE}/${shareobj.share_id}${API_URLS.ACCEPT}`,
            type: API_METHODS.POST,
          },
          data: shareobj,
        },
        //action to dispatch when effect succeeds:
        commit: { type: ACCEPT_SHARE_TARGET_COMMIT, meta: { tileId, user_id: shareobj.user_id } },
        // action to dispatch if network action fails permanently:
        rollback: { type: ACCEPT_SHARE_TARGET_ROLLBACK, meta: { tileId, user_id: shareobj.user_id } },
      },
    },
  });
};

export const deleteShareRequest = () => () => {
  console.log(store.getState().offline.outbox);
};
