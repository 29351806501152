import React from 'react';
import '../styles.css';

const RecipientsModal = ({ distributionData }) => {
  return (
    <ul>
      {distributionData?.map(({ user_name, last_name, user_id }) => (
        <li className="list-item" key={user_id}>
          <div className="recipient-avatar">
            {user_name[0]}
            {last_name[0]}
          </div>
          <div>
            {user_name} {last_name}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default RecipientsModal;
