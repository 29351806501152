/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import icons from '../../../assets/img/icons.svg';
import { required } from '../../../helpers';

const renderFieldWithActionSheet = ({ field, form: { touched, errors }, ...props }) => {
  const { active, type, toggleActionSheet, customclass, autoFocus, readOnly, icon, onBlur, onFocus, placeholderfloat } =
    props;
  const openActionSheet = (e) => {
    e.preventDefault();
    toggleActionSheet(field.name);
  };
  const hasValue = field.value && field.value.trim() && !active;
  return (
    <div
      className={`field-line ${customclass} ${active ? 'focused' : ''} ${
        errors[field.name] && touched[field.name] ? 'error ' : ''
      }`}
    >
      <label>{hasValue ? '' : placeholderfloat}</label>
      <input
        {...field}
        type={type}
        readOnly={readOnly}
        maxLength={100}
        autoComplete="off"
        onBlur={onBlur}
        onFocus={onFocus}
        onClick={(e) => {
          readOnly && openActionSheet(e);
        }}
        autoFocus={autoFocus}
      />
      <button
        className="action-sheet-btn"
        onClick={(e) => {
          openActionSheet(e);
        }}
        type="button"
      >
        <svg className="icon nav-icon">
          <use xlinkHref={`${icons}#${icon}`} />
        </svg>
        <span className="caret" />
      </button>
    </div>
  );
};

const taskSchema = Yup.object().shape({
  lostReason: Yup.string(),
});

const MarkAsLostForm = (props) => {
  const toggleActionSheet = (type) => {
    props.toggleActionSheet({ type, open: true });
  };

  const [focused, focusEl] = useState({});

  return (
    <div className={`followup lost ${props.active && 'active'}`}>
      {props.active && (
        <Formik
          initialValues={{ lostReason: '' }}
          onSubmit={(values, actions) => {
            props.lostTarget(values.lostReason);
            actions.setSubmitting(false);
            actions.setValues({
              lostReason: '',
            });
          }}
          validationSchema={taskSchema}
          render={({ handleSubmit, setValues, setFieldValue }) => {
            props.bindSetValue(setFieldValue);
            props.bindresetForm(setValues);
            return (
              <form onSubmit={handleSubmit}>
                <fieldset className="step create edit complete-step">
                  <p>Why didn't you win the target?</p>
                  <Field
                    component={renderFieldWithActionSheet}
                    type="text"
                    placeholderfloat="Describe your reasons for losing this target"
                    customclass="decorated"
                    name="lostReason"
                    label="Lost Reason"
                    toggleActionSheet={toggleActionSheet}
                    icon="action-suggest"
                    validate={[required]}
                    onFocus={() => focusEl({ lostReason: true })}
                    onBlur={() => focusEl({ lostReason: false })}
                    active={!!focused.lostReason}
                    autoFocus={false}
                  />
                  <button className="action next" type="submit">
                    <span>Submit</span>
                  </button>
                </fieldset>
              </form>
            );
          }}
        />
      )}
    </div>
  );
};

export default MarkAsLostForm;
