import { calculateBucketDays, calculateBucket, getNextWeekMondayDate, parseMoney } from '../../helpers';
import {
  API_PARAMS,
  filterLabel,
  taskType,
  reactive,
  ACCEPT_SHARE_TARGET_REQUEST,
  defaultTaskDescription,
} from '../../constant';
import { deleteFromlocal, saveToLocaldb } from '../../services/targets/dashboard.service';
export const makePayloadForCreation = (payload) => {
  const createdTarget = payload;
  createdTarget.status = API_PARAMS.ACTIVE;
  createdTarget.typeterm = filterLabel.type[createdTarget.type];
  createdTarget.valueterm = filterLabel.value[createdTarget.value];
  createdTarget.bucketdays = calculateBucketDays(createdTarget.due);
  createdTarget.bucket = calculateBucket(createdTarget.bucketdays);
  createdTarget.projected_revenue = parseMoney(createdTarget.projected_revenue);
  createdTarget.tasks = [
    {
      ...createdTarget.task,
      completed: null,
      done: taskType.incomplete,
      first_name: createdTarget.first_name,
      last_name: createdTarget.last_name,
    },
  ];
  delete createdTarget.task;
  createdTarget.collaborators = [];
  createdTarget.overlaps = [];
  createdTarget.isTemp = true;
  return createdTarget;
};
export const makePayloadForStatusChange = (payload, isOnline = false) => {
  const target = payload;
  if (payload.movingTo === API_PARAMS.ACTIVE && target.status === API_PARAMS.COMPLETED) {
    target.phase = reactive;
    target.due = getNextWeekMondayDate();
    target.bucketdays = calculateBucketDays(target.due);
    target.bucket = calculateBucket(target.bucketdays);
  }
  if (payload.movingTo === API_PARAMS.COMPLETED) {
    target.tasks.forEach((task) => {
      task.done = taskType.done;
      task.completed = new Date();
    });
  }
  target.status = payload.movingTo;
  target.modified = new Date();
  !isOnline && saveToLocaldb(target, target.id);
  return target;
};
export const makePayloadForEditTarget = (payload) => {
  const editedTarget = payload;
  editedTarget.typeterm = filterLabel.type[editedTarget.type];
  editedTarget.valueterm = filterLabel.value[editedTarget.value];
  editedTarget.revenue = parseMoney(editedTarget.revenue);
  editedTarget.projected_revenue = parseMoney(editedTarget.projected_revenue);
  editedTarget.bucketdays = calculateBucketDays(editedTarget.due);
  editedTarget.bucket = calculateBucket(editedTarget.bucketdays);
  const tasks = editedTarget.tasks.map((item) => {
    if (item.id === editedTarget.task.id) {
      return { ...item, ...editedTarget.task };
    }
    return item;
  });

  editedTarget.tasks = tasks;
  delete editedTarget.task;
  return editedTarget;
};
/***
 * make payload to show in offline
 */
export const makePayloadForAddTask = (task, targets) => {
  const index = targets.findIndex((item) => item.id === task.tile_id);
  const target = targets[index];
  target.due = task.due;
  target.bucketdays = calculateBucketDays(target.due);
  target.bucket = calculateBucket(target.bucketdays);
  if (task.accepted) {
    target.shared = task.accepted;
  }
  task.done = taskType.incomplete;
  const i = target.tasks.findIndex((item) => {
    return item.done === taskType.incomplete && item.user_id === task.user_id;
  });
  if (i > -1) {
    target.tasks[i].done = taskType.done;
    target.tasks[i].completed = new Date();
  }
  target.tasks.push(task);
  target.tasks.sort((item) => item.id);
  return target;
};
export const makePayloadForShareTarget = (payload) => {
  const collaborator = { ...payload.shareWith };
  const ifExistIndex = payload.collaborators.findIndex((item) => item.user_id === collaborator.id);
  collaborator.responded = null;
  collaborator.response = 'pending';
  collaborator.share_id = Date.now().toString();
  collaborator.user_id = collaborator.id;
  delete collaborator.id;
  delete collaborator._id;
  delete collaborator._rev;
  delete collaborator.user;
  if (payload.collaborators.length) {
    if (ifExistIndex === -1) {
      payload.collaborators.push(collaborator);
    } else {
      collaborator.share_id = payload.collaborators[ifExistIndex].share_id;
      collaborator.from_id = payload.collaborators[ifExistIndex].from_id;
      payload.collaborators[ifExistIndex] = collaborator;
    }
  } else {
    const owner = {
      share_id: 0,
      owner_id: collaborator.from_id,
      first_name: collaborator.from_first_name,
      last_name: collaborator.from_last_name,
      response: 'accepted',
      role: 'initiator',
    };
    payload.collaborators = [{ ...collaborator }, { ...owner }];
  }
  return payload;
};

export const makePayloadForShareTargetRevoke = (shareObj, type) => {
  const collaborator = shareObj;
  collaborator.responded = new Date();
  collaborator.response = type;
  return collaborator;
};
export const updateTargetAfterAccept = (shareObj, targets, type, actionType) => {
  const collaborator = makePayloadForShareTargetRevoke(shareObj, type);
  const target = targets.find((item) => item.id === shareObj.tileId);
  target.shared = type;
  const i = target.collaborators.findIndex((coll) => coll.share_id === collaborator.share_id);

  target.collaborators[i] = collaborator;
  if (actionType === ACCEPT_SHARE_TARGET_REQUEST) {
    // make A default task while accepting the request
    const task = {
      description: defaultTaskDescription,
      due: getNextWeekMondayDate(),
      help: null,
      completed: null,
      done: taskType.incomplete,
      id: Date.now(),
      first_name: shareObj.first_name,
      last_name: shareObj.first_name,
      user_id: shareObj.user_id,
    };
    target.due = task.due;
    target.bucketdays = calculateBucketDays(target.due);
    target.bucket = calculateBucket(target.bucketdays);
    target.tasks.push(task);
  }

  saveToLocaldb(target, target.id);
  return target;
};
export const shareTargetRejection = (shareObj, targets) => {
  deleteFromlocal(shareObj.tileId);
  const index = targets.findIndex((item) => item.id === shareObj.tileId);
  targets.splice(index, 1);
  return targets;
};

export const updateTaskofATile = (tile, localId, task) => {
  task.done = taskType.incomplete;
  tile.tasks.forEach((item, index) => {
    if (item.id === localId) {
      task.first_name = tile.tasks[index].first_name;
      task.last_name = tile.tasks[index].last_name;
      tile.tasks[index] = task;
    }
  });
  saveToLocaldb(tile, tile.id);
};
